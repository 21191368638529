<div [className]="!display?'d-none':''" class="w-100">
  <router-outlet>
  <app-spinner></app-spinner>
</router-outlet>
</div>
<div bsModal #childModal="bs-modal" class="modal fade" #staticModal="bs-modal" [config]="{backdrop: 'static'}" id="OpenModalBtnBox" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-child-name" class="modal-title pull-left">Network Alert</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal();logout();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        No Internet Connection!
      </div>
    </div>
  </div>
</div>