export const customEmojisNHL: any = [
    // {
    //     name: 'Hockey ground',
    //     shortNames: ['hockey-ground'],
    //     custom: true,
    //     text: '(hockey-ground)',
    //     emoticons: [],
    //     keywords: ['hockey ground', 'arena'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665743782-nhl-ground.png',
    // },
    // {
    //     name: 'Hockey player',
    //     shortNames: ['hockey-player'],
    //     custom: true,
    //     text: '(hockey-player)',
    //     emoticons: [],
    //     keywords: ['hockey player', 'hockey', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665743092-nhl-player.png',
    // },
    // {
    //     name: 'Player',
    //     shortNames: ['player'],
    //     custom: true,
    //     text: '(player)',
    //     emoticons: [],
    //     keywords: ['player', 'NHL players'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665744095-nhl-ice-player.png',
    // },
    // {
    //     name: 'Ice hockey',
    //     shortNames: ['ice-hockey'],
    //     custom: true,
    //     text: '(ice-hockey)',
    //     emoticons: [],
    //     keywords: ['ice hockey', 'hockey league', 'ice hockey player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665744381-nhl-with-bat-ice-hockey.png',
    // },
    // {
    //     name: 'Hockey mask',
    //     shortNames: ['hockey-mask'],
    //     custom: true,
    //     text: '(hockey-mask)',
    //     emoticons: [],
    //     keywords: ['hockey mask', 'mask'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665744814-hockey-helmet.png',
    // },
    // {
    //     name: 'Hockey player action',
    //     shortNames: ['hockey-player-action'],
    //     custom: true,
    //     text: '(hockey-player-action)',
    //     emoticons: [],
    //     keywords: ['hockey player', 'ice layers', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665746444-high-striker.png',
    // },
    // {
    //     name: 'Hockey',
    //     shortNames: ['hockey'],
    //     custom: true,
    //     text: '(hockey)',
    //     emoticons: [],
    //     keywords: ['hockey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665746713-hockey.png',
    // },
    // {
    //     name: 'Hockey dark',
    //     shortNames: ['hockey-dark'],
    //     custom: true,
    //     text: '(hockey-dark)',
    //     emoticons: [],
    //     keywords: ['field-hockey', 'field'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665746570-field-hockey.png',
    // },
    // {
    //     name: 'Field hockey',
    //     shortNames: ['field-hockey'],
    //     custom: true,
    //     text: '(field-hockey)',
    //     emoticons: [],
    //     keywords: ['field hockey', 'field'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747327-game-plan.png',
    // },
    // {
    //     name: 'Ball hockey',
    //     shortNames: ['ball-hockey'],
    //     custom: true,
    //     text: '(ball-hockey)',
    //     emoticons: [],
    //     keywords: ['ball hockey', 'ball', 'hockey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665820755-82394fieldhockeypxicon.png',
    // },
    // {
    //     name: 'Hockeydoller',
    //     shortNames: ['hockey-doller'],
    //     custom: true,
    //     text: '(hockey-doller)',
    //     emoticons: [],
    //     keywords: ['hockey-doller'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666179140-hockey---Copy---Copy.png',
    // },
    // {
    //     name: 'Medal',
    //     shortNames: ['medal'],
    //     custom: true,
    //     text: '(medal)',
    //     emoticons: [],
    //     keywords: ['medal'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180488-ice-hockey-medal.png',
    // },
    // {
    //     name: 'Cup',
    //     shortNames: ['cup'],
    //     custom: true,
    //     text: '(cup)',
    //     emoticons: [],
    //     keywords: ['cup'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180488-ice-hockey-cup.png',
    // },
    // {
    //     name: 'Icehockeyplayer',
    //     shortNames: ['ice-hockey-player'],
    //     custom: true,
    //     text: '(ice-hockey-player)',
    //     emoticons: [],
    //     keywords: ['ice-hockey-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180488-ice-hockey.png',
    // },
    // {
    //     name: 'Newplayer',
    //     shortNames: ['new-player'],
    //     custom: true,
    //     text: '(new-player)',
    //     emoticons: [],
    //     keywords: ['new-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180488-ice-hockey.png',
    // },
    // {
    //     name: 'Hockeystick',
    //     shortNames: ['hockey-stick'],
    //     custom: true,
    //     text: '(hockey-stick)',
    //     emoticons: [],
    //     keywords: ['hockey-stick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180488-hockey-stick.png',
    // },
    // {
    //     name: 'Hockeyplayercolor',
    //     shortNames: ['hockey-playercolor'],
    //     custom: true,
    //     text: '(hockey-playercolor)',
    //     emoticons: [],
    //     keywords: ['hockey-playercolor'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180488-hockey-playercolor.png',
    // },
    // {
    //     name: 'Puckfire',
    //     shortNames: ['puck-fire'],
    //     custom: true,
    //     text: '(puck-fire)',
    //     emoticons: [],
    //     keywords: ['puck-fire'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180520-puck.png',
    // },
    // {
    //     name: 'Scoreboard',
    //     shortNames: ['scoreboard'],
    //     custom: true,
    //     text: '(scoreboard)',
    //     emoticons: [],
    //     keywords: ['scoreboard'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180520-scoreboard.png',
    // },
    // {
    //     name: 'Stopwatchred',
    //     shortNames: ['stopwatch-red'],
    //     custom: true,
    //     text: '(stopwatch-red)',
    //     emoticons: [],
    //     keywords: ['stopwatch-red'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180520-stopwatchred.png',
    // },
    // {
    //     name: 'Ticket',
    //     shortNames: ['ticket'],
    //     custom: true,
    //     text: '(ticket)',
    //     emoticons: [],
    //     keywords: ['ticket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180520-ticket.png',
    // },
    // {
    //     name: 'Question',
    //     shortNames: ['question'],
    //     custom: true,
    //     text: '(question)',
    //     emoticons: [],
    //     keywords: ['question'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180520-question.png',
    // },
    // {
    //     name: 'Moneybag',
    //     shortNames: ['money-bag'],
    //     custom: true,
    //     text: '(money-bag)',
    //     emoticons: [],
    //     keywords: ['money-bag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180496-money-bag.png',
    // },
    // {
    //     name: 'Iceskate',
    //     shortNames: ['ice-skate'],
    //     custom: true,
    //     text: '(ice-skate)',
    //     emoticons: [],
    //     keywords: ['ice-skate'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180496-ice-skate.png',
    // },
    // {
    //     name: 'Puck',
    //     shortNames: ['puck'],
    //     custom: true,
    //     text: '(puck)',
    //     emoticons: [],
    //     keywords: ['puck'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180496-puck-1.png',
    // },
    // {
    //     name: 'Playerstand',
    //     shortNames: ['player-stand'],
    //     custom: true,
    //     text: '(player-stand)',
    //     emoticons: [],
    //     keywords: ['player-stand'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180496-playerstand.png',
    // },
    // {
    //     name: 'Playerrun',
    //     shortNames: ['player-run'],
    //     custom: true,
    //     text: '(player-run)',
    //     emoticons: [],
    //     keywords: ['player-run'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180496-playerrun.png',
    // },
    // {
    //     name: 'Newmedal',
    //     shortNames: ['new-medal'],
    //     custom: true,
    //     text: '(new-medal)',
    //     emoticons: [],
    //     keywords: ['new-medal'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180496-medal.png',
    // },
    // {
    //     name: 'Ladder',
    //     shortNames: ['ladder'],
    //     custom: true,
    //     text: '(ladder)',
    //     emoticons: [],
    //     keywords: ['ladder'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180496-ladder.png',
    // },
    // {
    //     name: 'Icehockeystick',
    //     shortNames: ['ice-hockey-stick'],
    //     custom: true,
    //     text: '(ice-hockey-stick)',
    //     emoticons: [],
    //     keywords: ['ice-hockey-stick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180495-ice-hockeystick.png',
    // },
    // {
    //     name: 'Playerruning',
    //     shortNames: ['player-runing'],
    //     custom: true,
    //     text: '(player-runing)',
    //     emoticons: [],
    //     keywords: ['player-runing'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180488-hockey-playerruning.png',
    // },
    // {
    //     name: 'Hockeynet',
    //     shortNames: ['hockey-net'],
    //     custom: true,
    //     text: '(hockey-net)',
    //     emoticons: [],
    //     keywords: ['hockey-net'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180464-hockey-.png',
    // },
    // {
    //     name: 'Hockeyicon',
    //     shortNames: ['hockey-icon'],
    //     custom: true,
    //     text: '(hockey-icon)',
    //     emoticons: [],
    //     keywords: ['hockey-icon'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180488-hockey-icon.png',
    // },
    // {
    //     name: 'Hockeymatch',
    //     shortNames: ['hockey-match'],
    //     custom: true,
    //     text: '(hockey-match)',
    //     emoticons: [],
    //     keywords: ['hockey-match'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180488-hockey-match.png',
    // },
    // {
    //     name: 'Menplayer',
    //     shortNames: ['men-player'],
    //     custom: true,
    //     text: '(men-player)',
    //     emoticons: [],
    //     keywords: ['men-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180488-hockey-player.png',
    // },
    // {
    //     name: 'Hockeyequipment',
    //     shortNames: ['hockey-equipment'],
    //     custom: true,
    //     text: '(hockey-equipment)',
    //     emoticons: [],
    //     keywords: ['hockey-equipment'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180464-hockey-equipment.png',
    // },
    // {
    //     name: 'Helmet',
    //     shortNames: ['helmet'],
    //     custom: true,
    //     text: '(helmet)',
    //     emoticons: [],
    //     keywords: ['helmet'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180463-hockey-2.png',
    // },
    // {
    //     name: 'Ground',
    //     shortNames: ['ground'],
    //     custom: true,
    //     text: '(ground)',
    //     emoticons: [],
    //     keywords: ['ground'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180463-hockey-ground.png',
    // },
    // {
    //     name: 'Hockeygreen',
    //     shortNames: ['hockey-green'],
    //     custom: true,
    //     text: '(hockey-green)',
    //     emoticons: [],
    //     keywords: ['hockey-green'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180463-hockey-green.png',
    // },
    // {
    //     name: 'Hockeygear',
    //     shortNames: ['hockey-gear'],
    //     custom: true,
    //     text: '(hockey-gear)',
    //     emoticons: [],
    //     keywords: ['hockey-gear'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180463-hockey-gear-64.png',
    // },
    // {
    //     name: 'Hockeycup',
    //     shortNames: ['hockey-cup'],
    //     custom: true,
    //     text: '(hockey-cup)',
    //     emoticons: [],
    //     keywords: ['hockey-cup'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180463-hockey-cup.png',
    // },
    // {
    //     name: 'Hockeycolor',
    //     shortNames: ['hockey-color'],
    //     custom: true,
    //     text: '(hockey-color)',
    //     emoticons: [],
    //     keywords: ['hockey-color'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180463-hockeycolor.png',
    // },
    // {
    //     name: 'Goldentrophy',
    //     shortNames: ['golden-trophy'],
    //     custom: true,
    //     text: '(golden-trophy)',
    //     emoticons: [],
    //     keywords: ['golden-trophy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180463-goldentrophy.png',
    // },
    // {
    //     name: 'Goalkeeper',
    //     shortNames: ['goal-keeper'],
    //     custom: true,
    //     text: '(goal-keeper)',
    //     emoticons: [],
    //     keywords: ['goal-keeper'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180454-goalkeeper.png',
    // },
    // {
    //     name: 'Goalhockey',
    //     shortNames: ['goal-hockey'],
    //     custom: true,
    //     text: '(goal-hockey)',
    //     emoticons: [],
    //     keywords: ['goal-hockey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180454-goal-hockey.png',
    // },
    // {
    //     name: 'Hockeygoal',
    //     shortNames: ['hockey-goal'],
    //     custom: true,
    //     text: '(hockey-goal)',
    //     emoticons: [],
    //     keywords: ['hockey-goal'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180454-goal.png',
    // },
    // {
    //     name: 'Foamhand',
    //     shortNames: ['foam-hand'],
    //     custom: true,
    //     text: '(foam-hand)',
    //     emoticons: [],
    //     keywords: ['foam-hand'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180454-foam-hand.png',
    // },
    // {
    //     name: 'Clipboard',
    //     shortNames: ['clipboard'],
    //     custom: true,
    //     text: '(clipboard)',
    //     emoticons: [],
    //     keywords: ['clipboard'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180454-clipboard.png',
    // },
    // {
    //     name: 'Champions',
    //     shortNames: ['champions'],
    //     custom: true,
    //     text: '(champions)',
    //     emoticons: [],
    //     keywords: ['champions'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180454-champions.png',
    // },
    // {
    //     name: 'Award',
    //     shortNames: ['award'],
    //     custom: true,
    //     text: '(award)',
    //     emoticons: [],
    //     keywords: ['award'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180454-award.png',
    // },
    // {
    //     name: 'Airhorn',
    //     shortNames: ['air-horn'],
    //     custom: true,
    //     text: '(air-horn)',
    //     emoticons: [],
    //     keywords: ['air-horn'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180454-air-horn.png',
    // },
    // {
    //     name: 'Womenhockey',
    //     shortNames: ['womenhockey'],
    //     custom: true,
    //     text: '(womenhockey)',
    //     emoticons: [],
    //     keywords: ['womenhockey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180036-womenhockey-player.png',
    // },
    // {
    //     name: 'Hockeycircle',
    //     shortNames: ['hockey-circle'],
    //     custom: true,
    //     text: '(hockey-circle)',
    //     emoticons: [],
    //     keywords: ['hockey-circle'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180036-ice-hockey-circle.png',
    // },
    // {
    //     name: 'Girl',
    //     shortNames: ['girl'],
    //     custom: true,
    //     text: '(girl)',
    //     emoticons: [],
    //     keywords: ['girl'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180036-ice-hockey.png',
    // },
    // {
    //     name: 'Readyplayer',
    //     shortNames: ['ready-player'],
    //     custom: true,
    //     text: '(ready-player)',
    //     emoticons: [],
    //     keywords: ['ready-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180035-hockey-player.png',
    // },
    // {
    //     name: 'Boyplayer',
    //     shortNames: ['boy-player'],
    //     custom: true,
    //     text: '(boy-player)',
    //     emoticons: [],
    //     keywords: ['boy-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180035-1666179140-hockey-player.png',
    // },
    // {
    //     name: 'Hockeygoalkipper',
    //     shortNames: ['hockey-goalkipper'],
    //     custom: true,
    //     text: '(hockey-goalkipper)',
    //     emoticons: [],
    //     keywords: ['hockey-goalkipper'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180035-1666179140-hockey-goal.png',
    // },
    // {
    //     name: 'Lessons',
    //     shortNames: ['lessons'],
    //     custom: true,
    //     text: '(lessons)',
    //     emoticons: [],
    //     keywords: ['lessons'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666179140-lessons.png',
    // },
    // {
    //     name: 'Hockeyarena',
    //     shortNames: ['hockey-arena'],
    //     custom: true,
    //     text: '(hockey-arena)',
    //     emoticons: [],
    //     keywords: ['hockey-arena'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666179140-hockey-arena-4.png',
    // },
    // {
    //     name: 'Live',
    //     shortNames: ['live'],
    //     custom: true,
    //     text: '(live)',
    //     emoticons: [],
    //     keywords: ['live'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666179140-hockey-5---Copy.png',
    // },
    // {
    //     name: 'Who',
    //     shortNames: ['who'],
    //     custom: true,
    //     text: '(who)',
    //     emoticons: [],
    //     keywords: ['who'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180534-who.png',
    // },
    // {
    //     name: 'Whistle',
    //     shortNames: ['whistle'],
    //     custom: true,
    //     text: '(whistle)',
    //     emoticons: [],
    //     keywords: ['whistle'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180534-whistle.png',
    // },
    // {
    //     name: 'Waterbottle',
    //     shortNames: ['water-bottle'],
    //     custom: true,
    //     text: '(water-bottle)',
    //     emoticons: [],
    //     keywords: ['water-bottle'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180534-water-bottle.png',
    // },
    // {
    //     name: 'Trophystar',
    //     shortNames: ['trophy-star'],
    //     custom: true,
    //     text: '(trophy-star)',
    //     emoticons: [],
    //     keywords: ['trophy-star'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180534-trophystar.png',
    // },
    // {
    //     name: 'Hockeytrophy',
    //     shortNames: ['hockey-trophy'],
    //     custom: true,
    //     text: '(hockey-trophy)',
    //     emoticons: [],
    //     keywords: ['hockey-trophy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180534-trophy.png',
    // },
    // {
    //     name: 'Tournament',
    //     shortNames: ['tournament'],
    //     custom: true,
    //     text: '(tournament)',
    //     emoticons: [],
    //     keywords: ['tournament'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180534-tournament.png',
    // },
    // {
    //     name: 'Stopwatch',
    //     shortNames: ['stopwatch'],
    //     custom: true,
    //     text: '(stopwatch)',
    //     emoticons: [],
    //     keywords: ['stopwatch'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180520-stopwatch.png',
    // },
    // {
    //     name: 'Gift',
    //     shortNames: ['gift'],
    //     custom: true,
    //     text: '(gift)',
    //     emoticons: [],
    //     keywords: ['gift'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180520-salary.png',
    // },
    // {
    //     name: 'Visitor',
    //     shortNames: ['visitor'],
    //     custom: true,
    //     text: '(visitor)',
    //     emoticons: [],
    //     keywords: ['visitor'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180520-sign.png',
    // },
    // {
    //     name: 'Questionmark',
    //     shortNames: ['question-mark'],
    //     custom: true,
    //     text: '(question-mark)',
    //     emoticons: [],
    //     keywords: ['question-mark'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180520-question-mark.png',
    // }
];