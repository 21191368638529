export const customEmojisAll: any = [
    // {
    //     name: 'Major',
    //     shortNames: ['major'],
    //     custom: true,
    //     text: '(major)',
    //     emoticons: [],
    //     keywords: ['major', 'league', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666085184-major.png',
    // },
    // {
    //     name: 'Mlbphotos',
    //     shortNames: ['mlb-photos'],
    //     custom: true,
    //     text: '(mlb-photos)',
    //     emoticons: [],
    //     keywords: ['mlb-photos'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666166722-29753-5-mlb-photos.png',
    // },
    // {
    //     name: 'Ball',
    //     shortNames: ['ball'],
    //     custom: true,
    //     text: '(ball)',
    //     emoticons: [],
    //     keywords: ['ball', 'gloves', 'catch', 'catching gloves'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665750402-ball.png',
    // },
    // {
    //     name: 'Stopwatch',
    //     shortNames: ['stopwatch'],
    //     custom: true,
    //     text: '(stopwatch)',
    //     emoticons: [],
    //     keywords: ['ball', 'baseball', 'stopwatch'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665750616-stopwatch.png',
    // },
    // {
    //     name: 'Baseballbat',
    //     shortNames: ['baseball-bat'],
    //     custom: true,
    //     text: '(baseball-bat)',
    //     emoticons: [],
    //     keywords: ['baseball-bat', 'baseballbat', 'bat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665750833-bat.png',
    // },
    // {
    //     name: 'Baseballplayer',
    //     shortNames: ['baseball-player'],
    //     custom: true,
    //     text: '(baseball-player)',
    //     emoticons: [],
    //     keywords: ['baseball-player', 'baseballplayer', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751033-baseball.png',
    // },
    // {
    //     name: 'Bat',
    //     shortNames: ['bat'],
    //     custom: true,
    //     text: '(bat)',
    //     emoticons: [],
    //     keywords: ['baseballbat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751267-batt.png',
    // },
    // {
    //     name: 'Baseballcatch',
    //     shortNames: ['catch'],
    //     custom: true,
    //     text: '(catch)',
    //     emoticons: [],
    //     keywords: ['catch', 'player', 'baseballplayer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751516-baseballcatch.png',
    // },
    // {
    //     name: 'Ballandbat',
    //     shortNames: ['ball-bat'],
    //     custom: true,
    //     text: '(ball-bat)',
    //     emoticons: [],
    //     keywords: ['baseball', 'base-ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751696-batball.png',
    // },
    // {
    //     name: 'Ballhit',
    //     shortNames: ['ball-hit'],
    //     custom: true,
    //     text: '(ball-hit)',
    //     emoticons: [],
    //     keywords: ['hit', 'hitball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751943-baseballhit.png',
    // },
    // {
    //     name: 'Throwingball',
    //     shortNames: ['throwing-ball'],
    //     custom: true,
    //     text: '(throwing-ball)',
    //     emoticons: [],
    //     keywords: ['throwingball', 'ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665752180-throwing.png',
    // },
    // {
    //     name: 'Competition',
    //     shortNames: ['competition'],
    //     custom: true,
    //     text: '(competition)',
    //     emoticons: [],
    //     keywords: ['competition', 'trophy', 'winner'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665753450-match.png',
    // },
    // {
    //     name: 'Batcolor',
    //     shortNames: ['batcolor'],
    //     custom: true,
    //     text: '(batcolor)',
    //     emoticons: [],
    //     keywords: ['bats', 'bat', 'match'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665753581-battball.png',
    // },
    // {
    //     name: 'Playerbat',
    //     shortNames: ['player-bat'],
    //     custom: true,
    //     text: '(player-bat)',
    //     emoticons: [],
    //     keywords: ['player-bat', 'player', 'bat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-50-1.png',
    // },
    // {
    //     name: 'Baseballicon',
    //     shortNames: ['baseball-icon'],
    //     custom: true,
    //     text: '(baseball-icon)',
    //     emoticons: [],
    //     keywords: ['baseball-icon', 'baseball', 'icon'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-50-2.png',
    // },
    // {
    //     name: 'Batball',
    //     shortNames: ['batball'],
    //     custom: true,
    //     text: '(batball)',
    //     emoticons: [],
    //     keywords: ['ball', 'batball', 'baseball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-60.png',
    // },
    // {
    //     name: 'Helmet',
    //     shortNames: ['helmet'],
    //     custom: true,
    //     text: '(helmet)',
    //     emoticons: [],
    //     keywords: ['ball', 'helmet', 'bat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-62.png',
    // },
    // {
    //     name: 'Glovesbatt',
    //     shortNames: ['gloves-batt'],
    //     custom: true,
    //     text: '(gloves-batt)',
    //     emoticons: [],
    //     keywords: ['ball', 'gloves', 'batt', 'catching gloves'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-64-1.png',
    // },
    // {
    //     name: 'Fantacy',
    //     shortNames: ['fantacy'],
    //     custom: true,
    //     text: '(fantacy)',
    //     emoticons: [],
    //     keywords: ['fantacy', 'icon'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-64-2.png',
    // },
    // {
    //     name: 'Audience',
    //     shortNames: ['audience'],
    //     custom: true,
    //     text: '(audience)',
    //     emoticons: [],
    //     keywords: ['Audience'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-64-3.png',
    // },
    // {
    //     name: 'Playerwithbatt',
    //     shortNames: ['player-with-batt'],
    //     custom: true,
    //     text: '(player-with-batt)',
    //     emoticons: [],
    //     keywords: ['player-with-batt', 'player', 'batt'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-64-4.png',
    // },
    // {
    //     name: 'Ballyellow',
    //     shortNames: ['ball-yellow'],
    //     custom: true,
    //     text: '(ball-yellow)',
    //     emoticons: [],
    //     keywords: ['ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-64-5.png',
    // },
    // {
    //     name: 'Colorbat',
    //     shortNames: ['color-bat'],
    //     custom: true,
    //     text: '(color-bat)',
    //     emoticons: [],
    //     keywords: ['colorbat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074894-icons8-baseball-64-6.png',
    // },
    // {
    //     name: 'Mobile',
    //     shortNames: ['mobile'],
    //     custom: true,
    //     text: '(mobile)',
    //     emoticons: [],
    //     keywords: ['mobile'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074894-icons8-baseball-64-7.png',
    // },
    // {
    //     name: 'Star',
    //     shortNames: ['star'],
    //     custom: true,
    //     text: '(star)',
    //     emoticons: [],
    //     keywords: ['star'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074894-icons8-baseball-64-8.png',
    // },
    // {
    //     name: 'Location',
    //     shortNames: ['location'],
    //     custom: true,
    //     text: '(location)',
    //     emoticons: [],
    //     keywords: ['location'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074894-icons8-baseball-64-9.png',
    // },
    // {
    //     name: 'Gloves',
    //     shortNames: ['gloves'],
    //     custom: true,
    //     text: '(gloves)',
    //     emoticons: [],
    //     keywords: ['gloves', 'catch', 'catching gloves'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074894-icons8-baseball-64-10.png',
    // },
    // {
    //     name: 'Crossbat',
    //     shortNames: ['crossbat'],
    //     custom: true,
    //     text: '(crossbat)',
    //     emoticons: [],
    //     keywords: ['crossbat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074894-icons8-baseball-64-11.png',
    // },
    // {
    //     name: 'Handgloves',
    //     shortNames: ['handgloves'],
    //     custom: true,
    //     text: '(handgloves)',
    //     emoticons: [],
    //     keywords: ['handgloves'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074894-icons8-baseball-78.png',
    // },
    // {
    //     name: 'Colorballbat',
    //     shortNames: ['colorballbat'],
    //     custom: true,
    //     text: '(colorballbat)',
    //     emoticons: [],
    //     keywords: ['colorballbat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074904-icons8-baseball-100-1.png',
    // },
    // {
    //     name: 'Cloudball',
    //     shortNames: ['cloudball'],
    //     custom: true,
    //     text: '(cloudball)',
    //     emoticons: [],
    //     keywords: ['ball', 'cloudball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074904-icons8-baseball-100.png',
    // },
    // {
    //     name: 'Throwball',
    //     shortNames: ['throwball'],
    //     custom: true,
    //     text: '(throwball)',
    //     emoticons: [],
    //     keywords: ['ball', 'throwball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074904-icons8-baseball-ball-64-1.png',
    // },
    // {
    //     name: 'Flyingball',
    //     shortNames: ['flyingball'],
    //     custom: true,
    //     text: '(flyingball)',
    //     emoticons: [],
    //     keywords: ['flyingball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074904-icons8-baseball-ball-64.png',
    // },
    // {
    //     name: 'Twobat',
    //     shortNames: ['twobat'],
    //     custom: true,
    //     text: '(twobat)',
    //     emoticons: [],
    //     keywords: ['ball', 'twobat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074904-icons8-baseball-bat-64-1.png',
    // },
    // {
    //     name: 'Enjoy',
    //     shortNames: ['enjoy'],
    //     custom: true,
    //     text: '(enjoy)',
    //     emoticons: [],
    //     keywords: ['enjoy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074904-icons8-baseball-bat-64.png',
    // },
    // {
    //     name: 'Cap',
    //     shortNames: ['cap'],
    //     custom: true,
    //     text: '(cap)',
    //     emoticons: [],
    //     keywords: ['cap'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074904-icons8-baseball-cap-100.png',
    // },
    // {
    //     name: 'Card',
    //     shortNames: ['card'],
    //     custom: true,
    //     text: '(card)',
    //     emoticons: [],
    //     keywords: ['card'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074904-icons8-baseball-card-64.png',
    // },
    // {
    //     name: 'Brownglove',
    //     shortNames: ['brown-glove'],
    //     custom: true,
    //     text: '(brown-glove)',
    //     emoticons: [],
    //     keywords: ['brown-glove', 'gloves', 'catching gloves'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074920-icons8-baseball-glove-64-1.png',
    // },
    // {
    //     name: 'Colorhand',
    //     shortNames: ['color-hand'],
    //     custom: true,
    //     text: '(color-hand)',
    //     emoticons: [],
    //     keywords: ['color-hand', 'gloves', 'catching gloves'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074920-icons8-baseball-glove-64.png',
    // },
    // {
    //     name: 'Playrglove',
    //     shortNames: ['playr-glove'],
    //     custom: true,
    //     text: '(playr-glove)',
    //     emoticons: [],
    //     keywords: ['playr-glove', 'gloves', 'catch', 'catching gloves'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074920-icons8-baseball-player-64-1.png',
    // },
    // {
    //     name: 'Heart',
    //     shortNames: ['heart'],
    //     custom: true,
    //     text: '(heart)',
    //     emoticons: [],
    //     keywords: ['heart'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074920-icons8-baseball-player-64-2.png',
    // },
    // {
    //     name: 'Watch',
    //     shortNames: ['watch'],
    //     custom: true,
    //     text: '(watch)',
    //     emoticons: [],
    //     keywords: ['watch'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074920-icons8-baseball-player-64-3.png',
    // },
    // {
    //     name: 'Ground',
    //     shortNames: ['ground'],
    //     custom: true,
    //     text: '(ground)',
    //     emoticons: [],
    //     keywords: ['ball', 'ground'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074921-icons8-baseball-stadium-64-2.png',
    // },
    // {
    //     name: 'Table',
    //     shortNames: ['table'],
    //     custom: true,
    //     text: '(table)',
    //     emoticons: [],
    //     keywords: ['table'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074921-icons8-baseball-stadium-64-3.png',
    // },
    // {
    //     name: 'Colorground',
    //     shortNames: ['color-ground'],
    //     custom: true,
    //     text: '(color-ground)',
    //     emoticons: [],
    //     keywords: ['color-ground', 'ground'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074921-icons8-baseball-stadium-64.png',
    // },
    // {
    //     name: 'Team',
    //     shortNames: ['team'],
    //     custom: true,
    //     text: '(team)',
    //     emoticons: [],
    //     keywords: ['team'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-baseball-team-64.png',
    // },
    // {
    //     name: 'Batting',
    //     shortNames: ['batting'],
    //     custom: true,
    //     text: '(batting)',
    //     emoticons: [],
    //     keywords: ['batting'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-batting-64.png',
    // },
    // {
    //     name: 'Pinkcap',
    //     shortNames: ['pinkcap'],
    //     custom: true,
    //     text: '(pinkcap)',
    //     emoticons: [],
    //     keywords: ['pinkcap'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-cap-48.png',
    // },
    // {
    //     name: 'Bluecap',
    //     shortNames: ['bluecap'],
    //     custom: true,
    //     text: '(bluecap)',
    //     emoticons: [],
    //     keywords: ['bluecap'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-hip-hop-music-48.png',
    // },
    // {
    //     name: 'Jacket',
    //     shortNames: ['jacket'],
    //     custom: true,
    //     text: '(jacket)',
    //     emoticons: [],
    //     keywords: ['jacket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-jacket-64.png',
    // },
    // {
    //     name: 'Minor',
    //     shortNames: ['minor'],
    //     custom: true,
    //     text: '(minor)',
    //     emoticons: [],
    //     keywords: ['minor'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-league-64.png',
    // },
    // {
    //     name: 'Live',
    //     shortNames: ['live'],
    //     custom: true,
    //     text: '(live)',
    //     emoticons: [],
    //     keywords: ['live'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-live-64.png',
    // },
    // {
    //     name: 'Management',
    //     shortNames: ['management'],
    //     custom: true,
    //     text: '(management)',
    //     emoticons: [],
    //     keywords: ['management'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-management-48-1.png',
    // },
    // {
    //     name: 'Managementteam',
    //     shortNames: ['management-team'],
    //     custom: true,
    //     text: '(management-team)',
    //     emoticons: [],
    //     keywords: ['management-team'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-management-48.png',
    // },
    // {
    //     name: 'Manager',
    //     shortNames: ['manager'],
    //     custom: true,
    //     text: '(manager)',
    //     emoticons: [],
    //     keywords: ['manager'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074941-icons8-manager-64.png',
    // },
    // {
    //     name: 'Sock',
    //     shortNames: ['sock'],
    //     custom: true,
    //     text: '(sock)',
    //     emoticons: [],
    //     keywords: ['sock'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074941-icons8-sock-64.png',
    // },
    // {
    //     name: 'Playerteam',
    //     shortNames: ['player-team'],
    //     custom: true,
    //     text: '(player-team)',
    //     emoticons: [],
    //     keywords: ['player-team'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074942-icons8-team-64-1.png',
    // },
    // {
    //     name: 'Teamblue',
    //     shortNames: ['teamblue'],
    //     custom: true,
    //     text: '(teamblue)',
    //     emoticons: [],
    //     keywords: ['teamblue'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074942-icons8-team-64.png',
    // },
    // {
    //     name: 'Bench',
    //     shortNames: ['bench'],
    //     custom: true,
    //     text: '(bench)',
    //     emoticons: [],
    //     keywords: ['bench'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074942-icons8-team-bench-64.png',
    // },
    // {
    //     name: 'Ticket',
    //     shortNames: ['ticket'],
    //     custom: true,
    //     text: '(ticket)',
    //     emoticons: [],
    //     keywords: ['ticket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074942-icons8-ticket-64.png',
    // },
    // {
    //     name: 'Matchticket',
    //     shortNames: ['matchticket'],
    //     custom: true,
    //     text: '(matchticket)',
    //     emoticons: [],
    //     keywords: ['tickets', 'ticket', 'matchticket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074942-icons8-tickets-64.png',
    // },
    // {
    //     name: 'Uniform',
    //     shortNames: ['uniform'],
    //     custom: true,
    //     text: '(uniform)',
    //     emoticons: [],
    //     keywords: ['uniform'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074942-icons8-uniform-64.png',
    // },
    // {
    //     name: 'Playing',
    //     shortNames: ['playing'],
    //     custom: true,
    //     text: '(playing)',
    //     emoticons: [],
    //     keywords: ['playing'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666081802-baseball-2.png',
    // },
    // {
    //     name: 'Trajectory',
    //     shortNames: ['trajectory'],
    //     custom: true,
    //     text: '(trajectory)',
    //     emoticons: [],
    //     keywords: ['trajectory', 'golfequipment'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665816388-trajectory-1.png',
    // },
    // {
    //     name: 'Golf stick',
    //     shortNames: ['golf-stick'],
    //     custom: true,
    //     text: '(golf-stick)',
    //     emoticons: [],
    //     keywords: ['golfstick', 'two stick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665816400-golf-stick-1.png',
    // },

    // {
    //     name: 'Golfing',
    //     shortNames: ['golfing'],
    //     custom: true,
    //     text: '(golfing)',
    //     emoticons: [],
    //     keywords: ['golfing', 'golfstick', 'golf ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665817008-golfing.png',
    // },
    // {
    //     name: 'Golffieldimage',
    //     shortNames: ['golf-field-image'],
    //     custom: true,
    //     text: '(golf-field-image)',
    //     emoticons: [],
    //     keywords: ['golf-field', 'golf distance', 'golf ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665816995-golf-field.png',
    // },
    // {
    //     name: 'Golfer',
    //     shortNames: ['golfer'],
    //     custom: true,
    //     text: '(golfer)',
    //     emoticons: [],
    //     keywords: ['golfer', 'golf ball', 'golf stick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665817016-golfer.png',
    // },
    // {
    //     name: 'Golfcart',
    //     shortNames: ['golf-cart'],
    //     custom: true,
    //     text: '(golf-cart)',
    //     emoticons: [],
    //     keywords: ['cart', 'golf-cart', 'golf equipment'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665817949-golf-cart.png',
    // },
    // {
    //     name: 'Golf-club',
    //     shortNames: ['golf-club'],
    //     custom: true,
    //     text: '(golf-club)',
    //     emoticons: [],
    //     keywords: ['golfball', 'clubhouse', 'golfclub'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022341-golf-club.png',
    // },

    // {
    //     name: 'Golf',
    //     shortNames: ['golf'],
    //     custom: true,
    //     text: '(golf)',
    //     emoticons: [],
    //     keywords: ['golf', 'golf stick', 'golf ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665818838-mini-golf.png',
    // },

    // {
    //     name: 'Birdie',
    //     shortNames: ['birdie'],
    //     custom: true,
    //     text: '(birdie)',
    //     emoticons: [],
    //     keywords: ['birdie', 'golf field', 'golf distance'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665818544-birdie.png',
    // },
    // {
    //     name: 'Golfswing',
    //     shortNames: ['swing'],
    //     custom: true,
    //     text: '(swing)',
    //     emoticons: [],
    //     keywords: ['swing', 'golfball', 'golf stick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665818981-swing.png',
    // },
    // {
    //     name: 'Caddy',
    //     shortNames: ['caddy'],
    //     custom: true,
    //     text: '(caddy)',
    //     emoticons: [],
    //     keywords: ['gilfbag', 'golfball', 'golf stick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665819170-caddy.png',
    // },
    // {
    //     name: 'Golfplayer',
    //     shortNames: ['golf-player'],
    //     custom: true,
    //     text: '(golf-player)',
    //     emoticons: [],
    //     keywords: ['golfplayer', 'golfball', 'golf stick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665819394-golf-player-2.png',
    // },
    // {
    //     name: 'Golfbirdie',
    //     shortNames: ['golf-birdie'],
    //     custom: true,
    //     text: '(golf-birdie)',
    //     emoticons: [],
    //     keywords: ['birdie', 'golfball', 'destinationflag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022285-birdie.png',
    // },
    // {
    //     name: 'Golfbook',
    //     shortNames: ['golf-book'],
    //     custom: true,
    //     text: '(golf-book)',
    //     emoticons: [],
    //     keywords: ['golfbook', 'golfball', 'flag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022286-book.png',
    // },
    // {
    //     name: 'Caddyboy',
    //     shortNames: ['caddy-boy'],
    //     custom: true,
    //     text: '(caddy-boy)',
    //     emoticons: [],
    //     keywords: ['caddyboy', 'golfball', 'golf stick', 'caddytrolly'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022286-caddy-1.png',
    // },
    // {
    //     name: 'Caddygirl',
    //     shortNames: ['caddy-girl'],
    //     custom: true,
    //     text: '(caddy-girl)',
    //     emoticons: [],
    //     keywords: ['caddygirl', 'golf stick', 'stickbag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022286-caddy.png',
    // },

    // {
    //     name: 'Championship',
    //     shortNames: ['championship'],
    //     custom: true,
    //     text: '(championship)',
    //     emoticons: [],
    //     keywords: ['championship', 'trophy', 'golf stick', 'cup'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022286-championship.png',
    // },

    // {
    //     name: 'Clothing',
    //     shortNames: ['clothing'],
    //     custom: true,
    //     text: '(clothing)',
    //     emoticons: [],
    //     keywords: ['clothing', 'girlsdress', 'uniform'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022286-clothing.png',
    // },
    // {
    //     name: 'Coach',
    //     shortNames: ['coach'],
    //     custom: true,
    //     text: '(coach)',
    //     emoticons: [],
    //     keywords: ['coach', 'golf stick', 'golfframe'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022286-coach.png',
    // },
    // {
    //     name: 'Discgolf',
    //     shortNames: ['disc-golf'],
    //     custom: true,
    //     text: '(disc-golf)',
    //     emoticons: [],
    //     keywords: ['disc-golf', 'golfball', 'disc'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022286-disc-golf.png',
    // },

    // {
    //     name: 'Golfgame',
    //     shortNames: ['golf-game'],
    //     custom: true,
    //     text: '(golgame)',
    //     emoticons: [],
    //     keywords: ['golfgame', 'golfball', 'golfstick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022315-golf-1.png',
    // },
    // {
    //     name: 'Bags',
    //     shortNames: ['bags'],
    //     custom: true,
    //     text: '(bags)',
    //     emoticons: [],
    //     keywords: ['bags', 'golf stick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022315-golf-2.png',
    // },
    // {
    //     name: 'Golferwomen',
    //     shortNames: ['golfer-women'],
    //     custom: true,
    //     text: '(golfer-women)',
    //     emoticons: [],
    //     keywords: ['golfgame', 'golfball', 'golfer', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022315-golf-4.png',
    // },
    // {
    //     name: 'Golfshot',
    //     shortNames: ['golf-shot'],
    //     custom: true,
    //     text: '(golf-shot)',
    //     emoticons: [],
    //     keywords: ['golfshot', 'golfball', 'golf stick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022315-golf-3.png',
    // },
    // {
    //     name: 'Golfequpment',
    //     shortNames: ['golf-equpment'],
    //     custom: true,
    //     text: '(golf-equpment)',
    //     emoticons: [],
    //     keywords: ['golfequipment', 'golfball', 'golfstick', 'golftrolly'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022315-golf-5.png',
    // },
    // {
    //     name: 'Golfshowinginmobile',
    //     shortNames: ['golf-showing-in-mobile'],
    //     custom: true,
    //     text: '(golf-showing-in-mobile)',
    //     emoticons: [],
    //     keywords: ['golfshowinginmobile', 'golfball', 'mobile', 'showing'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022315-golf-6.png',
    // },
    // {
    //     name: 'Golfdestination',
    //     shortNames: ['golf-destination'],
    //     custom: true,
    //     text: '(golf-destination)',
    //     emoticons: [],
    //     keywords: ['golf destination', 'golfball', 'flag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022315-golf.png',
    // },
    // {
    //     name: 'Golflargercart',
    //     shortNames: ['golf-largercart'],
    //     custom: true,
    //     text: '(golf-largercart)',
    //     emoticons: [],
    //     keywords: ['golfequipment', 'olflargercart', 'cart'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022341-golf-cart.png',
    // },
    // {
    //     name: 'Colourgolfball',
    //     shortNames: ['colour-golf-ball'],
    //     custom: true,
    //     text: ('colour-golf-ball'),
    //     emoticons: [],
    //     keywords: ['golfequipment', 'golfball', 'colourgolfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022341-golf-ball.png',
    // },
    // {
    //     name: 'Golfcarrybag',
    //     shortNames: ['golf-carry-bag'],
    //     custom: true,
    //     text: '(golf-carry-bag)',
    //     emoticons: [],
    //     keywords: ['golfequipment', 'golfball', 'golfstick', 'golfcarrybag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022341-golf-bag.png',
    // },
    // {
    //     name: 'Golfclubicon',
    //     shortNames: ['golf-club-icon'],
    //     custom: true,
    //     text: '(golf-club-icon)',
    //     emoticons: [],
    //     keywords: ['golf-club', 'golfball', 'golfclubicon', 'icon'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022342-golf-clubs-1.png',
    // },
    // {
    //     name: 'Golf-course',
    //     shortNames: ['golf-course'],
    //     custom: true,
    //     text: '(golf-course)',
    //     emoticons: [],
    //     keywords: ['golf-club', 'golfground', 'golfcourse'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022342-golf-course-1.png',
    // },
    // {
    //     name: 'Womengolfer',
    //     shortNames: ['women-golfer'],
    //     custom: true,
    //     text: '(women-golfer)',
    //     emoticons: [],
    //     keywords: ['womengolfer', 'flag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022368-golfer-1.png',
    // },
    // {
    //     name: 'Stickbag',
    //     shortNames: ['stickbag'],
    //     custom: true,
    //     text: '(stickbag)',
    //     emoticons: [],
    //     keywords: ['stickbag', 'bags'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022342-golf-clubs.png',
    // },
    // {
    //     name: 'Womensgolfer',
    //     shortNames: ['womensgolfer'],
    //     custom: true,
    //     text: '(womensgolfer)',
    //     emoticons: [],
    //     keywords: ['womensgolfer', 'flag', 'golfer', 'sticks'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022368-golfer-2.png',
    // },
    // {
    //     name: 'Mangolfer',
    //     shortNames: ['mangolfer'],
    //     custom: true,
    //     text: '(mangolfer)',
    //     emoticons: [],
    //     keywords: ['mangolfer', 'golfstick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022368-golfer-3.png',
    // },

    // {
    //     name: 'Golffield',
    //     shortNames: ['golffield'],
    //     custom: true,
    //     text: '(golffield)',
    //     emoticons: [],
    //     keywords: ['golffield', 'flag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022368-golf-field.png',
    // },
    // {
    //     name: 'Golfgameequipment',
    //     shortNames: ['golfgame-equipment'],
    //     custom: true,
    //     text: '(golfgame-equipment)',
    //     emoticons: [],
    //     keywords: ['golfgame-equipment', 'flag', 'ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022368-golf-game.png',
    // },
    // {
    //     name: 'Golfgloves',
    //     shortNames: ['golf-gloves'],
    //     custom: true,
    //     text: '(golf-gloves)',
    //     emoticons: [],
    //     keywords: ['golfglovesr', 'golfequipment'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022368-golf-gloves-1.png',
    // },
    // {
    //     name: 'Holeinone',
    //     shortNames: ['hole-in-one'],
    //     custom: true,
    //     text: '(hole-in-one)',
    //     emoticons: [],
    //     keywords: ['hole-in-one', 'golfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022413-hole-in-one-1.png',
    // },

    // {
    //     name: 'Lessons',
    //     shortNames: ['lessons'],
    //     custom: true,
    //     text: '(lessons)',
    //     emoticons: [],
    //     keywords: ['lessons', 'golfer', 'golfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022413-lessons-1.png',
    // },
    // {
    //     name: 'Reward',
    //     shortNames: ['reward'],
    //     custom: true,
    //     text: '(reward)',
    //     emoticons: [],
    //     keywords: ['reward', 'trophy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022443-reward.png',
    // },

    // {
    //     name: 'Range',
    //     shortNames: ['range'],
    //     custom: true,
    //     text: '(range)',
    //     emoticons: [],
    //     keywords: ['range'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022443-range.png',
    // },
    // {
    //     name: 'Smartwatchandball',
    //     shortNames: ['smartwatch-and-ball'],
    //     custom: true,
    //     text: '(smartwatch-and-ball)',
    //     emoticons: [],
    //     keywords: ['smartwatchandball', 'golfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022444-smart-watch.png',
    // },
    // {
    //     name: 'Projector',
    //     shortNames: ['projector'],
    //     custom: true,
    //     text: '(projector)',
    //     emoticons: [],
    //     keywords: ['projector', 'flag', 'golfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022443-projector.png',
    // },
    // {
    //     name: 'Shop',
    //     shortNames: ['shop'],
    //     custom: true,
    //     text: '(shop)',
    //     emoticons: [],
    //     keywords: ['shop', 'golfaequipment'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022443-shop.png',
    // },
    // {
    //     name: 'Smartwatch',
    //     shortNames: ['smartwatch'],
    //     custom: true,
    //     text: '(smartwatch)',
    //     emoticons: [],
    //     keywords: ['smartwatch', 'flag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022443-smartwatch.png',
    // },
    // {
    //     name: 'Winner',
    //     shortNames: ['winner'],
    //     custom: true,
    //     text: '(winner)',
    //     emoticons: [],
    //     keywords: ['winner', 'golfer', 'reward'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022492-winner-1.png',
    // },
    // {
    //     name: 'Teambuilding',
    //     shortNames: ['team-building'],
    //     custom: true,
    //     text: '(team-building)',
    //     emoticons: [],
    //     keywords: ['team-building', 'golfstick', 'golfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022444-team-building-1.png',
    // },
    // {
    //     name: 'Umbrella',
    //     shortNames: ['umbrella'],
    //     custom: true,
    //     text: '(umbrella)',
    //     emoticons: [],
    //     keywords: ['umbrella', 'golfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022492-umbrella.png',
    // },
    // {
    //     name: 'Player',
    //     shortNames: ['player'],
    //     custom: true,
    //     text: '(player)',
    //     emoticons: [],
    //     keywords: ['player', 'golfstick', 'golfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022443-player.png',
    // },
    // {
    //     name: 'Minigolfgame',
    //     shortNames: ['mini-golf-game'],
    //     custom: true,
    //     text: '(mini-golf-game)',
    //     emoticons: [],
    //     keywords: ['minigolfgame', 'golfstick', 'golfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022413-mini-golf.png',
    // },
    // {
    //     name: 'Stadium',
    //     shortNames: ['stadium'],
    //     custom: true,
    //     text: '(Stadium)',
    //     emoticons: [],
    //     keywords: ['stadium', 'ground'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665744832-stadium-1.png',
    // },

    // {
    //     name: 'Kick',
    //     shortNames: ['kick'],
    //     custom: true,
    //     text: '(kick)',
    //     emoticons: [],
    //     keywords: ['kick', 'penalty', 'football'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665745505-penalty-kick-1.png',
    // },
    // {
    //     name: 'Kickball',
    //     shortNames: ['kickball'],
    //     custom: true,
    //     text: '(kickball)',
    //     emoticons: [],
    //     keywords: ['kickball', 'football', 'player', 'attamptfootball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665746267-soccer-1.png',
    // },
    // {
    //     name: 'footballrun',
    //     shortNames: ['football-run'],
    //     custom: true,
    //     text: '(football-run)',
    //     emoticons: [],
    //     keywords: ['football', 'kick', 'sportsshoes', 'colouredfootball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665746899-football-player-1.png',
    // },
    // {
    //     name: 'Footballfield',
    //     shortNames: ['football-field'],
    //     custom: true,
    //     text: '(football-field)',
    //     emoticons: [],
    //     keywords: ['footballfield', 'ground', 'field'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747231-football-field.png',
    // },
    // {
    //     name: 'Player',
    //     shortNames: ['player-mls'],
    //     custom: true,
    //     text: '(player-mls)',
    //     emoticons: [],
    //     keywords: ['player', 'football', 'american', 'jersey', 'helmet'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747523-american-football-player-1.png',
    // },
    // {
    //     name: 'Americanfootballfield',
    //     shortNames: ['american-football-field'],
    //     custom: true,
    //     text: '(american-football-field)',
    //     emoticons: [],
    //     keywords: ['americanfootballfield', 'footballfield'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747713-american-football-field-1.png',
    // },

    // {
    //     name: 'Supporter',
    //     shortNames: ['supporter'],
    //     custom: true,
    //     text: '(supporter)',
    //     emoticons: [],
    //     keywords: ['supporter', 'fans'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747895-supporter-1.png',
    // },

    // {
    //     name: 'Cup',
    //     shortNames: ['cup'],
    //     custom: true,
    //     text: '(cup)',
    //     emoticons: [],
    //     keywords: ['trophy', 'cup'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665748073-world-cup.png',
    // },
    // {
    //     name: 'Ground',
    //     shortNames: ['Ground-mls'],
    //     custom: true,
    //     text: '(Ground-mls)',
    //     emoticons: [],
    //     keywords: ['Ground', 'field'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988968-american-football-field.png',
    // },
    // {
    //     name: 'Americanplayer',
    //     shortNames: ['american-player'],
    //     custom: true,
    //     text: '(american-player)',
    //     emoticons: [],
    //     keywords: ['americanplayer', 'player', 'football'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988969-american-football-player-2---Copy.png',
    // },
    // {
    //     name: 'Systemlive',
    //     shortNames: ['system-live'],
    //     custom: true,
    //     text: '(system-live)',
    //     emoticons: [],
    //     keywords: ['systemlive', 'match', 'game'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988969-football-2---Copy.png',
    // },
    // {
    //     name: 'Moveball',
    //     shortNames: ['Move-ball'],
    //     custom: true,
    //     text: '(Move-ball)',
    //     emoticons: [],
    //     keywords: ['Moveball', 'football', 'flying', 'football'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988969-football-3---Copy.png',
    // },
    // {
    //     name: 'Award',
    //     shortNames: ['award'],
    //     custom: true,
    //     text: '(award)',
    //     emoticons: [],
    //     keywords: ['award', 'trophy', 'cup'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988969-football-award.png',
    // },
    // {
    //     name: 'Womenplayer',
    //     shortNames: ['women-player'],
    //     custom: true,
    //     text: '(women-player)',
    //     emoticons: [],
    //     keywords: ['womenplayer', 'player', 'footballplayer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988978-football-player-5---Copy.png',
    // },

    // {
    //     name: 'players',
    //     shortNames: ['players'],
    //     custom: true,
    //     text: '(players)',
    //     emoticons: [],
    //     keywords: ['players', 'player', 'footballplayer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988979-football-players.png',
    // },

    // {
    //     name: 'Friendship',
    //     shortNames: ['friendship'],
    //     custom: true,
    //     text: '(friendship)',
    //     emoticons: [],
    //     keywords: ['players', 'friendship', 'footballplayer', 'handshake'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988979-friendship.png',
    // },
    // {
    //     name: 'Gloves',
    //     shortNames: ['gloves-mls'],
    //     custom: true,
    //     text: '(gloves-mls)',
    //     emoticons: [],
    //     keywords: ['gloves', 'playergloves'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988979-gloves.png',
    // },
    // {
    //     name: 'Goal',
    //     shortNames: ['goal'],
    //     custom: true,
    //     text: '(goal)',
    //     emoticons: [],
    //     keywords: ['goal', 'kick', 'footballgoal'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988979-goal---Copy-2.png',
    // },
    // {
    //     name: 'Livefootball',
    //     shortNames: ['Live-football'],
    //     custom: true,
    //     text: '(Live-football)',
    //     emoticons: [],
    //     keywords: ['livefootball', 'football'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989019-live.png',
    // },
    // {
    //     name: 'Match',
    //     shortNames: ['match'],
    //     custom: true,
    //     text: '(match)',
    //     emoticons: [],
    //     keywords: ['match', , 'footballmatch'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989019-match.png',
    // },
    // {
    //     name: 'Medal',
    //     shortNames: ['medal'],
    //     custom: true,
    //     text: '(medal)',
    //     emoticons: [],
    //     keywords: ['medal', 'award'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989019-medal.png',
    // },
    // {
    //     name: 'Headshots',
    //     shortNames: ['headshot'],
    //     custom: true,
    //     text: '(headshot)',
    //     emoticons: [],
    //     keywords: ['goal', 'headshot', 'football', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989019-play---Copy.png',
    // },
    // {
    //     name: 'Playing',
    //     shortNames: ['playing-mls'],
    //     custom: true,
    //     text: '(playing-mls)',
    //     emoticons: [],
    //     keywords: ['goal', 'kick', 'playing'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989019-playing---Copy.png',
    // },
    // {
    //     name: 'Confussed',
    //     shortNames: ['confussed'],
    //     custom: true,
    //     text: '(confussed)',
    //     emoticons: [],
    //     keywords: ['goal', 'kick', 'confussed', 'question'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989037-question---Copy---Copy.png',
    // },
    // {
    //     name: 'Referee',
    //     shortNames: ['referee'],
    //     custom: true,
    //     text: '(referee)',
    //     emoticons: [],
    //     keywords: ['goal', 'panelty', 'referee'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989037-referee---Copy---Copy.png',
    // },
    // {
    //     name: 'Scout',
    //     shortNames: ['scout'],
    //     custom: true,
    //     text: '(scout)',
    //     emoticons: [],
    //     keywords: ['scout', 'football', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989037-scout.png',
    // },
    // {
    //     name: 'Chestshot',
    //     shortNames: ['chestshot'],
    //     custom: true,
    //     text: '(chestshot)',
    //     emoticons: [],
    //     keywords: ['chestshot', 'football', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989038-soccer-1.png',
    // },
    // {
    //     name: 'Jersey',
    //     shortNames: ['jersey'],
    //     custom: true,
    //     text: '(jersey)',
    //     emoticons: [],
    //     keywords: ['jersey', 'footballjersey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989070-soccer-jersey.png',

    // },

    // {
    //     name: 'Timer',
    //     shortNames: ['timer'],
    //     custom: true,
    //     text: '(timer)',
    //     emoticons: [],
    //     keywords: ['timer', 'footballgame'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989071-timer.png',
    // },
    // {
    //     name: 'Team',
    //     shortNames: ['team-mls'],
    //     custom: true,
    //     text: '(team-mls)',
    //     emoticons: [],
    //     keywords: ['playinfootball', 'football', 'player', 'team'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989071-team.png',
    // },
    // {
    //     name: 'Versus',
    //     shortNames: ['versus'],
    //     custom: true,
    //     text: '(versus)',
    //     emoticons: [],
    //     keywords: ['versus', 'footballmatch', 'helmet'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989080-vs---Copy.png',
    // },

    // {
    //     name: 'Americanstadium',
    //     shortNames: ['American-stadium'],
    //     custom: true,
    //     text: '(American-stadium)',
    //     emoticons: [],
    //     keywords: ['Americanstadium', 'stadium', 'ground'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989071-stadium.png',
    // },

    // {
    //     name: 'Kickshot',
    //     shortNames: ['kick-shot'],
    //     custom: true,
    //     text: '(kick-shot)',
    //     emoticons: [],
    //     keywords: ['playinfootball', 'football', 'kickshot'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989070-soccer-player---Copy.png',
    // },
    // {
    //     name: 'Trophy',
    //     shortNames: ['trophy'],
    //     custom: true,
    //     text: '(trophy)',
    //     emoticons: [],
    //     keywords: ['trophy', 'football', 'cup'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989071-trophy-2---Copy.png',
    // },
    // {
    //     name: 'Worldcup',
    //     shortNames: ['world-cup'],
    //     custom: true,
    //     text: '(world-cup)',
    //     emoticons: [],
    //     keywords: ['worldcup', 'football', 'trophy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989080-world-cup.png',
    // },
    // {
    //     name: 'Ticket',
    //     shortNames: ['ticket-mls'],
    //     custom: true,
    //     text: '(ticket-mls)',
    //     emoticons: [],
    //     keywords: ['ticket', 'footballmatchticket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988562-ticket.png',
    // },
    // {
    //     name: 'Vest',
    //     shortNames: ['vest'],
    //     custom: true,
    //     text: '(vest)',
    //     emoticons: [],
    //     keywords: ['vest', 'football', 'player', 'jersey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665750933-vest-1.png',
    // },
    // {
    //     name: 'Barcelona',
    //     shortNames: ['barcelona'],
    //     custom: true,
    //     text: '(barcelona)',
    //     emoticons: [],
    //     keywords: ['barcelona', 'footballteam', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666014495-barcelona.png',
    // },

    // {
    //     name: 'Coach',
    //     shortNames: ['coach-mls'],
    //     custom: true,
    //     text: '(coach-mls)',
    //     emoticons: [],
    //     keywords: ['coach', 'footballcoach'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666014495-coach.png',
    // },
    // {
    //     name: 'Competition',
    //     shortNames: ['competition-mls'],
    //     custom: true,
    //     text: '(competition-mls)',
    //     emoticons: [],
    //     keywords: ['competition', 'trophy', 'versus'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666014495-competition.png',
    // },
    // {
    //     name: 'Lineup',
    //     shortNames: ['lineup'],
    //     custom: true,
    //     text: '(lineup)',
    //     emoticons: [],
    //     keywords: ['playinfootball', 'lineup', 'field'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666014495-lineup.png',
    // },
    // {
    //     name: 'Pitch',
    //     shortNames: ['pitch'],
    //     custom: true,
    //     text: '(pitch)',
    //     emoticons: [],
    //     keywords: ['pitch', 'football'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666014495-pitch.png',
    // },

    // {
    //     name: 'Fans',
    //     shortNames: ['fans'],
    //     custom: true,
    //     text: '(fans)',
    //     emoticons: [],
    //     keywords: ['fans', 'supporter'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666015443-fans.png',
    // },

    // {
    //     name: 'Goalonnet',
    //     shortNames: ['goal-on-net'],
    //     custom: true,
    //     text: '(goalonnet)',
    //     emoticons: [],
    //     keywords: ['goalonnet', 'football', 'net'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666015443-goalonnet.png',
    // },

    // {
    //     name: 'Scoreboard',
    //     shortNames: ['scoreboard'],
    //     custom: true,
    //     text: '(scoreboard)',
    //     emoticons: [],
    //     keywords: ['scoreboard', 'timer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666015443-scoreboard.png',
    // },
    // {
    //     name: 'Watching',
    //     shortNames: ['watching'],
    //     custom: true,
    //     text: '(watching)',
    //     emoticons: [],
    //     keywords: ['watching', 'football', 'livefootball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666015443-watching.png',
    // },

    // {
    //     name: 'Soccer',
    //     shortNames: ['soccer'],
    //     custom: true,
    //     text: '(soccer)',
    //     emoticons: [],
    //     keywords: ['soccer', 'player', 'match'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665755482-football-ball.png',
    // },
    // {
    //     name: 'KickFootballplayer',
    //     shortNames: ['kick-Football-player'],
    //     custom: true,
    //     text: '(kick-Football-player)',
    //     emoticons: [],
    //     keywords: ['soccer', 'player', 'kickFootballplayer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665755615-football-player.png',
    // },

    // {
    //     name: 'Fifaworldcup',
    //     shortNames: ['Fifa-worldcup'],
    //     custom: true,
    //     text: '(Fifa-worldcup)',
    //     emoticons: [],
    //     keywords: ['Fifaworldcup', 'worldcup', 'trophy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665755810-cup.png',
    // },

    // {
    //     name: 'Soccerball',
    //     shortNames: ['soccer-ball'],
    //     custom: true,
    //     text: '(soccer-ball)',
    //     emoticons: [],
    //     keywords: ['soccer-ball', 'soccerball', 'soccer-baoll'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665818629-goal.png',
    // },
    // {
    //     name: 'Footballplayer',
    //     shortNames: ['foot-ball-player'],
    //     custom: true,
    //     text: '(foot-ball-player)',
    //     emoticons: [],
    //     keywords: ['soccer', 'player', 'footballplayer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665816950-soccerball.png',
    // },
    // {
    //     name: 'Football',
    //     shortNames: ['football'],
    //     custom: true,
    //     text: '(football)',
    //     emoticons: [],
    //     keywords: ['soccer', 'player', 'football'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665817537-football.png',
    // },
    // {
    //     name: 'Soccerkick',
    //     shortNames: ['Soccerkick'],
    //     custom: true,
    //     text: '(Soccerkick)',
    //     emoticons: [],
    //     keywords: ['soccer', 'players', 'football_player', 'kick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665817968-kick.png',
    // },

    // {
    //     name: 'Netsoccerball',
    //     shortNames: ['Netsoccerball'],
    //     custom: true,
    //     text: '(Netsoccerball)',
    //     emoticons: [],
    //     keywords: ['soccer', 'Netsoccerball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665818238-goal.png',
    // },
    // {
    //     name: 'Ballonfire',
    //     shortNames: ['ball-on-fire'],
    //     custom: true,
    //     text: '(ball-on-fire)',
    //     emoticons: [],
    //     keywords: ['ball', 'fireball', 'ballplayer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665816666-soccer-ball.png',
    // },

    // {
    //     name: 'Playfootball',
    //     shortNames: ['play-football'],
    //     custom: true,
    //     text: '(play-football)',
    //     emoticons: [],
    //     keywords: ['soccerball', 'ball', 'soccerballkicked', 'Playootball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665819254-football-players.png',
    // },
    // {
    //     name: 'Mlslogo',
    //     shortNames: ['mls-logo'],
    //     custom: true,
    //     text: '(mls-logo)',
    //     emoticons: [],
    //     keywords: ['soccer', 'mls-logo', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666203846-mls-logo.png',
    // },

    // {
    //     name: 'Mls',
    //     shortNames: ['mls'],
    //     custom: true,
    //     text: '(mls)',
    //     emoticons: [],
    //     keywords: ['mls', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666203846-mls-main-logo.png',
    // },
    // {
    //     name: 'Timber',
    //     shortNames: ['timber'],
    //     custom: true,
    //     text: '(timber)',
    //     emoticons: [],
    //     keywords: ['timber', 'team'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666203847-timber.jfif',
    // },

    // {
    //     name: 'Hustondiamonds',
    //     shortNames: ['huston-diamonds'],
    //     custom: true,
    //     text: '(huston-diamonds)',
    //     emoticons: [],
    //     keywords: ['hustondiamonds', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666203846-huston-diamonds.png',
    // },
    // {
    //     name: 'Goldtrophy',
    //     shortNames: ['gold-trophy'],
    //     custom: true,
    //     text: '(gold-trophy)',
    //     emoticons: [],
    //     keywords: ['trophy', 'basketball', 'cup'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665744582-trophy.png',
    // },
    // {
    //     name: 'Goldmedal',
    //     shortNames: ['gold-medal'],
    //     custom: true,
    //     text: '(gold-medal)',
    //     emoticons: [],
    //     keywords: ['medal', 'award'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665745434-award.png',
    // },
    // {
    //     name: 'Fireball',
    //     shortNames: ['fire-ball'],
    //     custom: true,
    //     text: '(fire-ball)',
    //     emoticons: [],
    //     keywords: ['fireball', 'basketball', 'fire'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747166-5g.png',
    // },
    // {
    //     name: 'Nbadraft',
    //     shortNames: ['nba-draft'],
    //     custom: true,
    //     text: '(nba-draft)',
    //     emoticons: [],
    //     keywords: ['nbadraft', 'NBA', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747662-draft.png',
    // },
    // {
    //     name: 'Basketballplayer',
    //     shortNames: ['basket-ball-player'],
    //     custom: true,
    //     text: '(basket-ball-player)',
    //     emoticons: [],
    //     keywords: ['basketballplayer', 'basketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665749277-shot.png',
    // },
    // {
    //     name: 'Playerflliping',
    //     shortNames: ['player-flliping'],
    //     custom: true,
    //     text: '(player-flliping)',
    //     emoticons: [],
    //     keywords: ['player', 'basketball', 'swing basketball on finger'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665815812-dunk.png',
    // },

    // {
    //     name: 'Basketballcourt',
    //     shortNames: ['basket-ball-court'],
    //     custom: true,
    //     text: '(basket-ball-court)',
    //     emoticons: [],
    //     keywords: ['basketball', 'court', 'ground'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665750876-basketball-court.png',
    // },

    // {
    //     name: 'Basketball',
    //     shortNames: ['basket-ball'],
    //     custom: true,
    //     text: '(basket-ball)',
    //     emoticons: [],
    //     keywords: ['basketball', 'coloured basketball', 'ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751079-basketball.png',
    // },

    // {
    //     name: 'Bostonceltics',
    //     shortNames: ['boston-celtics'],
    //     custom: true,
    //     text: '(boston-celtics)',
    //     emoticons: [],
    //     keywords: ['bostonceltics', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666173186-boston-celtics.png',
    // },
    // {
    //     name: 'Brooklynnets',
    //     shortNames: ['brooklyn-nets'],
    //     custom: true,
    //     text: '(brooklyn-nets)',
    //     emoticons: [],
    //     keywords: ['brooklyn-nets', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666173186-brooklyn-nets.png',
    // },
    // {
    //     name: 'Charlottehornets',
    //     shortNames: ['charlotte-hornets'],
    //     custom: true,
    //     text: '(charlotte-hornets)',
    //     emoticons: [],
    //     keywords: ['charlotte-hornets', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666173186-charlotte-hornets.png',
    // },
    // {
    //     name: 'Chicagobulls',
    //     shortNames: ['chicago-bulls'],
    //     custom: true,
    //     text: '(chicago-bulls)',
    //     emoticons: [],
    //     keywords: ['chicago-bulls', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666173186-chicago-bulls.png',
    // },

    // {
    //     name: 'Clevelandcavaliers',
    //     shortNames: ['cleveland-cavaliers'],
    //     custom: true,
    //     text: '(cleveland-cavaliers)',
    //     emoticons: [],
    //     keywords: ['clevelandcavaliers', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666173186-cleveland-cavaliers.png',
    // },

    // {
    //     name: 'Dallasmavericks',
    //     shortNames: ['dallas-mavericks'],
    //     custom: true,
    //     text: '(dallas-mavericks)',
    //     emoticons: [],
    //     keywords: ['dallasmavericks', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666173186-dallas-mavericks.png',
    // },

    // {
    //     name: 'Denvernuggets',
    //     shortNames: ['denver-nuggets'],
    //     custom: true,
    //     text: '(denver-nuggets)',
    //     emoticons: [],
    //     keywords: ['denver-nuggets', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666173186-denver-nuggets.png',
    // },

    // {
    //     name: 'Goldenstatewarriors',
    //     shortNames: ['golden-state-warriors'],
    //     custom: true,
    //     text: '(golden-state-warriors)',
    //     emoticons: [],
    //     keywords: ['goldenstatewarriors', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666173186-golden-state-warriors.png',
    // },
    // {
    //     name: 'Houston-rockets',
    //     shortNames: ['houston-rockets'],
    //     custom: true,
    //     text: '(houston-rockets)',
    //     emoticons: [],
    //     keywords: ['houstonrockets', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666174712-houston-rockets.png',
    // },

    // {
    //     name: 'Indianapacers',
    //     shortNames: ['indiana-pacers'],
    //     custom: true,
    //     text: '(indiana-pacers)',
    //     emoticons: [],
    //     keywords: ['indianapacers', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666174712-indiana-pacers.png',
    // },
    // {
    //     name: 'Miamiheat',
    //     shortNames: ['miami-heat'],
    //     custom: true,
    //     text: '(miami-heat)',
    //     emoticons: [],
    //     keywords: ['miamiheat', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666174712-miami-heat.png',
    // },

    // {
    //     name: 'Minnesotatimberwolves',
    //     shortNames: ['minnesota-timberwolves'],
    //     custom: true,
    //     text: '(minnesota-timberwolves)',
    //     emoticons: [],
    //     keywords: ['minnesotatimberwolves', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666174712-minnesota-timberwolves.png',
    // },
    // {
    //     name: 'Angelesclippers',
    //     shortNames: ['angeles-clippers'],
    //     custom: true,
    //     text: '(angeles-clippers)',
    //     emoticons: [],
    //     keywords: ['angelesclippers', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666174712-los-angeles-clippers.png',
    // },
    // {
    //     name: 'Newyorkknicks',
    //     shortNames: ['new-york-knicks'],
    //     custom: true,
    //     text: '(new-york-knicks)',
    //     emoticons: [],
    //     keywords: ['newyorkknicks', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666174712-new-york-knicks.png',
    // },
    // {
    //     name: 'Memphisgrizzlies',
    //     shortNames: ['memphis-grizzlies'],
    //     custom: true,
    //     text: '(memphis-grizzlies)',
    //     emoticons: [],
    //     keywords: ['memphisgrizzlies', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666174712-memphis-grizzlies-1.png',
    // },
    // {
    //     name: 'Orlandomagic',
    //     shortNames: ['orlando-magic'],
    //     custom: true,
    //     text: '(orlando-magic)',
    //     emoticons: [],
    //     keywords: ['orlandomagic', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666176850-orlando-magic.png',
    // },
    // {
    //     name: 'Milwaukeebucks',
    //     shortNames: ['milwaukee-bucks'],
    //     custom: true,
    //     text: '(milwaukee-bucks)',
    //     emoticons: [],
    //     keywords: ['milwaukeebucks', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666174712-milwaukee-bucks.png',
    // },

    // {
    //     name: 'Phoenixsuns',
    //     shortNames: ['phoenix-suns'],
    //     custom: true,
    //     text: '(phoenix-suns)',
    //     emoticons: [],
    //     keywords: ['phoenixsuns', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666176850-phoenix-suns.png',
    // },
    // {
    //     name: 'Torontoraptors',
    //     shortNames: ['toronto-raptors'],
    //     custom: true,
    //     text: '(toronto-raptors)',
    //     emoticons: [],
    //     keywords: ['torontoraptors', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666176850-toronto-raptors.png',
    // },
    // {
    //     name: 'Portlandtrailblazers',
    //     shortNames: ['portland-trail-blazers'],
    //     custom: true,
    //     text: '(portland-trail-blazers)',
    //     emoticons: [],
    //     keywords: ['portlandtrailblazers', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666176850-portland-trail-blazers.png',
    // },
    // {
    //     name: 'Sacramentokings',
    //     shortNames: ['sacramento-kings'],
    //     custom: true,
    //     text: '(sacramento-kings)',
    //     emoticons: [],
    //     keywords: ['sacramentokings', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666176850-sacramento-kings.png',
    // },
    // {
    //     name: 'Orleanspelicans',
    //     shortNames: ['orleans-pelicans'],
    //     custom: true,
    //     text: '(orleans-pelicans)',
    //     emoticons: [],
    //     keywords: ['orleanspelicans', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666176851-orleans-pelicans.png',
    // },
    // {
    //     name: 'Sanantoniospurs',
    //     shortNames: ['san-antonio-spurs'],
    //     custom: true,
    //     text: '(san-antonio-spurs)',
    //     emoticons: [],
    //     keywords: ['sanantoniospurs', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666176851-san-antonio-spurs.png',
    // },
    // {
    //     name: 'Philidephiaers',
    //     shortNames: ['philidephia-ers'],
    //     custom: true,
    //     text: '(philidephia-ers)',
    //     emoticons: [],
    //     keywords: ['philidephiaers', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666176851-philidephia-ers.png',
    // },

    // {
    //     name: 'Utahjazz',
    //     shortNames: ['utah-jazz'],
    //     custom: true,
    //     text: '(utah-jazz)',
    //     emoticons: [],
    //     keywords: ['utahjazz', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666177789-utah-jazz.png',
    // },
    // {
    //     name: 'Washingtonwizards',
    //     shortNames: ['washington-wizards'],
    //     custom: true,
    //     text: '(washington-wizards)',
    //     emoticons: [],
    //     keywords: ['washingtonwizards', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666177789-washington-wizards.png',
    // },
    // {
    //     name: 'Nba',
    //     shortNames: ['nba'],
    //     custom: true,
    //     text: '(nba)',
    //     emoticons: [],
    //     keywords: ['nba', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666178768-nba.png',
    // },

    // {
    //     name: 'Basketballsuperleague',
    //     shortNames: ['basketball-super-league'],
    //     custom: true,
    //     text: '(basketball-super-league)',
    //     emoticons: [],
    //     keywords: ['basketball-superleague', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666178768-bsketballsuperleague.png',
    // },

    // {
    //     name: 'Basketball',
    //     shortNames: ['basketball'],
    //     custom: true,
    //     text: '(basketball)',
    //     emoticons: [],
    //     keywords: ['basketball', 'ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666178768-basketball.png',
    // },
    // {
    //     name: 'Teamnba',
    //     shortNames: ['team-nba'],
    //     custom: true,
    //     text: '(team-nba)',
    //     emoticons: [],
    //     keywords: ['teamnba', 'nba', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666178768-all-30team-nba.jfif',
    // },
    // {
    //     name: 'Wingstournamet',
    //     shortNames: ['wings-tournamet'],
    //     custom: true,
    //     text: '(bwings-tournamet)',
    //     emoticons: [],
    //     keywords: ['wingstournamet', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666179632-wings-tournamet.png',
    // },
    // {
    //     name: 'Nbatextlogo',
    //     shortNames: ['nba-text-logo'],
    //     custom: true,
    //     text: '(nba-text-logo)',
    //     emoticons: [],
    //     keywords: ['nbatextlogo', 'nbalogo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666179632-NBATEXTLOGO.png',
    // },
    // {
    //     name: 'Nbaroundlogo',
    //     shortNames: ['nba-round-logo'],
    //     custom: true,
    //     text: '(nba-round-logo)',
    //     emoticons: [],
    //     keywords: ['basketball', 'nbaroundlogo', 'nba', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180313-nbaroundlogo.png',
    // },
    // {
    //     name: 'Basketring',
    //     shortNames: ['basket-ring'],
    //     custom: true,
    //     text: '(basket-ring)',
    //     emoticons: [],
    //     keywords: ['basket', 'basketring'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-basket-ring.png',
    // },
    // {
    //     name: 'Colouredbasketball',
    //     shortNames: ['coloured-basketball'],
    //     custom: true,
    //     text: '(coloured-basketball)',
    //     emoticons: [],
    //     keywords: ['basketball', 'colouredbasketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-coloured-basketball.png',
    // },

    // {
    //     name: 'Dribblebasketballdunk',
    //     shortNames: ['dribble-basketball-dunk'],
    //     custom: true,
    //     text: '(dribble-basketball-dunk)',
    //     emoticons: [],
    //     keywords: ['basketball', 'dribblebasketballdunk', 'player', 'dunk'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-dribble-basketball-dunk.png',
    // },
    // {
    //     name: 'Calendar',
    //     shortNames: ['calendar'],
    //     custom: true,
    //     text: '(calendar)',
    //     emoticons: [],
    //     keywords: ['basketball', 'ccalendar', 'scored'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-calendar.png',
    // },
    // {
    //     name: 'Dribbble',
    //     shortNames: ['dribbble'],
    //     custom: true,
    //     text: '(dribbble)',
    //     emoticons: [],
    //     keywords: ['basketball', 'dribbble'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-dribbble.png',
    // },
    // {
    //     name: 'Dribblebasketball',
    //     shortNames: ['dribble-basketball'],
    //     custom: true,
    //     text: '(dribble-basketball)',
    //     emoticons: [],
    //     keywords: ['basketball', 'dribblebasketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-dribble-basketball.png',
    // },
    // {
    //     name: 'Dufflebag',
    //     shortNames: ['duffle-bag'],
    //     custom: true,
    //     text: '(duffle-bag)',
    //     emoticons: [],
    //     keywords: ['duffle-bag', 'bag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-duffle-bag.png',
    // },
    // {
    //     name: 'Gamesequipment',
    //     shortNames: ['games-equipment'],
    //     custom: true,
    //     text: '(games-equipment)',
    //     emoticons: [],
    //     keywords: ['basketball', 'gamesequipmentk'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666182509-games-equipment.png',
    // },

    // {
    //     name: 'Hoop',
    //     shortNames: ['hoop'],
    //     custom: true,
    //     text: '(hoop)',
    //     emoticons: [],
    //     keywords: ['basket', 'hoop', 'dunk'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666182509-hoop.png',
    // },
    // {
    //     name: 'Nbalogo',
    //     shortNames: ['nbalogo'],
    //     custom: true,
    //     text: '(nbalogo)',
    //     emoticons: [],
    //     keywords: ['nbalogo', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666182509-nbalogo.png',
    // },

    // {
    //     name: 'Referee',
    //     shortNames: ['referee-nba'],
    //     custom: true,
    //     text: '(referee-nba)',
    //     emoticons: [],
    //     keywords: ['referee', 'signal', 'whistle'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666182509-referee-signal.png',
    // },
    // {
    //     name: 'Scoreboard',
    //     shortNames: ['scoreboard-nba'],
    //     custom: true,
    //     text: '(scoreboard-nba)',
    //     emoticons: [],
    //     keywords: ['scoreboard'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666182509-scoreboard.png',
    // },
    // {
    //     name: 'Dunkshot',
    //     shortNames: ['dunkshot'],
    //     custom: true,
    //     text: '(dunkshot)',
    //     emoticons: [],
    //     keywords: ['basketball', 'dunkshot', 'player', 'dunk'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666182509-dunkshot.png',
    // },
    // {
    //     name: 'Dunk',
    //     shortNames: ['dunk'],
    //     custom: true,
    //     text: '(dunk)',
    //     emoticons: [],
    //     keywords: ['basketball', 'dunk', 'player', 'hoop'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666182509-dunk.png',
    // },
    // {
    //     name: 'Refreestartsign',
    //     shortNames: ['refree-start-sign'],
    //     custom: true,
    //     text: '(refree-start-sign)',
    //     emoticons: [],
    //     keywords: ['refree', 'refree-start-sign', 'startsign'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666183568-start-sign.png',
    // },
    // {
    //     name: 'Tshirt',
    //     shortNames: ['t-shirt'],
    //     custom: true,
    //     text: '(t-shirt)',
    //     emoticons: [],
    //     keywords: ['t-shirt', 'player', 'jersey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666183568-t-shirt.png',
    // },
    // {
    //     name: 'Substitution',
    //     shortNames: ['substitution'],
    //     custom: true,
    //     text: '(substitution)',
    //     emoticons: [],
    //     keywords: ['substitution', 'sign'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666183568-substitution.png',
    // },

    // {
    //     name: 'Stopwatch',
    //     shortNames: ['stopwatch-nba'],
    //     custom: true,
    //     text: '(stopwatch-nba)',
    //     emoticons: [],
    //     keywords: ['basketball', 'dribblebasketballdunk', 'player', 'dunk'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666183568-stopwatch.png',
    // },
    // {
    //     name: 'Whistle',
    //     shortNames: ['whistle'],
    //     custom: true,
    //     text: '(whistle',
    //     emoticons: [],
    //     keywords: ['whistle', 'refreeequipment'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666183568-sport-whistle.png',
    // },
    // {
    //     name: 'Vest',
    //     shortNames: ['vest-nba'],
    //     custom: true,
    //     text: '(vest-nba)',
    //     emoticons: [],
    //     keywords: ['vest', 'basketball', 'jersey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665750933-vest-1.png',
    // },
    // {
    //     name: 'Shot',
    //     shortNames: ['shot'],
    //     custom: true,
    //     text: '(shot)',
    //     emoticons: [],
    //     keywords: ['shot', 'basketball', 'swing'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751212-shot-1.png',
    // },
    // {
    //     name: 'Basketball player',
    //     shortNames: ['basketball-player'],
    //     custom: true,
    //     text: '(basketball-player)',
    //     emoticons: [],
    //     keywords: ['player', 'basketball',],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665752247-winner.png',
    // },
    // {
    //     name: 'Basketball court',
    //     shortNames: ['basketball-court'],
    //     custom: true,
    //     text: '(basketball-court)',
    //     emoticons: [],
    //     keywords: ['basketball court', 'basketball', 'ball', 'basket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665753714-basketball-court.png',
    // },

    // {
    //     name: 'Badge',
    //     shortNames: ['badge'],
    //     custom: true,
    //     text: '(badge)',
    //     emoticons: [],
    //     keywords: ['badge', 'reward'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095827-badge.png',
    // },
    // {
    //     name: 'Stadium',
    //     shortNames: ['stadium-nba'],
    //     custom: true,
    //     text: '(stadium-nba)',
    //     emoticons: [],
    //     keywords: ['stadium', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096047-stadium-3.png',
    // },
    // {
    //     name: 'Catchbasketball',
    //     shortNames: ['catch-basketball'],
    //     custom: true,
    //     text: '(catch-basketball)',
    //     emoticons: [],
    //     keywords: ['catchbasketball', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-catchbasketball.png',
    // },
    // {
    //     name: 'Board',
    //     shortNames: ['board'],
    //     custom: true,
    //     text: '(board)',
    //     emoticons: [],
    //     keywords: ['board', 'field'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-board.png',
    // },

    // {
    //     name: 'Basketballonbench',
    //     shortNames: ['basketball-on-bench'],
    //     custom: true,
    //     text: '(basketball-on-bench)',
    //     emoticons: [],
    //     keywords: ['basketballonbench', 'bench', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-bench.png',
    // },
    // {
    //     name: 'Spinbasketball-player',
    //     shortNames: ['spin-basketball-player'],
    //     custom: true,
    //     text: '(spin-basketball-player)',
    //     emoticons: [],
    //     keywords: ['basketball-player', 'basketball', 'player', 'spin'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-basketball-player-9.png',
    // }, {
    //     name: 'Bankshotplayer',
    //     shortNames: ['bank-shot-player'],
    //     custom: true,
    //     text: '(bank-shot-player)',
    //     emoticons: [],
    //     keywords: ['bankshotplayer', 'basketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-player-6.png',
    // },
    // {
    //     name: 'Basketballhoop',
    //     shortNames: ['basketball-hoop'],
    //     custom: true,
    //     text: '(basketball-hoop)',
    //     emoticons: [],
    //     keywords: ['basketballhoop', 'basketball', 'basket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-hoop.png',
    // },
    // {
    //     name: 'Hoopfront',
    //     shortNames: ['hoop-front'],
    //     custom: true,
    //     text: '(hoop-front)',
    //     emoticons: [],
    //     keywords: ['basketball-hoop', 'basketball', 'hoop'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-hoop-1.png',
    // },
    // {
    //     name: 'Basketballcourttop',
    //     shortNames: ['basketballcourt-top'],
    //     custom: true,
    //     text: '(basketballcourt-top)',
    //     emoticons: [],
    //     keywords: ['basketballhoop', 'basketball', 'basketballgame', 'hoop', 'basketballcourt-top'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketballgame.png',
    // },
    // {
    //     name: 'Scratchbasketballcourt',
    //     shortNames: ['scratchbasketballcourt'],
    //     custom: true,
    //     text: '(scratchbasketballcourt)',
    //     emoticons: [],
    //     keywords: ['basketballhoop', 'basketballcourt', 'basketballgame', 'hoop', 'scratchbasketballcourt'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-court-2.png',
    // },
    // {
    //     name: 'Practicebasketball',
    //     shortNames: ['practicebasketball'],
    //     custom: true,
    //     text: '(practicebasketball)',
    //     emoticons: [],
    //     keywords: ['player', 'basketball', 'practicebasketball', 'hoop', 'basketballcourt'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095828-basketball-17.png',
    // },
    // {
    //     name: 'Slamdunk',
    //     shortNames: ['slam-dunk'],
    //     custom: true,
    //     text: '(slam-unk)',
    //     emoticons: [],
    //     keywords: ['slamdunk', 'basketballcourt', 'basketballgame', 'hoop'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095827-basketball-16.png',
    // },
    // {
    //     name: 'GoldenTrophy',
    //     shortNames: ['Golden-Trophy'],
    //     custom: true,
    //     text: '(Golden-Trophy)',
    //     emoticons: [],
    //     keywords: ['Golden-Trophy', 'basketball', 'cup'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665750701-trophy-2.png',
    // },

    // {
    //     name: 'Vest',
    //     shortNames: ['vest-ncaabasketball'],
    //     custom: true,
    //     text: '(vest-ncaabasketball)',
    //     emoticons: [],
    //     keywords: ['vest', 'basketball', 'jersey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665750933-vest-1.png',
    // },
    // {
    //     name: 'Shot',
    //     shortNames: ['shot-ncaabasketball'],
    //     custom: true,
    //     text: '(shot-ncaabasketball)',
    //     emoticons: [],
    //     keywords: ['shot', 'basketball', 'swing'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751212-shot-1.png',
    // },

    // {
    //     name: 'Dribble',
    //     shortNames: ['dribble'],
    //     custom: true,
    //     text: '(dribble)',
    //     emoticons: [],
    //     keywords: ['dribble', 'basketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751519-dribble-1.png',
    // },
    // {
    //     name: 'Basketballplayerscoring',
    //     shortNames: ['basketball-player-scoring'],
    //     custom: true,
    //     text: '(basketball-player-scoring)',
    //     emoticons: [],
    //     keywords: ['player', 'basketball', 'shot'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665752033-shot.png',
    // },
    // {
    //     name: 'Winner',
    //     shortNames: ['winner-ncaabasketball'],
    //     custom: true,
    //     text: '(winner-ncaabasketball)',
    //     emoticons: [],
    //     keywords: ['player', 'winner',],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665752247-winner.png',
    // },
    // {
    //     name: 'Playerinaction',
    //     shortNames: ['player-in-action'],
    //     custom: true,
    //     text: '(player-in-action)',
    //     emoticons: [],
    //     keywords: ['basketballplayer', 'basketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665752786-basketball-player-6.png',
    // },
    // {
    //     name: 'Basketballground',
    //     shortNames: ['basketball-ground'],
    //     custom: true,
    //     text: '(basketball-ground)',
    //     emoticons: [],
    //     keywords: ['basketballgame', 'basket', 'ball', 'basketball-ground'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665753123-basketball-game.png',
    // },
    // {
    //     name: 'Ballinbasket',
    //     shortNames: ['ball-in-basket'],
    //     custom: true,
    //     text: '(ball-in-basket)',
    //     emoticons: [],
    //     keywords: ['basketball ', 'basket', 'ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665753388-basketball.png',
    // },
    // {
    //     name: 'Court',
    //     shortNames: ['court'],
    //     custom: true,
    //     text: '(court)',
    //     emoticons: [],
    //     keywords: ['basketball court', 'basketball', 'ball', 'basket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665753714-basketball-court.png',
    // },
    // {
    //     name: 'Basketball',
    //     shortNames: ['basketball2'],
    //     custom: true,
    //     text: '(basketball2)',
    //     emoticons: [],
    //     keywords: ['ball ', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665753900-basketball-10.png',
    // },
    // {
    //     name: 'Badge',
    //     shortNames: ['badge-basketball'],
    //     custom: true,
    //     text: '(badge-basketball)',
    //     emoticons: [],
    //     keywords: ['badge', 'reward'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095827-badge.png',
    // },
    // {
    //     name: 'Starbadges',
    //     shortNames: ['starbadges'],
    //     custom: true,
    //     text: '(starbadges)',
    //     emoticons: [],
    //     keywords: ['starbadges', 'badges', 'award', 'reward'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095827-badges.png',
    // },
    // {
    //     name: 'Ballonbasket',
    //     shortNames: ['ball-on-basket'],
    //     custom: true,
    //     text: '(ball-on-basket)',
    //     emoticons: [],
    //     keywords: ['ballonbasket', 'basketball', 'basket'],
    // },

    // {
    //     name: 'Basketballwithbasket',
    //     shortNames: ['Basketball-with-basket'],
    //     custom: true,
    //     text: '(Basketball-with-basket)',
    //     emoticons: [],
    //     keywords: ['basket', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095827-basketball-13.png',
    // },
    // {
    //     name: 'Threepoints',
    //     shortNames: ['three-points'],
    //     custom: true,
    //     text: '(three-points)',
    //     emoticons: [],
    //     keywords: ['basket', 'basketball', 'three-points'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096066-three-points.png',
    // },
    // {
    //     name: 'Store',
    //     shortNames: ['store'],
    //     custom: true,
    //     text: '(store)',
    //     emoticons: [],
    //     keywords: ['store', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096066-store.png',
    // },
    // {
    //     name: 'Trophy',
    //     shortNames: ['trophy-basketball'],
    //     custom: true,
    //     text: '(trophy-basketball)',
    //     emoticons: [],
    //     keywords: ['trophy', 'basketball', 'award'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096056-trophy-2.png',
    // },
    // {
    //     name: 'Stadium',
    //     shortNames: ['stadium-basketball'],
    //     custom: true,
    //     text: '(stadium-basketball)',
    //     emoticons: [],
    //     keywords: ['stadium', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096047-stadium-3.png',
    // },
    // {
    //     name: 'Baketballonstadium',
    //     shortNames: ['baketball-on-stadium'],
    //     custom: true,
    //     text: '(baketball-on-stadium)',
    //     emoticons: [],
    //     keywords: ['stadium', 'basketball', 'ground', 'baketballonstadium'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096047-stadium-2.png',
    // },
    // {
    //     name: 'Openstadium',
    //     shortNames: ['open-stadium'],
    //     custom: true,
    //     text: '(open-stadium)',
    //     emoticons: [],
    //     keywords: ['stadium', 'openstadium', 'ground', 'baketballstadium'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096046-stadium-1.png',
    // },

    // {
    //     name: 'Spinbasketball',
    //     shortNames: ['spin-basketball'],
    //     custom: true,
    //     text: '(spin-basketball)',
    //     emoticons: [],
    //     keywords: ['spinbasketball', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096046-spinbasketball.png',
    // },
    // {
    //     name: 'Shooting',
    //     shortNames: ['shooting'],
    //     custom: true,
    //     text: '(shooting)',
    //     emoticons: [],
    //     keywords: ['shooting', 'basketball', 'playerinaction'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096046-shooting.png',
    // },

    // {
    //     name: 'Basketballplayershoes',
    //     shortNames: ['basketballplayershoes'],
    //     custom: true,
    //     text: '(basketballplayershoes)',
    //     emoticons: [],
    //     keywords: ['playerquipment', 'basketballplayershoes'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096046-shoes-1.png',
    // },
    // {
    //     name: 'Referee',
    //     shortNames: ['referee-basketball'],
    //     custom: true,
    //     text: '(referee-basketball)',
    //     emoticons: [],
    //     keywords: ['referee', 'basketball', 'womenreferee', 'whistle'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096046-referee.png',
    // },

    // {
    //     name: 'Podium',
    //     shortNames: ['Podium'],
    //     custom: true,
    //     text: '(Podium)',
    //     emoticons: [],
    //     keywords: ['podium.', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096046-podium.png',
    // },
    // {
    //     name: 'Playing',
    //     shortNames: ['playing-ncaabasketball'],
    //     custom: true,
    //     text: '(playing-ncaabasketball)',
    //     emoticons: [],
    //     keywords: ['playing.', 'basketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096002-playing.png',
    // },
    // {
    //     name: 'Medal',
    //     shortNames: ['medal-ncaabasketball'],
    //     custom: true,
    //     text: '(medal-ncaabasketball)',
    //     emoticons: [],
    //     keywords: ['medal', 'rewad', 'award'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096002-medal-2.png',
    // },
    // {
    //     name: 'Lovebasketball',
    //     shortNames: ['love-basketball'],
    //     custom: true,
    //     text: '(love-basketball)',
    //     emoticons: [],
    //     keywords: ['love-basketball', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096002-lovebasketball.png',
    // },
    // {
    //     name: 'Jersey',
    //     shortNames: ['jersey-ncaabasketball'],
    //     custom: true,
    //     text: '(jersey-ncaabasketball)',
    //     emoticons: [],
    //     keywords: ['jersey', 'dress', 'jersey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096002-jersey-basketball.png',
    // },
    // {
    //     name: 'Basketballgame',
    //     shortNames: ['basketball-game'],
    //     custom: true,
    //     text: '(basketball-game)',
    //     emoticons: [],
    //     keywords: ['basketball-game', 'basketball', 'player', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096001-game-3.png',
    // },

    // {
    //     name: 'Dribblebasketball',
    //     shortNames: ['dribble-ncaabasketball'],
    //     custom: true,
    //     text: '(dribble-ncaabasketball)',
    //     emoticons: [],
    //     keywords: ['dribble-basketball', 'basketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-dribble-basketball-1.png',
    // },

    // {
    //     name: 'Coach',
    //     shortNames: ['coach-ncaabasketball'],
    //     custom: true,
    //     text: '(coach-ncaabasketball)',
    //     emoticons: [],
    //     keywords: ['coach', 'basketball',],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-coach.png',
    // },

    // {
    //     name: 'Catchbasketball',
    //     shortNames: ['catch-ncaabasketball'],
    //     custom: true,
    //     text: '(catch-ncaabasketball)',
    //     emoticons: [],
    //     keywords: ['catchbasketball', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-catchbasketball.png',
    // },

    // {
    //     name: 'Board',
    //     shortNames: ['board-ncaabasketball'],
    //     custom: true,
    //     text: '(board-ncaabasketball)',
    //     emoticons: [],
    //     keywords: ['board', 'field'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-board.png',
    // },
    // {
    //     name: 'Basketballonbench',
    //     shortNames: ['ncaabasketball-on-bench'],
    //     custom: true,
    //     text: '(ncaabasketball-on-bench)',
    //     emoticons: [],
    //     keywords: ['basketballonbench', 'bench', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-bench.png',
    // },

    // {
    //     name: 'Basketballtournament',
    //     shortNames: ['basketball-tournament'],
    //     custom: true,
    //     text: '(basketball-tournament.)',
    //     emoticons: [],
    //     keywords: ['basketballtournament', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-basketball-tournament.png',
    // },
    // {
    //     name: 'Players',
    //     shortNames: ['players-ncaabasketball'],
    //     custom: true,
    //     text: '(players-ncaabasketball)',
    //     emoticons: [],
    //     keywords: ['basketballplayers', 'players', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-basketball-players.png',
    // },
    // {
    //     name: 'Spinbasketballplayer',
    //     shortNames: ['spin-ncaabasketball-player'],
    //     custom: true,
    //     text: '(spin-ncaabasketball-player)',
    //     emoticons: [],
    //     keywords: ['basketball-player', 'basketball', 'player', 'spin'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-basketball-player-9.png',
    // },
    // {
    //     name: 'Manbasketballplayer',
    //     shortNames: ['man-basketball-player'],
    //     custom: true,
    //     text: '(man-basketball-player)',
    //     emoticons: [],
    //     keywords: ['manbasketballplayer', 'basketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-basketball-player-8.png',
    // },
    // {
    //     name: 'Bankshotplayer',
    //     shortNames: ['bank-shot-player-basketball'],
    //     custom: true,
    //     text: '(bank-shot-player-basketball)',
    //     emoticons: [],
    //     keywords: ['bankshotplayer', 'basketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-player-6.png',
    // },
    // {
    //     name: 'Basketballjersey',
    //     shortNames: ['basketball-jersey'],
    //     custom: true,
    //     text: '(basketball-jersey)',
    //     emoticons: [],
    //     keywords: ['basketballjersey', 'basketball', 'jersey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-jersey-1.png',
    // },
    // {
    //     name: 'Basketballicons',
    //     shortNames: ['basket-ballicons'],
    //     custom: true,
    //     text: '(basket-ballicons)',
    //     emoticons: [],
    //     keywords: ['basketballicons', 'basketball', 'basket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketballicons.png',
    // },

    // {
    //     name: 'Basketballhoop',
    //     shortNames: ['ncaabasketball-hoop'],
    //     custom: true,
    //     text: '(ncaabasketball-hoop)',
    //     emoticons: [],
    //     keywords: ['basketballhoop', 'basketball', 'basket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-hoop.png',
    // },
    // {
    //     name: 'Hoop',
    //     shortNames: ['hoop-ncabasketball'],
    //     custom: true,
    //     text: '(hoop-ncaabasketball)',
    //     emoticons: [],
    //     keywords: ['basketballhoop', 'basketball', 'hoop'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-hoop-1.png',
    // },
    // {
    //     name: 'Basketballcourt',
    //     shortNames: ['ncaabasketball-court'],
    //     custom: true,
    //     text: '(ncaabasketball-court)',
    //     emoticons: [],
    //     keywords: ['basketballhoop', 'basketball', 'basketballgame', 'hoop', 'basketballcourt'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketballgame.png',
    // },
    // {
    //     name: 'Scratchbasketballcourt',
    //     shortNames: ['scratchbasketballcourt-ncaabasketball'],
    //     custom: true,
    //     text: '(scratchbasketballcourt-ncaabasketball)',
    //     emoticons: [],
    //     keywords: ['basketballhoop', 'basketballcourt', 'basketballgame', 'hoop', 'scratchbasketballcourt'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-court-2.png',
    // },
    // {
    //     name: 'Basketballbadge',
    //     shortNames: ['basketball-badge'],
    //     custom: true,
    //     text: '(basketball-badge)',
    //     emoticons: [],
    //     keywords: ['basketballbadge', 'basketball', 'medal', 'rewad'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095948-basketball-badge.png',
    // },
    // {
    //     name: 'Handicapbasketballplayer',
    //     shortNames: ['Handicap-basketball-player'],
    //     custom: true,
    //     text: '(Handicap-basketball-player)',
    //     emoticons: [],
    //     keywords: ['basketballhoop', 'basketball', 'handicapplayer', 'hoop', 'handicapbasketballplayer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095948-basketball-18.png',
    // },
    // {
    //     name: 'Basketballbrochure',
    //     shortNames: ['basketball-brochure'],
    //     custom: true,
    //     text: '(basketball-brochure)',
    //     emoticons: [],
    //     keywords: ['brochure', 'basketball', 'jersey', 'basketballbrochure'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095828-basketball-brochure.png',
    // },

    // {
    //     name: 'Practicebasketball',
    //     shortNames: ['practice-basketball'],
    //     custom: true,
    //     text: '(practice-basketball)',
    //     emoticons: [],
    //     keywords: ['player', 'basketball', 'practicebasketball', 'hoop', 'basketballcourt'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095828-basketball-17.png',
    // },
    // {
    //     name: 'SlamDunk',
    //     shortNames: ['slam-dunk'],
    //     custom: true,
    //     text: '(slam-dunk)',
    //     emoticons: [],
    //     keywords: ['slamdunk', 'basketballcourt', 'basketballgame', 'hoop'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095827-basketball-16.png',
    // },

    // {
    //     name: 'Dunkshot',
    //     shortNames: ['dunkshot-ncaabasketball'],
    //     custom: true,
    //     text: '(dunkshot-ncaabasketball)',
    //     emoticons: [],
    //     keywords: ['basketball', 'dunkshot', 'player', 'dunk'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666182509-dunkshot.png',
    // },
    // {
    //     name: 'Colouredbasketball',
    //     shortNames: ['coloured-ncaabasketball'],
    //     custom: true,
    //     text: '(coloured-ncaabasketball)',
    //     emoticons: [],
    //     keywords: ['basketball', 'colouredbasketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-coloured-basketball.png',
    // },
    // {
    //     name: 'Basketring',
    //     shortNames: ['basket-ring=ncaabasketball'],
    //     custom: true,
    //     text: '(basket-ring-ncaabasketball)',
    //     emoticons: [],
    //     keywords: ['basket', 'basketring'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-basket-ring.png',
    // },
    // {
    //     name: 'Basketring',
    //     shortNames: ['ncaabasket-ring'],
    //     custom: true,
    //     text: '(ncaabasket-ring)',
    //     emoticons: [],
    //     keywords: ['basket', 'basketring'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-basket-ring.png',
    // },

    // {
    //     name: 'Basketballlogo',
    //     shortNames: ['basketball-logo'],
    //     custom: true,
    //     text: '(basketball-logo)',
    //     emoticons: [],
    //     keywords: ['basketballlogo', 'basketball', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666200379-basketball-logo.png',
    // },
    // {
    //     name: 'Ncaabasketball',
    //     shortNames: ['ncaa-basketball'],
    //     custom: true,
    //     text: '(ncaa-basketball)',
    //     emoticons: [],
    //     keywords: ['ncaabasketball', 'basketball', 'ncaa'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666200663-ncaa-basketball.png',
    // },
    // {
    //     name: 'Ncaacollagebasketbal',
    //     shortNames: ['ncaa-collage-basketbal'],
    //     custom: true,
    //     text: '(ncaa-collage-basketbal)',
    //     emoticons: [],
    //     keywords: ['ncaacollagebasketbal', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666200663-ncaa-collage-basketball.png',
    // },
    // {
    //     name: 'ncaa-logo',
    //     shortNames: ['ncaa-logo'],
    //     custom: true,
    //     text: '(ncaa-logo)',
    //     emoticons: [],
    //     keywords: ['ncaa-logo', 'basketball', 'ncaa', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666200663-ncaa-logo.png',
    // },
    // {
    //     name: 'Ncaalogo',
    //     shortNames: ['ncaa-png-logo'],
    //     custom: true,
    //     text: '(ncaa-png-logo)',
    //     emoticons: [],
    //     keywords: ['ncaa-png-logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666166230-football-ncaa-png-logo-7.png',
    // },
    // {
    //     name: 'Associationlogo',
    //     shortNames: ['association-logo'],
    //     custom: true,
    //     text: '(association-logo)',
    //     emoticons: [],
    //     keywords: ['association-logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666166472-national-collegiate-athletic-association-png-logo-2.png',
    // },
    // {
    //     name: 'Rugby',
    //     shortNames: ['rugby'],
    //     custom: true,
    //     text: '(rugby)',
    //     emoticons: [],
    //     keywords: ['rugby'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665745650-rugby.png',
    // },
    // {
    //     name: 'American football player',
    //     shortNames: ['american-football-player'],
    //     custom: true,
    //     text: '(american-football-player)',
    //     emoticons: [],
    //     keywords: ['american-football-player', 'american', 'football', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665745938-american-football.png',
    // },
    // {
    //     name: 'Rugbytrophygold',
    //     shortNames: ['rugby-trophy-gold'],
    //     custom: true,
    //     text: '(rugby-trophy-gold)',
    //     emoticons: [],
    //     keywords: ['rugby-trophy-gold'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092000-trophy-1.png',
    // },
    // {
    //     name: 'Competition',
    //     shortNames: ['competition-ncaa-football'],
    //     custom: true,
    //     text: '(competition-ncaa-football)',
    //     emoticons: [],
    //     keywords: ['competition', 'match', 'live'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665746559-competition.png',
    // },

    // {
    //     name: 'Helmet',
    //     shortNames: ['helmet-ncaa-football'],
    //     custom: true,
    //     text: '(helmetl-ncaa-football)',
    //     emoticons: [],
    //     keywords: ['helmet'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092000-american-football-2.png',
    // },
    // {
    //     name: 'Rugby ball',
    //     shortNames: ['rugbyball'],
    //     custom: true,
    //     text: '(rugbyball)',
    //     emoticons: [],
    //     keywords: ['soccer-ball', 'ball', 'rugby-ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747078-ball.png',
    // },
    // {
    //     name: 'Free-kick',
    //     shortNames: ['free-kick'],
    //     custom: true,
    //     text: '(free-kick)',
    //     emoticons: [],
    //     keywords: ['free-kick', 'ball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747357-free-kick.png',
    // },
    // {
    //     name: 'Trophy',
    //     shortNames: ['trophy-ncaa-football'],
    //     custom: true,
    //     text: '(trophy-ncaa-football)',
    //     emoticons: [],
    //     keywords: ['trophy', 'win'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747834-trophy.png',
    // },
    // {
    //     name: 'Tournament',
    //     shortNames: ['tournament-ncaa-football'],
    //     custom: true,
    //     text: '(tournament-ncaa-football)',
    //     emoticons: [],
    //     keywords: ['tournament'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092000-rugby-tournament.png',
    // },

    // {
    //     name: 'Whistle',
    //     shortNames: ['whistle-ncaa-football'],
    //     custom: true,
    //     text: '(whistle-ncaa-football)',
    //     emoticons: [],
    //     keywords: ['whistle'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092101-whistle.png',
    // },

    // {
    //     name: 'Goldentrophy',
    //     shortNames: ['golden-trophy'],
    //     custom: true,
    //     text: '(golden-trophy)',
    //     emoticons: [],
    //     keywords: ['goldentrophy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092101-trophy-2.png',
    // },

    // {
    //     name: 'Rugbytrophy',
    //     shortNames: ['rugby-trophy-ncaa-football'],
    //     custom: true,
    //     text: '(rugby-trophy-ncaa-football)',
    //     emoticons: [],
    //     keywords: ['rugby-trophy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092084-trophy-1.png',
    // },

    // {
    //     name: 'Fireball',
    //     shortNames: ['fireball'],
    //     custom: true,
    //     text: '(fireball)',
    //     emoticons: [],
    //     keywords: ['fireball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092084-rugby-ball-4.png',
    // },

    // {
    //     name: 'rugbyplayer',
    //     shortNames: ['rugby-player'],
    //     custom: true,
    //     text: '(rugby-player)',
    //     emoticons: [],
    //     keywords: ['rugby-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092084-rugby-player.png',
    // },
    // {
    //     name: 'Tackle',
    //     shortNames: ['tackle'],
    //     custom: true,
    //     text: '(tackle)',
    //     emoticons: [],
    //     keywords: ['tackle', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092084-tackle.png',
    // },

    // {
    //     name: 'Scoreboard',
    //     shortNames: ['scoreboard-ncaa-football'],
    //     custom: true,
    //     text: '(scoreboard-ncaa-football)',
    //     emoticons: [],
    //     keywords: ['scoreboard'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092084-scoreboard.png',
    // },
    // {
    //     name: 'Touchdown',
    //     shortNames: ['touchdown'],
    //     custom: true,
    //     text: '(touchdown)',
    //     emoticons: [],
    //     keywords: ['touchdown'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092083-touchdown-1.png',
    // },
    // {
    //     name: 'Rugbygoal',
    //     shortNames: ['rugby-goal'],
    //     custom: true,
    //     text: '(rugby-goal)',
    //     emoticons: [],
    //     keywords: ['rugby-goal'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092083-rugby-goal.png',
    // },
    // {
    //     name: 'Runingplayer',
    //     shortNames: ['runing-player'],
    //     custom: true,
    //     text: '(runing-player)',
    //     emoticons: [],
    //     keywords: ['runing-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092083-rugby-player-1.png',
    // },
    // {
    //     name: 'Rugbyking',
    //     shortNames: ['rugby-king'],
    //     custom: true,
    //     text: '(rugby-king)',
    //     emoticons: [],
    //     keywords: ['rugby-king'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092083-rugby-ball-3.png',
    // },
    // {
    //     name: 'Happyplayer',
    //     shortNames: ['happy-player'],
    //     custom: true,
    //     text: '(happy-player)',
    //     emoticons: [],
    //     keywords: ['happy-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092065-rugby-ball-1.png',
    // },
    // {
    //     name: 'Ballplayer',
    //     shortNames: ['ball-player'],
    //     custom: true,
    //     text: '(ball-player)',
    //     emoticons: [],
    //     keywords: ['ball-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092065-rugby-5.png',
    // },
    // {
    //     name: 'Rugbystand',
    //     shortNames: ['rugby-stand'],
    //     custom: true,
    //     text: '(rugby-stand)',
    //     emoticons: [],
    //     keywords: ['rugby-stand'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092065-rugby-2.png',
    // },
    // {
    //     name: 'Star',
    //     shortNames: ['star-ncaa-football'],
    //     custom: true,
    //     text: '(star-ncaa-football)',
    //     emoticons: [],
    //     keywords: ['star'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092064-rugby-4.png',
    // },
    // {
    //     name: 'Rugbylove',
    //     shortNames: ['rugby-love'],
    //     custom: true,
    //     text: '(rugby-love)',
    //     emoticons: [],
    //     keywords: ['rugby-love'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092064-rugby-3.png',
    // },
    // {
    //     name: 'Rugbylover',
    //     shortNames: ['rugby-lover'],
    //     custom: true,
    //     text: '(rugby-lover)',
    //     emoticons: [],
    //     keywords: ['rugby-lover'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092064-rugby-6.png',
    // },

    // {
    //     name: 'Newrugby',
    //     shortNames: ['new-rugby'],
    //     custom: true,
    //     text: '(new-rugby)',
    //     emoticons: [],
    //     keywords: ['new-rugby'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092064-rugby-ball-2.png',
    // },

    // {
    //     name: 'Rugbyboy',
    //     shortNames: ['rugby-boy'],
    //     custom: true,
    //     text: '(rugby-boy)',
    //     emoticons: [],
    //     keywords: ['rugby-boy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092064-rugby-1.png',
    // },
    // {
    //     name: 'Team',
    //     shortNames: ['team-ncaa-football'],
    //     custom: true,
    //     text: '(team-ncaa-football)',
    //     emoticons: [],
    //     keywords: ['team'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092064-player-2.png',
    // },
    // {
    //     name: 'Association',
    //     shortNames: ['association'],
    //     custom: true,
    //     text: '(association)',
    //     emoticons: [],
    //     keywords: ['association'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092038-association.png',
    // },
    // {
    //     name: 'Pinkhelmet',
    //     shortNames: ['pink-helmet'],
    //     custom: true,
    //     text: '(pink-helmet)',
    //     emoticons: [],
    //     keywords: ['pink-helmet'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092038-helmet.png',
    // },

    // {
    //     name: 'hat',
    //     shortNames: ['hat'],
    //     custom: true,
    //     text: '(hat)',
    //     emoticons: [],
    //     keywords: ['hat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092038-hat.png',
    // },

    // {
    //     name: 'Goal',
    //     shortNames: ['goal-ncaa-football'],
    //     custom: true,
    //     text: '(goal-ncaa-football)',
    //     emoticons: [],
    //     keywords: ['goal'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092038-goal.png',
    // },

    // {
    //     name: 'Hourglass',
    //     shortNames: ['hourglass'],
    //     custom: true,
    //     text: '(hourglass)',
    //     emoticons: [],
    //     keywords: ['hourglass'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159032-hourglass.png',
    // },

    // {
    //     name: 'Calendar',
    //     shortNames: ['calendar-ncaa-football'],
    //     custom: true,
    //     text: '(calendar-ncaa-football)',
    //     emoticons: [],
    //     keywords: ['calendar'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159032-calendar.png',
    // },

    // {
    //     name: 'Smartphone',
    //     shortNames: ['smartphone'],
    //     custom: true,
    //     text: '(smartphone)',
    //     emoticons: [],
    //     keywords: ['smartphone'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159033-smartphone.png',
    // },
    // {
    //     name: 'Womenplayer',
    //     shortNames: ['womenplayer'],
    //     custom: true,
    //     text: '(womenplayer)',
    //     emoticons: [],
    //     keywords: ['womenplayer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159033-player-4.png',
    // },

    // {
    //     name: 'Women',
    //     shortNames: ['women'],
    //     custom: true,
    //     text: '(women)',
    //     emoticons: [],
    //     keywords: ['women'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159033-american-football-player-3.png',
    // },

    // {
    //     name: 'Men',
    //     shortNames: ['men'],
    //     custom: true,
    //     text: '(men)',
    //     emoticons: [],
    //     keywords: ['men'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159033-american-football-player-2.png',
    // },
    // {
    //     name: 'Thinking',
    //     shortNames: ['thinking'],
    //     custom: true,
    //     text: '(thinking)',
    //     emoticons: [],
    //     keywords: ['thinking'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159033-player-3.png',
    // },

    // {
    //     name: 'Megaphone',
    //     shortNames: ['megaphone'],
    //     custom: true,
    //     text: '(megaphone)',
    //     emoticons: [],
    //     keywords: ['megaphone'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159033-megaphone.png',
    // },

    // {
    //     name: 'Tackleplayer',
    //     shortNames: ['tackle-player'],
    //     custom: true,
    //     text: '(tackle-player)',
    //     emoticons: [],
    //     keywords: ['tackle-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159033-tackle.png',
    // },
    // {
    //     name: 'Americanplayer',
    //     shortNames: ['american-player'],
    //     custom: true,
    //     text: '(american-player)',
    //     emoticons: [],
    //     keywords: ['american-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-american-football-player-1.png',
    // },

    // {
    //     name: 'Boy',
    //     shortNames: ['boy'],
    //     custom: true,
    //     text: '(boy)',
    //     emoticons: [],
    //     keywords: ['boy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-boy.png',
    // },

    // {
    //     name: 'Ballinhand',
    //     shortNames: ['ball-in-hand'],
    //     custom: true,
    //     text: '(ball-in-hand)',
    //     emoticons: [],
    //     keywords: ['ball-in-hand'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-rugby-player-on-one-knee-with-the-ball-in-a-hand.png',
    // },
    // {
    //     name: 'Runing',
    //     shortNames: ['runing'],
    //     custom: true,
    //     text: '(runing)',
    //     emoticons: [],
    //     keywords: ['runing'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-rugby-player-2.png',
    // },

    // {
    //     name: 'Yellowplayer',
    //     shortNames: ['yellow-player'],
    //     custom: true,
    //     text: '(yellow-player)',
    //     emoticons: [],
    //     keywords: ['yellow-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-rugby-player-1.png',
    // },
    // {
    //     name: 'playerwithball',
    //     shortNames: ['player-with-ball'],
    //     custom: true,
    //     text: '(player-with-ball)',
    //     emoticons: [],
    //     keywords: ['player-with-ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-rugby-player-with-the-ball.png',
    // },
    // {
    //     name: 'Competitive',
    //     shortNames: ['competitive'],
    //     custom: true,
    //     text: '(competitive)',
    //     emoticons: [],
    //     keywords: ['competitive'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-competitive.png',
    // },

    // {
    //     name: 'Chase',
    //     shortNames: ['chase'],
    //     custom: true,
    //     text: '(chase)',
    //     emoticons: [],
    //     keywords: ['chase'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-chase.png',
    // },
    // {
    //     name: 'Enjoy',
    //     shortNames: ['enjoy-ncaa-football'],
    //     custom: true,
    //     text: '(enjoy-ncaa-football)',
    //     emoticons: [],
    //     keywords: ['enjoy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-player-2.png',
    // },
    // {
    //     name: 'Playrwithhelmet',
    //     shortNames: ['playr-with-helmet'],
    //     custom: true,
    //     text: '(playr-with-helmet)',
    //     emoticons: [],
    //     keywords: ['playr-with-helmets'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159088-rugby-3.png',
    // },

];