import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipePipe } from './safe-html-pipe.pipe';
import { ReplaceEmojisPipe } from './emoji.pipe';
import { MonthPipe } from './monts.pipe';
import { CheckHtmlValidityPipe } from './check-html-validity.pipe';
import { AddressPipe } from './address.pipe';
import { TransformStringPipe } from './transform-string.pipe';
import { GenderTransformPipe } from './gender-transform.pipe';
import { IframeTransformPipe } from './iframe-transform.pipe';


@NgModule({
  declarations: [
    SafeHtmlPipePipe,
    ReplaceEmojisPipe,
    MonthPipe,
    CheckHtmlValidityPipe,
    AddressPipe,
    TransformStringPipe,
    GenderTransformPipe,
    IframeTransformPipe
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    SafeHtmlPipePipe,
    ReplaceEmojisPipe,
    MonthPipe,
    CheckHtmlValidityPipe,
    AddressPipe,
    TransformStringPipe,
    GenderTransformPipe,
    IframeTransformPipe
  ]
})
export class PipesModule { }
