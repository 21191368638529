import { Injectable, NgZone } from '@angular/core';

@Injectable()
export class VideoProcessingService {

  constructor(
    private ngZone: NgZone,
  ) { }

  // New funtion ofr genrating the preview(D)
  public generateThumbnail(file, seekTo = 0.0) {
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener('error', (ex) => {
        //here i am added new error because its provided a more structured error message
        reject(new Error("Error when loading video file"));

      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener('loadedmetadata', () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject(new Error("Video is too short."));
          //here i am added new error because its provided a more structured error message
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari



        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            videoPlayer.currentTime = seekTo;
          }, 200);
        })
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener('seeked', () => {
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          resolve(canvas.toDataURL());
        });
      });
    });
  }

  

}



