import { Component, HostListener, Input, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { switchMap, finalize } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { EditProfileService } from '../../_Service/EditProfile/edit-profile.service';
import { ToastrService } from 'ngx-toastr';
import { IndexService } from '../../_Service/Index/index.service';
import { UserConnectionService } from '../../_Service/UserConnection/user-connection.service';
import { CountryFlagService } from '../../_Service/country-flag.service';
import * as moment from 'moment-timezone';
import { DatePipe } from '@angular/common';

import { environment } from '../../../environments/environment';
//Encrypt and Decrypt
import * as CryptoJS from 'crypto-js';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';

import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import parsePhoneNumber from 'libphonenumber-js';
import { SigninSignUpService } from '../../_Service/UsersSignInSignUp/signin-sign-up.service';

import { GuidedTourService, Orientation } from 'ngx-guided-tour';
import { ReplaceEmojisPipe } from '../../_pipes/emoji.pipe';
import { Platform } from '@ionic/angular';

import { VideocallService } from '../../_Service/Videocall/videocall.service';

declare let $: any;
declare let cordova: any;
declare let FirebasePlugin: any;

@Component({
  selector: 'ap-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  providers: [ReplaceEmojisPipe]
})
export class NavigationComponent implements OnInit {

  //Adding the Array of country code with flags(D)

  //End Adding the Array of country code with flags(D)
  help_center = environment.help_center;
  terms_condition = environment.terms_and_condition;
  privacy_policy = environment.privacy_policy;


  isCollapsed: boolean = false;

  //User profile and cover
  platform_id: any;
  platform_name: any;
  user_profile_img: any;
  faqUrl: any;
  value: any;
  display: string;
  name: string;
  countryFlags: any[];

  inviteUserArr: any;


  modalRef: BsModalRef;
  modalRef1: BsModalRef;
  inviteForm: FormGroup;
  radioSel: any;
  radioSelected: string;
  userNaviDetails: any = {};

  //Timezone
  @Input() timezone: any = '';
  countryString: any;
  phoneNumber: any;
  phoneNumberInvalid: string;

  //Invitation variable(D)
  inviteByEmail: FormGroup;
  inviteByPhone: FormGroup;
  submitted: any;
  submittedPhone: any;
  //check the phone code is not enter the string(D)
  UserInputPhoneCheck: string;
  tempContact: any;
  UserInputEmailCheck: boolean = false;



  platform_logo: any;
  svg: any;
  phoneCode: any
  popup_content_singular: any;
  popup_content: any //Dynamic set the popup hover content
  other_popup_content: any; //Dynamic set the popup hover content
  alias_mode_name: any; // Alias Mode name in Open Forum.
  alias_lower_case_user_name: any;
  alias_user_name: any;

  web_shortcut: any;
  playStoreAppShortcut: any;
  appleStoreAppShortcut: any;
  ip_addr: any;

  //For line loader(D)
  indexNo: any;
  lineLoader: boolean;
  lineLoaderPost: boolean;
  indexNoPost: any;
  userId: any;//user id user variable(n)
  postId: any;
  clicked: boolean;
  isFollowBackclicked: boolean;
  acceptConnectRequestClicked: boolean;
  rejectConnectRequestClicked: boolean;
  //For line loader(D)
  acceptPostRequestClicked: boolean;
  rejectPostRequestClicked: boolean;
  connectRequest: any;
  connectRequestPost: any;
  isAcceptOpenModalClick: boolean;

  followingUserId: any;   //connect user id user variable(a)
  followingUserType: any;
  followerUserType: any;
  followUserId: any;

  //snak bar(D)
  message: string = 'You have new notification';
  actionButtonLabel: string = 'message';
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 4000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'left';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  //snak bar(D)
  //Contact list (D)
  isDeviceSupported: boolean;
  isDeviceIphone: boolean;
  isDeviceAndriod: boolean;
  isDeviceIpad: boolean;
  contactEnable: number;
  selectPhoneNumber: string;
  multiContactArr: any;
  contactUserName: any;
  //Contact list (D)

  badgeClass: any;
  project_url: any;
  res_profile_type: any;
  controls: boolean;

  isChatPage: boolean;

  // Close the pop modal on back button click (D)
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }
  // Close the pop modal on back button click (D)
  showShortcut: boolean = true;
  constructor(

    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private route: Router,
    private toastr: ToastrService,
    private _UserConnectionService: UserConnectionService,
    private datePipe: DatePipe,
    private _IndexService: IndexService,
    private _countryFlagService: CountryFlagService,
    private http: HttpClient,
    private _EditProfileService: EditProfileService,
    config: NgbPopoverConfig,
    public snackBar: MatSnackBar,
    private _SigninSignUp: SigninSignUpService,
    private guidedTourService: GuidedTourService,
    private emojPipe: ReplaceEmojisPipe,
    private platform: Platform,
    private _VideocallService: VideocallService,
  ) {
    if (this.platform.is('cordova')) {

      this.platform.resume.subscribe((res) => {
        // alert('app resumed');
        this.getNotificatioNumberCountRefresh();
        this.getMessageNotificationCount();
      });

      this.showShortcut = false;
      this.getAppVersion();
    }
    if (/Android/i.test(navigator.userAgent)) {
      this.web_shortcut = environment.android_web_shortcut;
      this.isDeviceAndriod = true;
    } else if (/iPhone/i.test(navigator.userAgent)) {
      this.web_shortcut = environment.android_web_shortcut;
      this.isDeviceIphone = true;
    } else if (/iPad/i.test(navigator.userAgent)) {
      this.web_shortcut = environment.android_web_shortcut;
      this.isDeviceIpad = true;
    } else {
      this.web_shortcut = environment.android_web_shortcut;
      this.isDeviceAndriod = false;
      this.isDeviceIphone = false;
      this.isDeviceIpad = false;
    }

    //Checking the device type
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.controls = true;
    } else {
      this.controls = false;
    }
    //End Checking the device type

    this.project_url = environment.project_url;

    this.user_profile_img = environment.user_profile_img;
    this.platform_id = environment.platform_id;
    this.platform_logo = environment.platform_logo;
    this.platform_name = environment.platform_name;
    this.alias_mode_name = environment.alias_mode_name;
    this.alias_lower_case_user_name = environment.alias_lower_case_user_name;
    this.alias_user_name = environment.alias_user_name;
    this.faqUrl = environment.faqUrl;
    this.popup_content_singular = environment.popup_content_singular;
    this.popup_content = environment.popup_content;
    this.other_popup_content = environment.other_popup_content;
    this.svg = environment.svg;
    this.badgeClass = environment.badgeClass;
    this.playStoreAppShortcut = environment.playStoreAppShortcut;
    this.appleStoreAppShortcut = environment.appleStoreAppShortcut;
    //Timezone
    this.countryString = '';
    this.phoneCode = '';

    if (this.route.url.includes('eventChat')) {
      this.isChatPage = true;
    } else {
      this.isChatPage = false;
    }


    // customize default values of popovers used by this component tree
    //get the country string(D)
    if (localStorage.getItem("countryString") && this.decryptData(localStorage.getItem('countryString'))) {
      this.countryString = this.decryptData(localStorage.getItem('countryString'));
    }
    config.placement = 'bottom';
    config.triggers = 'hover';

    this._IndexService.profileImageService.subscribe(refrsh => {
      if (refrsh && refrsh != '') {
        this.ngOnInit();
      }
    });

    this._IndexService.userNameUpdate.subscribe(userNamerefresh => {
      if (userNamerefresh && userNamerefresh != '') {
        this.refreshUserInfo()
      }
    });


    this._IndexService.aliasFriendMessageService.subscribe(refrsh => {

      this.activeTab = this.decryptData(localStorage.getItem('activeTab'));

      if (refrsh.includes('Other')) {
        if (refrsh == 'Other_Main') {
          this.filteredUsers = [];
          this.login_user_type = 1;
          this.section_type = 1;

        } else if (refrsh == 'Other_Alias') {
          this.filteredUsers = [];

          this.login_user_type = 2;
          this.section_type = 2;
        } else {
          this.filteredUsers = [];
          this.route.navigate(['/index']);
        }
      } else {

        if (localStorage.getItem("userProfileType") && this.decryptData(localStorage.getItem('userProfileType'))
          && this.decryptData(localStorage.getItem('userProfileType')) == 'Main') {

          this.filteredUsers = [];
          this.login_user_type = 1;
          this.section_type = 1;

          if (localStorage.getItem("activeTab") && this.decryptData(localStorage.getItem('activeTab')) == 'Pals') {
            this.activeTab = 'Pals';
          } else {
            // set active tab variable (a)
            this.activeTab = this.decryptData(localStorage.getItem('activeTab'));
          }

        } else if (localStorage.getItem("userProfileType") && this.decryptData(localStorage.getItem('userProfileType'))
          && this.decryptData(localStorage.getItem('userProfileType')) == 'Alias') {

          this.filteredUsers = [];
          if (localStorage.getItem("activeTab") && this.decryptData(localStorage.getItem('activeTab')) == 'Open Forum') {
            this.activeTab = 'Open Forum';
          } else {
            // set active tab variable (a)
            this.activeTab = this.decryptData(localStorage.getItem('activeTab'));
          }

          //Open Fofun check user type(n)
          if (localStorage.getItem("openForumUserProfileType") && this.decryptData(localStorage.getItem('openForumUserProfileType'))) {
            this.section_type = 2;
            if (this.decryptData(localStorage.getItem('openForumUserProfileType')) == '1') {
              this.login_user_type = this.decryptData(localStorage.getItem('openForumUserProfileType'));

            } else if (this.decryptData(localStorage.getItem('openForumUserProfileType')) == '2') {
              this.login_user_type = this.decryptData(localStorage.getItem('openForumUserProfileType'));
            } else {
              this.login_user_type = 1;
            }
          } else {
            this.section_type = 1;
            this.login_user_type = 1;
          }
          //Open Fofun check user type(n)

        } else {

          if (refrsh == 'Main') {
            this.filteredUsers = [];
            this.login_user_type = 1;
            this.section_type = 1;
          } else if (refrsh == 'Alias') {
            this.filteredUsers = [];
            //Open Fofun check user type(n)
            if (localStorage.getItem("openForumUserProfileType") && this.decryptData(localStorage.getItem('openForumUserProfileType'))) {
              this.section_type = 2;
              if (this.decryptData(localStorage.getItem('openForumUserProfileType')) == '1') {
                this.login_user_type = this.decryptData(localStorage.getItem('openForumUserProfileType'));
              } else if (this.decryptData(localStorage.getItem('openForumUserProfileType')) == '2') {
                this.login_user_type = this.decryptData(localStorage.getItem('openForumUserProfileType'));
              } else {
                this.login_user_type = 1;
              }
            } else {
              this.section_type = 1;
              this.login_user_type = 1;
            }
            //Open Fofun check user type(n)
          } else {
            this.filteredUsers = [];
            this.route.navigate(['/index']);
          }

        }
      }
    });
  }

  appVersion: string;

  getAppVersion(): void {
    if (typeof cordova !== 'undefined') {
      cordova.getAppVersion.getVersionNumber().then((version: string) => {
        this.appVersion = version;
      }).catch(() => {
        this.appVersion = '';
      });
    } else {
      this.appVersion = '';
    }
  }

  refrsh: any;
  login_user_type: any;

  filteredUsers: any = [];
  usersForm: FormGroup;
  isLoading = false;
  inviteShowBtn: any;

  //Notification variable(n)
  getUserNameArr: any;
  showMoreUserList: any;
  nextPage: any;
  primaryLoader: any = false;
  notiCountPost: any;
  notDataAvailableLoader: any;
  notification_count: any;
  notification_ids: any;

  notification_login_user_id: any;
  //Notification variable(n)

  //Message variable(n)
  getChatMessageArr: any;

  showMoreMessageList: any;
  nextPageMessageList: any;

  message_notification_count: any;
  message_user_login_id: any;

  getChatMessagePost: any;
  getChatMessageLoader: any;
  getChatMessageNoDataLoader: any;
  //End Message variable(n)

  // active tab variable (a)
  activeTab: any;
  section_type: any;
  status: any;
  cur_date: any;

  //Check the Alias user name(D)
  aliasNameBlank: boolean;
  //Check the Alias user name(D)

  //show and hide the pending post(D)
  pendingPost: boolean = false;
  autoCloseStatus: boolean = true;
  cur_date_time: any
  //show and hide the pending post(D)
  user_details: any;

  totalMatchCount: any = 0;

  changeActiveTab(active) {
    this.activeTab = active;
    localStorage.setItem("activeTab", this.encryptData(active));
    this.usersForm.controls.userInput.setValue('');
    this.pendingPost = false;
    let aliasName = this.decryptData(localStorage.getItem('aliasName'));
    if (aliasName == '1') {
      this.aliasNameBlank = false;
    }
  }

  videocallregister() { 
    // console.log(this.user_details.id);
    this._VideocallService.loginUsersetArray(this.user_details);
    // this._VideocallService.register(this.user_details.id);
  }

  ngOnInit() {

    if (localStorage.getItem('token')) {
      this.status = 1;
    } else {
      this.status = 3;
    }

    this.totalMatchCount = 0;

    //Scroller Function With Show More Funcationality(n)
    window.addEventListener('scroll', this.scroll, true); //third parameter
    //End Scroller Function With Show More Funcationality(n)
    this.isDeviceSupported = ('contacts' in (navigator as any) && 'ContactsManager' in window);
    this.UserInputPhoneCheck = '';
    this.selectPhoneNumber = '';
    this.tempContact = [];
    this.multiContactArr = [];
    this.contactUserName = '';
    this.phoneCode = {};
    this.autoCloseStatus = true;
    this.user_details = {};
    //Invite email and phone form(D)
    this.inviteByEmail = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
    });

    this.inviteByPhone = this.formBuilder.group({
      countryCode: ['+1', [Validators.required, Validators.maxLength(10)]],
      phone: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20), Validators.pattern('^[1-9][0-9]{0,16}$')]],
    });
    this.submitted = false;
    this.submittedPhone = false;
    //End of invite email and phone form(D)

    this.pendingPost = false;



    this.user_details.profile_type = localStorage.getItem('type_tour');
    this.user_details.name = localStorage.getItem('type_tour_name');

    this.countryFlags = this._countryFlagService.getCountryCode();
    this.phoneCode = this.countryFlags[0];
    // this.status = 1;
    if (!localStorage.getItem("activeTab") && !this.decryptData(localStorage.getItem('activeTab'))) {
      localStorage.setItem("activeTab", this.encryptData('Open Forum'));
      this.activeTab = 'Open Forum';
    } else {
      // set active tab variable (a)
      this.activeTab = this.decryptData(localStorage.getItem('activeTab'));
    }

    this.userNaviDetails = {};
    this.userNaviDetails.profile_pic = '';
    this.userNaviDetails.alias_profile_pic = '';

    this.inviteUserArr = {};
    this.inviteUserArr.type = 'phone';
    this.inviteUserArr.email = [];
    this.inviteUserArr.phone = [];
    this.inviteUserArr.countryCode = this.countryFlags[0];

    //Notification variable(n)
    this.getUserNameArr = [];
    this.notiCountPost = {};
    this.notiCountPost.page = '';
    this.notiCountPost.search = '';
    this.notDataAvailableLoader = true;

    //Message variable(n)
    this.getChatMessageArr = [];
    this.getChatMessagePost = {};
    this.getChatMessagePost.page = '';
    this.getChatMessagePost.search = '';

    this.message_notification_count = 0;
    this.message_user_login_id = 0;

    this.getChatMessageLoader = true;
    this.getChatMessageNoDataLoader = true;
    //End Message variable(n)

    //Timezone

    //For line loader(D)
    this.indexNo = '';
    this.lineLoader = false;
    this.lineLoaderPost = false;
    this.indexNoPost = '';
    this.userId = '';//user id user variable(n)
    this.postId = '';
    this.clicked = false;
    this.isFollowBackclicked = false;
    this.acceptConnectRequestClicked = false;
    this.rejectConnectRequestClicked = false;
    //For line loader(D)
    this.acceptPostRequestClicked = false;
    this.rejectPostRequestClicked = false;
    this.isAcceptOpenModalClick = false;
    //Notification variable(n)

    //this.getSelecteditem();
    this.inviteShowBtn = false;
    let set = function () {
      let width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
      let topOffset = 0;
      if (width < 1170) {
        $("#main-wrapper").addClass("mini-sidebar");
      } else {
        $("#main-wrapper").removeClass("mini-sidebar");
      }
    };
    $(window).ready(set);
    $(window).on("resize", set);


    $(".search-box a, .search-box .app-search .srh-btn").on('click', function () {
      $(".app-search").toggle(200);
    });


    $("body").trigger("resize");
    if (localStorage.getItem("token")) {
      let api_token = localStorage.getItem("token");
    }

    this.filteredUsers = [];
    this.usersForm = this.formBuilder.group({
      userInput: [null, [Validators.maxLength(200)]]
    });


    this._EditProfileService.getLoginUserMyProfileDetails().subscribe(
      res => {
        if (res && res['status_code'] == 200) {
          if (res && res['user_details']) {

            
            this._IndexService.sendLoginUserInfo(this.encryptData(JSON.stringify(res)));

            this.user_details = res['user_details'];

            this.videocallregister();

            localStorage.setItem('type_tour', this.user_details.profile_type);
            localStorage.setItem('type_tour_name', this.user_details.name);
            // Check the alias name blank other wise redirect to edit Profile
            if (res && res['user_details'] && res['user_details']['alias_name'] == "") {
              this.aliasNameBlank = true
            } else {
              this.aliasNameBlank = false;
            }
            // Check the alias name blank other wise redirect to edit Profile
          }
          if (res && res['user_details'] && res['user_details']['guidtour'] != 1) {
            setTimeout(() => {
              if (this.controls) {
                this.startTourIGTMob();
              } else {
                this.startTourIGT();
              }
            }, 5000);
          }
        } else if (res && res['status_code'] == 401) {
          localStorage.clear();
          this.route.navigate(['/login']);
          this.toastr.error(res['message'], 'Error!');
          sessionStorage.removeItem('fullcomponent');
        } else {
          this.toastr.error('Something went wrong.', 'Error!');
        }
      }, error => {
        if (error && error['status_code'] == 401) {
          localStorage.clear();
          this.route.navigate(['/login']);
          this.toastr.error(error['message'], 'Error!');
          sessionStorage.removeItem('fullcomponent');
        }
      });

    this._IndexService.getUserNavigationInfo().subscribe(
      res => {
        if (res && res['status_code'] == 200) {
          this.userNaviDetails = res["navi_details"];
          this.status = res["navi_details"]['status'];
          if (res && res["navi_details"] && res["navi_details"]['status'] == 2 && !this.route.isActive('/contact', true)) {
            this.route.navigate(['/contact']);
          }
          //Current Geo Details By User Login(a)
          if (localStorage.getItem('geoDetails')) {
            this.loadMainData();

          } else {
            this._IndexService.currentGeoDetails().subscribe(res => {
              if (res && res['geo'] && res['geo']['timezone']) {
                this.timezone = res['geo']['timezone'];
                localStorage.setItem('geoDetails', this.encryptData(JSON.stringify(res)));

              }
              this.loadMainData();
            });
          }
        } else if (res && res['status_code'] == 401) {
          localStorage.clear();
          this.route.navigate(['/login']);
          this.toastr.error(res['message'], 'Error!');
          sessionStorage.removeItem('fullcomponent');
        }
        //End Current Geo Details By User Login(a)
      }, (err) => {
        this.route.navigate(['/login']);

      });


    //contact_user_id
    //Socket(n)
    //Notification(n)
    this._UserConnectionService.getRequestSocket().subscribe((res) => {
      if (res && res['contact_user_id']) {
        let user_id_array = [];
        let contact_user_id_string = "" + res['contact_user_id'];
        user_id_array = contact_user_id_string.split(",");
        if (user_id_array.includes("" + this.notification_login_user_id)) {
          user_id_array = [];
          this.getNotificatioNumberCountRefresh()
        }
      } else if (res && res['type'] && res['type'] == 'talking points') {
        this.getNotificatioNumberCountRefresh();
      }
    });

    //Message(n)
    this._UserConnectionService.getMessageSocket().subscribe((res) => {
      if (res && res['type'] && res['type'] == 'message') {
        if (res['responce'] && res['responce']['chat_user_id'] == this.message_user_login_id) {
          if (this.route.url === '/messageChat') {
            this._IndexService.chatMessageRefreshService(res);
          } else {
            this.getMessageNotificationCount();
          }
        }
      } else if (res && res['type'] && res['type'] == 'liveMatch') {


      }
    });

    this._IndexService.getChatMessageRefresh1.subscribe(res => {
      if (res && res == 'countRefesh') {
        this.getMessageNotificationCount();
      }
    });
    //Socket(n)
    this.usersForm.get('userInput').valueChanges
      .pipe(
        switchMap(value => {
          if (value == '') {
            return 'false';
          } else {
            value = value.trim();
            return this.http.get(`${environment.base_url}/getSearchUser?apikey=api_token&login_user_type=` + this.login_user_type + `&platform_id=` + this.platform_id + `&section_type=` + this.section_type + `&search=` + encodeURIComponent(value)).pipe(
              finalize(() => {
                this.isLoading = false
              }),
            )
          }

        }
        )
      )
      .subscribe(data => {
        if (data != 'false' && data && data['privacy_settings']) {
          this.filteredUsers = data['privacy_settings'];
        }
        else {
          this.filteredUsers = [];
        }

      })

    //<-------==============Add to home screen button (D) ===========------->
    //A2HS button feature(D)

    this._IndexService.badgeCountService.subscribe(res => {
      if (res && res == 'chatData') {
        this.loadMainData();
      }
    });
  }

  loadMainData() {
    let res = JSON.parse(this.decryptData(localStorage.getItem('geoDetails')));
    if (res && res['geo'] && res['geo']['timezone']) {
      this.timezone = res['geo']['timezone'];

      this.cur_date = moment().tz(this.timezone).format("DD/MM/YYYY");
      this.cur_date_time = moment().format("X");
      this.countryString = res['geo']['country'];
      localStorage.setItem('countryString', this.encryptData(this.countryString));
    }

    if (res && res['public_ip']) {
      if (res['public_ip'].search("::ffff:") != -1) {
        let ip_addr_arr = res['public_ip'].split(':');
        this.ip_addr = ip_addr_arr[3];

        let data = {
          ip_address: this.ip_addr
        }
        this._IndexService.ipUpdateInUser(data).subscribe(result => {

        }, (err) => {

        });
      }
    }


    //Message count get nottification(n)
    this._IndexService.getMessageNotificationCount().subscribe(
      res => {
        if (res && res['status_code'] == 200) {
          this.message_notification_count = res['message_notification_count'];
          this.message_user_login_id = res['message_user_login_id'];
        }

        //Notification count (n)
        this.notification_count = 0;
        this.notification_ids = 0;
        this.notification_login_user_id = 0;
        this._IndexService.getNotificatioNumberCount().subscribe(
          res => {
            if (res && res['status_code'] == 200) {
              this.notification_count = res['notification_count'];
              this.clearBadge(this.notification_count + this.message_notification_count);
              if (res['notification_ids']) {
                this.notification_ids = res['notification_ids'];
              } else {
                this.notification_ids = 0;
              }

              this.notification_login_user_id = res['login_user_id'];
            }

          }, (err) => {
            this.primaryLoader = false;
            if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
              localStorage.clear();
              sessionStorage.removeItem('fullcomponent');
              this.route.navigate(['/login']);
            }
          });
        //Notification count (n)

      });
    //End Message count get nottification(n)
  }

  //<-===================Get the contact list=============->
  getPhoneContactListOpenModal(template: TemplateRef<any>) {
    if (this.isDeviceSupported && (this.isDeviceAndriod || this.isDeviceIphone || this.isDeviceIpad)) {
      this.openContacts()
    } else {
      this.modalRef1 = this.modalService.show(template, { class: 'modal-sm modal-dialog', backdrop: true, ignoreBackdropClick: true });
    }
  }

  openContacts() {
    this.isDeviceSupported = ('contacts' in (navigator as any) && 'ContactsManager' in window);
    let api = ((navigator as any).contacts || (navigator as any).mozContacts);
    if (api && !!api.select) { // new Chrome API
      api.select(['name', 'email', 'tel'], { multiple: false })
        .then((contacts) => {
          this.UserInputPhoneCheck = '';
          this.selectPhoneNumber = '';
          this.tempContact = contacts;
          this.multiContactArr = [];
          this.phoneCode = {};
          if (this.tempContact.length) {
            if (this.tempContact.length > 0 && this.tempContact[0]['tel'].length > 0 && this.tempContact[0]['tel'][0]) {
              if (this.tempContact[0]['tel'].length > 1) {
                this.contactUserName = this.tempContact[0]['name'][0];
                this.multiContactArr = this.tempContact[0]['tel'];
                this.phoneCode = this.countryFlags[0];
              } else {
                this.selectPhoneNumber = this.tempContact[0]['tel'][0];
                this.selectPhoneNumber = String(this.selectPhoneNumber);
                this.countryString = String(this.countryString);
                this.phoneNumber = parsePhoneNumber(this.selectPhoneNumber, this.countryString);
                this.havePhoneNumber()
              }
            } else if (this.tempContact.length > 0 && this.tempContact[0]['tel'].length > 0) {
              if (this.tempContact[0]['tel'].length > 0) {
                this.contactUserName = this.tempContact[0]['name'][0];
                this.multiContactArr = this.tempContact[0]['tel'];
                this.phoneCode = this.countryFlags[0];
              } else {
                this.multiContactArr = this.tempContact[0]['tel'];
                this.contactUserName = this.tempContact[0]['name'][0];
                this.selectPhoneNumber = this.tempContact[0]['tel'];
                this.countryString = String(this.countryString);
                this.phoneNumber = parsePhoneNumber(this.selectPhoneNumber, this.countryString);
                this.havePhoneNumber()
              }
            } else {
              this.multiContactArr = [];
              this.contactUserName = ''
              this.phoneCode = this.countryFlags[0];
              this.selectPhoneNumber = '';
              this.inviteUserArr.phone = [];
              this.phoneNumber = {}
              this.inviteByPhone.patchValue({ 'phone': '' });
              this.UserInputPhoneCheck = "No contact number available";
            }
          } else {
            this.phoneCode = this.countryFlags[0];
            this.selectPhoneNumber = '';
            this.inviteUserArr.phone = [];
            this.phoneNumber = {};
            this.inviteByPhone.patchValue({ 'phone': '' });
            this.UserInputPhoneCheck = "No contact number available";
          }
        })
        .catch((err) => {
          this.phoneCode = this.countryFlags[0];
          this.selectPhoneNumber = '';
          this.phoneNumber = {}
          this.inviteUserArr.phone = [];
          this.inviteByPhone.patchValue({ 'phone': '' });
          this.UserInputPhoneCheck = "No contact number available";
        });
    }
  }

  selectContact(contact, keyNumber) {
    this.contactEnable = keyNumber;
    if (contact) {
      this.selectPhoneNumber = contact;
      this.selectPhoneNumber = String(this.selectPhoneNumber);
      this.countryString = String(this.countryString);
      this.phoneNumber = parsePhoneNumber(this.selectPhoneNumber, this.countryString);
      this.havePhoneNumber()
    } else {
      this.phoneCode = this.countryFlags[0];
      this.selectPhoneNumber = '';
      this.phoneNumber = {}
      this.inviteUserArr.phone = [];
      this.inviteByPhone.patchValue({ 'phone': '' });
      this.multiContactArr = [];
      this.UserInputPhoneCheck = "No contact number available";
    }
  }

  havePhoneNumber() {
    if (this.phoneNumber) {
      if (this.phoneNumber.countryCallingCode) {
        let country_code = '+' + this.phoneNumber.countryCallingCode;
        //Geting the user country code with flag(D)
        for (let i = 0; i < this.countryFlags.length; i++) {
          if (country_code == this.countryFlags[i]['code']) {
            this.phoneCode = this.countryFlags[i];
          }
        }
        //Geting the user country code with flag(D)
      } else {
        this.phoneCode = this.countryFlags[0];
      }
      this.selectPhoneNumber = this.phoneNumber.nationalNumber;
      this.inviteByPhone.patchValue({ 'phone': this.phoneNumber.nationalNumber });
    } else {
      this.phoneCode = this.countryFlags[0];
      this.selectPhoneNumber = '';
      this.inviteUserArr.phone = [];
      this.multiContactArr = [];
      this.phoneNumber = {};
      this.inviteByPhone.patchValue({ 'phone': '' });
      this.UserInputPhoneCheck = "No contact number available";
    }
  }

  declineSingleSelect() {
    this.modalRef1.hide();
    this.phoneCode = this.countryFlags[0];
    this.selectPhoneNumber = '';
    this.phoneNumber = {}
    this.inviteUserArr.phone = [];
    this.inviteByPhone.patchValue({ 'phone': '' });
  }

  get f() {
    return this.inviteByEmail.controls;
  }

  get f1() {
    return this.inviteByPhone.controls;
  }

  notificationDetialsPage() {
    this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.route.navigate(['notification']);
    });
  }

  //Decrypt Data (n)
  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, '1234509876encryptdecrypt1234567890');
      if (bytes.toString()) {
        if (CryptoJS.enc.Utf8) {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
      }
      if (data) {
        return data;
      }

    } catch (e) {

    }
  }
  //End Decrypt Data (n)

  /**********Notification***********/
  getNotificatioNumberCountRefresh() {
    this.notification_count = 0;
    this.notification_ids = 0;
    this._IndexService.getNotificatioNumberCount().subscribe(
      res => {
        if (res && res['status_code'] == 200) {
          this.notification_count = res['notification_count'];
          this.clearBadge(this.notification_count + this.message_notification_count);
          // var message = 'You have new notification';
          // var action = "notification";
          // this.snackNotification(message,action)
          // navigator.setClientBadge(this.notification_count);
          this.notification_ids = res['notification_ids'];
        }

      }, (err) => {
        this.primaryLoader = false;
        if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
          localStorage.clear();
          sessionStorage.removeItem('fullcomponent');
          this.route.navigate(['/login']);
        }
      });
  }

  notificationIconClick() {
    this.getUserNameArr = [];
    this.notiCountPost = {};
    this.notiCountPost.page = '';
    this.notiCountPost.search = '';

    this.notification_count = 0
    this.clearBadge(this.notification_count + this.message_notification_count);
    if (this.notification_ids == '') {
      this.notification_ids = 0;
    }
    if (this.notification_ids) {
      this.notiCountPost.notification_ids = this.notification_ids;
    } else {
      this.notiCountPost.notification_ids = '0';
    }


    this.primaryLoader = true;
    this.notDataAvailableLoader = true;
    this.notificationCountFun(this.notiCountPost);

  }

  deviceType = environment.device_type;
  clearBadge(notimsgCount) {
    if (typeof cordova !== 'undefined') {
      const badgePlugin = cordova.plugins.notification.badge;
      if (notimsgCount) {
        badgePlugin.set(notimsgCount);
      }
      else {
        badgePlugin.configure({ indicator: 'badge', autoClear: true });
        // alert('n-2');
        badgePlugin.clear();
      }

      // Clear the badge count when the app is resumed
      // alert('n-1');
      // badgePlugin.configure({ indicator: 'badge', autoClear: true });
      // // alert('n-2');
      // badgePlugin.clear();
      // alert('n-3');
      if (this.deviceType == 'android') {
        FirebasePlugin.clearAllNotifications();
        FirebasePlugin.setBadgeNumber(0);
      }
    } else {
      console.error('Cordova is not available.');
    }
  }

  notificationCountFun(notiCountPost) {
    this.showMoreUserList = false;

    if (this.getUserNameArr.length == 0) {
      this.notDataAvailableLoader = true;
    } else {
      this.notDataAvailableLoader = false;
    }

    this.primaryLoader = true;

    this._IndexService.getNotificatioCount(notiCountPost).subscribe(
      res => {
        this.notiCountPost.notification_ids = '0';

        this.notDataAvailableLoader = false;
        this.primaryLoader = false;

        if (res && res['privacy_settings']) {
          for (let i = 0; i < res['privacy_settings'].length; i++) {
            this.getUserNameArr = this.getUserNameArr.filter(item => item.id != res['privacy_settings'][i].id);
            if (res['privacy_settings'][i]['post_text']) {
              let string = res['privacy_settings'][i]['post_text'];

              string = string.replace(/<\/?a[^>]*>/g, "");

              res['privacy_settings'][i]['post_text'] = string;

              if (res['privacy_settings'][i]['post_text'] && res['privacy_settings'][i]['post_text'] != '') {

                if (res['privacy_settings'][i]['type'] != 'breakingNews') {
                  let textMsgArr = this.emojPipe.transform(res['privacy_settings'][i]['post_text'], this.sheet, this.size, 64, this.sheetId, true, this.backgroundImageFn, true);
                  if (textMsgArr && textMsgArr['html']) {
                    res['privacy_settings'][i]['post_text'] = textMsgArr['html'];
                  }
                }
              } else {

              }
            } else {

            }

            if (res['privacy_settings'][i]['share_post_text'] && res['privacy_settings'][i]['share_post_text'] != '') {

              if (res['privacy_settings'][i]['type'] != 'breakingNews') {
                let textMsgArr = this.emojPipe.transform(res['privacy_settings'][i]['share_post_text'], this.sheet, this.size, 64, this.sheetId, true, this.backgroundImageFn, true);
                if (textMsgArr && textMsgArr['html']) {
                  res['privacy_settings'][i]['share_post_text'] = textMsgArr['html'];
                }
              }

            }

            if (res['privacy_settings'][i]['created_at'] && res['privacy_settings'][i]['created_at'] != '0000-00-00 00:00:00') {
              let temp_created_date_time = res['privacy_settings'][i]['created_at'];
              let temp_created_date = temp_created_date_time.split(" ");
              res['privacy_settings'][i]['date_created_at'] = temp_created_date[0];

              let created_at = moment(res['privacy_settings'][i]['created_at']).tz(this.timezone);
              //Handle if moment module give '_d' or not(D)
              let created_date, created_time, created_date_new;
              if (created_at && created_at['_d']) {
                created_date = this.datePipe.transform(created_at._d, "dd MMM, yyyy");
                created_time = this.datePipe.transform(created_at._d, "hh:mm a");
                created_date_new = this.datePipe.transform(created_at, "dd/MM/yyyy");
              } else {
                created_date = this.datePipe.transform(created_at, "dd MMM, yyyy");
                created_time = this.datePipe.transform(created_at, "hh:mm a");
                created_date_new = this.datePipe.transform(created_at, "dd/MM/yyyy");
              }
              //Handle if moment module give '_d' or not(D)
              let created_date_time = moment(created_date).format("X");
              if (res['privacy_settings'][i]['type'] && res['privacy_settings'][i]['type'] == 'dob') {
                res['privacy_settings'][i]['created_at'] = created_date;
              } else {
                res['privacy_settings'][i]['created_at'] = created_date + ' at ' + created_time;
              }

              if (this.cur_date_time >= created_date_time && res['privacy_settings'][i]['type'] == 'dob') {
                let new_date = new Date(created_date);
                res['privacy_settings'][i]['birth_msg'] = this.formatDate(new_date);

              } else {
                res['privacy_settings'][i]['birth_msg'] = '';
              }
            }

            this.getUserNameArr.push(res['privacy_settings'][i]);
          }

        }


        this.showMoreUserList = res['showMore'];
        if (res && res['nextPage']) {
          this.nextPage = res['nextPage'];
        }

      }, (err) => {
        this.primaryLoader = false;
        if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
          localStorage.clear();
          sessionStorage.removeItem('fullcomponent');
          this.route.navigate(['/login']);
        }
      });
  }


  formatDate(someDateTimeStamp) {
    let fulldays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let dt = new Date(someDateTimeStamp),
      date = dt.getDate(),
      month = months[dt.getMonth()],
      timeDiff = someDateTimeStamp - Date.now(),
      diffDays = new Date().getDate() - date,
      diffMonths = new Date().getMonth() - dt.getMonth(),
      diffYears = new Date().getFullYear() - dt.getFullYear();

    if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
      return "Today";
    } else if (diffYears === 0 && diffDays === 1) {
      return "Yesterday";
    } else if (diffYears === 0 && diffDays === -1) {
      return "Tomorrow";
    } else if (diffYears === 0 && (diffDays < -1 && diffDays > -7)) {
      return fulldays[dt.getDay()];
    } else if (diffYears >= 1) {
      return month + " " + date + ", " + new Date(someDateTimeStamp).getFullYear();
    } else {
      return month + " " + date;
    }
  }

  //Get Search User List By Name click on show more button (n)
  showMoreUserListByName(nextPage) {
    if (nextPage) {
      this.notiCountPost = {};
      this.notiCountPost.page = nextPage;
      this.notiCountPost.search = '';
      this.notiCountPost.notification_ids = '0';
      this.notificationCountFun(this.notiCountPost);
    }
  }

  /**********End Notification***********/

  /**********Message************/
  getMessageNotificationCount() {
    this._IndexService.getMessageNotificationCount().subscribe(
      res => {
        if (res && res['status_code'] == 200) {
          this.message_notification_count = res['message_notification_count'];

          this.clearBadge(this.notification_count + this.message_notification_count);
          // var message = 'You have new message notification';
          // var action = "message";
          // this.snackNotification(message,action)
          this.message_user_login_id = res['message_user_login_id'];
        }

      }, (err) => {
        this.primaryLoader = false;
        if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
          localStorage.clear();
          sessionStorage.removeItem('fullcomponent');
          this.route.navigate(['/login']);
        }
      });
  }

  messageIconClick() {
    this.getChatMessageArr = [];

    //this.message_notification_count = 0

    this.getChatMessagePost = {};
    this.getChatMessagePost.page = '';
    this.getChatMessagePost.search = '';

    this.getChatMessageLoader = true;
    this.getChatMessageNoDataLoader = true;

    this.getNotificationMessageList(this.getChatMessagePost);
    this.getMessageNotificationCount();
    // this.clearBadge();
  }

  sheetId: any = 100;
  sheet: any = 'itsgametime';
  size = 24;
  sheetSize = 64;
  backgroundImageFn = (set: string, sheetSize: number) => {
    return `${environment.emoji_path}${this.sheet}/${sheetSize}.png`;
  }
  getNotificationMessageList(getChatMessagePost) {
    this.showMoreMessageList = false;

    if (this.getChatMessageArr.length == 0) {
      this.getChatMessageNoDataLoader = true;
    } else {
      this.getChatMessageNoDataLoader = false;
    }


    this.getChatMessageLoader = true;
    this._IndexService.getNotificationMessageList(getChatMessagePost).subscribe(
      res => {

        this.getChatMessageLoader = false;
        this.getChatMessageNoDataLoader = false;
        if (res && res['privacy_settings']) {
          for (let i = 0; i < res['privacy_settings'].length; i++) {
            this.getChatMessageArr = this.getChatMessageArr.filter(item => item.id != res['privacy_settings'][i].id);
            if (res['privacy_settings'][i]['created_at'] && res['privacy_settings'][i]['created_at'] != '0000-00-00 00:00:00') {

              let created_at = moment(res['privacy_settings'][i]['created_at']).tz(this.timezone);
              //Handle if moment module give '_d' or not(D)
              let created_date, created_time;
              if (created_at && created_at['_d']) {
                created_date = this.datePipe.transform(created_at._d, "dd MMM, yyyy");
                created_time = this.datePipe.transform(created_at._d, "hh:mm a");
              } else {
                created_date = this.datePipe.transform(created_at, "dd MMM, yyyy");
                created_time = this.datePipe.transform(created_at, "hh:mm a");
              }
              //Handle if moment module give '_d' or not(D)

              if (res['privacy_settings'][i]['type'] && res['privacy_settings'][i]['type'] == 'dob') {
                res['privacy_settings'][i]['created_at'] = created_date;
              } else {
                res['privacy_settings'][i]['created_at'] = created_date + ' at ' + created_time;
              }

              if (res['privacy_settings'][i]['type'] && res['privacy_settings'][i]['type'] == '1') {

              } else {
                let textMsgArr = this.emojPipe.transform(res['privacy_settings'][i]['message'], this.sheet, this.size, 64, this.sheetId, true, this.backgroundImageFn, true);
                if (textMsgArr && textMsgArr['html']) {
                  res['privacy_settings'][i]['message'] = textMsgArr['html'];
                  res['privacy_settings'][i]['classShow'] = textMsgArr['classShow'];
                }
              }
            }

            this.getChatMessageArr.push(res['privacy_settings'][i]);
            //For removing the <br> tag from notification(D)
            for (let k = 0; k < this.getChatMessageArr.length; k++) {
              this.getChatMessageArr[k]['message'] = this.getChatMessageArr[k]['message'].replace(/\<br>/g, ' ');
            }
            //End For removing the <br> tag from notification(D)
          }
        }
        this.showMoreMessageList = res['showMore'];
        if (res && res['nextPage']) {
          this.nextPageMessageList = res['nextPage'];
        }

      }, (err) => {
        this.primaryLoader = false;
        if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
          localStorage.clear();
          sessionStorage.removeItem('fullcomponent');
          this.route.navigate(['/login']);
        }
      });
  }

  showMoreMessageNotificationList(nextPage) {
    if (nextPage) {
      this.getChatMessagePost = {};
      this.getChatMessagePost.page = nextPage;
      this.getChatMessagePost.search = '';

      this.getChatMessageLoader = true;
      this.getChatMessageNoDataLoader = true;

      this.getNotificationMessageList(this.getChatMessagePost);
    }
  }

  clickUserMessageFun(chatMessage) {
    this._IndexService.chatTextUserForMessage(this.route.url);
    if (chatMessage && chatMessage.user_from) {
      localStorage.removeItem("newChat");
      localStorage.setItem("chatUser", this.encryptData(chatMessage.user_from));
      let uncuPost = {
        chat_user_id: chatMessage.user_from
      }

      this._IndexService.updateMessageNotification(uncuPost).subscribe(
        res => {
          this.getMessageNotificationCount();
          this.route.navigate(['/messageChat']);
        }, (err) => {
          this.route.navigate(['/messageChat']);
        });

    } else {
      localStorage.removeItem("chatUser");
      this.route.navigate(['/messageChat']);
    }
  }

  openMessageWindow() {
    localStorage.removeItem("chatUser");
    localStorage.setItem("newChat", this.encryptData(1));
    this._IndexService.chatTextUserForMessage(this.route.url);
    this.route.navigate(['/messageChat']);
  }

  /**********End Message************/

  // Get User Navigation Function (a)
  getUserNavigationInfo() {
    this._IndexService.getUserNavigationInfo().subscribe(
      res => {
        if (res && res['status_code'] == 200) {
          this.userNaviDetails = res["navi_details"];
          this.status = res["navi_details"]['status'];
          if (res && res["navi_details"] && res["navi_details"]['status'] == 2 && !this.route.isActive('/contact', true)) {
            this.route.navigate(['/contact']);
          }
        } else if (res && res['status_code'] == 401) {
          localStorage.clear();
          this.route.navigate(['/login']);
          this.toastr.error(res['message'], 'Error!');
          sessionStorage.removeItem('fullcomponent');
        }
      }, (err) => {
        if (err && err['status_code'] == 401) {
          localStorage.clear();
          this.route.navigate(['/login']);
          this.toastr.error(err['message'], 'Error!');
          sessionStorage.removeItem('fullcomponent');
        } else {
          this.status = 3;
          if (!this.route.isActive('/contact', true)) {
            this.route.navigate(['/contact']);
          }
        }
      });
  }
  // End Get User Navigation Function (a)

  // Email Validation Inviting Users (a)
  private checkEmail(control: FormControl) {
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(control.value) == false) {

      return {
        'email': true
      };
    }

    return null;
  }
  // End Email Validation Inviting Users (a)

  // Email Validators Inviting Users (a)
  public validators = [this.checkEmail];

  public errorMessages = {
    'email': 'Should be an Email'
  };
  // End Email Validators Inviting Users (a)

  // Check Validation On Text Change Inviting Users (a)
  onTextChange($event) {
    if ($event) {
      this.inviteShowBtn = false;
    } else {
      if (this.inviteUserArr.email.length > 0 && this.inviteUserArr.email.length <= 5) {
        this.inviteShowBtn = true;
      } else {
        this.inviteShowBtn = false;
      }
    }
  }
  // End Check Validation On Text Change Inviting Users (a)

  // Phone Validation Inviting Users (a)
  private checkPhone(control: FormControl) {
    let reg = /^([0-9-]{8,14})$/;

    if (reg.test(control.value) == false) {

      return {
        'phone': true
      };
    }

    return null;
  }
  // End Phone Validation Inviting Users (a)

  // Phone Validators Inviting Users (a)
  public validatorsPhone = [this.checkPhone];

  public errorMessagesPhone = {
    'phone': 'Should be a valid number'
  };
  // End Phone Validators Inviting Users (a)

  // Check Validation Phone On Text Change Inviting Users (a)
  onTextChangePhone($event) {
    if ($event) {
      this.inviteShowBtn = false;
    } else {
      if (this.inviteUserArr.phone.length > 0 && this.inviteUserArr.phone.length <= 5) {
        this.inviteShowBtn = true;
      } else {
        this.inviteShowBtn = false;
      }
    }
  }
  // Check Validation Phone On Text Change Inviting Users (a)

  //Search Name to show more and search icon click to redirect more search user(n)
  showMoreUserClick() {
    $('#inputField').focus(function () {
      this.blur();
    });
    if (this.usersForm.value && this.usersForm.value.userInput) {
      let searchName = this.usersForm.value.userInput.replace(/^#/, '');
      let res = encodeURIComponent(searchName);
      let enc = res.replace("(", "%28").replace(")", "%29");
      this.route.navigateByUrl('/userSearch/' + enc);
    }
  }

  //Search Name click to redirect more search user(n)
  selectSearchOption(searchName) {
    if (searchName) {
      var searchName = searchName.replace(/^#/, '')
      let res = encodeURIComponent(searchName);
      let enc = res.replace("(", "%28").replace(")", "%29");
      this.route.navigateByUrl('/userSearch/' + enc);
    }
  }

  // Change Radio Selected Function (a)
  changeInviteRadio(type) {
    this.inviteUserArr.type = type;
    if (type == 'email') {
      this.inviteUserArr.email = [];
      this.UserInputEmailCheck = false;
    } else {
      this.inviteUserArr.phone = [];
      this.UserInputPhoneCheck = '';
      this.phoneCode = this.countryFlags[0];
      this.selectPhoneNumber = '';
    }
    this.inviteShowBtn = false;
  }
  // End Change Radio Selected Function (a)

  // Send New Invites Function (a) (Not user (D))
  newInvite(inviteUserArr) {
    this.submitted = true;
    if (this.inviteByEmail.invalid || this.inviteByPhone.invalid) {
      return;
    } else {
      this.inviteUserArr.email = this.inviteByEmail.value.email;
      this.inviteUserArr.countryCode = this.inviteByEmail.value.countryCode.code;
      this.inviteUserArr.phone = this.inviteByEmail.value.phone;

      let login_user_name = '';
      if (this.login_user_type == 1) {
        login_user_name = this.userNaviDetails.name;
      } else {
        if (this.userNaviDetails.alias_name != '') {
          login_user_name = this.userNaviDetails.alias_name;
        } else {
          login_user_name = this.userNaviDetails.alias_name + ' (Alias)';
        }
      }
      //Get Login User name(n)

      if (inviteUserArr.type && inviteUserArr.type == 'email') {
        let EmailData = {
          type: 'email',
          email: inviteUserArr.email,
          login_user_name: this.userNaviDetails.name
        };
        this._IndexService.inviteUsers(EmailData).subscribe(
          res => {
            this.modalRef.hide();
            this.inviteShowBtn = true;
            this.toastr.success('User invites sent successfully', 'Success!', { timeOut: 1000 });
            this.ngOnInit();
          }, (err) => {
            this.inviteShowBtn = true;
            this.modalRef.hide();
          });

      } else if (inviteUserArr.type && inviteUserArr.type == 'phone') {
        //Code for single invitation send(D)
        let PhoneData = {
          type: 'phone',
          phone: inviteUserArr.countryCode + '-' + inviteUserArr.phone,
          login_user_name: this.userNaviDetails.name
        };

        this._IndexService.inviteUsers(PhoneData).subscribe(
          res => {
            this.modalRef.hide();
            this.inviteShowBtn = true;
            this.toastr.success('User invites sent successfully', 'Success!', { timeOut: 1000 });
            this.ngOnInit();
          }, (err) => {
            this.inviteShowBtn = true;
            this.modalRef.hide();
          });
      } else {
        this.modalRef.hide();
        this.toastr.error("Not found type", 'Error!');
      }
    }
  }
  // End Send New Invites Function (a)

  // Send New Invites Function by email (D)
  emailInvite(inviteUserArr) {
    this.submitted = true;
    if (this.inviteByEmail.invalid) {
      return;
    } else {
      this.inviteUserArr.email = this.inviteByEmail.value.email;
      let login_user_name = '';
      if (this.login_user_type == 1) {
        login_user_name = this.userNaviDetails.name;
      } else {
        if (this.userNaviDetails.alias_name != '') {
          login_user_name = this.userNaviDetails.alias_name;
        } else {
          login_user_name = this.userNaviDetails.alias_name + ' (Alias)';
        }
      }
      //Get Login User name(n)
      this.modalRef.hide();
      if (inviteUserArr.type && inviteUserArr.type == 'email') {
        let EmailData = {
          type: 'email',
          email: inviteUserArr.email,
          login_user_name: this.userNaviDetails.name
        };
        this._IndexService.inviteUsers(EmailData).subscribe(
          res => {
            this.modalRef.hide();
            this.inviteShowBtn = true;
            this.toastr.success('User invites sent successfully', 'Success!', { timeOut: 1000 });
            this.ngOnInit();
          }, (err) => {
            this.inviteShowBtn = true;
            this.modalRef.hide();
          });

      } else {
        this.modalRef.hide();
        this.toastr.error("Not found type", 'Error!');
      }
    }
  }
  // End Send New Invites Function (a)

  // Send New Invites Function by Phone (D)
  phoneInvite(inviteUserArr) {
    this.submittedPhone = true;
    if (this.inviteByPhone.invalid) {
      return;
    } else {
      if (this.UserInputPhoneCheck != '') {
        return;
      }

      this.inviteUserArr.countryCode = this.inviteByPhone.value.countryCode;
      this.inviteUserArr.phone = this.inviteByPhone.value.phone;
      let login_user_name = '';
      if (this.login_user_type == 1) {
        login_user_name = this.userNaviDetails.name;
      } else {
        if (this.userNaviDetails.alias_name != '') {
          login_user_name = this.userNaviDetails.alias_name;
        } else {
          login_user_name = this.userNaviDetails.alias_name + ' (Alias)';
        }
      }
      //Get Login User name(n)
      this.modalRef.hide();
      if (inviteUserArr.type && inviteUserArr.type == 'phone') {

        //Code for single invitation send(D)
        let PhoneData = {
          type: 'phone',
          phone: inviteUserArr.countryCode.code + '-' + inviteUserArr.phone,
          login_user_name: this.userNaviDetails.name
        };

        this._IndexService.inviteUsers(PhoneData).subscribe(
          res => {
            this.modalRef.hide();
            this.inviteShowBtn = true;
            this.toastr.success('User invites sent successfully', 'Success!', { timeOut: 1000 });
            this.ngOnInit();
          }, (err) => {
            this.inviteShowBtn = true;
            this.modalRef.hide();
          });
      } else {
        this.modalRef.hide();
        this.toastr.error("Not found type", 'Error!');
      }
    }
  }
  // End Send New Invites Function (a)
  //All comment code remove and save at backup date 09/july/2020




  //Encrypt Data(n)
  encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), '1234509876encryptdecrypt1234567890').toString();
    } catch (e) {
      console.log(e);
    }
  }
  //End encrypt Data(n)

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg modal-dialog-centered', backdrop: true, ignoreBackdropClick: true });
    this.phoneCode = this.countryFlags[0];
    this.changeInviteRadio("phone")
    this.selectPhoneNumber = '';
    this.UserInputPhoneCheck = '';
    this.inviteUserArr.phone = [];
    this.inviteUserArr.email = [];
    this.multiContactArr = [];
  }

  openScoreModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg modal-dialog-centered', backdrop: true, ignoreBackdropClick: true });
    this.showLeagues();

  }


  leaguesLoader: boolean = true;
  leaguesList: any = [];
  followedLeagues: any = [];
  unfollowedLeagues: any = [];
  league_response: any = {};
  showLeagues() {
    this.leaguesLoader = true;
    let data = {
      "search": ''
    }
    this.league_response = {};
    this._SigninSignUp.showLeagues(data).subscribe(
      res => {
        if (res && res['status_code'] == 200) {
          this.leaguesList = res['leagues'];
          let followedLeaguesArr = [];
          let unfollowedLeaguesArr = [];
          this.totalMatchCount = 0;
          this.leaguesList.forEach(element => {
            if (element['score_count'] && element['score_count'] != null) {
              this.totalMatchCount += element['score_count'];
            }

            if (element['is_follow']) {
              followedLeaguesArr.push(element);
            } else {
              unfollowedLeaguesArr.push(element);
            }
          });
          this.followedLeagues = followedLeaguesArr.sort((a, b) => a.name.localeCompare(b.name));
          this.unfollowedLeagues = unfollowedLeaguesArr.sort((a, b) => a.name.localeCompare(b.name));
          this.leaguesLoader = false;

          this.league_response = res;
          this._IndexService.liveScoreLeagueRefresh(this.league_response);
        }

      }, err => {
        console.log(err);
      }
    );
  }

  searchLeagueNameClick(user) {
    localStorage.setItem('showScore', this.encryptData('showScore'));
    localStorage.setItem("searchuser", this.encryptData(user.user_id));
    localStorage.setItem("searchusertype", this.encryptData(1));

    if (this.modalRef) {
      this.closescores();
    }
    if (this.route.url.includes('followUserProfile')) {

      if (user && user.user_name && user.user_name != '') {
        this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.route.navigate([user.user_name, '']);
        });
      } else if (user && user.user_id && user.user_id != '') {
        let encId = this.encryptData(user.user_id);
        this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.route.navigateByUrl('/followUserProfile?q=' + encodeURIComponent(encId));
        });
      } else {
        this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.route.navigate(['/followUserProfile']);
        });
      }
    } else {
      if (user && user.user_name && user.user_name != '') {
        this.route.navigate([user.user_name, '']);
      } else if (user && user.user_id && user.user_id != '') {
        let encId = this.encryptData(user.user_id);
        this.route.navigateByUrl('/followUserProfile?q=' + encodeURIComponent(encId));
      } else {
        this.route.navigate(['/followUserProfile']);
      }

    }
  }

  searchTeamLeagueNameClick(user) {
    if (user.id) {
      localStorage.setItem('selectTeamId', this.encryptData(user.id));
    } else {
      localStorage.setItem('selectTeamId', this.encryptData('All'));
    }
    if (this.modalRef) {
      this.closescores();
    }
    if (this.route.url.includes('/matches')) {
      this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        if (user.id) {
          if (user.live_score > 0) {
            this.route.navigate(['/matches', 'live', user.username]);
          } else {
            this.route.navigate(['/matches', 'scheduled', user.username]);
          }
        } else {
          if (this.totalMatchCount > 0) {
            this.route.navigate(['/matches', 'live', 'all']);
          } else {
            this.route.navigate(['/matches', 'scheduled', 'all']);
          }

        }
      });
    } else {
      if (user.id) {
        if (user.live_score > 0) {
          this.route.navigate(['/matches', 'live', user.username]);
        } else {
          this.route.navigate(['/matches', 'scheduled', user.username]);
        }
      } else {
        if (this.totalMatchCount > 0) {
          this.route.navigate(['/matches', 'live', 'all']);
        } else {
          this.route.navigate(['/matches', 'scheduled', 'all']);
        }
      }
    }
  }

  closescores() {
    this.modalRef.hide();
  }



  closeInvite() {
    this.modalRef.hide();
    this.changeInviteRadio("phone")
    this.phoneCode = this.countryFlags[0];
    this.selectPhoneNumber = '';
    this.UserInputPhoneCheck = '';
    this.inviteUserArr.phone = [];
    this.inviteUserArr.email = [];
    this.multiContactArr = [];
  }

  //Select User Type there are two type user Main(Friend Tab)/Alias(Alias Tab)
  SelectUserType(type) {
    if (type && type == 'Alias') {
      localStorage.setItem("userProfileType", this.encryptData('Alias'));
      this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.route.navigate(['indexAlias']);
      });
    } else {
      localStorage.setItem("userProfileType", this.encryptData('Main'));
      this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.route.navigate(['index']);
      });
    }
  }

  //myprofile redirect
  myprofileRedirectPage() {
    this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.route.navigate(['myProfile']);
    });
  }

  //alias profile redirect
  aliasprofileRedirectPage() {
    this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.route.navigate(['aliasProfile']);
    });
  }

  //alias profile redirect
  newsListRedirectPage() {
    localStorage.setItem("userProfileType", this.encryptData('Main'));
    this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.route.navigate(['news']);
    });
  }


  //User logout(n)
  logout() {
    localStorage.clear();
    sessionStorage.removeItem('fullcomponent');
    this.route.navigate(['/login']);
  }

  readNotification(key, userDetails) {
    let Data = {
      "notification_id": userDetails.id
    }
    this._UserConnectionService.readNotification(Data).subscribe(
      res => {
        this.getUserNameArr[key].read_status = 1;

      }, (err) => {
        if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
          localStorage.clear();
          sessionStorage.removeItem('fullcomponent');
          this.route.navigate(['/login']);
        }
      }
    );
  }

  readCirlePostNotification(key, userDetails) {
    let Data = {
      "only_accept": 1,
      "from_user": userDetails.user_from,
      "post_id": userDetails.source_id
    }
    this._IndexService.updateCirclePostStatus(Data).subscribe(
      res => {
        this.getUserNameArr[key].connection_status = 1;
        this.route.navigate(['/notification']);
      }, (err) => {
        if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
          localStorage.clear();
          sessionStorage.removeItem('fullcomponent');
          this.route.navigate(['/index']);
        }
      }
    );
  }

  circle_post_redirction(key, userDetails) {
    this.getUserNameArr[key].connection_status = 1;
    this.route.navigate(['/notification']);
  }

  markAllAsReadNotification() {
    let Data = {};
    this._UserConnectionService.markAllAsReadNotification(Data).subscribe(
      res => {

        this.getUserNameArr.forEach(element => {
          element.read_status = 1;
        });

      }, (err) => {
        if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
          localStorage.clear();
          sessionStorage.removeItem('fullcomponent');
          this.route.navigate(['/login']);
        }
      }
    );
  }

  markAllAsReadMessage() {
    let Data = {};
    this._UserConnectionService.markAllAsReadMessage(Data).subscribe(
      res => {

        this.getChatMessageArr.forEach(element => {
          element.status = 1;
        });

      }, (err) => {
        if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
          localStorage.clear();
          sessionStorage.removeItem('fullcomponent');
          this.route.navigate(['/login']);
        }
      }
    );
  }

  // Redirect open modal to edit profile for the set the stealth/alias name(D)
  aliasRedirectOpenModal(template) {
    if (this.aliasNameBlank) {
      this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered', backdrop: true, ignoreBackdropClick: true });
    } else {
      this.route.navigate(['/aliasProfile']);
    }
  }

  redirectEditProfile() {
    this.route.navigate(['/editProfile']);
  }

  decline(): void {
    this.modalRef.hide();
  }
  // Redirect open modal to edit profile for the set the stealth/alias name(D)

  //Post View Detail click to redirect on details page(n)
  postViewDetailFun(post) {
    if (post.ids) {

      if (post.post_media_id != '') {
        localStorage.setItem('post_media_id', this.encryptData(post.post_media_id));
      } else {
        localStorage.removeItem('post_media_id');
      }

      //Login user type(Alias/Main) valriable(n)
      if (post.login_user_type == 1) {
        if (post.post_privacy && post.post_privacy == 2) {
          this._IndexService.aliasFriendChangeMessage("Alias");
          localStorage.setItem("userProfileType", this.encryptData('Alias'));
          localStorage.setItem("activeTab", this.encryptData('Open Forum'));
          localStorage.setItem('openForumUserProfileType', this.encryptData(post.login_user_type));
          this.route.navigateByUrl('/aliasPostViewDetail?string=' + this.encryptData(post.ids));
        } else {
          this._IndexService.aliasFriendChangeMessage("Main");
          localStorage.setItem("userProfileType", this.encryptData('Main'));
          localStorage.setItem("activeTab", this.encryptData('Pals'));
          localStorage.setItem('openForumUserProfileType', this.encryptData(post.login_user_type));
          //End Login user type(Alias/Main) valriable(n)
          this.route.navigateByUrl('/postViewDetail?string=' + this.encryptData(post.ids));
        }
      } else if (post.login_user_type == 2) {
        this._IndexService.aliasFriendChangeMessage("Alias");
        localStorage.setItem("userProfileType", this.encryptData('Alias'));
        localStorage.setItem("activeTab", this.encryptData('Open Forum'));
        localStorage.setItem('openForumUserProfileType', this.encryptData(post.login_user_type));
        this.route.navigateByUrl('/aliasPostViewDetail?string=' + this.encryptData(post.ids));
      } else {
        this.route.navigate(['/index']);
      }
    }
  }
  //Post View Detail click to redirect on details page(n)

  postViewDetailFunCircle(post) {
    if (post.source_id) {
      this.route.navigateByUrl('/postViewDetail?string=' + this.encryptData(post.source_id));
    }
  }

  //Post View Detail click to redirect on details page(n)
  postViewDetailCommReplyFun(post) {

    if (post.ids) {

      if (post.post_media_id != '') {
        localStorage.setItem('post_media_id', this.encryptData(post.post_media_id));
      } else {
        localStorage.removeItem('post_media_id');
      }

      //Login user type(Alias/Main) valriable(n)
      if (post.user_to_type == 1) {
        if (post.post_privacy && post.post_privacy == 2) {
          this._IndexService.aliasFriendChangeMessage("Alias");
          localStorage.setItem("userProfileType", this.encryptData('Alias'));
          localStorage.setItem("activeTab", this.encryptData('Open Forum'));
          localStorage.setItem('openForumUserProfileType', this.encryptData(post.user_to_type));
          this.route.navigateByUrl('/aliasPostViewDetail?string=' + this.encryptData(post.ids));
        } else {
          this._IndexService.aliasFriendChangeMessage("Main");
          localStorage.setItem("userProfileType", this.encryptData('Main'));
          localStorage.setItem("activeTab", this.encryptData('Pals'));
          localStorage.setItem('openForumUserProfileType', this.encryptData(post.user_to_type));
          //End Login user type(Alias/Main) valriable(n)
          this.route.navigateByUrl('/postViewDetail?string=' + this.encryptData(post.ids));
        }
      } else if (post.user_to_type == 2) {
        this._IndexService.aliasFriendChangeMessage("Alias");
        localStorage.setItem("userProfileType", this.encryptData('Alias'));
        localStorage.setItem("activeTab", this.encryptData('Open Forum'));
        localStorage.setItem('openForumUserProfileType', this.encryptData(post.user_to_type));
        this.route.navigateByUrl('/aliasPostViewDetail?string=' + this.encryptData(post.ids));
      } else {
        this.route.navigate(['/index']);
      }
    }
  }
  //Post View Detail click to redirect on details page(n)

  //Post View Detail click to redirect on details page(n)
  postViewDetailFunGroup(post) {

    if (post.ids) {
      //Login user type(Alias/Main) valriable(n)
      if (post.user_to_type == 1) {
        if (post.post_privacy && post.post_privacy == 2) {
          this._IndexService.aliasFriendChangeMessage("Alias");
          localStorage.setItem("userProfileType", this.encryptData('Alias'));
          localStorage.setItem("activeTab", this.encryptData('Open Forum'));
          localStorage.setItem('openForumUserProfileType', this.encryptData(post.user_to_type));
          this.route.navigateByUrl('/aliasPostViewDetail?string=' + this.encryptData(post.ids));
        } else {
          this._IndexService.aliasFriendChangeMessage("Main");
          localStorage.setItem("userProfileType", this.encryptData('Main'));
          localStorage.setItem("activeTab", this.encryptData('Pals'));
          localStorage.setItem('openForumUserProfileType', this.encryptData(post.user_to_type));
          //End Login user type(Alias/Main) valriable(n)
          this.route.navigateByUrl('/postViewDetail?string=' + this.encryptData(post.ids));
        }
      } else if (post.user_to_type == 2) {
        this._IndexService.aliasFriendChangeMessage("Alias");
        localStorage.setItem("userProfileType", this.encryptData('Alias'));
        localStorage.setItem("activeTab", this.encryptData('Open Forum'));
        localStorage.setItem('openForumUserProfileType', this.encryptData(post.user_to_type));
        this.route.navigateByUrl('/aliasPostViewDetail?string=' + this.encryptData(post.ids));
      } else {
        this.route.navigate(['/index']);
      }
    }
  }
  //Post View Detail click to redirect on details page(n)

  //Check the email validation and display the error message on invalid email address formate(D)
  inviteEmailCheck(event: any) {
    let RegEx = new RegExp(/^([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-zA-Z]{2,6}$/);
    let str = event.target.value;
    if (RegEx.test(str)) {
      this.UserInputEmailCheck = false;
      return event.target.value;
    } else {
      this.UserInputEmailCheck = true;
      event.target.value = str.replace(/ /g, '');
      this.inviteByEmail.patchValue({ 'email': str.replace(/ /g, '') })
    }
  }
  // End Check the email validation and display the error message on invalid email address formate(D)

  // Adding the phone code validation on key up method(D)
  onKeyPhoneNumberCheck(event: any) {
    this.multiContactArr = [];
    let str = event.target.value;
    let regExp = new RegExp(/^(?!000000)\d{1,20}$/);
    let regExpZeroNotAllowed = new RegExp(/^[1-9][0-9]{0,16}$/);
    if (regExp.test(event.target.value) && regExpZeroNotAllowed.test(event.target.value)) {
      this.UserInputPhoneCheck = '';
      this.inviteByPhone.patchValue({ 'phone': str })
    } else {
      let regExpZero = new RegExp(/^[0][0-9]{0,16}$/);
      if (regExpZero.test(event.target.value)) {
        this.UserInputPhoneCheck = 'Phone number with 0 at start not allowed';
        event.target.value = event.target.value.replace(/^[0][0-9]{0,16}$/g, '');
        this.inviteByPhone.patchValue({ 'phone': event.target.value.replace(/^[0][0-9]{0,16}$/g, '') });
      } else {
        this.UserInputPhoneCheck = 'Phone number must be numeric only!';
        event.target.value = event.target.value.replace(/\D+/g, '');
        this.inviteByPhone.patchValue({ 'phone': event.target.value.replace(/\D+/g, '') });
      }
    }
  }
  // Adding the phone code validation on key up method(D)

  //Start the redirect the user(D)
  searchUserNameClick(user) {
    console.log("aman..")

    if (!this.isAcceptOpenModalClick) {
      localStorage.setItem("searchuser", this.encryptData(user.user_from));
      if (user.type == 'follow') {
        localStorage.setItem("searchusertype", this.encryptData(user.follower_type));
      } else {
        if (user.connection_status == '1' || user.connection_status == '2' || user.connection_status == '3') {
          this.changeActiveTab('Pals')
          localStorage.setItem("userProfileType", this.encryptData('Main'));
        }
        localStorage.setItem("searchusertype", this.encryptData(1));
      }
      if (user.following_type == 1) {
        localStorage.setItem("openForumUserProfileType", this.encryptData(1));
      } else {
        localStorage.setItem("openForumUserProfileType", this.encryptData(2));
      }

      if (user && user.user_name && user.user_name != '') {
        this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.route.navigate([user.user_name, '']);
        });
      } else if (user && user.user_from && user.user_from != '') {
        let encId = this.encryptData(user.user_from);
        this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.route.navigateByUrl('/followUserProfile?q=' + encodeURIComponent(encId));
        });
      } else {
        this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.route.navigate(['/followUserProfile']);
        });
      }
    }
    else if (user.type && user.type == 'liveMatch') {
      localStorage.setItem("searchuser", this.encryptData(user.ids));
      localStorage.setItem("searchusertype", this.encryptData(1));
      if (user && user.user_name && user.user_name != '') {
        this.route.navigate([user.user_name, '']);
      } else if (user && user.ids && user.ids != '') {
        let encId = this.encryptData(user.ids);
        this.route.navigateByUrl('/followUserProfile?q=' + encodeURIComponent(encId));
      } else {
        this.route.navigate(['/followUserProfile']);
      }

    }
  }
  //End the redirect the user(D)

  //set the new chat message redirect variable(D)
  newChatMessageRedirect() {
    localStorage.setItem("newChat", this.encryptData(1));
    this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.route.navigate(['messageChat']);
    });
  }

  //show and hide the pending post(D)
  showPendingPost() {
    this.pendingPost = !this.pendingPost;
    this.autoCloseStatus = false;
  }
  //show and hide the pending post(D)

  //Adding the accept and reject button code in notificaition (D)
  acceptuserData: any;
  acceptConnectRequestOpenModal(template: TemplateRef<any>, user) {
    this.acceptuserData = user;
    this.isAcceptOpenModalClick = true;
    this.clicked = false;
    this.acceptConnectRequestClicked = false;
    this.connectRequest = {};
    this.userName = user['name'];
    this.connectRequest.connection_id = user['source_id'];
    this.connectRequest.status = 2;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm modal-dialog-centered' });
  }

  confirmConnectRequest() {
    this.acceptConnectRequestClicked = true;
    this._IndexService.updateConnectionStatus(this.connectRequest).subscribe(
      res => {
        if (res && res['status_code'] == 200) {
          if (res && res['type'] && res['type'] == 'accepted') {
            //push notification start
            const data = {
              user_id: this.acceptuserData.user_from,
              type: 'Connnection accept'
            }

            this._UserConnectionService.sendPushNotification(data);
            //push notification end

            // Socket(n)
            let socketData = {
              contact_user_id: res['notify_to'],
              type: 'connectionAccept',
            };
            this._UserConnectionService.sendRequestSocket(socketData);
            // Socket(n)

            let notificationEmail = {
              to_user_details: res['user_details'],
              from_user_details: res['from_user_details'],
              notification_setting: res['notification_setting']
            }

            this._UserConnectionService.connectionAcceptEmail(notificationEmail).subscribe();
          }
          this.modalRef.hide();
          this.isAcceptOpenModalClick = false;
          this.acceptConnectRequestClicked = false;
        } else if (res && res['status_code'] == 401) {
          this.modalRef.hide();
          this.isAcceptOpenModalClick = false;
          this.acceptConnectRequestClicked = false;
        }
      }, (err) => {
        this.primaryLoader = false;
        if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
          localStorage.clear();
          sessionStorage.removeItem('fullcomponent');
          this.acceptConnectRequestClicked = false;
          this.route.navigate(['/login']);
        }
        this.modalRef.hide();
        this.acceptConnectRequestClicked = false;
        this.isAcceptOpenModalClick = false;
      });

  }

  //Cancel Connect Request Open Modal & click confirm button(n)
  rejectConnectRequestOpenModal(template: TemplateRef<any>, user, number) {
    this.isAcceptOpenModalClick = true;
    this.lineLoader = true;
    this.rejectConnectRequestClicked = false;
    this.connectRequestPost = {};
    this.userId = user['id'];
    this.userName = user['name'];
    this.connectRequestPost.connectUserId = user['source_id'];
    this.connectRequestPost.status = 3;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm modal-dialog-centered', backdrop: true, ignoreBackdropClick: true });
  }

  confirmOrRejectConnectRequest() {
    this.rejectConnectRequestClicked = true;
    this._UserConnectionService.disconnectUser(this.connectRequestPost).subscribe(
      res => {
        this._UserConnectionService.changeMessage("success");
        if (res && res['status_code'] == 200) {
          this.lineLoader = false;
          this.indexNo = '';
          this.modalRef.hide()
          this.isAcceptOpenModalClick = false;
          this.rejectConnectRequestClicked = false;
          this.toastr.success(res['message'], 'Success', { timeOut: 1000 });
        } else if (res && res['status_code'] == 408) {
          this.lineLoader = false;
          this.indexNo = '';
          this.modalRef.hide()
          this.isAcceptOpenModalClick = false;
          this.rejectConnectRequestClicked = false;
          this.toastr.info(res['message'], 'Info!', { timeOut: 1000 });
        }

      }, (err) => {
        this.primaryLoader = false;
        if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
          localStorage.clear();
          sessionStorage.removeItem('fullcomponent');
          this.rejectConnectRequestClicked = false;
          this.route.navigate(['/login']);
        }
        this.rejectConnectRequestClicked = false;
        this.lineLoader = false;
        this.modalRef.hide();
      });

  }


  //Adding the accept and reject circle post button code in notificaition (D)
  post_id: any;
  index_val: any;
  acceptPostRequestOpenModal(template: TemplateRef<any>, user, key) {
    this.isAcceptOpenModalClick = true;
    this.clicked = false;
    this.acceptPostRequestClicked = false;
    this.connectRequest = {};
    this.userName = user['name'];
    this.post_id = user['source_id'];
    this.connectRequest.connection_id = user['source_id'];
    this.connectRequest.status = 2;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm modal-dialog-centered' });
    this.index_val = key;
  }

  confirmPostRequest() {
    this.acceptPostRequestClicked = true;
    let Data = {
      "post_id": this.post_id,
    }
    this._IndexService.updateCirclePostStatus(Data).subscribe(
      res => {
        if (res && res['status_code'] == 200) {
          this.getUserNameArr[this.index_val]['read_status'] = 1;
          this.getUserNameArr[this.index_val]['connection_status'] = 1;
          this.toastr.success('Post Approved Succesfully!', 'Success', { timeOut: 1000 });
          this._IndexService.notificationDetailRefreshRequest("Refresh");
          this.modalRef.hide();
          this.isAcceptOpenModalClick = false;
          this.acceptPostRequestClicked = false;
        } else if (res && res['status_code'] == 401) {
          this.modalRef.hide();
          this.isAcceptOpenModalClick = false;
          this.acceptPostRequestClicked = false;
        }
      }, (err) => {

        this.primaryLoader = false;
        if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
          localStorage.clear();
          sessionStorage.removeItem('fullcomponent');
          this.acceptPostRequestClicked = false;
          this.route.navigate(['/login']);
        }
        this.modalRef.hide();
        this.acceptPostRequestClicked = false;
        this.isAcceptOpenModalClick = false;
      });

  }

  //Cancel post Request Open Modal & click confirm button(n)
  user_from: any;
  rejectPostRequestOpenModal(template: TemplateRef<any>, user, number) {
    this.isAcceptOpenModalClick = true;
    this.lineLoader = true;
    this.rejectPostRequestClicked = false;
    this.connectRequestPost = {};
    this.userId = user['id'];
    this.user_from = user['user_from'];
    this.userName = user['name'];
    this.post_id = user['source_id'];
    this.connectRequestPost.connectUserId = user['source_id'];
    this.connectRequestPost.status = 3;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm modal-dialog-centered', backdrop: true, ignoreBackdropClick: true });
  }

  confirmOrRejectPostRequest() {
    this.rejectPostRequestClicked = true;
    let data = {
      "post_id": this.post_id,
      "from_user": this.user_from,
    }
    this._IndexService.deleteMember(data).subscribe(
      res => {
        this._UserConnectionService.changeMessage("success");
        if (res && res['status_code'] == 200) {
          this.lineLoader = false;
          this.indexNo = '';
          this.modalRef.hide()
          this.isAcceptOpenModalClick = false;
          this.rejectPostRequestClicked = false;
          this.toastr.success(res['message'], 'Success', { timeOut: 1000 });
        } else if (res && res['status_code'] == 408) {
          this.lineLoader = false;
          this.indexNo = '';
          this.modalRef.hide()
          this.isAcceptOpenModalClick = false;
          this.rejectPostRequestClicked = false;
          this.toastr.info(res['message'], 'Info!', { timeOut: 1000 });
        }

      }, (err) => {
        this.primaryLoader = false;
        if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
          localStorage.clear();
          sessionStorage.removeItem('fullcomponent');
          this.rejectPostRequestClicked = false;
          this.route.navigate(['/login']);
        }
        this.rejectPostRequestClicked = false;
        this.lineLoader = false;
        this.modalRef.hide();
      });

  }


  cancelPostAcceptRequest(): void {
    this.indexNo = '';
    this.lineLoader = false;
    this.indexNoPost = '';
    this.lineLoaderPost = false;
    this.acceptPostRequestClicked = false;
    this.modalRef.hide();
  }

  cancelPostRejectRequest(): void {
    this.indexNo = '';
    this.lineLoader = false;
    this.indexNoPost = '';
    this.lineLoaderPost = false;
    this.rejectPostRequestClicked = false;
    this.modalRef.hide();
  }


  declineRequest(): void {
    this.indexNo = '';
    this.lineLoader = false;
    this.indexNoPost = '';
    this.lineLoaderPost = false;
    this.modalRef.hide();
  }

  cancelAcceptRequest(): void {
    this.indexNo = '';
    this.lineLoader = false;
    this.indexNoPost = '';
    this.lineLoaderPost = false;
    this.acceptConnectRequestClicked = false;
    this.modalRef.hide();
  }

  cancelRejectRequest(): void {
    this.indexNo = '';
    this.lineLoader = false;
    this.indexNoPost = '';
    this.lineLoaderPost = false;
    this.rejectConnectRequestClicked = false;
    this.modalRef.hide();
  }

  //Decline the Accept request(D)


  //End cancel Connect Request Open Modal & click confirm button(n)
  //End Adding the accept and reject button code in notificaition (D)

  //Follow User open modal click button(a)
  userName: any = '';
  followUserOpenModal(template: TemplateRef<any>, user) {
    this.isFollowBackclicked = false;
    this.isAcceptOpenModalClick = true;
    if (user && user['user_from']) {
      this.followingUserId = user['user_from'];
      this.userName = user['name'];
      this.followingUserType = user['follower_type'];
      this.followerUserType = user['following_type'];
      this.modalRef = this.modalService.show(template, { class: 'modal-sm modal-dialog-centered' });
    }
  }
  //End Follow User open modal click button(a)

  followUserConfirm() {
    this.isFollowBackclicked = true;
    if (localStorage.getItem('userProfileType')) {
      let postConnectUser = {
        login_user_type: this.followerUserType,
        connect_user_type: this.followingUserType,
        connect_user_id: this.followingUserId
      }

      this._UserConnectionService.followUserRequeset(postConnectUser).subscribe(
        res => {
          if (res && res['status_code'] == 200) {

            // Socket(n)
            let socketData = {
              contact_user_id: this.followingUserId,
              type: 'follow',
            };
            this._UserConnectionService.sendRequestSocket(socketData);

            let notificationEmail = {
              to_user_details: res['user_details'],
              from_user_details: res['from_user_details'],
              notification_setting: res['notification_setting']
            }

            this._UserConnectionService.followRequestEmail(notificationEmail).subscribe();
            // Socket(n)

            this._UserConnectionService.changeMessage("success");
            this.isAcceptOpenModalClick = false;
            this.modalRef.hide();
            this.isFollowBackclicked = false;
            this.toastr.success('follow back successfully', 'Success', { timeOut: 1000 });

          } else {
            if (res && res['status_code'] == 408) {
              this.toastr.info(res['message'], 'Info!');
              this.isFollowBackclicked = false;
            } else {
              this.toastr.error(res['message'], 'Error!');
              this.isFollowBackclicked = false;
            }
          }
        }, (err) => {
          this.modalRef.hide();
          if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
            localStorage.clear();
            sessionStorage.removeItem('fullcomponent');
            this.route.navigate(['/login']);
          } else {
            if (err && err['message']) {
              this.isFollowBackclicked = false;
            }
          }
        });

    } else {
      this.route.navigate(['/index']);
    }
  }
  //Follow user for click on confirm button(a)
  //Follow user for click on cancel button(a)
  followUserCancel() {
    this.followingUserId = 0;
    this.isFollowBackclicked = false;
    this.modalRef.hide();
  }
  //End Follow user for click on cancel button(a)



  pendingPostRedirect(status) {
    localStorage.setItem("pendingPostStatus", this.encryptData(status));
    if (status == '3') {
      this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.route.navigate(['aliasProfile']);
      });
    } else {
      this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.route.navigate(['myProfile']);
      });
    }
    this.autoCloseStatus = true;
    this.pendingPost = !this.pendingPost;
  }

  refreshUserInfo() {
    this.login_user_type = 1;
    this._IndexService.getUserNavigationInfo().subscribe(
      res => {
        if (res && res['status_code'] == 200) {
          this.userNaviDetails = res["navi_details"];
          this.status = res["navi_details"]['status'];
          this.login_user_type = 1;
          if (res && res["navi_details"] && res["navi_details"]['status'] == 2 && !this.route.isActive('/contact', true)) {
            this.route.navigate(['/contact']);
          }
        }
        else if (res && res['status_code'] == 401) {
          localStorage.clear();
          this.route.navigate(['/login']);
          this.toastr.error(res['message'], 'Error!');
          sessionStorage.removeItem('fullcomponent');
        }
      }, (err) => {
        if (err && err['status_code'] == 401) {
          localStorage.clear();
          this.route.navigate(['/login']);
          this.toastr.error(err['message'], 'Error!');
          sessionStorage.removeItem('fullcomponent');
        } else {
          this.status = 3;
          if (!this.route.isActive('/contact', true)) {
            this.route.navigate(['/contact']);
          }
        }
      });
  }


  autoClose() {
    this.autoCloseStatus = true;
  }

  getMobileAppModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg modal-dialog-centered', backdrop: true, ignoreBackdropClick: true });
  }

  navigateToEdiProfile(tab: string) {
    this._UserConnectionService.activeMainTab(tab);
    this.route.navigateByUrl('/editProfile');
  }

  //Scroller Function With Show More Funcationality(n)
  scroll = (event: any): void => {

    let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;

    if ((pos >= max - 300) && (this.showMoreUserList || this.showMoreMessageList)) {

    }
  }

  GoToAllSports(type) {
    if (type && type == 'Alias') {
      localStorage.setItem("userProfileType", this.encryptData('Alias'));
      this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.route.navigate(['indexAlias']);
      });
    } else {
      localStorage.setItem("userProfileType", this.encryptData('Main'));
      this.route.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.route.navigate(['allSports']);
        this._UserConnectionService.activeMainTab('tab3');
      });
    }
  }

  sendOnBreakingNews(login_user_id) {
    let newInfoUrl = '/news/' + login_user_id;
    this.changeActiveTab('News');
    this.route.navigateByUrl(newInfoUrl);
  }


  sendOnBirthday(user) {
    this.route.navigateByUrl('/notification');
  }

  sendOnLiveMatch() {
    this.route.navigate(['/matches', 'live', 'all']);
  }
  //pop modal scroll (D)
  onScroll() {
    if (this.showMoreUserList) {
      this.showMoreUserListByName(this.nextPage)
    }
    if (this.showMoreMessageList) {
      this.showMoreMessageNotificationList(this.nextPageMessageList)
    }
  }
  //pop modal scroll (D)

  public startTourIGT() {
    this.route.navigateByUrl('/index');

    this.guidedTourService.startTour({
      tourId: 'tour',
      useOrb: false,
      minimumScreenSize: 20,
      preventBackdropFromAdvancing: true,
      steps: [
        {
          title: 'Fans Section',
          selector: '#FansSection',
          content: 'This is your home/fans page. You can interact with your friends, post share and more...',
          orientation: Orientation.Bottom,
          action: () => {
            this.route.navigateByUrl('/index');
          }
        },
        {
          title: 'All Leagues',
          selector: '#fans-all-leagues',
          content: 'Leagues Followed & Unfollowed - See live scores, statistics, scheduled and past events.',
          orientation: Orientation.Right,
          action: () => {
            this.route.navigateByUrl('/index');
          }
        },
        {
          title: 'Breaking News',
          selector: '#breakingNews',
          content: 'Get updated on the latest news form your favorite leagues, teams, and players.',
          orientation: Orientation.Left,
          action: () => {
            this.route.navigateByUrl('/index')
          }
        },
        {
          title: 'Your Profile',
          selector: '#myProfileSection',
          content: 'Add current and previous sports experiences, achievements, personal informations and more...',
          orientation: Orientation.Bottom,
          action: () => {
            this.route.navigateByUrl('/myProfile')
          }
        },

        {
          title: 'Scores Section',
          selector: '#scoresSection',
          content: 'Check live scores, scheduled and past events. Chat online on the events of your choice.',
          orientation: Orientation.Bottom,
          action: () => {
            this.route.navigateByUrl('/matches/scheduled/all')
          }
        },

        {
          title: 'All Sports',
          selector: '#allSportsTab',
          content: 'Check all players, teams and leagues and follow your favorite ones.',
          orientation: Orientation.Bottom,
          action: () => {
            this.route.navigateByUrl('/allSports');
          }
        },
        {
          title: 'All Sports',
          selector: '#allPlayersSection',
          content: 'Follow your favorites - See their latest posts, news and other sports informations.',
          orientation: Orientation.Left,
          useHighlightPadding: true,
          scrollAdjustment: -1000,
          action: () => {
            this.route.navigateByUrl('/allSports');
          }
        }
      ],
      skipCallback: (stepSkippedOn) => {
        let data = {
          'key': 1
        }
        this._IndexService.guidTourUpdate(data).subscribe(res => {
          this.route.navigateByUrl('/index');
        });
      },
      completeCallback: () => {
        let data = {
          'key': 1
        }
        this._IndexService.guidTourUpdate(data).subscribe(res => {
          this.route.navigateByUrl('/index');
        })
      },
    })
  }

  donetext: boolean = false;
  public startTourIGTMob() {
    this.donetext = false;
    this.guidedTourService.startTour({
      tourId: 'tour',
      useOrb: false,
      minimumScreenSize: 20,
      preventBackdropFromAdvancing: true,
      steps: [
        {
          title: 'Fans Section',
          selector: '#fansTabMob',
          content: 'This is your home/fans page. You can interact with your friends, post, share and more... ',
          useHighlightPadding: true,
          orientation: Orientation.BottomLeft,
          action: () => {
            this.route.navigateByUrl('/index');
          }
        },

        {
          title: 'Breaking News',
          selector: '#breakingNewsTabMob',
          content: 'Get updated on the latest news from your favorite leagues, teams and players.',
          scrollAdjustment: 200,
          action: () => {
            this.route.navigateByUrl('/index');
          },
        },
        {
          title: 'Your Profile',
          selector: '#myProfileTabMob',
          content: 'Add current and previous sports experiences, achievements, personal informations and more...',
          orientation: Orientation.BottomLeft,
          action: () => {
            this.route.navigateByUrl('/myProfile');
          },

        },

        {
          title: 'Scores Section',
          selector: '#scoreTabMob',
          content: 'Check live scores, scheduled and past events. Chat online on the events of your choice.',
          orientation: Orientation.BottomRight,
          action: () => {
            this.route.navigateByUrl('/matches/scheduled/all');
          }
        },

        {
          title: 'All Sports',
          selector: '#allSportsTabMob',
          content: 'Check all players, teams and leagues and follow your favorite ones.',
          orientation: Orientation.BottomRight,
          useHighlightPadding: true,
          action: () => {
            this.route.navigateByUrl('/allSports');
          }
        },
        {
          title: 'All Sports',
          selector: '#allPlayersSection',
          content: 'Follow your favorites - See their latest posts, news and other sports informations.',
          useHighlightPadding: true,
          scrollAdjustment: -1250,
          action: () => {
            this.route.navigateByUrl('/allSports');
          }
        }
      ],
      skipCallback: (stepSkippedOn) => {
        let data = {
          'key': 1
        }
        this._IndexService.guidTourUpdate(data).subscribe(res => {
          this.route.navigateByUrl('/index');
        });
      },
      completeCallback: () => {
        let data = {
          'key': 1
        }
        this._IndexService.guidTourUpdate(data).subscribe(res => {
          this.route.navigateByUrl('/index');
        })
      },
    })
  }

  startTourIGTMob2() {
    this.donetext = true;
    window.scroll(0, 500);
    setTimeout(() => {
      this.guidedTourService.startTour({
        tourId: 'tour',
        useOrb: false,
        minimumScreenSize: 20,
        preventBackdropFromAdvancing: true,
        steps: [
          {
            title: 'Sports Experience',
            selector: '#sportsTabMob',
            content: 'see sports experience here',
            orientation: Orientation.Top,
            action: () => {
              this.route.navigateByUrl('/myProfile');
            },
            scrollAdjustment: 250,
          },
          {
            title: 'Achievements',
            selector: '#achievementsTabMob',
            content: 'see achievements here',
            orientation: Orientation.TopRight,
            scrollAdjustment: 250,
          },
          {
            title: 'Scores',
            selector: '#scoreTabMob',
            content: 'see event details here',
            orientation: Orientation.BottomRight,
            action: () => {
              this.route.navigateByUrl('/matches/scheduled/all');
            }
          },
          {
            title: 'Event',
            selector: '#sportsSection',
            content: 'event info',
            action: () => {
              this.route.navigateByUrl('/matches/scheduled/all');
            }
          },
          {
            title: 'All Sports',
            selector: '#allSportsTabMob',
            content: 'all teams, players, leagues to follow',
            orientation: Orientation.BottomRight,
            useHighlightPadding: true,
            action: () => {
              this.route.navigateByUrl('/allSports');
            }
          },
          {
            title: 'Follow your Favorites',
            selector: '#allPlayersSection',
            content: 'Follow you favorites players, team, and leagues',
            useHighlightPadding: true,
            scrollAdjustment: -1250,
            action: () => {
              this.route.navigateByUrl('/allSports');
            }
          }
        ],
        skipCallback: (stepSkippedOn) => {
          let data = {
            'key': 1
          }
          this._IndexService.guidTourUpdate(data).subscribe(res => {
            this.route.navigateByUrl('/index');
          });
        },
        completeCallback: () => {
          let data = {
            'key': 1
          }
          this._IndexService.guidTourUpdate(data).subscribe(res => {
          })
        },
      });
    }, 200);

  }

  igtAiFuc() {
    this.route.navigateByUrl('/igtAssistant');
  }

  ngDoCheck() {
    if (this.route.url.includes('eventChat')) {
      this.isChatPage = true;
    } else {
      this.isChatPage = false;
    }

    const el = document.getElementById('cdk-overlay-0');
    if (el) {
      el.style.top = '53.9444px';
    }
  }

  ngAfterViewInit() {
    let chatFrame = document.getElementById('launcher');
    if (chatFrame) {
      chatFrame.style.display = 'none';
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }
  //End Scroller Function With Show More Funcationality(n)
}


//All commented code remove and save at backup date 09/july/2020
