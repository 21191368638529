import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullComponent } from './full.component';
import { NavigationComponent } from '../../shared/header-navigation/navigation.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgSelectModule } from '@ng-select/ng-select';
import { GuidedTourModule } from 'ngx-guided-tour';
import { PipesModule } from '../../_pipes/pipes.module';
import { OuterHeaderNavigationModule } from '../../../app/shared/outer-header-navigation/outer-header-navigation.module';



@NgModule({
  declarations: [
    FullComponent,
    NavigationComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    NgbModule,
    InfiniteScrollModule,
    NgSelectModule,
    GuidedTourModule,
    OuterHeaderNavigationModule
  ],
  exports: [
    FullComponent,
    NavigationComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FullModule { }
