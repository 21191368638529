import { Component, OnInit, ViewChild, HostListener, ApplicationRef, Inject, PLATFORM_ID, NgZone } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import * as CryptoJS from 'crypto-js';
import { OnlineStatusService, OnlineStatusType } from './_modules/online-status';
import { CanonicalService } from './_Service/shared/canonical.service';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { filter } from 'rxjs/operators';
import { MessagingService } from './_Service/messaging/messaging.service';
import { SigninSignUpService } from './_Service/UsersSignInSignUp/signin-sign-up.service';
import { Platform } from '@ionic/angular';
import { environment } from '../environments/environment';

declare let FirebasePlugin: any; // Declare the FirebasePlugin variable
declare let FCMPlugin: any; // Declare the FCMPlugin variable
declare let cordova: any; // Declare the FCMPlugin variable

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  statusData: any;
  modalRef: BsModalRef;
  private readonly publicKey = 'BGzIhCvwPYIOaiRJsqTu-MgmuUvIAyv_EjhKAnMF1IaaiaR1nMTvK9jxcTvSsLg_W3q0eyNOB2Jx-my6Aoii5iw'
  smModal: any;
  // Close the pop modal on back button click (D)
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }
  // Close the pop modal on back button click (D)

  display = true;

  constructor(
    private router: Router,
    private canonicalService: CanonicalService,
    private updateServiceWorker: SwUpdate,
    private snackbar: MatSnackBar,
    private appRef: ApplicationRef,
    private swPush: SwPush,
    private route: Router,
    private onlineStatusService: OnlineStatusService,
    private messagingService: MessagingService,
    private _SigninSignUp: SigninSignUpService,
    private platform: Platform,
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object) {

    if (!isPlatformBrowser(this.platformId)) {
      this.display = false;
    }

    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      if (status) {
        this.hideChildModal();
      } else {
        this.showChildModal();
      }
    });
    this.updateClient();
    this.checkUpdate();
  }

  onActivate(event) {

  }

  message;
  deviceType = environment.device_type;
  onDeviceReadyAndroid() {
    let $this = this;
    FirebasePlugin.onTokenRefresh(function (fcmToken) {
      if (fcmToken) {
        // Token received successfully 
        if (!fcmToken) {
          setTimeout(() => this.onTokenRefresh(), 500);
        }
        if (fcmToken && fcmToken != '(null)') {
          $this._SigninSignUp.userAddNotificationToken({ token: fcmToken, device_type: $this.deviceType }).subscribe(res => {
            if (res && res['message']) {
              // console.log("Token:--");
              // console.log(res['message']);
            }
          });
        }
      } else {
        // Handle the case where the token is null or there's an error
        // console.log("Token is null or there's an error");
      }
    }, (error) => {
      // console.log('error retrieving token: ' + error);
    });

    FirebasePlugin.onMessageReceived(function (message) {
      // console.log("Message type: " + message.messageType);
      if (message.messageType === "notification") {
        // console.log("Notification message received");
        if (message.tap) {
          $this.handleNotificationTap(message.param2);
          // console.log("Tapped in " + message.tap);
        }
      }
      console.dir(message);
    }, function (error) {
      console.error(error);
    });

   
  }

  onDeviceReadyIos() {
    let $this = this;
    FCMPlugin.onTokenRefresh(function (token) {
      if (token) {
        // Token received successfully 
        if (!token) {
          setTimeout(() => this.onTokenRefresh(), 500);
        }
        if (token && token != '(null)') {
          $this._SigninSignUp.userAddNotificationToken({ token: token, device_type: $this.deviceType }).subscribe(res => {
            if (res && res['message']) {
              // console.log("Token:--");
              // console.log(res['message']);
            }
          });
        }
      } else {
        // Handle the case where the token is null or there's an error
        // console.log("Token is null or there's an error");
      }
    }, (error) => {
      // console.log('error retrieving token: ' + error);
    });


    FCMPlugin.onNotification(function (data) {
      if (data.wasTapped) {
        let clickAction = data.aps.category;
        // console.log('Notification received in the foreground:', clickAction);
        // Notification was tapped
        $this.handleNotificationTap(clickAction);
      } else {
        // Notification was received in the foreground
        $this.handleForegroundNotification(data);
      }
    });


  }


  handleNotificationTap(clickAction) {
    // Handle the click_action here
    if (clickAction) {
      let modifiedUrlString = clickAction.replace(/ionic:\/\/(www\.|staging\.)?itsgametime\.com|http:\/\/(www\.)?itsgametime\.com|https:\/\/(www\.)?itsgametime\.com|https:\/\/(staging\.)?itsgametime\.com|http:\/\/(staging\.)?itsgametime\.com/gm, "");

      if (modifiedUrlString) {
        if (this.isUserLoggedIn()) {
          // Navigate to '/RefreshComponent' first
          this.route.navigateByUrl('/RefreshComponent').then(() => {
            // Navigate to the modified URL after navigating to '/RefreshComponent'
            this.route.navigateByUrl(modifiedUrlString);
          }).catch((error) => {
            //alert('Navigation error:2');
          });
          
        } else {
          // Handle the case when the user is not logged in (perhaps show a login screen)
          // console.log("User not logged in. Handle accordingly.");
        }
      }
    }
  }

  isUserLoggedIn() {
    // Implement your logic to check if the user is logged in
    // For example, check for the presence of a session token in local storage
    return localStorage.getItem("token") !== null;
  }

  handleForegroundNotification(data) {
    // Handle the notification received in the foreground
    // console.log('Notification received in the foreground:', data);
  }

  ngOnInit() {
    if (this.platform.is('cordova')) {
      if (this.deviceType == 'android') {
        document.addEventListener("deviceready", () => this.onDeviceReadyAndroid(), false);
      } else if (this.deviceType == 'ios') {
        document.addEventListener("deviceready", () => this.onDeviceReadyIos(), false);
      }
    }
    else {
      // code for web push notification start
      this.messagingService.requestPermission('add');
      this.messagingService.receiveMessage();
      this.message = this.messagingService.currentMessage;
      // code for web push notification end
    }

    
    this.swPush.messages.subscribe((message) => console.log(message));
    this.swPush.notificationClicks.subscribe(({ action, notification }) => {
      window.open(notification.data.url)
    });


    //Event for disable the user to drag and drop any media file from apart from modal
    window.addEventListener("dragenter", function (e) {
      e.preventDefault();
      e.dataTransfer.effectAllowed = "none";
      e.dataTransfer.dropEffect = "none";
    }, false);

    window.addEventListener("dragover", function (e) {
      e.preventDefault();
      e.dataTransfer.effectAllowed = "none";
      e.dataTransfer.dropEffect = "none";
    });

    window.addEventListener("drop", function (e) {
      e.preventDefault();
      e.dataTransfer.effectAllowed = "none";
      e.dataTransfer.dropEffect = "none";
    });
    //Event for disable the user to drag and drop any media file from apart from modal

    window.addEventListener('storage', (event) => {
      if (event && (event.storageArea == localStorage) && (event.key == 'token')) {
        let token = localStorage.getItem('token');
        if (token != undefined || token != null) {
          if (!document.hasFocus()) {
            this.router.navigate(['/index']);
          }
        }
      }
    }, false);

  }

  previousUrl: string;
  counter: number = 0;
  ngAfterViewInit() {
    let currentUrl = this.route.url;
    this.route.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url != '' && event.url != '/RefreshComponent' && this.counter != 0 && currentUrl != this.previousUrl) {
          this.counter = 0;
        }
        if (event.url != '' && event.url != '/RefreshComponent' && this.counter == 0 && currentUrl != this.previousUrl) {
          this.previousUrl = event.url;
          this.counter++;
          this.canonicalService.setCanonicalURL();
        }
      });
  }

  @ViewChild('childModal', { static: false }) childModal: ModalDirective;

  showChildModal(): void {
    this.childModal.show();
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  pageRefresh() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };

    let currentUrl = this.router.url + '?';

    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      });
  }

  logout() {
    localStorage.clear();
    sessionStorage.removeItem('fullcomponent');
    this.router.navigate(['/login']);
  }

  updateClient() {
    if (!this.updateServiceWorker.isEnabled) {
    }
 

    this.updateServiceWorker.available.subscribe(evt => {
      let snack = this.snackbar.open('Update Available', 'Reload Please.');

      snack.onAction().subscribe(() => {
        window.location.reload();
      });

      setTimeout(() => {
        snack.dismiss();
      }, 10000);
    });

   
  }

  checkUpdate() {
    this.appRef.isStable.subscribe((isStable => {
      if (isStable) {
        const timeInterVal = interval(600000);
        timeInterVal.subscribe(() => {
          this.updateServiceWorker.checkForUpdate().then(() => console.log('checked'));
        })
      }
    }))
  }

  //Encrypt Data(n)
  encryptData(data) {

    try {
      if (data) {
        return CryptoJS.AES.encrypt(JSON.stringify(data), '1234509876encryptdecrypt1234567890').toString();
      }
    } catch (e) {
    }
  }
  //End encrypt Data(n)

  //Decrypt Data (n)
  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, '1234509876encryptdecrypt1234567890');
      if (bytes.toString()) {
        if (CryptoJS.enc.Utf8) {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
      }
      if (data) {
        return data;
      }

    } catch (e) {
    }
  }
  //End Decrypt Data (n)



}
