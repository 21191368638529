export const customEmojisNBA: any = [
    // {
    //     name: 'Goldtrophy',
    //     shortNames: ['gold-trophy'],
    //     custom: true,
    //     text: '(gold-trophy)',
    //     emoticons: [],
    //     keywords: ['trophy', 'basketball', 'cup'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665744582-trophy.png',
    // },
    // {
    //     name: 'Goldmedal',
    //     shortNames: ['gold-medal'],
    //     custom: true,
    //     text: '(gold-medal)',
    //     emoticons: [],
    //     keywords: ['medal', 'award'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665745434-award.png',
    // },
    // {
    //     name: 'Fireball',
    //     shortNames: ['fire-ball'],
    //     custom: true,
    //     text: '(fire-ball)',
    //     emoticons: [],
    //     keywords: ['fireball', 'basketball', 'fire'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747166-5g.png',
    // },
    // {
    //     name: 'Nbadraft',
    //     shortNames: ['nba-draft'],
    //     custom: true,
    //     text: '(nba-draft)',
    //     emoticons: [],
    //     keywords: ['nbadraft', 'NBA', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747662-draft.png',
    // },

    // {
    //     name: 'Basketballplayer',
    //     shortNames: ['basket-ball-player'],
    //     custom: true,
    //     text: '(basket-ball-player)',
    //     emoticons: [],
    //     keywords: ['basketballplayer', 'basketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665749277-shot.png',
    // },
    // {
    //     name: 'Playerflliping',
    //     shortNames: ['player-flliping'],
    //     custom: true,
    //     text: '(player-flliping)',
    //     emoticons: [],
    //     keywords: ['player', 'basketball', 'swing basketball on finger'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665815812-dunk.png',
    // },
    // {
    //     name: 'Basketballcourt',
    //     shortNames: ['basket-ball-court'],
    //     custom: true,
    //     text: '(basket-ball-court)',
    //     emoticons: [],
    //     keywords: ['basketball', 'court', 'ground'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665750876-basketball-court.png',
    // },
    // {
    //     name: 'Basketball',
    //     shortNames: ['basket-ball'],
    //     custom: true,
    //     text: '(basket-ball)',
    //     emoticons: [],
    //     keywords: ['basketball', 'coloured basketball', 'ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751079-basketball.png',
    // },
    // {
    //     name: 'Bostonceltics',
    //     shortNames: ['boston-celtics'],
    //     custom: true,
    //     text: '(boston-celtics)',
    //     emoticons: [],
    //     keywords: ['bostonceltics', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666173186-boston-celtics.png',
    // },
    // {
    //     name: 'Brooklynnets',
    //     shortNames: ['brooklyn-nets'],
    //     custom: true,
    //     text: '(brooklyn-nets)',
    //     emoticons: [],
    //     keywords: ['brooklyn-nets', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666173186-brooklyn-nets.png',
    // },
    // {
    //     name: 'Charlottehornets',
    //     shortNames: ['charlotte-hornets'],
    //     custom: true,
    //     text: '(charlotte-hornets)',
    //     emoticons: [],
    //     keywords: ['charlotte-hornets', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666173186-charlotte-hornets.png',
    // },
    // {
    //     name: 'Chicagobulls',
    //     shortNames: ['chicago-bulls'],
    //     custom: true,
    //     text: '(chicago-bulls)',
    //     emoticons: [],
    //     keywords: ['chicago-bulls', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666173186-chicago-bulls.png',
    // },
    // {
    //     name: 'Clevelandcavaliers',
    //     shortNames: ['cleveland-cavaliers'],
    //     custom: true,
    //     text: '(cleveland-cavaliers)',
    //     emoticons: [],
    //     keywords: ['clevelandcavaliers', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666173186-cleveland-cavaliers.png',
    // },
    // {
    //     name: 'Dallasmavericks',
    //     shortNames: ['dallas-mavericks'],
    //     custom: true,
    //     text: '(dallas-mavericks)',
    //     emoticons: [],
    //     keywords: ['dallasmavericks', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666173186-dallas-mavericks.png',
    // },
    // {
    //     name: 'Denvernuggets',
    //     shortNames: ['denver-nuggets'],
    //     custom: true,
    //     text: '(denver-nuggets)',
    //     emoticons: [],
    //     keywords: ['denver-nuggets', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666173186-denver-nuggets.png',
    // },
    // {
    //     name: 'Goldenstatewarriors',
    //     shortNames: ['golden-state-warriors'],
    //     custom: true,
    //     text: '(golden-state-warriors)',
    //     emoticons: [],
    //     keywords: ['goldenstatewarriors', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666173186-golden-state-warriors.png',
    // },
    // {
    //     name: 'Houston-rockets',
    //     shortNames: ['houston-rockets'],
    //     custom: true,
    //     text: '(houston-rockets)',
    //     emoticons: [],
    //     keywords: ['houstonrockets', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666174712-houston-rockets.png',
    // },
    // {
    //     name: 'Indianapacers',
    //     shortNames: ['indiana-pacers'],
    //     custom: true,
    //     text: '(indiana-pacers)',
    //     emoticons: [],
    //     keywords: ['indianapacers', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666174712-indiana-pacers.png',
    // },
    // {
    //     name: 'Miamiheat',
    //     shortNames: ['miami-heat'],
    //     custom: true,
    //     text: '(miami-heat)',
    //     emoticons: [],
    //     keywords: ['miamiheat', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666174712-miami-heat.png',
    // },
    // {
    //     name: 'Minnesotatimberwolves',
    //     shortNames: ['minnesota-timberwolves'],
    //     custom: true,
    //     text: '(minnesota-timberwolves)',
    //     emoticons: [],
    //     keywords: ['minnesotatimberwolves', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666174712-minnesota-timberwolves.png',
    // },
    // {
    //     name: 'Angelesclippers',
    //     shortNames: ['angeles-clippers'],
    //     custom: true,
    //     text: '(angeles-clippers)',
    //     emoticons: [],
    //     keywords: ['angelesclippers', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666174712-los-angeles-clippers.png',
    // },
    // {
    //     name: 'Newyorkknicks',
    //     shortNames: ['new-york-knicks'],
    //     custom: true,
    //     text: '(new-york-knicks)',
    //     emoticons: [],
    //     keywords: ['newyorkknicks', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666174712-new-york-knicks.png',
    // },
    // {
    //     name: 'Memphisgrizzlies',
    //     shortNames: ['memphis-grizzlies'],
    //     custom: true,
    //     text: '(memphis-grizzlies)',
    //     emoticons: [],
    //     keywords: ['memphisgrizzlies', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666174712-memphis-grizzlies-1.png',
    // },
    // {
    //     name: 'Orlandomagic',
    //     shortNames: ['orlando-magic'],
    //     custom: true,
    //     text: '(orlando-magic)',
    //     emoticons: [],
    //     keywords: ['orlandomagic', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666176850-orlando-magic.png',
    // },
    // {
    //     name: 'Milwaukeebucks',
    //     shortNames: ['milwaukee-bucks'],
    //     custom: true,
    //     text: '(milwaukee-bucks)',
    //     emoticons: [],
    //     keywords: ['milwaukeebucks', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666174712-milwaukee-bucks.png',
    // },
    // {
    //     name: 'Phoenixsuns',
    //     shortNames: ['phoenix-suns'],
    //     custom: true,
    //     text: '(phoenix-suns)',
    //     emoticons: [],
    //     keywords: ['phoenixsuns', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666176850-phoenix-suns.png',
    // },
    // {
    //     name: 'Torontoraptors',
    //     shortNames: ['toronto-raptors'],
    //     custom: true,
    //     text: '(toronto-raptors)',
    //     emoticons: [],
    //     keywords: ['torontoraptors', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666176850-toronto-raptors.png',
    // },
    // {
    //     name: 'Portlandtrailblazers',
    //     shortNames: ['portland-trail-blazers'],
    //     custom: true,
    //     text: '(portland-trail-blazers)',
    //     emoticons: [],
    //     keywords: ['portlandtrailblazers', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666176850-portland-trail-blazers.png',
    // },
    // {
    //     name: 'Sacramentokings',
    //     shortNames: ['sacramento-kings'],
    //     custom: true,
    //     text: '(sacramento-kings)',
    //     emoticons: [],
    //     keywords: ['sacramentokings', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666176850-sacramento-kings.png',
    // },
    // {
    //     name: 'Orleanspelicans',
    //     shortNames: ['orleans-pelicans'],
    //     custom: true,
    //     text: '(orleans-pelicans)',
    //     emoticons: [],
    //     keywords: ['orleanspelicans', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666176851-orleans-pelicans.png',
    // },
    // {
    //     name: 'Sanantoniospurs',
    //     shortNames: ['san-antonio-spurs'],
    //     custom: true,
    //     text: '(san-antonio-spurs)',
    //     emoticons: [],
    //     keywords: ['sanantoniospurs', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666176851-san-antonio-spurs.png',
    // },
    // {
    //     name: 'Philidephiaers',
    //     shortNames: ['philidephia-ers'],
    //     custom: true,
    //     text: '(philidephia-ers)',
    //     emoticons: [],
    //     keywords: ['philidephiaers', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666176851-philidephia-ers.png',
    // },
    // {
    //     name: 'Utahjazz',
    //     shortNames: ['utah-jazz'],
    //     custom: true,
    //     text: '(utah-jazz)',
    //     emoticons: [],
    //     keywords: ['utahjazz', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666177789-utah-jazz.png',
    // },
    // {
    //     name: 'Washingtonwizards',
    //     shortNames: ['washington-wizards'],
    //     custom: true,
    //     text: '(washington-wizards)',
    //     emoticons: [],
    //     keywords: ['washingtonwizards', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666177789-washington-wizards.png',
    // },
    // {
    //     name: 'Nba',
    //     shortNames: ['nba'],
    //     custom: true,
    //     text: '(nba)',
    //     emoticons: [],
    //     keywords: ['nba', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666178768-nba.png',
    // },
    // {
    //     name: 'Basketballsuperleague',
    //     shortNames: ['basketball-super-league'],
    //     custom: true,
    //     text: '(basketball-super-league)',
    //     emoticons: [],
    //     keywords: ['basketball-superleague', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666178768-bsketballsuperleague.png',
    // },
    // {
    //     name: 'Basketball',
    //     shortNames: ['basketball'],
    //     custom: true,
    //     text: '(basketball)',
    //     emoticons: [],
    //     keywords: ['basketball', 'ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666178768-basketball.png',
    // },
    // {
    //     name: 'Teamnba',
    //     shortNames: ['team-nba'],
    //     custom: true,
    //     text: '(team-nba)',
    //     emoticons: [],
    //     keywords: ['teamnba', 'nba', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666178768-all-30team-nba.jfif',
    // },
    // {
    //     name: 'Wingstournamet',
    //     shortNames: ['wings-tournamet'],
    //     custom: true,
    //     text: '(bwings-tournamet)',
    //     emoticons: [],
    //     keywords: ['wingstournamet', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666179632-wings-tournamet.png',
    // },
    // {
    //     name: 'Nbatextlogo',
    //     shortNames: ['nba-text-logo'],
    //     custom: true,
    //     text: '(nba-text-logo)',
    //     emoticons: [],
    //     keywords: ['nbatextlogo', 'nbalogo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666179632-NBATEXTLOGO.png',
    // },
    // {
    //     name: 'Nbaroundlogo',
    //     shortNames: ['nba-round-logo'],
    //     custom: true,
    //     text: '(nba-round-logo)',
    //     emoticons: [],
    //     keywords: ['basketball', 'nbaroundlogo', 'nba', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666180313-nbaroundlogo.png',
    // },
    // {
    //     name: 'Basketring',
    //     shortNames: ['basket-ring'],
    //     custom: true,
    //     text: '(basket-ring)',
    //     emoticons: [],
    //     keywords: ['basket', 'basketring'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-basket-ring.png',
    // },
    // {
    //     name: 'Colouredbasketball',
    //     shortNames: ['coloured-basketball'],
    //     custom: true,
    //     text: '(coloured-basketball)',
    //     emoticons: [],
    //     keywords: ['basketball', 'colouredbasketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-coloured-basketball.png',
    // },
    // {
    //     name: 'Dribblebasketballdunk',
    //     shortNames: ['dribble-basketball-dunk'],
    //     custom: true,
    //     text: '(dribble-basketball-dunk)',
    //     emoticons: [],
    //     keywords: ['basketball', 'dribblebasketballdunk', 'player', 'dunk'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-dribble-basketball-dunk.png',
    // },
    // {
    //     name: 'Calendar',
    //     shortNames: ['calendar'],
    //     custom: true,
    //     text: '(calendar)',
    //     emoticons: [],
    //     keywords: ['basketball', 'ccalendar', 'scored'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-calendar.png',
    // },
    // {
    //     name: 'Dribbble',
    //     shortNames: ['dribbble'],
    //     custom: true,
    //     text: '(dribbble)',
    //     emoticons: [],
    //     keywords: ['basketball', 'dribbble'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-dribbble.png',
    // },
    // {
    //     name: 'Dribblebasketball',
    //     shortNames: ['dribble-basketball'],
    //     custom: true,
    //     text: '(dribble-basketball)',
    //     emoticons: [],
    //     keywords: ['basketball', 'dribblebasketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-dribble-basketball.png',
    // },
    // {
    //     name: 'Dufflebag',
    //     shortNames: ['duffle-bag'],
    //     custom: true,
    //     text: '(duffle-bag)',
    //     emoticons: [],
    //     keywords: ['duffle-bag', 'bag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-duffle-bag.png',
    // },
    // {
    //     name: 'Gamesequipment',
    //     shortNames: ['games-equipment'],
    //     custom: true,
    //     text: '(games-equipment)',
    //     emoticons: [],
    //     keywords: ['basketball', 'gamesequipmentk'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666182509-games-equipment.png',
    // },
    // {
    //     name: 'Hoop',
    //     shortNames: ['hoop'],
    //     custom: true,
    //     text: '(hoop)',
    //     emoticons: [],
    //     keywords: ['basket', 'hoop', 'dunk'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666182509-hoop.png',
    // },
    // {
    //     name: 'Nbalogo',
    //     shortNames: ['nbalogo'],
    //     custom: true,
    //     text: '(nbalogo)',
    //     emoticons: [],
    //     keywords: ['nbalogo', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666182509-nbalogo.png',
    // },
    // {
    //     name: 'Referee',
    //     shortNames: ['referee'],
    //     custom: true,
    //     text: '(referee)',
    //     emoticons: [],
    //     keywords: ['referee', 'signal', 'whistle'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666182509-referee-signal.png',
    // },
    // {
    //     name: 'Scoreboard',
    //     shortNames: ['scoreboard'],
    //     custom: true,
    //     text: '(scoreboard)',
    //     emoticons: [],
    //     keywords: ['scoreboard'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666182509-scoreboard.png',
    // },
    // {
    //     name: 'Dunkshot',
    //     shortNames: ['dunkshot'],
    //     custom: true,
    //     text: '(dunkshot)',
    //     emoticons: [],
    //     keywords: ['basketball', 'dunkshot', 'player', 'dunk'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666182509-dunkshot.png',
    // },
    // {
    //     name: 'Dunk',
    //     shortNames: ['dunk'],
    //     custom: true,
    //     text: '(dunk)',
    //     emoticons: [],
    //     keywords: ['basketball', 'dunk', 'player', 'hoop'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666182509-dunk.png',
    // },
    // {
    //     name: 'Dunk-basketball',
    //     shortNames: ['dunk-basketball'],
    //     custom: true,
    //     text: '(dunk-basketball)',
    //     emoticons: [],
    //     keywords: ['basketball', 'basketballdunk', 'hoop', 'dunk'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666182509-dunk-basketball.png',
    // },
    // {
    //     name: 'Refreestartsign',
    //     shortNames: ['refree-start-sign'],
    //     custom: true,
    //     text: '(refree-start-sign)',
    //     emoticons: [],
    //     keywords: ['refree', 'refree-start-sign', 'startsign'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666183568-start-sign.png',
    // },
    // {
    //     name: 'Tshirt',
    //     shortNames: ['t-shirt'],
    //     custom: true,
    //     text: '(t-shirt)',
    //     emoticons: [],
    //     keywords: ['t-shirt', 'player', 'jersey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666183568-t-shirt.png',
    // },
    // {
    //     name: 'Substitution',
    //     shortNames: ['substitution'],
    //     custom: true,
    //     text: '(substitution)',
    //     emoticons: [],
    //     keywords: ['substitution', 'sign'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666183568-substitution.png',
    // },
    // {
    //     name: 'Stopwatch',
    //     shortNames: ['stopwatch'],
    //     custom: true,
    //     text: '(stopwatch)',
    //     emoticons: [],
    //     keywords: ['basketball', 'dribblebasketballdunk', 'player', 'dunk'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666183568-stopwatch.png',
    // },
    // {
    //     name: 'Whistle',
    //     shortNames: ['whistle'],
    //     custom: true,
    //     text: '(whistle',
    //     emoticons: [],
    //     keywords: ['whistle', 'refreeequipment'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666183568-sport-whistle.png',
    // },
    // {
    //     name: 'Vest',
    //     shortNames: ['vest'],
    //     custom: true,
    //     text: '(vest)',
    //     emoticons: [],
    //     keywords: ['vest', 'basketball', 'jersey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665750933-vest-1.png',
    // },
    // {
    //     name: 'Shot',
    //     shortNames: ['shot'],
    //     custom: true,
    //     text: '(shot)',
    //     emoticons: [],
    //     keywords: ['shot', 'basketball', 'swing'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751212-shot-1.png',
    // },
    // {
    //     name: 'Basketball player',
    //     shortNames: ['basketball-player'],
    //     custom: true,
    //     text: '(basketball-player)',
    //     emoticons: [],
    //     keywords: ['player', 'basketball',],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665752247-winner.png',
    // },
    // {
    //     name: 'Basketball court',
    //     shortNames: ['basketball-court'],
    //     custom: true,
    //     text: '(basketball-court)',
    //     emoticons: [],
    //     keywords: ['basketball court', 'basketball', 'ball', 'basket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665753714-basketball-court.png',
    // },
    // {
    //     name: 'Badge',
    //     shortNames: ['badge'],
    //     custom: true,
    //     text: '(badge)',
    //     emoticons: [],
    //     keywords: ['badge', 'reward'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095827-badge.png',
    // },
    // {
    //     name: 'Stadium',
    //     shortNames: ['stadium'],
    //     custom: true,
    //     text: '(stadium)',
    //     emoticons: [],
    //     keywords: ['stadium', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096047-stadium-3.png',
    // },
    // {
    //     name: 'Catchbasketball',
    //     shortNames: ['catch-basketball'],
    //     custom: true,
    //     text: '(catch-basketball)',
    //     emoticons: [],
    //     keywords: ['catchbasketball', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-catchbasketball.png',
    // },
    // {
    //     name: 'Board',
    //     shortNames: ['board'],
    //     custom: true,
    //     text: '(board)',
    //     emoticons: [],
    //     keywords: ['board', 'field'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-board.png',
    // },
    // {
    //     name: 'Basketballonbench',
    //     shortNames: ['basketball-on-bench'],
    //     custom: true,
    //     text: '(basketball-on-bench)',
    //     emoticons: [],
    //     keywords: ['basketballonbench', 'bench', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-bench.png',
    // },
    // {
    //     name: 'Spinbasketball-player',
    //     shortNames: ['spin-basketball-player'],
    //     custom: true,
    //     text: '(spin-basketball-player)',
    //     emoticons: [],
    //     keywords: ['basketball-player', 'basketball', 'player', 'spin'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-basketball-player-9.png',
    // },
    // {
    //     name: 'Bankshotplayer',
    //     shortNames: ['bank-shot-player'],
    //     custom: true,
    //     text: '(bank-shot-player)',
    //     emoticons: [],
    //     keywords: ['bankshotplayer', 'basketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-player-6.png',
    // },
    // {
    //     name: 'Basketballhoop',
    //     shortNames: ['basketball-hoop'],
    //     custom: true,
    //     text: '(basketball-hoop)',
    //     emoticons: [],
    //     keywords: ['basketballhoop', 'basketball', 'basket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-hoop.png',
    // },
    // {
    //     name: 'Hoopfront',
    //     shortNames: ['hoop-front'],
    //     custom: true,
    //     text: '(hoop-front)',
    //     emoticons: [],
    //     keywords: ['basketball-hoop', 'basketball', 'hoop'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-hoop-1.png',
    // },
    // {
    //     name: 'Basketballcourttop',
    //     shortNames: ['basketballcourt-top'],
    //     custom: true,
    //     text: '(basketballcourt-top)',
    //     emoticons: [],
    //     keywords: ['basketballhoop', 'basketball', 'basketballgame', 'hoop', 'basketballcourt-top'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketballgame.png',
    // },
    // {
    //     name: 'Scratchbasketballcourt',
    //     shortNames: ['scratchbasketballcourt'],
    //     custom: true,
    //     text: '(scratchbasketballcourt)',
    //     emoticons: [],
    //     keywords: ['basketballhoop', 'basketballcourt', 'basketballgame', 'hoop', 'scratchbasketballcourt'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-court-2.png',
    // },
    // {
    //     name: 'Practicebasketball',
    //     shortNames: ['practicebasketball'],
    //     custom: true,
    //     text: '(practicebasketball)',
    //     emoticons: [],
    //     keywords: ['player', 'basketball', 'practicebasketball', 'hoop', 'basketballcourt'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095828-basketball-17.png',
    // },
    // {
    //     name: 'Slamdunk',
    //     shortNames: ['slam-dunk'],
    //     custom: true,
    //     text: '(slam-unk)',
    //     emoticons: [],
    //     keywords: ['slamdunk', 'basketballcourt', 'basketballgame', 'hoop'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095827-basketball-16.png',
    // }
];