<header *ngIf="isLoggedIn" class="topbar mb-3">
	<nav class="navbar top-navbar navbar-expand-md navbar-light p-md-0" aria-label="setting-icon">
		<div class="container pt-0 pb-0">
			<div class="navbar-header w-100 d-flex justify-content-between align-items-center pb-3 pb-md-0">
				<a class="navbar-brand" href="javascript:void(0);">
					<img src="{{platform_logo}}" class="img-fluid" width="160" alt="...">
				</a>
				<ul class="navbar-nav my-lg-0 notification-icon d-lg-flex">
					<li class="nav-item dropdown" ngbDropdown placement="bottom-right" [ngClass]="{active: activeTab=='Settings'}">
						<a ngbDropdownToggle class="nav-link waves-effect waves-dark notification-icon pt-0 pt-sm-2" href="javascript:void(0)" placement="bottom">
							<img src="assets/images/svg_patriot/settings.png" alt="settings" width="22">
							<small class="d-block">Settings</small>
						</a>
						<div class="dropdown-menu animated fadeIn" ngbDropdownMenu>
							<ul class="dropdown-user">
								<li><a href="javascript:void(0);" (click)="logout()"><i class="fa fa-power-off"></i> Logout</a></li>
							</ul>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</header> 

<!-- <div *ngIf="!isLoggedIn" data-collapse="medium" data-animation="over-right" data-duration="400" data-w-id="6b543a3e-f9c6-f9bd-eba9-d60107508ad2" data-easing="ease-out" data-easing2="ease-in" role="banner" class="navbar w-nav"> -->
<div *ngIf="!isLoggedIn" data-collapse="medium" data-animation="over-right" data-duration="400" data-easing="ease-out" data-easing2="ease-in" role="banner" class="navbar w-nav">
	<div class="wrapper_navbar">
		<div class="menu-button w-nav-button">
			<div class="icon-2 w-icon-nav-menu"></div>
		</div>
		<a routerLink="/" class="brand w-nav-brand"><img loading="lazy" src="assets/landing-images/New-Logo-ItsGameTime-1.svg" width="430" alt="logo itsgametime" class="logo_itsgametime"></a>
		<nav role="navigation" class="nav-menu w-nav-menu" aria-label="menu">
			<a routerLink="/" class="nav-link w-nav-link" [ngClass]="{'active_link w--current': activeUrl=='/'}">Home</a>
			<a routerLink="/about" aria-current="page" class="nav-link w-nav-link" [ngClass]="{'active_link w--current': activeUrl=='/about'}">About Us</a>
			<a href="javascript:void(0)" routerLink="/news" class="nav-link w-nav-link" [ngClass]="{'active_link w--current': activeUrl=='/news'}">News</a>
			<a href="javascript:void(0)" routerLink="/blog" class="nav-link w-nav-link" [ngClass]="{'active_link w--current': activeUrl=='/blog'}">Blog</a>
			<a href="{{help_center}}" target="_blank" class="nav-link w-nav-link" rel="noopener">Help Center</a>
			<a routerLink="/login" class="button_signup w-button">Join Now / Sign In</a>
		</nav>
	</div>
</div>