import { Injectable } from '@angular/core'; // we may have to add Injector if use of auth service directly not works
import { HttpInterceptor } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private authService : AuthService) { }

  intercept(req,next)
  {

    let tokenizedReq = req.clone({
      setHeaders : {
        Authorization : `Bearer ${this.authService.getToken()}`
      }
    });
    return next.handle(tokenizedReq);

    

    
  }
  
}
