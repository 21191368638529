import { Injectable } from "@angular/core";
import { HttpClient, HttpProgressEvent } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class IndexService {

  constructor(private Http: HttpClient) {

  }

  getNews() {
    return this.Http.get(`https://newsapi.org/v2/top-headlines?country=us&apiKey=3a9c333077d346a4a183da2dd64d19f5`);
  }

  getNewsApi() {
    return this.Http.get(`${environment.base_url}/getNewsApi`);
  }


  getNewsList(Data) {
    return this.Http.post(`${environment.base_url}/getNewsList`, Data);
  }

  getNewsDetailsByTitle(data) {
    return this.Http.post(`${environment.base_url}/getNewsDetailsByTitle`, data);
  }

  newsCategories() {
    return this.Http.get(`${environment.base_url}/newsCategories`);
  }

  private badgeCount = new BehaviorSubject('');
  badgeCountService = this.badgeCount.asObservable();

  sendNotiMsgBadgeNum(badgeCount: string) {
    this.badgeCount.next(badgeCount);
  }

  private TabSource = new BehaviorSubject('');
  aliasFriendMessageService = this.TabSource.asObservable();

  aliasFriendChangeMessage(aliasFriendMessage: string) {
    this.TabSource.next(aliasFriendMessage)
  }

  private resUserInfo = new BehaviorSubject('');
  getLoginUserInfo = this.resUserInfo.asObservable();

  sendLoginUserInfo(reqUserInfo: string) {
    this.TabSource.next(reqUserInfo)
  }

  private league_response = {};
  private liveScoreTabSource = new BehaviorSubject(this.league_response);
  liveScoreLeagueRefreshRes = this.liveScoreTabSource.asObservable();

  liveScoreLeagueRefresh(liveScore) {
    this.liveScoreTabSource.next(liveScore)
  }

  private notificationRefreshSource = new BehaviorSubject('');
  notificationDetailRefresh = this.notificationRefreshSource.asObservable();

  notificationDetailRefreshRequest(refreshMessage: string) {
    this.notificationRefreshSource.next(refreshMessage)
  }
  //Change profile image service
  private TabSourceprofileImage = new BehaviorSubject('');
  profileImageService = this.TabSourceprofileImage.asObservable();

  changeProfileImages(profileImage: string) {
    this.TabSource.next(profileImage);
    this.TabSourceprofileImage.next(profileImage);
  }
  //End Change profile image service

  private messageSource = new BehaviorSubject('');
  userNameUpdate = this.messageSource.asObservable();

  updateUserName(message: string) {
    this.messageSource.next(message)
  }



  private TabSource1 = new BehaviorSubject('');
  chatTextMessageService = this.TabSource1.asObservable();

  chatTextUserForMessage(textChatMessage: string) {
    this.TabSource1.next(textChatMessage)
  }

  //Refresh Chat message  
  private refreshChatmessageSource = new BehaviorSubject('');
  getChatMessageRefresh = this.refreshChatmessageSource.asObservable();

  chatMessageRefreshService(RefreshMessage: string) {
    this.refreshChatmessageSource.next(RefreshMessage)
  }

  //refresh notification count
  private refreshChatmessageSource1 = new BehaviorSubject('');
  getChatMessageRefresh1 = this.refreshChatmessageSource1.asObservable();

  chatMessageRefreshService1(RefreshMessage: string) {
    this.refreshChatmessageSource1.next(RefreshMessage)
  }
  //Refresh Chat message 

  // My profile gallery
  private myprofilegalleryview = new BehaviorSubject('');
  getMyprofilegalleryview = this.myprofilegalleryview.asObservable();

  myProfileGalleryService(RefreshMessage: string) {
    this.myprofilegalleryview.next(RefreshMessage)
  }
  // End My profile gallery

  // Alias profile gallery
  private aliasprofilegalleryview = new BehaviorSubject('');
  getAliasprofilegalleryview = this.aliasprofilegalleryview.asObservable();

  aliasProfileGalleryService(RefreshMessage: string) {
    this.aliasprofilegalleryview.next(RefreshMessage)
  }
  // End Alias profile gallery

  // Visit profile gallery
  private visitprofilegalleryview = new BehaviorSubject('');
  getVisitprofilegalleryview = this.visitprofilegalleryview.asObservable();

  visitProfileGalleryService(RefreshMessage: string) {
    this.visitprofilegalleryview.next(RefreshMessage)
  }
  // End Alias profile gallery

  getPrivacySettings() {
    return this.Http.get(`${environment.base_url}/getPrivacySettings`);
  }


  addPost(Data) {
    return this.Http.post(`${environment.base_url}/addPost`, Data,);
  }

  updatePost(Data) {
    return this.Http.post(`${environment.base_url}/updatePost`, Data,);
  }

  updateSharePost(Data) {
    return this.Http.post(`${environment.base_url}/updateSharePost`, Data,);
  }


  calcProgressPercent(event: HttpProgressEvent) {
    return Math.round(100 * event.loaded / event.total);
  }

  addPostWithUrl(Data) {
    return this.Http.post(`${environment.base_url}/addPostWithUrl`, Data);
  }


  addSharePost(Data) {
    return this.Http.post(`${environment.base_url}/addSharePost`, Data);
  }

  inviteUsers(Data) {
    return this.Http.post(`${environment.social_media_url}/inviteUsers`, Data);
  }

  getUserNavigationInfo() {
    return this.Http.get(`${environment.base_url}/getUserNavigationInfo`);
  }

  getNotificatioCount(Data) {
    return this.Http.post(`${environment.base_url}/getNotificatioCount`, Data);
  }

  leaguesGifStickerImagesShowMore(Data) {
    return this.Http.post(`${environment.base_url}/leaguesGifStickerImagesShowMore`, Data);
  }

  updateConnectionStatus(Data) {
    return this.Http.post(`${environment.base_url}/updateConnectionStatus`, Data);
  }

  getNotificatioNumberCount() {
    return this.Http.get(`${environment.base_url}/getNotificatioNumberCount`);
  }

  getMessageNotificationCount() {
    return this.Http.get(`${environment.base_url}/getMessageNotificationCount`);
  }

  getNotificationMessageList(Data) {
    return this.Http.post(`${environment.base_url}/getNotificationMessageList`, Data);
  }

  updateMessageNotification(Data) {
    return this.Http.post(`${environment.base_url}/updateMessageNotification`, Data);
  }

  getMainPostList(Data) {
    return this.Http.post(`${environment.base_url}/getMainPostList`, Data);
  }

  getAliasPostList(Data) {
    return this.Http.post(`${environment.base_url}/getAliasPostList`, Data);
  }

  getPostDetailsById(Data) {
    return this.Http.post(`${environment.base_url}/getPostDetailsById`, Data);
  }

  getPostDetailsByIdOpen(Data) {
    return this.Http.post(`${environment.base_url}/getPostDetailsByIdOpen`, Data);
  }

  getPostListSelf(Data) {
    return this.Http.post(`${environment.base_url}/getPostListSelf`, Data);
  }

  getOpenForumSelfPostList(Data) {
    return this.Http.post(`${environment.base_url}/getOpenForumSelfPostList`, Data);
  }

  /** To be deleted **/
  getOpenForumSelfPostListApprove(Data) {
    return this.Http.post(`${environment.base_url}/getOpenForumSelfPostListApprove`, Data);
  }
  /** To be deleted **/

  getSearchPostList(Data) {
    return this.Http.post(`${environment.base_url}/getSearchPostList`, Data);
  }

  getSearchBirthdayPostList(Data) {
    return this.Http.post(`${environment.base_url}/getSearchBirthdayPostList`, Data);
  }

  getBirthdayPostListSelf(Data) {
    return this.Http.post(`${environment.base_url}/getBirthdayPostListSelf`, Data);
  }

  postLike(Data) {
    return this.Http.post(`${environment.base_url}/postLike`, Data);
  }

  getPostComment(Data) {
    return this.Http.post(`${environment.base_url}/getPostComment`, Data);
  }

  getShowMoreReply(Data) {
    return this.Http.post(`${environment.base_url}/getShowMoreReply`, Data);
  }

  getShowMoreMediaReply(Data) {
    return this.Http.post(`${environment.base_url}/getShowMoreMediaReply`, Data);
  }


  addPostComment(Data) {
    return this.Http.post(`${environment.base_url}/addPostComment`, Data);
  }

  updateCommentAndReply(Data) {
    return this.Http.post(`${environment.base_url}/updateCommentAndReply`, Data);
  }

  updatePostMediaCommentReply(Data) {
    return this.Http.post(`${environment.base_url}/updatePostMediaCommentReply`, Data);
  }

  addNewsSharePost(Data) {
    return this.Http.post(`${environment.base_url}/addNewsSharePost`, Data);
  }

  addNewsSharePostWithFriend(Data) {
    return this.Http.post(`${environment.base_url}/addNewsSharePostWithFriend`, Data);
  }

  addSharePostWithFriend(Data) {
    return this.Http.post(`${environment.base_url}/addSharePostWithFriend`, Data);
  }

  uploadProfilePic(Data) {
    return this.Http.post(`${environment.base_url}/uploadProfilePic`, Data);
  }

  removeProfilePic(Data) {
    return this.Http.post(`${environment.base_url}/removeProfilePic`, Data);
  }

  uploadCoverPic(Data) {
    return this.Http.post(`${environment.base_url}/uploadCoverPic`, Data);
  }

  removeCoverPic(Data) {
    return this.Http.post(`${environment.base_url}/removeCoverPic`, Data);
  }

  removeCoverPicByAdmin(Data) {
    return this.Http.post(`${environment.base_url}/removeCoverPicByAdmin`, Data);
  }

  removeProfilePicByAdmin(Data) {
    return this.Http.post(`${environment.base_url}/removeProfilePicByAdmin`, Data);
  }

  addPostCommentReply(Data) {
    return this.Http.post(`${environment.base_url}/addPostCommentReply`, Data);
  }

  removePost(Data) {
    return this.Http.post(`${environment.base_url}/removePost`, Data);
  }

  approvePost(Data) {
    return this.Http.post(`${environment.base_url}/approvePost`, Data);
  }

  hidePost(Data) {
    return this.Http.post(`${environment.base_url}/hidePost`, Data);
  }

  deletePostCommentAndReply(Data) {
    return this.Http.post(`${environment.base_url}/deletePostCommentAndReply`, Data);
  }

  getTopHashTagPost(Data) {
    return this.Http.post(`${environment.base_url}/getTopHashTagPost`, Data);
  }

  getSearchPostListByHashTag(Data) {
    return this.Http.post(`${environment.base_url}/getSearchPostListByHashTag`, Data);
  }

  currentGeoDetails() {
    return this.Http.get(`${environment.social_media_url}/currentGeoDetails`);
  }

  sharePostTwitter(Data) {
    return this.Http.post(`${environment.social_media_url}/sharePostTwitter`, Data);
  }

  getLoginUserSocialMediaDetails() {
    return this.Http.get(`${environment.base_url}/getLoginUserSocialMediaDetails`);
  }

  disconnectSocialMediaAccount(Data) {
    return this.Http.post(`${environment.base_url}/disconnectSocialMediaAccount`, Data);
  }

  urlmetadata(Data) {
    return this.Http.post(`${environment.base_url}/urlmetadata`, Data);
  }

  pendingRequest(Data) {
    return this.Http.post(`${environment.base_url}/pendingRequest`, Data);
  }

  getBlogsList(Data) {
    return this.Http.post(`${environment.base_url}/getBlogsList`, Data);
  }

  getBlogDetails(Data) {
    return this.Http.post(`${environment.base_url}/getBlogDetails`, Data);
  }

  createBlog(Data) {
    return this.Http.post(`${environment.base_url}/createBlog`, Data);
  }

  updateBlogStatus(Data) {
    return this.Http.post(`${environment.base_url}/updateBlogStatus`, Data);
  }

  getBlogsListAdmin(Data) {
    return this.Http.post(`${environment.base_url}/getBlogsListAdmin`, Data);
  }

  deleteBlog(Data) {
    return this.Http.post(`${environment.base_url}/deleteBlog`, Data);
  }

  editBlog(Data) {
    return this.Http.post(`${environment.base_url}/editBlog`, Data);
  }

  addBlogSiteMap(Data) {
    return this.Http.post(`${environment.base_url}/addBlogSiteMap`, Data);
  }


  postCommentLike(Data) {
    return this.Http.post(`${environment.base_url}/postCommentLike`, Data);
  }

  postCommenReplytLike(Data) {
    return this.Http.post(`${environment.base_url}/postCommenReplytLike`, Data);
  }

  editBlogSiteMap(Data) {
    return this.Http.post(`${environment.base_url}/editBlogSiteMap`, Data);
  }

  getBlogDetailsByTitle(Data) {
    return this.Http.post(`${environment.base_url}/getBlogDetailsByTitle`, Data);
  }

  mostRecentBlogs(Data) {
    return this.Http.post(`${environment.base_url}/mostRecentBlogs`, Data);
  }

  getAllOpenForumPostList(Data) {
    return this.Http.post(`${environment.base_url}/getAllOpenForumPostList`, Data);
  }

  getSideGalleryTypeWise(Data) {
    return this.Http.post(`${environment.base_url}/getSideGalleryTypeWise`, Data);
  }

  getGalleryMedia(Data) {
    return this.Http.post(`${environment.base_url}/getGalleryMedia`, Data);
  }

  getSideGalleryTypeWiseVisitProfile(Data) {
    return this.Http.post(`${environment.base_url}/getSideGalleryTypeWiseVisitProfile`, Data);
  }

  getGalleryMediaVisitProfile(Data) {
    return this.Http.post(`${environment.base_url}/getGalleryMediaVisitProfile`, Data);
  }

  postAsMessageSend(Data) {
    return this.Http.post(`${environment.base_url}/postAsMessageSend`, Data);
  }

  newsAsMessageSend(Data) {
    return this.Http.post(`${environment.base_url}/newsAsMessageSend`, Data);
  }

  createAlbum(Data) {
    return this.Http.post(`${environment.base_url}/createAlbum`, Data);
  }

  getAlbumList(Data) {
    return this.Http.post(`${environment.base_url}/getAlbumList`, Data);
  }

  getAlbumDetails(Data) {
    return this.Http.post(`${environment.base_url}/getAlbumDetails`, Data);
  }

  removeUploadedMedia(Data) {
    return this.Http.post(`${environment.base_url}/removeUploadedMedia`, Data);
  }

  removePostMedia(Data) {
    return this.Http.post(`${environment.base_url}/removePostMedia`, Data);
  }

  updateAlbum(Data) {
    return this.Http.post(`${environment.base_url}/updateAlbum`, Data);
  }

  getVisitAlbumList(Data) {
    return this.Http.post(`${environment.base_url}/getVisitAlbumList`, Data);
  }

  deleteAlbum(Data) {
    return this.Http.post(`${environment.base_url}/deleteAlbum`, Data);
  }

  postMediaLike(Data) {
    return this.Http.post(`${environment.base_url}/postMediaLike`, Data);
  }


  postMediaShare(Data) {
    return this.Http.post(`${environment.base_url}/postMediaShare`, Data);
  }


  setCoverPhoto(Data) {
    return this.Http.post(`${environment.base_url}/setCoverPhoto`, Data);
  }

  addPostMediaComment(Data) {
    return this.Http.post(`${environment.base_url}/addPostMediaComment`, Data);
  }

  getPostMediaComment(Data) {
    return this.Http.post(`${environment.base_url}/getPostMediaComment`, Data);
  }

  addPostMediaCommentReply(Data) {
    return this.Http.post(`${environment.base_url}/addPostMediaCommentReply`, Data);
  }

  postMediaCommentLike(Data) {
    return this.Http.post(`${environment.base_url}/postMediaCommentLike`, Data);
  }

  postMediaCommentReplyLike(Data) {
    return this.Http.post(`${environment.base_url}/postMediaCommentReplyLike`, Data);
  }

  deletePostMediaCommentAndReply(Data) {
    return this.Http.post(`${environment.base_url}/deletePostMediaCommentAndReply`, Data);
  }

  ipUpdateInUser(Data) {
    return this.Http.post(`${environment.base_url}/ipUpdateInUser`, Data);
  }

  addNewsSharePostWithFollowSports(Data) {
    return this.Http.post(`${environment.base_url}/addNewsSharePostWithFollowSports`, Data);
  }

  saveNewsNotification(Data) {
    return this.Http.post(`${environment.base_url}/saveNewsNotification`, Data);
  }

  getSportProfileDetails(Data) {
    return this.Http.post(`${environment.base_url}/getSportProfileDetails`, Data);
  }

  getMediaDetails(Data) {
    return this.Http.post(`${environment.base_url}/getMediaDetails`, Data);
  }

  getBirthdayPost(Data) {
    return this.Http.post(`${environment.base_url}/getBirthdayPost`, Data);
  }

  addCirclePostMember(Data) {
    return this.Http.post(`${environment.base_url}/addCirclePostMember`, Data);
  }

  deleteMember(Data) {
    return this.Http.post(`${environment.base_url}/deleteMember`, Data);
  }

  getCirclePostMember(Data) {
    return this.Http.post(`${environment.base_url}/getCirclePostMember`, Data);
  }

  updateCirclePostStatus(Data) {
    return this.Http.post(`${environment.base_url}/updateCirclePostStatus`, Data);
  }

  followTeamLiveMatch(Data) {
    return this.Http.post(`${environment.base_url}/followTeamLiveMatch`, Data);
  }

  unFollowTeamLiveMatch(Data) {
    return this.Http.post(`${environment.base_url}/unFollowTeamLiveMatch`, Data);
  }

  showAllMatches(Data) {
    return this.Http.post(`${environment.base_url}/showAllMatches`, Data);
  }

  getnewsitemap() {
    return this.Http.get(`${environment.base_url}/getnewslisturl`);
  }

  getLiveMatchInfo(Data) {
    return this.Http.post(`${environment.base_url}/getLiveMatchInfo`, Data);
  }

  guidTourUpdate(data) {
    return this.Http.post(`${environment.base_url}/guidTourUpdate`, data);
  }

  leaguesGifStickerImagesAdmin(Data) {
    return this.Http.post(`${environment.base_url}/leaguesGifStickerImagesAdmin`, Data);
  }

  updateLeagueImageStatus(Data) {
    return this.Http.post(`${environment.base_url}/updateLeagueImageStatus`, Data);
  }

  addLeagueChatImage(Data) {
    return this.Http.post(`${environment.base_url}/addLeagueChatImage`, Data);
  }

  getChatImageDetails(Data) {
    return this.Http.post(`${environment.base_url}/getChatImageDetails`, Data);
  }

  updateLeagueChatImage(Data) {
    return this.Http.post(`${environment.base_url}/updateLeagueChatImage`, Data);
  }

  deleteChatImageDetails(Data) {
    return this.Http.post(`${environment.base_url}/deleteChatImageDetails`, Data);
  }

  invitePlayerCoach(Data) {
    return this.Http.post(`${environment.base_url}/invitePlayerCoach`, Data);
  }

  getMatchById(data){
    return this.Http.post(`${environment.base_url}/getMatchById`, data);
  }
}
