<!-- Normal header-->
<header class="topbar" *ngIf="status == 1">
  <!-- desktop nav start -->
  <nav *ngIf="!isChatPage" class="navbar top-navbar navbar-expand-md navbar-light p-md-0  d-lg-flex d-none" aria-label="">
    <div class="container p-0 justify-content-between justify-content-md-around">
      <div class="row">

        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header col-4 col-xl-5 d-flex justify-content-start justify-content-lg-between pb-3 pb-md-0">
          <a class="navbar-brand pt-2" href="javascript:void(0);" (click)="SelectUserType('Main'); changeActiveTab('Pals')">
            <img alt="loading..."    src="{{ platform_logo }}" class="img-fluid" alt="..." width="130" />
          </a>
          <div class="search search_width shadow-sm d-block bg-white border overflow-hidden rounded ml-xl-5 mx-md-2 ml-1 my-auto col p-0 border-radius-10">
            <form class="" [formGroup]="usersForm">
              <div class="form-group">
                <mat-form-field class="example-full-width pb-0 w-100 form-control-design1" appearance="fill">
                  <input #inputField matInput [matAutocomplete]="auto" formControlName="userInput" class="text-limit  pos-search" maxlength="200" placeholder="Search friend, athlete, team, sports…" />
                </mat-form-field>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectSearchOption($event.option.value)">
                  <ng-container *ngIf="!isLoading">
                    <mat-option class="d-block text-truncate" *ngFor="let user of filteredUsers" [value]="user.name">
                      <div class="mw text-truncate name_display_limit_setting">
                        {{ user.name }}
                      </div>
                    </mat-option>
                  </ng-container>
                  <span><a href="javascript:void(0);" class="showMoreUser bg-dark-blue" (click)="showMoreUserClick()">Show
                      More</a></span>
                </mat-autocomplete>
              </div>
            </form>
            <span class="mdi mdi-magnify mdi-2x cursor-pointer " (click)="showMoreUserClick()"></span>
          </div>
          <div class="d-block d-md-none ml-1"><a href="javascript:void(0);" class="score-label" (click)="openScoreModal(scores)">Scores</a></div>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse col-8 col-xl-7">
          <!-- ============================================================== -->
          <!-- toggle and nav items -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- User profile and search -->
          <!-- ============================================================== -->

          <div class="ml-md-auto w-sm-100 d-flex">
            <!-- desktop view starts -->
            <ul class="navbar-nav my-lg-0 notification-icon d-lg-flex d-none skew-topbar px-1">
              <!-- ============================================================== -->
              <!-- Profile -->
              <!-- ============================================================== -->
              <li id="myProfileSection" class="nav-item" [ngClass]="{ active: activeTab == 'Me' }">
                <a class="nav-link waves-effect waves-dark notification-icon" href="javascript:void(0)" placement="bottom" (click)="changeActiveTab('Me');myprofileRedirectPage()" *ngIf="login_user_type == 1">
                  <img alt="loading..."    *ngIf="userNaviDetails.profile_pic != ''" src="{{ userNaviDetails.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" width="27" />
                  <img alt="loading..."    *ngIf="userNaviDetails.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" width="27" />
                  <small class="d-block navigation_name truncate-name profile-short" [ngClass]="{ 'font-weight-bold': activeTab == 'Me' }">
                    <!-- My Profile -->
                    {{user_details.name}}
                  </small>
                </a>
                <a class="nav-link waves-effect waves-dark notification-icon" href="javascript:void(0)" placement="bottom" (click)="changeActiveTab('Me');aliasprofileRedirectPage()" *ngIf="login_user_type == 2">
                  <img alt="loading..."    *ngIf="userNaviDetails.alias_profile_pic != ''" src="{{ userNaviDetails.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" width="27" />
                  <img alt="loading..."    *ngIf="userNaviDetails.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" width="27" />
                  <small class="d-block navigation_name truncate-name">
                    <span *ngIf="userNaviDetails.alias_name != ''">{{
                      userNaviDetails.alias_name
                      }}</span>
                    <span *ngIf="userNaviDetails.alias_name == ''">{{ userNaviDetails.name }}
                      ({{alias_user_name}})</span>
                  </small>
                </a>
              </li>
              <li class="nav-item" [ngClass]="{ active: activeTab == 'Pals' }">
                <a id="FansSection" class="nav-link waves-effect waves-dark notification-icon" placement="bottom" href="javascript:void(0)" (click)="SelectUserType('Main'); changeActiveTab('Pals')">
        
                  <img alt="loading..."    src="assets/images/icon/Fans-ICON.svg" alt="fans" width="22" class="rounded m-auto">
                  <small class="d-block mt-1" [ngClass]="{ 'font-weight-bold': activeTab == 'Pals' }">Fans</small>
                </a>
              </li>
              
              <li class="nav-item dropdown" ngbDropdown #myDrop="ngbDropdown" placement="bottom-right" [ngClass]="{ active: activeTab == 'Notification' }">
                <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark notification-icon" href="javascript:void(0)" (click)="
                  notificationIconClick(); changeActiveTab('Notification')
                " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" placement="bottom">
               
                  <img alt="loading..."    src="assets/images/svg_patriot/notification.svg" alt="notification" />
                  <span class="badge badge-danger badge-pill notify-Notification" *ngIf="notification_count > 0">
                    {{ notification_count }}
                  </span>
                  <small class="d-block mt-1" [ngClass]="{ 'font-weight-bold': activeTab == 'Notification' }">Notifications</small>
                </a>
                <div class="dropdown-menu dropdown-menu-left mailbox fadeIn" ngbDropdownMenu>
                  <ul>
                    <li>
                      <div class="drop-title color-gray bg-white">
                        Notifications
                        <a *ngIf="getUserNameArr.length > 0" href="javascript:void(0);" (click)="markAllAsReadNotification()" class="float-right" style="color: #fff !important;">Mark
                          all as read</a>
                      </div>
                    </li>
                    <li *ngIf="getUserNameArr.length > 0">
                      <div class="message-center pt-2" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false">
                        <div *ngFor="let user of getUserNameArr; let i = index" [ngClass]="{ 'bg-read': (user.read_status == '0' && user.type != 'circlepost') || (user.connection_status == '0' && user.type == 'circlepost')}">

                          <a *ngIf="user.type == 'connection'" href="javascript:void(0);" (click)="readNotification(i,user);searchUserNameClick(user)">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <div class="mail-contnet">
                              <h5 class="d-flex">
                                <span class="d-block text-truncate mw">{{ user.name }}
                                </span>
                                <span *ngIf="user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                              </h5>
                              <div class="d-flex justify-content-between">
                                <span class="mail-desc" style="white-space: normal !important;">
                                  You have
                                  <span *ngIf="user.connection_status == 1">a new</span>
                                  <span *ngIf="user.connection_status == 2">accepted</span>
                                  <span *ngIf="user.connection_status == 3">rejected</span> Connection Request.
                                  <span class="time">{{ user.created_at }}</span>
                                </span>
                                <span class="float-right mb-2" *ngIf="user.connection_status == 1 || user.connection_status == 3">
                                  <button class="btn btn-sm btn-info bg-dark-blue" (click)="acceptConnectRequestOpenModal(acceptConnectRequest,user)" placement="top" ngbTooltip="Accept">
                                    <span class="mdi mdi-check"></span>
                                  </button>
                                  <button (click)="rejectConnectRequestOpenModal(rejectConnectRequest,user)" class="btn btn-sm btn-light bg-white ml-2" placement="top" ngbTooltip="Reject">
                                    <span class="mdi mdi-close"></span>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </a>

                          <a *ngIf="user.type == 'connectionAccept'" href="javascript:void(0);" (click)="readNotification(i,user);searchUserNameClick(user)">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <div class="mail-contnet">
                              <h5 class="d-flex">
                                <span class="d-block text-truncate mw">{{ user.name }}
                                </span>
                                <span *ngIf="user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                              </h5>
                              <span class="mail-desc" style="white-space: normal !important;">
                                accepted your Connection Request.
                              </span>
                              <span class="time">{{ user.created_at }}</span>
                            </div>
                          </a>

                          <a *ngIf="user.type == 'follow'" href="javascript:void(0);" (click)="readNotification(i,user);searchUserNameClick(user)">
                            <span *ngIf="user.follower_type == 1">
                              <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <span *ngIf="user.follower_type == 2">
                              <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <div class="mail-contnet">
                              <span class="mail-desc" style="white-space: normal !important;">
                                <h5 class="d-flex" *ngIf="user.follower_type == 1 || user.alias_name == ''">
                                  <span class="d-block text-truncate mw">{{ user.name }}
                                  </span>
                                  <span *ngIf="user.follower_type==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                  <span *ngIf="user.follower_type == 2 && user.alias_name == ''">
                                    ({{alias_user_name}})
                                  </span>
                                </h5>
                                <h5 *ngIf="user.follower_type == '2'">
                                  <span class="d-block text-truncate mw">{{
                                    user.alias_name
                                    }}</span>
                                </h5>
                                <p class="text-muted mb-0">
                                  started following
                                  <span *ngIf="user.following_type == '1'" class="d-inline-flex">
                                    <span class="d-inline-block text-truncate mw">{{ userNaviDetails.name }} </span>
                                    &nbsp;- Your Profile
                                  </span>
                                  <span *ngIf="user.following_type == '2'" class="d-inline-flex">
                                    <span class="d-inline-block text-truncate mw">
                                      <span *ngIf="userNaviDetails.alias_name != ''">
                                        {{ userNaviDetails.alias_name }}
                                      </span>
                                      <span *ngIf="userNaviDetails.alias_name == ''">
                                        {{ userNaviDetails.name }}
                                      </span>
                                    </span>
                                    &nbsp;- Your {{alias_user_name}} Profile
                                  </span>
                                </p>
                              </span>
                              <div class="d-flex justify-content-between">
                                <span class="time">{{ user.created_at }}</span>
                                <span *ngIf="(user.following_type == '1' || user.following_type == '2') && !user.is_follow" class="float-right">
                                  <button href="javascript:void(0);" class="btn btn-sm btn-info bg-gray py-1" (click)="followUserOpenModal(follow,user)"><small>Follow Back</small></button>
                                </span>
                              </div>
                            </div>
                          </a>

                          <a *ngIf="user.type == 'postlikes'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                            <span *ngIf="user.user_to == 1">
                              <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <span *ngIf="user.user_to == 2">
                              <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <div class="mail-contnet">
                              <span class="mail-desc" style="white-space: normal !important;">
                                <h5 class="d-flex" *ngIf="user.user_to == 1 || user.alias_name == ''">
                                  <span class="d-block text-truncate mw">{{
                                    user.name
                                    }}</span>
                                  <span *ngIf="user.user_to==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                  <span *ngIf="
                                    user.user_to == 2 && user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                                </h5>
                                <h5 *ngIf="user.user_to == '2'">
                                  <span class="d-block text-truncate mw">
                                    {{ user.alias_name }}</span>
                                </h5>
                                <span class="d-inline text-muted">
                                  liked your
                                  <span *ngIf="user.login_user_type == 1">
                                    <span *ngIf="user.post_privacy == 2">
                                      primary open forum
                                    </span>
                                    <span *ngIf="user.post_privacy != 2">
                                      fans
                                    </span>
                                  </span>
                                  <span *ngIf="user.login_user_type == 2">
                                    {{alias_lower_case_user_name}} open forum
                                  </span> post
                                </span>
                                <span class="text-limit" *ngIf="
                                  (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                  <span>
                                    - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>

                                <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                  <span>
                                    - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>
                              </span>
                              <span class="time">{{ user.created_at }}</span>
                            </div>
                          </a>

                          <a *ngIf="user.type == 'postComment'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                            <span *ngIf="user.user_to == 1">
                              <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <span *ngIf="user.user_to == 2">
                              <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <div class="mail-contnet">
                              <span class="mail-desc" style="white-space: normal !important;">
                                <h5 class="d-flex" *ngIf="user.user_to == 1 || user.alias_name == ''">
                                  <span class="d-block text-truncate mw">{{
                                    user.name
                                    }}</span>
                                  <span *ngIf="user.user_to==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                  <span *ngIf="
                                    user.user_to == 2 && user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                                </h5>
                                <h5 *ngIf="user.user_to == '2'">
                                  <span class="d-block text-truncate mw">
                                    {{ user.alias_name }}</span>
                                </h5>
                                <span class="d-inline text-muted">
                                  commented on your
                                  <span *ngIf="user.login_user_type == 1">
                                    <span *ngIf="user.post_privacy == 2">
                                      primary open forum
                                    </span>
                                    <span *ngIf="user.post_privacy != 2">
                                      fans
                                    </span>
                                  </span>
                                  <span *ngIf="user.login_user_type == 2">
                                    {{alias_lower_case_user_name}} open forum
                                  </span> post
                                </span>
                                <span *ngIf="
                                (user.share_post_text == '' || user.share_post_text == null) &&
                                user.post_text && user.post_text != ''
                              " class="text-limit" [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                  <span>
                                    - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>

                                <span *ngIf="user.share_post_text && user.share_post_text != ''" class="text-limit" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                  <span>
                                    - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>
                              </span>
                              <span class="time">{{ user.created_at }}</span>
                            </div>
                          </a>

                          <a *ngIf="user.type == 'postCommentReply'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailCommReplyFun(user)">
                            <span *ngIf="user.user_from_type == 1">
                              <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <span *ngIf="user.user_from_type == 2">
                              <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <div class="mail-contnet">
                              <span class="mail-desc" style="white-space: normal !important;">
                                <h5 class="d-flex" *ngIf="user.user_from_type == 1 || user.alias_name == ''">
                                  <span class="d-block text-truncate mw">{{ user.name }}</span>
                                  <span *ngIf="user.user_from_type==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                  <span *ngIf="user.user_from_type == 2 && user.alias_name == ''">({{alias_user_name}})</span>
                                </h5>
                                <h5 *ngIf="user.user_from_type == '2'">
                                  <span class="d-block text-truncate mw">
                                    {{ user.alias_name }}
                                  </span>
                                </h5>
                                <span class="d-inline text-muted" *ngIf="user.reply_notification_type == 1">
                                  replied to
                                  <span *ngIf="user.comm_user_type == 1 || user.post_user_alias_name == ''" class="d-inline-flex">
                                    <span class="d-inline-block text-truncate mw">{{ user.post_user_name }}'s</span>
                                    <span *ngIf="user.comm_user_type==1 && user.post_user_verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                    <span *ngIf="user.comm_user_type == 2 && user.post_user_alias_name == ''">({{alias_user_name}})'s</span>
                                  </span>
                                  <span *ngIf="user.comm_user_type == '2'" class="d-inline-flex">
                                    <span class="d-inline-block text-truncate mw">
                                      {{ user.post_user_alias_name }}
                                    </span>
                                  </span> comment on your
                                  <span *ngIf="user.login_user_type == 1">
                                    <span *ngIf="user.post_privacy == 2">
                                      primary open forum
                                    </span>
                                    <span *ngIf="user.post_privacy != 2">
                                      fans
                                    </span>
                                  </span>
                                  <span *ngIf="user.login_user_type == 2">
                                    {{alias_lower_case_user_name}} open forum
                                  </span> post
                                </span>
                                <span class="d-inline text-muted" *ngIf="user.reply_notification_type == 2">
                                  replied to your comment on
                                  <span *ngIf="user.login_user_type == 1 || user.post_user_alias_name == ''" class="d-inline-flex">
                                    <span class="d-inline-block text-truncate mw">{{ user.post_user_name }}'s</span>
                                    <span *ngIf="user.login_user_type==1 && user.post_user_verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                    <span *ngIf="user.login_user_type == 2 && user.post_user_alias_name == ''">({{alias_user_name}})'s</span>
                                  </span>
                                  <span *ngIf="user.login_user_type == '2'" class="d-inline-flex">
                                    <span class="d-inline-block text-truncate mw">
                                      {{ user.post_user_alias_name }}
                                    </span>
                                  </span> post
                                </span>
                                <span class="text-limit" *ngIf="(user.share_post_text == '' || user.share_post_text == null) && user.post_text && user.post_text != ''" [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                  <span>
                                    - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>

                                <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                  <span>
                                    - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>
                              </span>
                              <span class="time">{{ user.created_at }}</span>
                            </div>
                          </a>

                          <a *ngIf="user.type == 'tagTeamGroup'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFunGroup(user)">
                            <span *ngIf="user.user_from_type == 1">
                              <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <span *ngIf="user.user_from_type == 2">
                              <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <div class="mail-contnet">
                              <span class="mail-desc" style="white-space: normal !important;">
                                <h5 class="d-flex" *ngIf="user.user_from_type == 1 || user.alias_name == ''">
                                  <span class="d-block text-truncate mw">
                                    {{ user.name }}
                                  </span>
                                  <span *ngIf="user.user_from_type==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                  <span *ngIf="user.user_from_type == 2 && user.alias_name == ''">({{alias_user_name}})</span>
                                </h5>
                                <h5 class="d-block text-truncate mw" *ngIf="user.user_from_type == '2'">
                                  {{ user.alias_name }}
                                </h5>
                                Invited
                                <span *ngIf="user.user_to_type == '1'" class="d-inline-flex">
                                  <span class="d-inline-block text-truncate mw">{{ userNaviDetails.name }} </span>
                                  &nbsp;-
                                  Your Profile
                                </span>
                                <span *ngIf="user.user_to_type == '2'" class="d-inline-flex">
                                  <span class="d-inline-block text-truncate mw">
                                    <span *ngIf="userNaviDetails.alias_name != ''">
                                      {{ userNaviDetails.alias_name }}
                                    </span>
                                    <span *ngIf="userNaviDetails.alias_name == ''">
                                      {{ userNaviDetails.name }}
                                    </span>
                                  </span>
                                  &nbsp;- Your {{alias_user_name}} Profile
                                </span> to discuss on this Post.
                              </span>
                              <span class="time">{{ user.created_at }}</span>
                            </div>
                          </a>

                          <a *ngIf="user.type == 'postShareByFriend'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFunGroup(user)">
                            <span *ngIf="user.user_from_type == 1">
                              <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <span *ngIf="user.user_from_type == 2">
                              <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <div class="mail-contnet">
                              <span class="mail-desc" style="white-space: normal !important;">
                                <h5 class="d-flex" *ngIf="
                                  user.user_from_type == 1 ||
                                  user.alias_name == ''
                                ">
                                  <span class="d-block text-truncate mw">
                                    {{ user.name }}
                                  </span>
                                  <span *ngIf="user.user_from_type==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                  <span *ngIf="
                                    user.user_from_type == 2 &&
                                    user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                                </h5>
                                <h5 class="d-block text-truncate mw" *ngIf="user.user_from_type == '2'">
                                  {{ user.alias_name }}
                                </h5>
                                Posted on
                                <span *ngIf="user.post_privacy == 2">
                                  <span *ngIf="user.user_to_type == '1'" class="d-inline-flex">
                                    your Open Forum primary
                                  </span>
                                  <span *ngIf="user.user_to_type == '2'" class="d-inline-flex">
                                    your Open Forum {{alias_lower_case_user_name}}
                                  </span>
                                </span>
                                <span *ngIf="user.post_privacy != 2">
                                  your Fans
                                </span> timeline.
                              </span>
                              <div class="d-flex justify-content-between">
                                <span class="time">{{ user.created_at }}</span>

                              </div>
                            </div>
                          </a>

                          <a *ngIf="user.type == 'postmedialikes'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                            <span *ngIf="user.user_to == 1">
                              <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <span *ngIf="user.user_to == 2">
                              <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <div class="mail-contnet">
                              <span class="mail-desc" style="white-space: normal !important;">
                                <h5 class="d-flex" *ngIf="
                                  user.user_to == 1 || user.alias_name == ''
                                ">
                                  <span class="d-block text-truncate mw">{{
                                    user.name
                                    }}</span>
                                  <span *ngIf="user.user_to==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                  <span *ngIf="
                                    user.user_to == 2 && user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                                </h5>
                                <h5 *ngIf="user.user_to == '2'">
                                  <span class="d-block text-truncate mw">
                                    {{ user.alias_name }}</span>
                                </h5>
                                <span class="d-inline text-muted">
                                  liked a media file in your
                                  <span *ngIf="user.login_user_type == 1">
                                    <span *ngIf="user.post_privacy == 2">
                                      primary open forum
                                    </span>
                                    <span *ngIf="user.post_privacy != 2">
                                      fans
                                    </span>
                                  </span>
                                  <span *ngIf="user.login_user_type == 2">
                                    {{alias_lower_case_user_name}} open forum
                                  </span> post
                                </span>
                                <span class="text-limit" *ngIf="
                                  (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                  <span>
                                    - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>

                                <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                  <span>
                                    - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>
                              </span>
                              <span class="time">{{ user.created_at }}</span>
                            </div>
                          </a>

                          <a *ngIf="user.type == 'postMediaComment'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                            <span *ngIf="user.user_to == 1">
                              <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <span *ngIf="user.user_to == 2">
                              <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <div class="mail-contnet">
                              <span class="mail-desc" style="white-space: normal !important;">
                                <h5 class="d-flex" *ngIf="
                                  user.user_to == 1 || user.alias_name == ''
                                ">
                                  <span class="d-block text-truncate mw">{{
                                    user.name
                                    }}</span>
                                  <span *ngIf="user.user_to==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                  <span *ngIf="
                                    user.user_to == 2 && user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                                </h5>
                                <h5 *ngIf="user.user_to == '2'">
                                  <span class="d-block text-truncate mw">
                                    {{ user.alias_name }}</span>
                                </h5>
                                <span class="d-inline text-muted">
                                  commented on a media file of your
                                  <span *ngIf="user.login_user_type == 1">
                                    <span *ngIf="user.post_privacy == 2">
                                      primary open forum
                                    </span>
                                    <span *ngIf="user.post_privacy != 2">
                                      fans
                                    </span>
                                  </span>
                                  <span *ngIf="user.login_user_type == 2">
                                    {{alias_lower_case_user_name}} open forum
                                  </span> post
                                </span>
                                <span class="text-limit" *ngIf="
                                    (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                  <span>
                                    - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>

                                <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                  <span>
                                    - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>
                              </span>
                              <span class="time">{{ user.created_at }}</span>
                            </div>
                          </a>

                          <a *ngIf="user.type == 'postMediaCommentReply'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailCommReplyFun(user)">
                            <span *ngIf="user.user_from_type == 1">
                              <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <span *ngIf="user.user_from_type == 2">
                              <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <div class="mail-contnet">
                              <span class="mail-desc" style="white-space: normal !important;">
                                <h5 class="d-flex" *ngIf="user.user_from_type == 1 || user.alias_name == ''">
                                  <span class="d-block text-truncate mw">{{ user.name }}</span>
                                  <span *ngIf="user.user_from_type==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                  <span *ngIf="user.user_from_type == 2 && user.alias_name == ''">({{alias_user_name}})</span>
                                </h5>
                                <h5 *ngIf="user.user_from_type == '2'">
                                  <span class="d-block text-truncate mw">
                                    {{ user.alias_name }}
                                  </span>
                                </h5>
                                <span class="d-inline text-muted" *ngIf="user.reply_notification_type == 1">
                                  replied to
                                  <span *ngIf="user.comm_user_type == 1 || user.post_user_alias_name == ''" class="d-inline-flex">
                                    <span class="d-inline-block text-truncate mw">{{ user.post_user_name }}'s</span>
                                    <span *ngIf="user.comm_user_type==1 && user.post_user_verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                    <span *ngIf="user.comm_user_type == 2 && user.post_user_alias_name == ''">({{alias_user_name}})</span>
                                  </span>
                                  <span *ngIf="user.comm_user_type == '2'" class="d-inline-flex">
                                    <span class="d-inline-block text-truncate mw">
                                      {{ user.post_user_alias_name }}
                                    </span>
                                  </span> comment on a media file of your
                                  <span *ngIf="user.login_user_type == 1">
                                    <span *ngIf="user.post_privacy == 2">
                                      primary open forum
                                    </span>
                                    <span *ngIf="user.post_privacy != 2">
                                      fans
                                    </span>
                                  </span>
                                  <span *ngIf="user.login_user_type == 2">
                                    {{alias_lower_case_user_name}} open forum
                                  </span> post
                                </span>
                                <span class="d-inline text-muted" *ngIf="user.reply_notification_type == 2">
                                  replied to your comment on a media file of
                                  <span *ngIf="user.login_user_type == 1 || user.post_user_alias_name == ''" class="d-inline-flex">
                                    <span class="d-inline-block text-truncate mw">{{ user.post_user_name }}'s</span>
                                    <span *ngIf="user.login_user_type==1 && user.post_user_verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                    <span *ngIf="user.login_user_type == 2 && user.post_user_alias_name == ''">({{alias_user_name}})</span>
                                  </span>
                                  <span *ngIf="user.login_user_type == '2'" class="d-inline-flex">
                                    <span class="d-inline-block text-truncate mw">
                                      {{ user.post_user_alias_name }}
                                    </span>
                                  </span> post
                                </span>
                                <span class="text-limit" *ngIf="(user.share_post_text == '' || user.share_post_text == null) && user.post_text && user.post_text != ''" [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                  <span>
                                    - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>

                                <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                  <span>
                                    - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>
                              </span>
                              <span class="time">{{ user.created_at }}</span>
                            </div>
                          </a>

                          <a *ngIf="user.type == 'talkingPoints'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <div class="mail-contnet">
                              <span class="mail-desc" style="white-space: normal !important;">
                                <h5 class="d-flex">
                                  <span class="d-block text-truncate mw">
                                    {{user.name}}
                                  </span>
                                  <span *ngIf="user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                </h5>
                                <span class="d-inline text-muted">
                                  Announcement from Admin
                                </span>
                                <span class="text-limit" *ngIf="
                                  (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                  <span>
                                    - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>

                                <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                  <span>
                                    - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>
                              </span>
                              <span class="time">{{ user.created_at }}</span>
                            </div>
                          </a>


                          <a *ngIf="user.type == 'postCommentlikes'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                            <span *ngIf="user.user_to == 1">
                              <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <span *ngIf="user.user_to == 2">
                              <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <div class="mail-contnet">
                              <span class="mail-desc" style="white-space: normal !important;">
                                <h5 class="d-flex" *ngIf="
                                  user.user_to == 1 || user.alias_name == ''
                                ">
                                  <span class="d-block text-truncate mw">{{
                                    user.name
                                    }}</span>
                                  <span *ngIf="user.user_to==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                  <span *ngIf="
                                    user.user_to == 2 && user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                                </h5>
                                <h5 *ngIf="user.user_to == '2'">
                                  <span class="d-block text-truncate mw">
                                    {{ user.alias_name }}</span>
                                </h5>
                                <span class="d-inline text-muted">
                                  liked your comment on a
                                  <span *ngIf="user.login_user_type == 1">
                                    <span *ngIf="user.post_privacy == 2">
                                      primary open forum
                                    </span>
                                    <span *ngIf="user.post_privacy != 2">
                                      fans
                                    </span>
                                  </span>
                                  <span *ngIf="user.login_user_type == 2">
                                    {{alias_lower_case_user_name}} open forum
                                  </span> post
                                </span>
                                <span class="text-limit" *ngIf="
                                  (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                  <span>
                                    - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>

                                <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                  <span>
                                    - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>
                              </span>
                              <span class="time">{{ user.created_at }}</span>
                            </div>
                          </a>

                          <a *ngIf="user.type == 'postCommentReplylikes'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                            <span *ngIf="user.user_to == 1">
                              <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <span *ngIf="user.user_to == 2">
                              <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <div class="mail-contnet">
                              <span class="mail-desc" style="white-space: normal !important;">
                                <h5 class="d-flex" *ngIf="
                                  user.user_to == 1 || user.alias_name == ''
                                ">
                                  <span class="d-block text-truncate mw">{{
                                    user.name
                                    }}</span>
                                  <span *ngIf="user.user_to==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                  <span *ngIf="
                                    user.user_to == 2 && user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                                </h5>
                                <h5 *ngIf="user.user_to == '2'">
                                  <span class="d-block text-truncate mw">
                                    {{ user.alias_name }}</span>
                                </h5>
                                <span class="d-inline text-muted">
                                  liked your comment reply on a
                                  <span *ngIf="user.login_user_type == 1">
                                    <span *ngIf="user.post_privacy == 2">
                                      primary open forum
                                    </span>
                                    <span *ngIf="user.post_privacy != 2">
                                      fans
                                    </span>
                                  </span>
                                  <span *ngIf="user.login_user_type == 2">
                                    {{alias_lower_case_user_name}} open forum
                                  </span> post
                                </span>
                                <span class="text-limit" *ngIf="
                                  (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                  <span>
                                    - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>

                                <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                  <span>
                                    - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>
                              </span>
                              <span class="time">{{ user.created_at }}</span>
                            </div>
                          </a>

                          <a *ngIf="user.type == 'postMediaCommentlikes'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                            <span *ngIf="user.user_to == 1">
                              <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <span *ngIf="user.user_to == 2">
                              <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <div class="mail-contnet">
                              <span class="mail-desc" style="white-space: normal !important;">
                                <h5 class="d-flex" *ngIf="
                                  user.user_to == 1 || user.alias_name == ''
                                ">
                                  <span class="d-block text-truncate mw">{{
                                    user.name
                                    }}</span>
                                  <span *ngIf="user.user_to==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                  <span *ngIf="
                                    user.user_to == 2 && user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                                </h5>
                                <h5 *ngIf="user.user_to == '2'">
                                  <span class="d-block text-truncate mw">
                                    {{ user.alias_name }}</span>
                                </h5>
                                <span class="d-inline text-muted">
                                  liked your comment on a media file in
                                  <span *ngIf="user.login_user_type == 1">
                                    <span *ngIf="user.post_privacy == 2">
                                      primary open forum
                                    </span>
                                    <span *ngIf="user.post_privacy != 2">
                                      fans
                                    </span>
                                  </span>
                                  <span *ngIf="user.login_user_type == 2">
                                    {{alias_lower_case_user_name}} open forum
                                  </span> post
                                </span>
                                <span class="text-limit" *ngIf="
                                  (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                  <span>
                                    - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>

                                <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                  <span>
                                    - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>
                              </span>
                              <span class="time">{{ user.created_at }}</span>
                            </div>
                          </a>


                          <a *ngIf="user.type == 'postMediaCommentReplylikes'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                            <span *ngIf="user.user_to == 1">
                              <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <span *ngIf="user.user_to == 2">
                              <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>
                            <div class="mail-contnet">
                              <span class="mail-desc" style="white-space: normal !important;">
                                <h5 class="d-flex" *ngIf="
                                  user.user_to == 1 || user.alias_name == ''
                                ">
                                  <span class="d-block text-truncate mw">{{
                                    user.name
                                    }}</span>
                                  <span *ngIf="user.user_to==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                  <span *ngIf="
                                    user.user_to == 2 && user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                                </h5>
                                <h5 *ngIf="user.user_to == '2'">
                                  <span class="d-block text-truncate mw">
                                    {{ user.alias_name }}</span>
                                </h5>
                                <span class="d-inline text-muted">
                                  liked your comment reply on a media file in
                                  <span *ngIf="user.login_user_type == 1">
                                    <span *ngIf="user.post_privacy == 2">
                                      primary open forum
                                    </span>
                                    <span *ngIf="user.post_privacy != 2">
                                      fans
                                    </span>
                                  </span>
                                  <span *ngIf="user.login_user_type == 2">
                                    {{alias_lower_case_user_name}} open forum
                                  </span> post
                                </span>
                                <span class="text-limit">
                                  <span *ngIf="
                                  (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                    - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>

                                <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                  <span>
                                    - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>
                              </span>
                              <span class="time">{{ user.created_at }}</span>
                            </div>
                          </a>

                          <!-- ///////////////////////05/06/2021(s)///////////////////// -->
                          <a *ngIf="user.type == 'breakingNews'" href="javascript:void(0);" (click)="readNotification(i,user);sendOnBreakingNews(user.login_user_id)">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <div class="mail-contnet">
                              <!-- <span class="mail-desc" style="white-space: normal !important;"> -->
                              <span class="mail-desc">
                                <h5 class="d-flex">
                                  <span class="d-block text-truncate mw">
                                    Breaking News
                                  </span>
                                  <span *ngIf="user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                </h5>
                                <!-- <small>
                                  <span class="label" [ngClass]="user.league_color ? user.league_color : 'default-badge'">{{user.league_name}}</span>
                                </small> -->

                                <small>
                                  <span *ngIf="user.league_name == 'IPL'">
                                    <span class="label"
                                      [ngClass]="user.league_color ? user.league_color : 'default-badge'">Cricket
                                    </span>
                                  </span>
                              
                                  <span *ngIf="user.league_name != 'IPL'">
                                    <span class="label"
                                      [ngClass]="user.league_color ? user.league_color : 'default-badge'">{{user.league_name}}
                                    </span>
                                  </span>
                                </small>

                                &nbsp;
                                <span class="d-inline text-muted">
                                  {{user.post_text}}
                                </span>
                                <span class="text-limit" *ngIf="
                                  (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                  <span>
                                    - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>

                                <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                  <span>
                                    - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>
                              </span>
                              <span class="time">{{ user.created_at }}</span>
                            </div>
                          </a>
                          <!-- ///////////////////////05/06/2021(s)///////////////////// -->

                          <!-- birthday -->
                          <a *ngIf="user.type == 'dob' && user.birth_msg!= ''" href="javascript:void(0);" (click)="readNotification(i,user);sendOnBirthday(user)">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <div class="mail-contnet">
                              <span class="mail-desc" style="white-space: normal !important;">
                                <h5 class="d-flex">
                                  <span class="d-block text-truncate mw">
                                    Birthday
                                  </span>
                                </h5>
                                <span class="d-inline text-muted" *ngIf="(user.birth_msg == 'today' || user.birth_msg == 'yesterday') && user.name != ''">
                                  It's {{user.name}} birthday {{user.birth_msg}}.</span>

                                <span class="d-inline text-muted" *ngIf="(user.birth_msg != 'today' || user.birth_msg != 'yesterday') && user.name != ''">
                                  It's {{user.name}} birthday on {{user.birth_msg}}.</span>
                                <span class="text-limit" *ngIf="user.post_text && user.post_text != '' && user.post_text != null" [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                  <span>
                                    - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                  </span>
                                </span>
                              </span>
                              <span class="time">{{ user.created_at }}</span>
                            </div>
                          </a>
                          <!-- birthday -->

                          <!-- circle post -->
                          <a *ngIf="user.type == 'circlepost'" href="javascript:void(0);" (click)="readCirlePostNotification(i,user);">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <div class="mail-contnet">
                              <span class="mail-desc" style="white-space: normal !important;">

                                <span class="d-inline text-muted" *ngIf="user.name != ''">
                                  {{user.name}} has added you in his post.
                                </span>

                                <span class="text-limit" *ngIf="user.post_text && user.post_text != '' && user.post_text != null" [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                  <span>
                                    <span *ngIf="user.read_status == 0" [innerHTML]="user.post_text | safeHtmlPipe"></span>

                                    <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);" (click)="postViewDetailFunCircle(user)" *ngIf="user.read_status == 1">
                                      <span [innerHTML]="user.post_text | safeHtmlPipe"></span>
                                    </a>
                                  </span>
                                </span>
                              </span>
                              <span class="float-right mb-2" *ngIf="user.read_status == 0">
                                <button class="btn btn-sm btn-info bg-dark-blue" (click)="acceptPostRequestOpenModal(acceptPostRequest,user,i)" placement="top" ngbTooltip="Post Accept">
                                  <span class="mdi mdi-check"></span>
                                </button>
                                <button (click)="rejectPostRequestOpenModal(rejectPostRequest,user)" class="btn btn-sm btn-light bg-white ml-2" placement="top" ngbTooltip="Post Reject">
                                  <span class="mdi mdi-close"></span>
                                </button>
                              </span>
                              <span class="time">{{ user.created_at }}</span>
                            </div>
                          </a>
                          <!-- circle post-->

                          <!-- Live Match -->
                          <a *ngIf="user.type == 'liveMatch'" href="javascript:void(0);" (click)="readNotification(i,user);sendOnLiveMatch()">
                            <span>
                              <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            </span>

                            <div class="mail-contnet">
                              <span class="mail-desc" style="white-space: normal !important;">
                                <h5 class="d-block text-truncate mw" (click)="searchUserNameClick(user)">{{user.name}}
                                  <span *ngIf="user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                </h5>
                                Please click here to see today's live event for your followed teams.
                              </span>
                              <span class="time">{{ user.created_at }}</span>
                            </div>

                          </a>
                          <!-- Live Match -->
                        </div>
                        <div class="text-center" *ngIf="primaryLoader">
                          <div *ngFor="let e of [].constructor(1)" class="d-flex align-items-center px-2 mt-2">
                            <div>
                              <h5 class="mt-0 d-flex align-items-start">
                                <span class="shared-dom">
                                  <div class="sub-rect-connection pure-background"></div>
                                </span>
                              </h5>
                            </div>

                            <div class="w-75 ml-2">
                              <h5 class="mt-0 d-flex align-items-start w-75">
                                <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                  <div class="background-masker btn-divide-left"></div>
                                </span>
                              </h5>
                              <h5 class="mt-0 d-flex align-items-start w-50">
                                <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                  <div class="background-masker btn-divide-left"></div>
                                </span>
                              </h5>
                              <h5 class="mt-0 d-flex align-items-start w-25">
                                <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                  <div class="background-masker btn-divide-left"></div>
                                </span>
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div ngbDropdownItem class="text-center position-relative" *ngIf="showMoreUserList">
                          <a href="javascript:void(0);" (click)="
                            $event.stopPropagation();
                            myDrop.open();
                            showMoreUserListByName(nextPage)
                          " class="read-more" placement="top">
                            <span class="mdi mdi-chevron-double-down">Show More</span>
                          </a>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="getUserNameArr.length == 0" class="text-center">
                      <div class="text-center" *ngIf="notDataAvailableLoader">
                        <div *ngFor="let e of [].constructor(3)" class="d-flex align-items-center px-2 mt-2">
                          <div>
                            <h5 class="mt-0 d-flex align-items-start">
                              <span class="shared-dom">
                                <div class="sub-rect-connection pure-background"></div>
                              </span>
                            </h5>
                          </div>

                          <div class="w-75 ml-2">
                            <h5 class="mt-0 d-flex align-items-start w-75">
                              <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                <div class="background-masker btn-divide-left"></div>
                              </span>
                            </h5>
                            <h5 class="mt-0 d-flex align-items-start w-50">
                              <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                <div class="background-masker btn-divide-left"></div>
                              </span>
                            </h5>
                            <h5 class="mt-0 d-flex align-items-start w-25">
                              <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                <div class="background-masker btn-divide-left"></div>
                              </span>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="!notDataAvailableLoader">
                        <h5 class="mt-2">
                          <strong>No Notifications available!</strong>
                        </h5>
                        <p>You will get notified here if any activity happens</p>
                      </div>
                    </li>
                    <li *ngIf="getUserNameArr.length > 0">
                      <a class="text-center d-block bg-gray p-2" href="javascript:void(0);" (click)="notificationDetialsPage()"><strong>Check
                          all Notifications</strong></a>
                    </li>
                  </ul>
                </div>
              </li>
              <li id="scoresSection" class="nav-item dropdown" ngbDropdown placement="bottom-right" [ngClass]="{ active: activeTab == 'Scores' }">
                <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark notification-icon" href="javascript:void(0)" (click)="changeActiveTab('Scores'); showLeagues();" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" placement="bottom">
                  <img alt="loading..."    src="assets/images/svg_patriot/score_v7.svg" width="30px;" alt="score" />
                  <small class="d-block mt-1" [ngClass]="{ 'font-weight-bold': activeTab == 'Scores' }">Scores</small>
                </a>
                <div class="dropdown-menu dropdown-menu-left scorebox mailbox fadeIn" ngbDropdownMenu>
                  <ul>
                    <li>
                      <div class="drop-title color-gray bg-white">
                        Scores
                    
                      </div>
                    </li>
                    <li *ngIf="leaguesList.length > 0">
                      <div class="message-center" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false">
                        <ul *ngIf="leaguesList.length > 0" class="list-unstyled notMes">

                          <div>
                            <div style="cursor: pointer;">
                              <li class="p-2" href="javascript:void(0)" (click)="searchTeamLeagueNameClick('all')">
                                <img alt="loading..."    src="assets/images/icon/My-Leagues-Icon.svg" class="img-fluid rounded-circle img-mh leauge-icon" alt="..." width="20" /><span class="ml-2">All leagues</span>

                                <span *ngIf="totalMatchCount && totalMatchCount != 0" class="pull-right ml-1 mt-2 label nfl-badge">{{totalMatchCount}}</span>

                              </li>
                            </div>
                          </div>

                          <h4 class="bg-dark-blue fs-14 text-center py-1 px-3">Followed Leagues</h4>
                          <div *ngIf="followedLeagues.length > 0">
                            <div style="cursor: pointer;" *ngFor="let league of followedLeagues; let i = index;">
                              <li class="p-2" href="javascript:void(0)" (click)="searchTeamLeagueNameClick(league)">
                                <img alt="loading..."    *ngIf="league.profile_pic != ''" src="{{ league.profile_pic }}" class="img-fluid img-new rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="25" /><span class="mw-name"> {{league.name}}</span>

                                <span *ngIf="league.score_count && league.score_count != null" class="pull-right ml-1 label nfl-badge">{{league.score_count}}</span>

                                <span *ngIf="league.live_score && league.live_score > 0" class="pull-right label nfl-badge">Live</span>


                              </li>
                            </div>
                          </div>
                          <li class="mx-2" *ngIf="followedLeagues.length == 0"> You Haven't Followed Any League </li>

                          <h4 *ngIf="unfollowedLeagues.length > 0" class="bg-dark-blue fs-14 text-center  sticky-top py-1 px-3">Other Leagues</h4>
                          <div *ngIf="unfollowedLeagues.length > 0">
                            <div style="cursor: pointer;" *ngFor="let league of unfollowedLeagues; let i = index;">
                              <li class="p-2" href="javascript:void(0)" (click)="searchTeamLeagueNameClick(league)">
                                <img alt="loading..."    *ngIf="league.profile_pic != ''" src="{{ league.profile_pic }}" class="img-fluid img-new rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="25" />
                                <span class="mw-name"> {{league.name}}</span>

                                <span *ngIf="league.score_count && league.score_count != null" class="pull-right ml-1 label nfl-badge">{{league.score_count}}</span>

                                <span *ngIf="league.live_score && league.live_score > 0" class="pull-right label nfl-badge">Live</span>


                              </li>
                            </div>
                          </div>
                        </ul>
                      </div>
                    </li>
                    <li *ngIf="leaguesList.length == 0" class="text-center">
                      <div class="p-2" *ngFor="let e of [].constructor(3);">
                        <h5 class="mt-0 d-flex align-items-start mx-2">
                          <span class="shared-dom">
                            <div class="sub-rect-connection pure-background"></div>
                          </span>
                          <span class="flex-fill animated-background-name first-loader-name mt-2">
                            <div class="background-masker btn-divide-left"></div>
                          </span>
                        </h5>
                      </div>

                      
                    </li>
                    
                  </ul>
                </div>
              </li>

              <li class="nav-item" (click)="changeActiveTab('igtai');igtAiFuc();" placement="bottom" [ngClass]="{ active: activeTab == 'igtai' }">
                <a class="nav-link dropdown-toggle waves-effect waves-dark " href="javascript:void(0)">
                  <img alt="loading..."    src="assets/icons/sports-icon/IGT-AI-Icon-Blue.svg" width="30" alt="score" />
                  <small class="d-block mt-1" [ngClass]="{ 'font-weight-bold': activeTab == 'igtai' }">IGT AI</small>
                </a>
              </li>




             
              <li id="allSportsTab" class="nav-item" [ngClass]="{ active: activeTab == 'allSports' }">
                <a class="nav-link waves-effect waves-dark notification-icon" href="javascript:void(0)" placement="bottom" (click)="GoToAllSports('Main'); changeActiveTab('allSports')">
              
                  <img alt="loading..."    src="assets/icons/sports-icon/All-Sports-Icon-Blue.svg" alt="Sport" width="24" />
                  <small class="d-block mt-1 all-sport-short" [ngClass]="{ 'font-weight-bold': activeTab == 'allSports' }">All&nbsp;Sports</small>
                </a>
              </li>



            </ul>
            <ul class="navbar-nav my-lg-0 notification-icon-2 d-lg-flex d-none skew-topbar invert px-1">


              <li (click)="messageIconClick();changeActiveTab('Message');autoClose()" class="nav-item dropdown" ngbDropdown #myDrop1="ngbDropdown" placement="bottom-right" [ngClass]="{ active: activeTab == 'Message' }">

                <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark notification-icon" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" placement="bottom">
                 
                  <img alt="loading..."    src="assets/icons/sports-icon/Messages-Icon-White.svg" width="25" alt="message" />
                  <small class="d-block mt-1 text-white" [ngClass]="{ 'font-weight-bold': activeTab == 'Message' }">Messages</small>
                  <span class="badge badge-danger badge-pill notify-count" *ngIf="message_notification_count > 0">
                    {{ message_notification_count }}
                  </span>
                </a>
                <div class="dropdown-menu dropdown-menu-left mailbox fadeIn dropdown_custom" ngbDropdownMenu>
                  <ul>
                    <li>
                      <div class="drop-title">
                        Messages
                        <a href="javascript:void(0);" (click)="newChatMessageRedirect()" class="float-right ml-2 text-white" placement="top" ngbTooltip="New message">
                          <i class="ti-pencil-alt"></i></a>
                        <a *ngIf="getChatMessageArr.length > 0" href="javascript:void(0);" (click)="markAllAsReadMessage()" class="float-right text-white">
                          Mark all as read
                        </a>
                      </div>
                    </li>

                    <!-- Message -->
                    <li *ngIf="getChatMessageArr.length > 0">
                      <div class="message-center" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false">
                        <div *ngFor="let chatMessage of getChatMessageArr" [ngClass]="{ 'bg-read': chatMessage.status == '0' }">
                          <a (click)="clickUserMessageFun(chatMessage);autoClose()" href="javascript:void(0);">
                            <span class="user-img">
                              <img alt="loading..."    *ngIf="chatMessage.profile_pic != ''" src="{{ chatMessage.profile_pic }}" class="img-circle" alt="..." width="40" />
                              <img alt="loading..."    *ngIf="chatMessage.profile_pic == ''" src="{{ user_profile_img }}" class="img-circle" alt="..." width="40" />
                            </span>
                            <div class="mail-contnet">
                              <h5 class="d-flex name_display_limit mw">
                                {{ chatMessage.name }}
                                <span *ngIf="chatMessage.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                <b *ngIf="chatMessage.notification_count > 0 && chatMessage.notification_count <= 100" class="text-info">&nbsp;({{chatMessage.notification_count}})</b>
                                <b *ngIf="chatMessage.notification_count > 100" class="text-info">&nbsp;({{chatMessage.notification_count}}+)</b>
                              </h5>
                              <span *ngIf="chatMessage.post_id == '0'">
                                <span class="d-block text-muted text-limit not-active" *ngIf="chatMessage.type == 0">
                                  -"<span [innerHTML]="chatMessage.message | safeHtmlPipe"></span>"</span>
                                <span class="d-block text-muted text-limit" *ngIf="chatMessage.type == 1">
                                  <span class="mdi mdi-attachment mdi-rotate-315 d-inline-block"></span>
                                  <span *ngIf="chatMessage.mime_type.includes('image/')">Media</span>
                                  <span *ngIf="chatMessage.mime_type.includes('video/')">Media</span>
                                  <span *ngIf="chatMessage.mime_type.includes('audio/')">Audio</span>
                                  <span *ngIf="
                                    chatMessage.mime_type.includes('application/')
                                  ">File</span>
                                </span>
                              </span>
                              <span class="d-block text-muted text-limit" *ngIf="chatMessage.post_id != '0' && chatMessage.type =='2'">
                                Shared a post with you.
                              </span>
                              <span class="d-block text-muted text-limit" *ngIf="chatMessage.post_id != '0' && chatMessage.type =='3'">
                                Shared news with you.
                              </span>
                              <span class="d-block text-muted text-limit" *ngIf="chatMessage.post_id != '0' && chatMessage.type =='4'">
                                Shared blog with you.
                              </span>

                              <span class="d-block text-muted text-limit" *ngIf="chatMessage.post_id == '0' && chatMessage.type =='5'">
                                <span class="d-block text-muted text-limit not-active" *ngIf="chatMessage.type == '5' && chatMessage.url_link!=''">
                                  -"<span [innerHTML]="chatMessage.url_link"></span>"</span>
                              </span>
                              <span class="time">{{
                                chatMessage.created_at
                                }}</span>
                            </div>
                          </a>
                        </div>
                        <div class="text-center" *ngIf="getChatMessageLoader">
                          <div *ngFor="let e of [].constructor(1)" class="d-flex align-items-center px-2 mt-2">
                            <div>
                              <h5 class="mt-0 d-flex align-items-start">
                                <span class="shared-dom">
                                  <div class="sub-rect-connection pure-background"></div>
                                </span>
                              </h5>
                            </div>

                            <div class="w-75 ml-2">
                              <h5 class="mt-0 d-flex align-items-start w-75">
                                <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                  <div class="background-masker btn-divide-left"></div>
                                </span>
                              </h5>
                              <h5 class="mt-0 d-flex align-items-start w-50">
                                <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                  <div class="background-masker btn-divide-left"></div>
                                </span>
                              </h5>
                              <h5 class="mt-0 d-flex align-items-start w-25">
                                <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                  <div class="background-masker btn-divide-left"></div>
                                </span>
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div ngbDropdownItem class="text-center position-relative" *ngIf="showMoreMessageList">
                          <a href="javascript:void(0);" (click)="
                            $event.stopPropagation();
                            myDrop1.open();
                            showMoreMessageNotificationList(nextPageMessageList)
                          " class="read-more" placement="top">
                            <span class="mdi mdi-chevron-double-down">Show More</span>
                          </a>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="getChatMessageArr.length == 0" class="text-center">
                      <div class="text-center" *ngIf="getChatMessageNoDataLoader">
                        <div *ngFor="let e of [].constructor(3)" class="d-flex align-items-center px-2 mt-2">
                          <div>
                            <h5 class="mt-0 d-flex align-items-start">
                              <span class="shared-dom">
                                <div class="sub-rect-connection pure-background"></div>
                              </span>
                            </h5>
                          </div>

                          <div class="w-75 ml-2">
                            <h5 class="mt-0 d-flex align-items-start w-75">
                              <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                <div class="background-masker btn-divide-left"></div>
                              </span>
                            </h5>
                            <h5 class="mt-0 d-flex align-items-start w-50">
                              <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                <div class="background-masker btn-divide-left"></div>
                              </span>
                            </h5>
                            <h5 class="mt-0 d-flex align-items-start w-25">
                              <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                <div class="background-masker btn-divide-left"></div>
                              </span>
                            </h5>
                          </div>
                        </div>
                       
                      </div>
                      <div *ngIf="!getChatMessageNoDataLoader">
                        <h5 class="mt-2">
                          <strong>No Messages found!</strong>
                        </h5>
                        <p>
                          You will get a ping here if anyone sends a message to you
                        </p>
                      </div>
                    </li>
                    <!-- Message -->
                    <li (click)="openMessageWindow();autoClose()" *ngIf="getChatMessageArr.length > 0">
                      <a class="text-center d-block bg-light p-2 message-footer" href="javascript:void(0);">
                        <strong>See All Messages</strong>
                      </a>
                    </li>
                    <li (click)="openMessageWindow();autoClose()" *ngIf="getChatMessageArr.length == 0">
                      <a class="text-center d-block bg-light p-2 message-footer" href="javascript:void(0);"><strong>See
                          All
                          Messages</strong></a>
                    </li>
                  </ul>
                </div>
              </li>
             
              <li class="nav-item" [ngClass]="{ active: activeTab == 'News' }">
                <a class="nav-link waves-effect waves-dark notification-icon" href="javascript:void(0)" placement="bottom" (click)="changeActiveTab('News');newsListRedirectPage()">
                 
                  <img alt="loading..."    src="assets/icons/sports-icon/News-Icon-White.svg" width="40" alt="news" />
                  <small class="d-block text-white mt-1" [ngClass]="{ 'font-weight-bold': activeTab == 'News' }">News</small>
                </a>
              </li>
              <li class="nav-item dropdown" ngbDropdown placement="bottom-right" [ngClass]="{ active: activeTab == 'Settings' }" [autoClose]="autoCloseStatus">
                <a ngbDropdownToggle class="nav-link waves-effect waves-dark notification-icon" href="javascript:void(0)" placement="bottom" (click)="changeActiveTab('Settings')">
                 
                  <img alt="loading..."    src="assets/icons/sports-icon/Settings-Icon-White.svg" alt="settings" width="24" />
                  <small class="d-block mt-1 text-white" [ngClass]="{ 'font-weight-bold': activeTab == 'Settings' }">Settings</small>
                </a>
                <div class="dropdown-menu animated fadeIn" ngbDropdownMenu>
                  <ul class="dropdown-user h-auto">
                    <li>
                      <a href="javascript:void(0);" (click)="myprofileRedirectPage()" class="bg-light px-1">
                        <div class="dw-user-box p-0 d-flex align-items-center form-row">
                          <div class="u-img col-2">
                            <img alt="loading..."    *ngIf="userNaviDetails.profile_pic != ''" src="{{ userNaviDetails.profile_pic }}" alt="user" />
                            <img alt="loading..."    *ngIf="userNaviDetails.profile_pic == ''" src="{{ user_profile_img }}" alt="user" />
                          </div>
                        
                          <div class="pl-3 d-flex flex-column flex-fill col-10">
                            <h5 class="d-flex mb-0 d-flex align-items-center">
                              <span class="name_display_limit_setting">
                                {{ userNaviDetails.name }}
                              </span>
                              <span *ngIf="userNaviDetails.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                            </h5>
                            <p class="text-muted name_display_limit_setting mb-0">
                              {{ userNaviDetails.username }}
                            </p>
                          </div>
                        
                        </div>
                      </a>
                    </li>
                    
                    <!-- HIDE ALL SOCIAL MEDIA LOGIN FOR NOW (DO NOT DELETE)-->
                    <li>
                      <a href="javascript:void(0);" routerLink="/accountSetting" (click)="autoClose()">
                        <i class="ti-settings"></i> Account Settings
                      </a>
                    </li>

                    <li>
                      <a href="javascript:void(0);" (click)="autoClose();openModal(inviteUser);">
                        <i class="ti-comments"></i> Invite Friends
                      </a>
                    </li>

                    <li>
                      <a href="javascript:void(0);" (click)="autoClose(); navigateToEdiProfile('1');">
                        <i class="ti-pencil"></i> Edit Profile
                      </a>
                    </li>

                    <!-- for pending list(D) -->
                    <li>
                      <a href="javascript:void(0)" (click)="pendingPostRedirect('1')">
                        <i class="ti-time"></i> Pending Posts
                      </a>
                     
                    </li>
                    <!--End for pending list(D) -->
                    <li>
                      <a href="javascript:void(0)" [routerLink]="['/matches', 'scheduled', 'all']">
                        <i class="ti-alarm-clock"></i> Scheduled Events
                      </a>
                    </li>

                    <li role="separator" class="divider"></li>
                    <li (click)="autoClose()">
                      
                      <a target="_blank" rel="noopener" href="{{help_center}}"  >
                        <i class="ti-help"></i> Help Center
                      </a>
                    </li>
                    <!-- <li *ngIf="web_shortcut != '' && showShortcut" (click)="autoClose()">
                      <a target="_blank" rel="noopener" href="{{web_shortcut}}">
                        <i class="ti-link"></i> Create Mobile App Shortcut
                      </a>
                    </li> -->

                    <li *ngIf="web_shortcut != '' && showShortcut" >
                      <a href="javascript:void(0);" (click)="getMobileAppModal(getMobileApp);" >
                        <i class="ti-link"></i> Get Mobile App 
                      </a>
                    </li>


                    <li (click)="autoClose()">
                      <a href="javascript:void(0);" routerLink="/contact">
                        <i class="ti-support"></i> Contact Us
                      </a>
                    </li>
                    <li (click)="startTourIGT();">
                      <a href="javascript:void(0);">
                        <i class="ti-bookmark-alt"></i> Tour Guide
                      </a>
                    </li>

                    <li>
                      <a href="javascript:void(0);" (click)="logout()"><i class="fa fa-power-off"></i> Logout</a>
                    </li>
                    <li *ngIf="appVersion && appVersion!='' && controls" role="separator" class="divider mt-0 mb-0"></li>
                    <li *ngIf="appVersion && appVersion!='' && controls" class="text-center mt-0 mb-0">
                      <a href="javascript:void(0);"><i class=""></i>App Version: {{ appVersion }}</a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <!-- desktop view end -->
          </div>
        </div>
      </div>
    </div>
  </nav>
  <!-- desktop nav end -->

  <!-- mobile/tab nav start -->
  <nav *ngIf="!isChatPage" class="navbar top-navbar navbar-expand-md navbar-light p-0 d-lg-none" aria-label="">
    <div class="container p-0 justify-content-center py-0 py-md-2 mobile_top_header">
      <!-- ============================================================== -->
      <!-- Logo -->
      <!-- ============================================================== -->
      <div class="navbar-header d-flex justify-content-center justify-content-lg-between pb-md-0">

        <a class="navbar-brand ml-2 my-auto mr-3" href="javascript:void(0);" (click)="SelectUserType('Main'); changeActiveTab('Pals')">
          <img alt="loading..."    src="{{ platform_logo }}" class="img-fluid d-none d-sm-block" alt="..." width="100" />
          <img alt="loading..."    src="assets/images/logo-mob.png" class="img-fluid d-block d-sm-none" alt="..." width="30" />
        </a>
        <div class="search search_width_mobile shadow-sm d-block bg-white border overflow-hidden rounded mx-xl-5 mx-md-2 ml-1 my-auto col p-0 border-radius-10 mr-1">
          <form class="" [formGroup]="usersForm">
            <mat-form-field class="example-full-width pb-0 w-100 form-control-design1" appearance="fill">
              <input #inputField matInput [matAutocomplete]="auto" formControlName="userInput" class="text-limit pos-search" maxlength="200" placeholder="Search friend, athlete, team, sports…" />
            </mat-form-field>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectSearchOption($event.option.value)">
              <ng-container *ngIf="!isLoading">
                <mat-option class="d-block text-truncate" *ngFor="let user of filteredUsers" [value]="user.name">
                  <div class="mw text-truncate name_display_limit_setting">
                    {{ user.name }}
                  </div>
                </mat-option>
              </ng-container>
              <span><a href="javascript:void(0);" class="showMoreUser bg-dark-blue" (click)="showMoreUserClick()">Show
                  More</a></span>
            </mat-autocomplete>
          </form>
          <span class="mdi mdi-magnify mdi-2x cursor-pointer" (click)="showMoreUserClick()"></span>
        </div>
        <div class="ml-1 mt-1 d-flex">
          <span id="scoreTabMob" class="nav-item dropdown">
            <a href="javascript:void(0);" class="score-label d-block pos-5" (click)="openScoreModal(scores)">
              <img alt="loading..."    src="assets/images/svg_patriot/score_sm.png" alt="fans" width="24" />
              
              <small class="lable-show text-white m-0" style="margin: 3px !important; line-height: 1.4;">Scores</small>
            </a>
          </span>

          <span class="nav-item dropdown" ngbDropdown placement="bottom-right" [autoClose]="autoCloseStatus">
            <a ngbDropdownToggle class="nav-link waves-effect waves-dark notification-icon px-1" href="javascript:void(0)" placement="bottom" (click)="changeActiveTab('Settings')">
             
              <img alt="loading..."    src="assets/images/svg_patriot/Settings_sm.png" width="24" alt="settings" />
              <small class="lable-show text-white">Settings</small>
            </a>
            <div class="dropdown-menu animated fadeIn" ngbDropdownMenu>
              <ul class="dropdown-user h-auto">
                <li>
                  <a href="javascript:void(0);" (click)="myprofileRedirectPage();autoClose()" class="bg-light">
                    <div class="dw-user-box p-0 d-flex align-items-center form-row">
                      <div class="u-img col-2">
                        <img alt="loading..."    *ngIf="userNaviDetails.profile_pic != ''" src="{{ userNaviDetails.profile_pic }}" alt="user" />
                        <img alt="loading..."    *ngIf="userNaviDetails.profile_pic == ''" src="{{ user_profile_img }}" alt="user" />
                      </div>
                      <div class="pl-3 d-flex flex-column flex-fill col-10 ">
                        <h5 class="mb-0 d-flex align-items-center">
                          <span class="name_display_limit_setting">
                            {{ userNaviDetails.name }}
                          </span>
                          <span *ngIf="userNaviDetails.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                        </h5>
                        <p class="text-muted d-block name_display_limit_setting mb-0">
                          {{ userNaviDetails.username }}
                        </p>
                      </div>
                      
                    </div>
                  </a>
                </li>
                
                <!-- HIDE ALL SOCIAL MEDIA LOGIN FOR NOW (DO NOT DELETE)-->
                <li>
                  <a href="javascript:void(0);" (click)="autoClose()" routerLink="/accountSetting">
                    <i class="ti-settings"></i> Account Settings
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" (click)="autoClose();openModal(inviteUser);">
                    <i class="ti-comments"></i> Invite Friends
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" (click)="autoClose(); navigateToEdiProfile('1');">
                    <i class="ti-pencil"></i> Edit Profile
                  </a>
                </li>

               

                <!-- for pending list(D) -->
                <li>
                  <a href="javascript:void(0)" (click)="pendingPostRedirect('1')">
                    <i class="ti-time"></i> Pending Posts
                  </a>
                  
                </li>
                <!--End for pending list(D) -->

                <li>
                  <a href="javascript:void(0)" [routerLink]="['/matches', 'scheduled', 'all']">
                    <i class="ti-alarm-clock"></i> Scheduled Events
                  </a>
                </li>

                <li role="separator" class="divider"></li>
                <li (click)="autoClose()">
                  <a target="_blank" rel="noopener" href="{{help_center}}">
                    <i class="ti-help"></i> Help Center
                  </a>
                </li>
                <!-- <li *ngIf="web_shortcut != '' && showShortcut" (click)="autoClose()">
                  <a target="_blank" rel="noopener" href="{{web_shortcut}}">
                    <i class="ti-link"></i> Create Mobile App Shortcut
                  </a>
                </li> -->

                <li *ngIf="web_shortcut != '' && showShortcut" >
                  <a href="javascript:void(0);" (click)="getMobileAppModal(getMobileApp);" >
                    <i class="ti-link"></i> Get Mobile App 
                  </a>
                </li>
                
                <li>
                  <a href="javascript:void(0);" routerLink="/contact" (click)="autoClose()">
                    <i class="ti-support"></i> Contact Us
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" (click)="startTourIGTMob()">
                    <i class="ti-bookmark-alt"></i> Tour Guide
                  </a>
                </li>

                <li>
                  <a href="javascript:void(0);" (click)="logout()"><i class="fa fa-power-off"></i> Logout</a>
                </li>
                <li *ngIf="appVersion && appVersion!='' && controls" role="separator" class="divider mt-0 mb-0"></li>
                <li *ngIf="appVersion && appVersion!='' && controls" class="text-center mt-0 mb-0">
                  <a href="javascript:void(0);"><i class=""></i>App Version: {{ appVersion }}</a>
                </li>
              </ul>
            </div>
          </span>
        </div>

      </div>
      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
    </div>
    <div class="container-fluid bg-white p-0 justify-content-center mobile_bottom_header">
      <div class="navbar-collapse justify-content-center">
        <!-- ============================================================== -->
        <!-- toggle and nav items -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- User profile and search -->
        <!-- ============================================================== -->

        <div class="w-sm-100 d-flex justify-content-center pt-md-2">
          <!-- mobile view starts -->
          <ul class="navbar-nav my-lg-0 notification-icon d-lg-none d-flex justify-content-between">
            <!-- ============================================================== -->
            <!-- Profile -->
            <!-- ============================================================== -->
            <li id="myProfileTabMob" class="nav-item new-new-item" [ngClass]="{ active: activeTab == 'Me' }">
              <a class="nav-link waves-effect waves-dark notification-icon" href="javascript:void(0)" placement="bottom" (click)="changeActiveTab('Me');myprofileRedirectPage()" *ngIf="login_user_type == 1">
                <img alt="loading..."    *ngIf="userNaviDetails.profile_pic != ''" src="{{ userNaviDetails.profile_pic }}" class="rounded-circle" alt="..." width="27" height="27" />
                <img alt="loading..."    *ngIf="userNaviDetails.profile_pic == ''" src="{{ user_profile_img }}" class="rounded-circle" alt="..." width="27" height="27" />
               
                <small class="lable-show truncate-name mt-1" [ngClass]="{ 'font-weight-bold': activeTab == 'Me' }">My&nbsp;Profile</small>
              </a>
              <a class="nav-link waves-effect waves-dark notification-icon" href="javascript:void(0)" placement="bottom" (click)="changeActiveTab('Me');aliasprofileRedirectPage()" *ngIf="login_user_type == 2">
                <img alt="loading..."    *ngIf="userNaviDetails.alias_profile_pic != ''" src="{{ userNaviDetails.alias_profile_pic }}" class="rounded-circle" alt="..." width="27" height="27" />
                <img alt="loading..."    *ngIf="userNaviDetails.alias_profile_pic == ''" src="{{ user_profile_img }}" class="rounded-circle" alt="..." width="27" height="27" />
                
                <small class="lable-show truncate-name mt-1 " [ngClass]="{ 'font-weight-bold': activeTab == 'Me' }">My&nbsp;Profile</small>
              </a>
            </li>
            <li id="fansTabMob" class="nav-item new-new-item" [ngClass]="{ active: activeTab == 'Pals' }">
              <a (click)="SelectUserType('Main'); changeActiveTab('Pals')" class="nav-link waves-effect waves-dark notification-icon" href="javascript:void(0)" placement="bottom">
              
                <img alt="loading..."    src="assets/images/icon/Fans-ICON.svg" alt="fans" width="25" />
                
                <small class="lable-show mt-1" [ngClass]="{ 'font-weight-bold': activeTab == 'Pals' }">Fans</small>
              </a>
            </li>
           
            <li class="nav-item dropdown new-new-item" ngbDropdown #myDrop="ngbDropdown" placement="bottom-right" [ngClass]="{ active: activeTab == 'Notification' }">
              <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark notification-icon" href="javascript:void(0)" (click)="
                  notificationIconClick(); changeActiveTab('Notification')
                " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" placement="bottom">
               
                <img alt="loading..."    src="assets/images/svg_patriot/notification.svg" alt="notification" />
             
                <small class="lable-show mt-1" [ngClass]="{ 'font-weight-bold': activeTab == 'Notification' }">Notifications</small>
                <span class="badge badge-danger badge-pill notify-Notification" *ngIf="notification_count > 0">
                  {{ notification_count }}
                </span>
              </a>
              <div class="dropdown-menu dropdown-menu-left mailbox fadeIn dropdown_custom" ngbDropdownMenu>
                <ul>
                  <li>
                    <div class="drop-title">
                      Notifications
                      <a *ngIf="getUserNameArr.length > 0" href="javascript:void(0);" (click)="markAllAsReadNotification()" class="float-right">Mark all as read</a>
                    </div>
                  </li>
                  <li *ngIf="getUserNameArr.length > 0">
                    <div class="message-center" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false">
                      <div *ngFor="let user of getUserNameArr; let i = index" [ngClass]="{ 'bg-read': (user.read_status == '0' && user.type != 'circlepost') || (user.connection_status == '0' && user.type == 'circlepost')}">
                        <a *ngIf="user.type == 'connection'" href="javascript:void(0);" (click)="readNotification(i,user);searchUserNameClick(user)">
                          <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          <div class="mail-contnet">
                            <h5 class="d-flex">
                              <span class="d-block text-truncate mw">{{ user.name }}
                              </span>
                              <span *ngIf="user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                            </h5>
                            <div class="d-flex justify-content-between">
                              <span class="mail-desc" style="white-space: normal !important;">
                                You have
                                <span *ngIf="user.connection_status == 1">a new</span>
                                <span *ngIf="user.connection_status == 2">accepted</span>
                                <span *ngIf="user.connection_status == 3">rejected</span> Connection Request.
                                <span class="time">{{ user.created_at }}</span>
                              </span>
                              <span class="float-right mb-2 not-button" *ngIf="user.connection_status == 1 || user.connection_status == 3">
                                <button class="btn btn-sm btn-info bg-dark-blue" (click)="acceptConnectRequestOpenModal(acceptConnectRequest,user)" placement="top" ngbTooltip="Accept">
                                  <span class="mdi mdi-check"></span>
                                </button>
                                <button (click)="rejectConnectRequestOpenModal(rejectConnectRequest,user)" class="btn btn-sm btn-light bg-white ml-2" placement="top" ngbTooltip="Reject">
                                  <span class="mdi mdi-close"></span>
                                </button>
                              </span>
                            </div>
                          </div>
                        </a>
                        <a *ngIf="user.type == 'connectionAccept'" href="javascript:void(0);" (click)="readNotification(i,user);searchUserNameClick(user)">
                          <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          <div class="mail-contnet">
                            <h5 class="d-flex">
                              <span class="d-block text-truncate mw">{{ user.name }}
                              </span>
                              <span *ngIf="user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                            </h5>
                            <span class="mail-desc" style="white-space: normal !important;">
                              accepted your Connection Request.
                            </span>
                            <span class="time">{{ user.created_at }}</span>
                          </div>
                        </a>
                        <a *ngIf="user.type == 'follow'" href="javascript:void(0);" (click)="readNotification(i,user);searchUserNameClick(user);">
                          <span *ngIf="user.follower_type == 1">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <span *ngIf="user.follower_type == 2">
                            <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <div class="mail-contnet">
                            <span class="mail-desc" style="white-space: normal !important;">
                              <h5 class="d-flex" *ngIf="
                                  user.follower_type == 1 ||
                                  user.alias_name == ''
                                ">
                                <span class="d-block text-truncate mw">{{ user.name }}
                                </span>
                                <span *ngIf="user.follower_type && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                <span *ngIf="
                                    user.follower_type == 2 &&
                                    user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                              </h5>
                              <h5 *ngIf="user.follower_type == '2'">
                                <span class="d-block text-truncate mw">{{
                                  user.alias_name
                                  }}</span>
                              </h5>
                              <p class="text-muted mb-0">
                                started following
                                <span *ngIf="user.following_type == '1'" class="d-inline-flex flex-wrap">
                                  <span class="d-inline-block text-truncate mw">{{ userNaviDetails.name }} </span>
                                  &nbsp;- Your Profile
                                </span>
                                <span *ngIf="user.following_type == '2'" class="d-inline-flex flex-wrap">
                                  <span class="d-inline-block text-truncate mw">
                                    <span *ngIf="userNaviDetails.alias_name != ''">
                                      {{ userNaviDetails.alias_name }}
                                    </span>
                                    <span *ngIf="userNaviDetails.alias_name == ''">
                                      {{ userNaviDetails.name }}
                                    </span>
                                  </span>
                                  &nbsp;- Your {{alias_user_name}} Profile
                                </span>
                              </p>
                              <div class="d-flex justify-content-between">
                                <span class="time">{{ user.created_at }}</span>
                                <span *ngIf="(user.following_type == '1' || user.following_type == '2') && !user.is_follow" class="float-right action-tool">
                                  <button href="javascript:void(0);" class="btn btn-sm py-1 btn-info bg-gray" (click)="followUserOpenModal(follow,user)"><small>Follow Back</small></button>
                                </span>
                              </div>
                            </span>
                          </div>
                        </a>
                        <a *ngIf="user.type == 'postlikes'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                          <span *ngIf="user.user_to == 1">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <span *ngIf="user.user_to == 2">
                            <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <div class="mail-contnet">
                            <span class="mail-desc" style="white-space: normal !important;">
                              <h5 class="d-flex" *ngIf="
                                  user.user_to == 1 || user.alias_name == ''
                                ">
                                <span class="d-block text-truncate mw">{{
                                  user.name
                                  }}</span>
                                <span *ngIf="user.user_to==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                <span *ngIf="
                                    user.user_to == 2 && user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                              </h5>
                              <h5 *ngIf="user.user_to == '2'">
                                <span class="d-block text-truncate mw">
                                  {{ user.alias_name }}</span>
                              </h5>
                              <span class="d-inline text-muted">
                                liked your
                                <span *ngIf="user.login_user_type == 1">
                                  <span *ngIf="user.post_privacy == 2">
                                    primary open forum
                                  </span>
                                  <span *ngIf="user.post_privacy != 2">
                                    fans
                                  </span>
                                </span>
                                <span *ngIf="user.login_user_type == 2">
                                  {{alias_lower_case_user_name}} open forum
                                </span> post
                              </span>
                              <span class="text-limit" *ngIf="
                                (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                <span>
                                  - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>

                              <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                <span>
                                  - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                            </span>
                            <span class="time">{{ user.created_at }}</span>
                          </div>
                        </a>
                        <a *ngIf="user.type == 'postComment'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                          <span *ngIf="user.user_to == 1">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <span *ngIf="user.user_to == 2">
                            <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <div class="mail-contnet">
                            <span class="mail-desc" style="white-space: normal !important;">
                              <h5 class="d-flex" *ngIf="
                                  user.user_to == 1 || user.alias_name == ''
                                ">
                                <span class="d-block text-truncate mw">{{
                                  user.name
                                  }}</span>
                                <span *ngIf="user.user_to==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                <span *ngIf="
                                    user.user_to == 2 && user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                              </h5>
                              <h5 *ngIf="user.user_to == '2'">
                                <span class="d-block text-truncate mw">
                                  {{ user.alias_name }}</span>
                              </h5>
                              <span class="d-inline text-muted">
                                commented on your
                                <span *ngIf="user.login_user_type == 1">
                                  <span *ngIf="user.post_privacy == 2">
                                    primary open forum
                                  </span>
                                  <span *ngIf="user.post_privacy != 2">
                                    fans
                                  </span>
                                </span>
                                <span *ngIf="user.login_user_type == 2">
                                  {{alias_lower_case_user_name}} open forum
                                </span> post
                              </span>
                              <span class="text-limit" *ngIf="
                                (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                <span>
                                  - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>

                              <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                <span>
                                  - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                            </span>
                            <span class="time">{{ user.created_at }}</span>
                          </div>
                        </a>
                        <a *ngIf="user.type == 'postCommentReply'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailCommReplyFun(user)">
                          <span *ngIf="user.user_from_type == 1">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <span *ngIf="user.user_from_type == 2">
                            <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <div class="mail-contnet">
                            <span class="mail-desc" style="white-space: normal !important;">
                              <h5 class="d-flex" *ngIf="
                                  user.user_from_type == 1 ||
                                  user.alias_name == ''
                                ">
                                <span class="d-block text-truncate mw">{{
                                  user.name
                                  }}</span>
                                <span *ngIf="user.user_from_type==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                <span *ngIf="
                                    user.user_from_type == 2 &&
                                    user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                              </h5>
                              <h5 *ngIf="user.user_from_type == '2'">
                                <span class="d-block text-truncate mw">
                                  {{ user.alias_name }}</span>
                              </h5>
                              <span class="d-inline text-muted" *ngIf="user.reply_notification_type == 1">
                                replied to
                                <span *ngIf="user.comm_user_type == 1 || user.post_user_alias_name == ''" class="d-inline-flex">
                                  <span class="d-inline-block text-truncate mw">{{ user.post_user_name }}'s</span>
                                  <span *ngIf="user.comm_user_type==1 && user.post_user_verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                  <span *ngIf="user.comm_user_type == 2 && user.post_user_alias_name == ''">({{alias_user_name}})</span>
                                </span>
                                <span *ngIf="user.comm_user_type == '2'" class="d-inline-flex">
                                  <span class="d-inline-block text-truncate mw">
                                    {{ user.post_user_alias_name }}
                                  </span>
                                </span> comment on your
                                <span *ngIf="user.login_user_type == 1">
                                  <span *ngIf="user.post_privacy == 2">
                                    primary open forum
                                  </span>
                                  <span *ngIf="user.post_privacy != 2">
                                    fans
                                  </span>
                                </span>
                                <span *ngIf="user.login_user_type == 2">
                                  {{alias_lower_case_user_name}} open forum
                                </span> post
                              </span>
                              <span class="d-inline text-muted" *ngIf="user.reply_notification_type == 2">
                                replied to your comment on
                                <span *ngIf="user.comm_user_type == 1 || user.post_user_alias_name == ''" class="d-inline-flex">
                                  <span class="d-inline-block text-truncate mw">{{ user.post_user_name }}'s</span>
                                  <span *ngIf="user.comm_user_type==1 && user.post_user_verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                  <span *ngIf="user.comm_user_type == 2 && user.post_user_alias_name == ''">({{alias_user_name}})</span>
                                </span>
                                <span *ngIf="user.comm_user_type == '2'" class="d-inline-flex">
                                  <span class="d-inline-block text-truncate mw">
                                    {{ user.post_user_alias_name }}
                                  </span>
                                </span> post
                              </span>
                              <span class="text-limit" *ngIf="(user.share_post_text == '' || user.share_post_text == null) && user.post_text && user.post_text != ''" [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                <span>
                                  - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>

                              <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                <span>
                                  - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                            </span>
                            <span class="time">{{ user.created_at }}</span>
                          </div>
                        </a>
                        <a *ngIf="user.type == 'tagTeamGroup'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFunGroup(user)">
                          <span *ngIf="user.user_from_type == 1">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <span *ngIf="user.user_from_type == 2">
                            <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <div class="mail-contnet">
                            <span class="mail-desc" style="white-space: normal !important;">
                              <h5 class="d-flex" *ngIf="
                                  user.user_from_type == 1 ||
                                  user.alias_name == ''
                                ">
                                <span class="d-block text-truncate mw">
                                  {{ user.name }}
                                </span>
                                <span *ngIf="user.user_from_type==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                <span *ngIf="
                                    user.user_from_type == 2 &&
                                    user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                              </h5>
                              <h5 class="d-block text-truncate mw" *ngIf="user.user_from_type == '2'">
                                {{ user.alias_name }}
                              </h5>
                              Invited
                              <span *ngIf="user.user_to_type == '1'" class="d-inline-flex">
                                <span class="d-inline-block text-truncate mw">{{ userNaviDetails.name }} </span> &nbsp;-
                                Your Profile
                              </span>
                              <span *ngIf="user.user_to_type == '2'" class="d-inline-flex">
                                <span class="d-inline-block text-truncate mw">
                                  <span *ngIf="userNaviDetails.alias_name != ''">
                                    {{ userNaviDetails.alias_name }}
                                  </span>
                                  <span *ngIf="userNaviDetails.alias_name == ''">
                                    {{ userNaviDetails.name }}
                                  </span>
                                </span>
                                &nbsp;- Your {{alias_user_name}} Profile
                              </span> to discuss on this Post.
                            </span>
                          </div>
                        </a>
                        <a *ngIf="user.type == 'postShareByFriend'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFunGroup(user)">
                          <span *ngIf="user.user_from_type == 1">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <span *ngIf="user.user_from_type == 2">
                            <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <div class="mail-contnet">
                            <span class="mail-desc" style="white-space: normal !important;">
                              <h5 class="d-flex" *ngIf="
                                  user.user_from_type == 1 ||
                                  user.alias_name == ''
                                ">
                                <span class="d-block text-truncate mw">
                                  {{ user.name }}
                                </span>
                                <span *ngIf="user.user_from_type==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                <span *ngIf="
                                    user.user_from_type == 2 &&
                                    user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                              </h5>
                              <h5 class="d-block text-truncate mw" *ngIf="user.user_from_type == '2'">
                                {{ user.alias_name }}
                              </h5>
                              Posted on
                              <span *ngIf="user.post_privacy == 2">
                                <span *ngIf="user.user_to_type == '1'" class="d-inline-flex">
                                  your Open Forum primary
                                </span>
                                <span *ngIf="user.user_to_type == '2'" class="d-inline-flex">
                                  your Open Forum {{alias_lower_case_user_name}}
                                </span>
                              </span>
                              <span *ngIf="user.post_privacy != 2">
                                your Fans
                              </span> timeline.
                            </span>
                            <span class="time">{{ user.created_at }}</span>
                          </div>
                        </a>
                        <a *ngIf="user.type == 'postmedialikes'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                          <span *ngIf="user.user_to == 1">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <span *ngIf="user.user_to == 2">
                            <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <div class="mail-contnet">
                            <span class="mail-desc" style="white-space: normal !important;">
                              <h5 class="d-flex" *ngIf="
                                  user.user_to == 1 || user.alias_name == ''
                                ">
                                <span class="d-block text-truncate mw">{{
                                  user.name
                                  }}</span>
                                <span *ngIf="user.user_to==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                <span *ngIf="
                                    user.user_to == 2 && user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                              </h5>
                              <h5 *ngIf="user.user_to == '2'">
                                <span class="d-block text-truncate mw">
                                  {{ user.alias_name }}</span>
                              </h5>
                              <span class="d-inline text-muted">
                                liked a media file in your
                                <span *ngIf="user.login_user_type == 1">
                                  <span *ngIf="user.post_privacy == 2">
                                    primary open forum
                                  </span>
                                  <span *ngIf="user.post_privacy != 2">
                                    fans
                                  </span>
                                </span>
                                <span *ngIf="user.login_user_type == 2">
                                  {{alias_lower_case_user_name}} open forum
                                </span> post
                              </span>
                              <span class="text-limit" *ngIf="
                                (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                <span>
                                  - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                              <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                <span>
                                  - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                            </span>
                          </div>
                        </a>
                        <a *ngIf="user.type == 'postMediaComment'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                          <span *ngIf="user.user_to == 1">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <span *ngIf="user.user_to == 2">
                            <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <div class="mail-contnet">
                            <span class="mail-desc" style="white-space: normal !important;">
                              <h5 class="d-flex" *ngIf="
                                  user.user_to == 1 || user.alias_name == ''
                                ">
                                <span class="d-block text-truncate mw">{{
                                  user.name
                                  }}</span>
                                <span *ngIf="user.user_to==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                <span *ngIf="
                                    user.user_to == 2 && user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                              </h5>
                              <h5 *ngIf="user.user_to == '2'">
                                <span class="d-block text-truncate mw">
                                  {{ user.alias_name }}</span>
                              </h5>
                              <span class="d-inline text-muted">
                                commented on a media file of your
                                <span *ngIf="user.login_user_type == 1">
                                  <span *ngIf="user.post_privacy == 2">
                                    primary open forum
                                  </span>
                                  <span *ngIf="user.post_privacy != 2">
                                    fans
                                  </span>
                                </span>
                                <span *ngIf="user.login_user_type == 2">
                                  {{alias_lower_case_user_name}} open forum
                                </span> post
                              </span>
                              <span class="text-limit" *ngIf="
                                (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                <span>
                                  - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>

                              <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                <span>
                                  - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                            </span>
                            <span class="time">{{ user.created_at }}</span>
                          </div>
                        </a>
                        <a *ngIf="user.type == 'postMediaCommentReply'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailCommReplyFun(user)">
                          <span *ngIf="user.user_from_type == 1">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <span *ngIf="user.user_from_type == 2">
                            <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <div class="mail-contnet">
                            <span class="mail-desc" style="white-space: normal !important;">
                              <h5 class="d-flex" *ngIf="
                                  user.user_from_type == 1 ||
                                  user.alias_name == ''
                                ">
                                <span class="d-block text-truncate mw">{{
                                  user.name
                                  }}</span>
                                <span *ngIf="user.user_from_type==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                <span *ngIf="
                                    user.user_from_type == 2 &&
                                    user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                              </h5>
                              <h5 *ngIf="user.user_from_type == '2'">
                                <span class="d-block text-truncate mw">
                                  {{ user.alias_name }}</span>
                              </h5>
                              <span class="d-inline text-muted" *ngIf="user.reply_notification_type == 1">
                                replied to
                                <span *ngIf="user.comm_user_type == 1 || user.post_user_alias_name == ''" class="d-inline-flex">
                                  <span class="d-inline-block text-truncate mw">{{ user.post_user_name }}'s</span>
                                  <span *ngIf="user.comm_user_type==1 && user.post_user_verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                  <span *ngIf="user.comm_user_type == 2 && user.post_user_alias_name == ''">({{alias_user_name}})</span>
                                </span>
                                <span *ngIf="user.comm_user_type == '2'" class="d-inline-flex">
                                  <span class="d-inline-block text-truncate mw">
                                    {{ user.post_user_alias_name }}
                                  </span>
                                </span> comment on a media file of your
                                <span *ngIf="user.login_user_type == 1">
                                  <span *ngIf="user.post_privacy == 2">
                                    primary open forum
                                  </span>
                                  <span *ngIf="user.post_privacy != 2">
                                    fans
                                  </span>
                                </span>
                                <span *ngIf="user.login_user_type == 2">
                                  {{alias_lower_case_user_name}} open forum
                                </span> post
                              </span>
                              <span class="d-inline text-muted" *ngIf="user.reply_notification_type == 2">
                                replied to your comment on a media file of
                                <span *ngIf="user.comm_user_type == 1 || user.post_user_alias_name == ''" class="d-inline-flex">
                                  <span class="d-inline-block text-truncate mw">{{ user.post_user_name }}'s</span>
                                  <span *ngIf="user.comm_user_type==1 && user.post_user_verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                  <span *ngIf="user.comm_user_type == 2 && user.post_user_alias_name == ''">({{alias_user_name}})</span>
                                </span>
                                <span *ngIf="user.comm_user_type == '2'" class="d-inline-flex">
                                  <span class="d-inline-block text-truncate mw">
                                    {{ user.post_user_alias_name }}
                                  </span>
                                </span> post
                              </span>
                              <span class="text-limit" *ngIf="(user.share_post_text == '' || user.share_post_text == null) && user.post_text && user.post_text != ''" [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                <span>
                                  - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>

                              <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                <span>
                                  - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                            </span>
                            <span class="time">{{ user.created_at }}</span>
                          </div>
                        </a>

                        <a *ngIf="user.type == 'talkingPoints'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                          <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />

                          <div class="mail-contnet">
                            <span class="mail-desc" style="white-space: normal !important;">
                              <h5 class="d-flex">
                                <span class="d-block text-truncate mw">
                                  {{ user.name }}
                                </span>
                                <span *ngIf="user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                              </h5>
                              <span class="d-inline text-muted">
                                Announcement from Admin
                              </span>
                              <span class="text-limit" *ngIf="
                                (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                <span>
                                  - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                              <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                <span>
                                  - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                            </span>
                          </div>
                        </a>

                        <a *ngIf="user.type == 'postCommentlikes'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                          <span *ngIf="user.user_to == 1">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <span *ngIf="user.user_to == 2">
                            <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <div class="mail-contnet">
                            <span class="mail-desc" style="white-space: normal !important;">
                              <h5 class="d-flex" *ngIf="
                                  user.user_to == 1 || user.alias_name == ''
                                ">
                                <span class="d-block text-truncate mw">{{
                                  user.name
                                  }}</span>
                                <span *ngIf="user.user_to==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                <span *ngIf="
                                    user.user_to == 2 && user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                              </h5>
                              <h5 *ngIf="user.user_to == '2'">
                                <span class="d-block text-truncate mw">
                                  {{ user.alias_name }}</span>
                              </h5>
                              <span class="d-inline text-muted">
                                liked your comment on a
                                <span *ngIf="user.login_user_type == 1">
                                  <span *ngIf="user.post_privacy == 2">
                                    primary open forum
                                  </span>
                                  <span *ngIf="user.post_privacy != 2">
                                    fans
                                  </span>
                                </span>
                                <span *ngIf="user.login_user_type == 2">
                                  {{alias_lower_case_user_name}} open forum
                                </span> post
                              </span>
                              <span class="text-limit" *ngIf="
                                (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                <span>
                                  - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>

                              <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                <span>
                                  - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                            </span>
                          </div>
                        </a>

                        <a *ngIf="user.type == 'postCommentReplylikes'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                          <span *ngIf="user.user_to == 1">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <span *ngIf="user.user_to == 2">
                            <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <div class="mail-contnet">
                            <span class="mail-desc" style="white-space: normal !important;">
                              <h5 class="d-flex" *ngIf="
                                  user.user_to == 1 || user.alias_name == ''
                                ">
                                <span class="d-block text-truncate mw">{{
                                  user.name
                                  }}</span>
                                <span *ngIf="user.user_to==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                <span *ngIf="
                                    user.user_to == 2 && user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                              </h5>
                              <h5 *ngIf="user.user_to == '2'">
                                <span class="d-block text-truncate mw">
                                  {{ user.alias_name }}</span>
                              </h5>
                              <span class="d-inline text-muted">
                                liked your comment reply on a
                                <span *ngIf="user.login_user_type == 1">
                                  <span *ngIf="user.post_privacy == 2">
                                    primary open forum
                                  </span>
                                  <span *ngIf="user.post_privacy != 2">
                                    fans
                                  </span>
                                </span>
                                <span *ngIf="user.login_user_type == 2">
                                  {{alias_lower_case_user_name}} open forum
                                </span> post
                              </span>
                              <span class="text-limit" *ngIf="
                                (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                <span>
                                  - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>

                              <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                <span>
                                  - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                            </span>
                          </div>
                        </a>

                        <a *ngIf="user.type == 'postMediaCommentlikes'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                          <span *ngIf="user.user_to == 1">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <span *ngIf="user.user_to == 2">
                            <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <div class="mail-contnet">
                            <span class="mail-desc" style="white-space: normal !important;">
                              <h5 class="d-flex" *ngIf="
                                  user.user_to == 1 || user.alias_name == ''
                                ">
                                <span class="d-block text-truncate mw">{{
                                  user.name
                                  }}</span>
                                <span *ngIf="user.user_to==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                <span *ngIf="
                                    user.user_to == 2 && user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                              </h5>
                              <h5 *ngIf="user.user_to == '2'">
                                <span class="d-block text-truncate mw">
                                  {{ user.alias_name }}</span>
                              </h5>
                              <span class="d-inline text-muted">
                                liked your comment on a media file in
                                <span *ngIf="user.login_user_type == 1">
                                  <span *ngIf="user.post_privacy == 2">
                                    primary open forum
                                  </span>
                                  <span *ngIf="user.post_privacy != 2">
                                    fans
                                  </span>
                                </span>
                                <span *ngIf="user.login_user_type == 2">
                                  {{alias_lower_case_user_name}} open forum
                                </span> post
                              </span>
                              <span class="text-limit" *ngIf="
                                (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                <span>
                                  - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                              <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                <span>
                                  - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                            </span>
                          </div>
                        </a>

                        <a *ngIf="user.type == 'postMediaCommentReplylikes'" href="javascript:void(0);" (click)="readNotification(i,user);postViewDetailFun(user)">
                          <span *ngIf="user.user_to == 1">
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <span *ngIf="user.user_to == 2">
                            <img alt="loading..."    *ngIf="user.alias_profile_pic != ''" src="{{ user.alias_profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.alias_profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <div class="mail-contnet">
                            <span class="mail-desc" style="white-space: normal !important;">
                              <h5 class="d-flex" *ngIf="
                                  user.user_to == 1 || user.alias_name == ''
                                ">
                                <span class="d-block text-truncate mw">{{
                                  user.name
                                  }}</span>
                                <span *ngIf="user.user_to==1 && user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                                <span *ngIf="
                                    user.user_to == 2 && user.alias_name == ''
                                  ">({{alias_user_name}})</span>
                              </h5>
                              <h5 *ngIf="user.user_to == '2'">
                                <span class="d-block text-truncate mw">
                                  {{ user.alias_name }}</span>
                              </h5>
                              <span class="d-inline text-muted">
                                liked your comment reply on a media file in
                                <span *ngIf="user.login_user_type == 1">
                                  <span *ngIf="user.post_privacy == 2">
                                    primary open forum
                                  </span>
                                  <span *ngIf="user.post_privacy != 2">
                                    fans
                                  </span>
                                </span>
                                <span *ngIf="user.login_user_type == 2">
                                  {{alias_lower_case_user_name}} open forum
                                </span> post
                              </span>
                              <span class="text-limit" *ngIf="
                                (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                <span>
                                  - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                              <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                <span>
                                  - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                            </span>
                          </div>
                        </a>

                        <!-- ///////////////////////05/06/2021(s)///////////////////// -->
                        <a *ngIf="user.type == 'breakingNews'" href="javascript:void(0);" (click)="readNotification(i,user);sendOnBreakingNews(user.login_user_id)">
                          <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          <div class="mail-contnet">
                            <!-- <span class="mail-desc" style="white-space: normal !important;"> -->
                            <span class="mail-desc">
                              <h5 class="d-flex">
                                <span class="d-block text-truncate mw">
                                  Breaking News
                                </span>
                                <span *ngIf="user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                              </h5>
                              <!-- <small>
                                <span class="label" [ngClass]="user.league_color ? user.league_color : 'default-badge'">{{user.league_name}}</span>
                              </small> -->

                              <small>
                                <span *ngIf="user.league_name == 'IPL'">
                                    <span class="label"
                                        [ngClass]="user.league_color ? user.league_color : 'default-badge'">Cricket
                                    </span>
                                </span>
                        
                                <span *ngIf="user.league_name != 'IPL'">
                                    <span class="label"
                                        [ngClass]="user.league_color ? user.league_color : 'default-badge'">{{user.league_name}}
                                    </span>
                                </span>
                              </small>

                              &nbsp;
                              <span class="d-inline text-muted">
                                {{user.post_text}}
                              </span>
                              <span class="text-limit" *ngIf="
                                (user.share_post_text == '' || user.share_post_text == null) &&
                                    user.post_text && user.post_text != ''
                                  " [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                <span>
                                  - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                              <span class="text-limit" *ngIf="user.share_post_text && user.share_post_text != ''" [ngClass]="{'min-height': user.share_post_text && user.share_post_text.includes('emoji-pipe-image') }">
                                <span>
                                  - "<span [innerHTML]="user.share_post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                            </span>
                            <span class="time">{{ user.created_at }}</span>
                          </div>
                        </a>
                        <!-- ///////////////////////05/06/2021(s)///////////////////// -->
                        <!-- ///////////////////////28/10/2021(s)///////////////////// -->
                        <a *ngIf="user.type == 'dob' && user.birth_msg!= ''" href="javascript:void(0);" (click)="readNotification(i,user);sendOnBirthday(user)">
                          <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          <div class="mail-contnet">
                            <span class="mail-desc" style="white-space: normal !important;">
                              <h5 class="d-flex">
                                <span class="d-block text-truncate mw">
                                  Birthday
                                </span>
                                <span *ngIf="user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                              </h5>
                             
                              <span *ngIf="(user.birth_msg == 'today' || user.birth_msg == 'yesterday') && user.name != ''">
                                It's <span class="font-weight-bold" href="javascript:void(0);" (click)="searchVisitUserNameClick(user)">
                                  {{user.name}}</span> birthday {{user.birth_msg}}.</span>

                              <span *ngIf="(user.birth_msg != 'today' || user.birth_msg != 'yesterday') && user.name != ''">
                                It's <span class="font-weight-bold" href="javascript:void(0);" (click)="searchVisitUserNameClick(user)">
                                  {{user.name}}</span> birthday on {{user.birth_msg}}.</span>
                              <span class="text-limit" *ngIf="user.post_text && user.post_text != '' && user.post_text != null" [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                <span>
                                  - "<span [innerHTML]="user.post_text | safeHtmlPipe"></span>"
                                </span>
                              </span>
                            </span>
                            <span class="time">{{ user.created_at }}</span>
                          </div>
                        </a>
                        <!-- ///////////////////////28/10/2021(s)///////////////////// -->

                        <!-- circle post -->
                        <a *ngIf="user.type == 'circlepost'" href="javascript:void(0);" (click)="readCirlePostNotification(i,user);">
                          <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          <div class="mail-contnet">
                            <span class="mail-desc" style="white-space: normal !important;">

                              <span class="d-inline text-muted" *ngIf="user.name != ''">
                                {{user.name}} has added you in his post.
                              </span>

                              <span class="text-limit" *ngIf="user.post_text && user.post_text != '' && user.post_text != null" [ngClass]="{'min-height': user.post_text && user.post_text.includes('emoji-pipe-image')}">
                                <span>
                                  <span *ngIf="user.read_status == 0" [innerHTML]="user.post_text | safeHtmlPipe"></span>
                                  <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);" (click)="postViewDetailFunCircle(user)" *ngIf="user.read_status == 1">
                                    <span [innerHTML]="user.post_text | safeHtmlPipe"></span>
                                  </a>
                                </span>
                            
                              </span>
                            </span>

                            <span class="float-right mb-2" *ngIf="user.read_status == 0">
                              <button class="btn btn-sm btn-info bg-dark-blue" (click)="acceptPostRequestOpenModal(acceptPostRequest,user,i)" placement="top" ngbTooltip="Accept">
                                <span class="mdi mdi-check"></span>
                              </button>
                              <button (click)="rejectPostRequestOpenModal(rejectConnectRequest,user)" class="btn btn-sm btn-light bg-white ml-2" placement="top" ngbTooltip="Reject">
                                <span class="mdi mdi-close"></span>
                              </button>
                            </span>
                            <span class="time">{{ user.created_at }}</span>
                          </div>
                        </a>
                        <!-- circle post-->

                        <!-- Live Match -->
                        <a *ngIf="user.type == 'liveMatch'" href="javascript:void(0);" (click)="readNotification(i,user);sendOnLiveMatch()">
                          <span>
                            <img alt="loading..."    *ngIf="user.profile_pic != ''" src="{{ user.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="user.profile_pic == ''" src="{{ user_profile_img }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="40" />
                          </span>
                          <div class="mail-contnet">
                            <span class="mail-desc" style="white-space: normal !important;">
                              <h5 class="d-block text-truncate mw" (click)="searchUserNameClick(user)">{{user.name}}
                                <span *ngIf="user.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                              </h5>
                              Please click here to see today's event schedule for your followed teams.
                            </span>
                            <span class="time">{{ user.created_at }}</span>
                          </div>
                        </a>
                        <!-- Live Match -->
                      </div>
                      <div class="text-center" *ngIf="primaryLoader">
                        <div *ngFor="let e of [].constructor(1)" class="d-flex align-items-center px-2 mt-2">
                          <div>
                            <h5 class="mt-0 d-flex align-items-start">
                              <span class="shared-dom">
                                <div class="sub-rect-connection pure-background"></div>
                              </span>
                            </h5>
                          </div>

                          <div class="w-75 ml-2">
                            <h5 class="mt-0 d-flex align-items-start w-75">
                              <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                <div class="background-masker btn-divide-left"></div>
                              </span>
                            </h5>
                            <h5 class="mt-0 d-flex align-items-start w-50">
                              <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                <div class="background-masker btn-divide-left"></div>
                              </span>
                            </h5>
                            <h5 class="mt-0 d-flex align-items-start w-25">
                              <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                <div class="background-masker btn-divide-left"></div>
                              </span>
                            </h5>
                          </div>
                        </div>
                        
                      </div>
                      <div ngbDropdownItem class="text-center position-relative" *ngIf="showMoreUserList">
                        <a href="javascript:void(0);" (click)="
                            $event.stopPropagation();
                            myDrop.open();
                            showMoreUserListByName(nextPage)
                          " class="read-more" placement="top">
                          <span class="mdi mdi-chevron-double-down">Show More</span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li *ngIf="getUserNameArr.length == 0" class="text-center">
                    <div class="text-center" *ngIf="notDataAvailableLoader">
                      <div *ngFor="let e of [].constructor(3)" class="d-flex align-items-center px-2 mt-2">
                        <div>
                          <h5 class="mt-0 d-flex align-items-start">
                            <span class="shared-dom">
                              <div class="sub-rect-connection pure-background"></div>
                            </span>
                          </h5>
                        </div>

                        <div class="w-75 ml-2">
                          <h5 class="mt-0 d-flex align-items-start w-75">
                            <span class="flex-fill animated-background-name first-loader-name-news m-0">
                              <div class="background-masker btn-divide-left"></div>
                            </span>
                          </h5>
                          <h5 class="mt-0 d-flex align-items-start w-50">
                            <span class="flex-fill animated-background-name first-loader-name-news m-0">
                              <div class="background-masker btn-divide-left"></div>
                            </span>
                          </h5>
                          <h5 class="mt-0 d-flex align-items-start w-25">
                            <span class="flex-fill animated-background-name first-loader-name-news m-0">
                              <div class="background-masker btn-divide-left"></div>
                            </span>
                          </h5>
                        </div>
                      </div>
                    
                    </div>
                    <div *ngIf="!notDataAvailableLoader">
                      <h5 class="mt-2">
                        <strong>No Notifications available!</strong>
                      </h5>
                      <p>You will get notified here if any activity happens</p>
                    </div>
                  </li>
                  <li *ngIf="getUserNameArr.length > 0">
                    <a class="text-center d-block bg-light p-2 bg-gray" href="javascript:void(0);" (click)="notificationDetialsPage()"><strong style="color: #fff;">Check
                        all Notifications</strong></a>
                  </li>
                </ul>
              </div>
            </li>


            <li class="nav-item new-new-item" (click)="changeActiveTab('igtai');igtAiFuc();" [ngClass]="{ active: activeTab == 'igtai' }">
              <a class="nav-link waves-effect waves-dark notification-icon" href="javascript:void(0)" placement="bottom">
                <img alt="loading..."    src="assets/icons/sports-icon/IGT-AI-Icon-Blue.svg" alt="IGT AI" width="30" />
                <small [ngClass]="{ 'font-weight-bold': activeTab == 'igtai' }" class="lable-show truncate-name mt-1">IGT AI</small>
              </a>
            </li>

            <li id="allSportsTabMob" class="nav-item new-new-item" [ngClass]="{ active: activeTab == 'allSports' }">
              <a class="nav-link waves-effect waves-dark notification-icon" href="javascript:void(0)" placement="bottom" (click)="GoToAllSports('Main'); changeActiveTab('allSports')">
               
                <img alt="loading..."    src="assets/icons/sports-icon/All-Sports-Icon-Blue.svg" alt="Sport" width="24" />
                
                <small class="lable-show mt-1" [ngClass]="{ 'font-weight-bold': activeTab == 'allSports' }">All&nbsp;Sports</small>
              </a>
            </li>


            
            <li (click)="messageIconClick();changeActiveTab('Message');autoClose()" class="nav-item new-new-item dropdown" ngbDropdown #myDrop1="ngbDropdown" placement="bottom-right" [ngClass]="{ active: activeTab == 'Message' }">
              <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark notification-icon" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" placement="bottom">
            
                <img alt="loading..."    src="assets/icons/sports-icon/Messages-Icon-Blue.svg" width="24" alt="message" />
               
                <small class="lable-show mt-1" [ngClass]="{ 'font-weight-bold': activeTab == 'Message' }">Messages</small>
                <span class="badge badge-danger badge-pill notify-count" *ngIf="message_notification_count > 0">
                  {{ message_notification_count }}
                </span>
              </a>
              <div class="dropdown-menu dropdown-menu-left mailbox fadeIn custom_message" ngbDropdownMenu>
                <ul>
                  <li>
                    <div class="drop-title">
                      Messages
                      <a href="javascript:void(0);" (click)="newChatMessageRedirect()" class="float-right ml-2 text-white" placement="top" ngbTooltip="New message">
                        <i class="ti-pencil-alt"></i></a>
                      <a *ngIf="getChatMessageArr.length > 0" (click)="markAllAsReadMessage()" href="javascript:void(0);" class="float-right text-white">
                        Mark all as read
                      </a>
                    </div>
                  </li>

                  <!-- Message -->
                  <li *ngIf="getChatMessageArr.length > 0">
                    <div class="message-center" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false">
                      <div *ngFor="let chatMessage of getChatMessageArr" [ngClass]="{ 'bg-read': chatMessage.status == '0' }">
                        <a (click)="clickUserMessageFun(chatMessage);autoClose()" href="javascript:void(0);">
                          <span>
                            <img alt="loading..."    *ngIf="chatMessage.profile_pic != ''" src="{{ chatMessage.profile_pic }}" class="img-circle" alt="..." width="40" />
                            <img alt="loading..."    *ngIf="chatMessage.profile_pic == ''" src="{{ user_profile_img }}" class="img-circle" alt="..." width="40" />
                            <span class="profile-status online pull-right"></span>
                          </span>
                          <div class="mail-contnet">
                            <h5 class="d-flex name_display_limit mw">
                              {{ chatMessage.name }}
                              <span *ngIf="chatMessage.verified_badge == 1" class="{{badgeClass}} pl-1 text-info"></span>
                              <b *ngIf="chatMessage.notification_count > 0 && chatMessage.notification_count <= 100" class="text-info">&nbsp;({{chatMessage.notification_count}})</b>
                              <b *ngIf="chatMessage.notification_count > 100" class="text-info">&nbsp;({{chatMessage.notification_count}}+)</b>
                            </h5>
                            <span *ngIf="chatMessage.post_id == '0'">
                              <span class="d-block text-muted text-limit not-active" *ngIf="chatMessage.type == 0">
                                -"<span [innerHTML]="chatMessage.message | safeHtmlPipe"></span>"</span>
                              <span class="d-block text-muted text-limit" *ngIf="chatMessage.type == 1">
                                <span class="mdi mdi-attachment mdi-rotate-315 d-inline-block"></span>
                                <span *ngIf="chatMessage.mime_type.includes('image/')">Media</span>
                                <span *ngIf="chatMessage.mime_type.includes('video/')">Media</span>
                                <span *ngIf="chatMessage.mime_type.includes('audio/')">Audio</span>
                                <span *ngIf="
                                    chatMessage.mime_type.includes('application/')
                                  ">File</span>
                              </span>
                            </span>
                            <span class="d-block text-muted text-limit" *ngIf="chatMessage.post_id != '0' && chatMessage.type =='2'">
                              Shared a post with you.
                            </span>
                            <span class="d-block text-muted text-limit" *ngIf="chatMessage.post_id != '0' && chatMessage.type =='3'">
                              Shared a news with you.
                            </span>
                            <span class="d-block text-muted text-limit" *ngIf="chatMessage.post_id != '0' && chatMessage.type =='4'">
                              Shared a blog with you.
                            </span>
                            <span class="d-block text-muted text-limit" *ngIf="chatMessage.post_id == '0' && chatMessage.type =='5'">
                              <span class="d-block text-muted text-limit not-active" *ngIf="chatMessage.type == '5' && chatMessage.url_link!=''">
                                -"<span [innerHTML]="chatMessage.url_link"></span>"</span>
                            </span>
                            <span class="time">{{
                              chatMessage.created_at
                              }}</span>
                           
                          </div>
                        </a>
                      </div>
                      <div class="text-center" *ngIf="getChatMessageLoader">
                        <div *ngFor="let e of [].constructor(1)" class="d-flex align-items-center px-2 mt-2">
                          <div>
                            <h5 class="mt-0 d-flex align-items-start">
                              <span class="shared-dom">
                                <div class="sub-rect-connection pure-background"></div>
                              </span>
                            </h5>
                          </div>

                          <div class="w-75 ml-2">
                            <h5 class="mt-0 d-flex align-items-start w-75">
                              <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                <div class="background-masker btn-divide-left"></div>
                              </span>
                            </h5>
                            <h5 class="mt-0 d-flex align-items-start w-50">
                              <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                <div class="background-masker btn-divide-left"></div>
                              </span>
                            </h5>
                            <h5 class="mt-0 d-flex align-items-start w-25">
                              <span class="flex-fill animated-background-name first-loader-name-news m-0">
                                <div class="background-masker btn-divide-left"></div>
                              </span>
                            </h5>
                          </div>
                        </div>
                      
                      </div>
                      <div ngbDropdownItem class="text-center position-relative" *ngIf="showMoreMessageList">
                        <a href="javascript:void(0);" (click)="
                            $event.stopPropagation();
                            myDrop1.open();
                            showMoreMessageNotificationList(nextPageMessageList)
                          " class="read-more" placement="top">
                          <span class="mdi mdi-chevron-double-down">Show More</span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li *ngIf="getChatMessageArr.length == 0" class="text-center">
                    <div class="text-center" *ngIf="getChatMessageNoDataLoader">
                      <div *ngFor="let e of [].constructor(3)" class="d-flex align-items-center px-2 mt-2">
                        <div>
                          <h5 class="mt-0 d-flex align-items-start">
                            <span class="shared-dom">
                              <div class="sub-rect-connection pure-background"></div>
                            </span>
                          </h5>
                        </div>

                        <div class="w-75 ml-2">
                          <h5 class="mt-0 d-flex align-items-start w-75">
                            <span class="flex-fill animated-background-name first-loader-name-news m-0">
                              <div class="background-masker btn-divide-left"></div>
                            </span>
                          </h5>
                          <h5 class="mt-0 d-flex align-items-start w-50">
                            <span class="flex-fill animated-background-name first-loader-name-news m-0">
                              <div class="background-masker btn-divide-left"></div>
                            </span>
                          </h5>
                          <h5 class="mt-0 d-flex align-items-start w-25">
                            <span class="flex-fill animated-background-name first-loader-name-news m-0">
                              <div class="background-masker btn-divide-left"></div>
                            </span>
                          </h5>
                        </div>
                      </div>
                     
                    </div>
                    <div *ngIf="!getChatMessageNoDataLoader">
                      <h5 class="mt-2">
                        <strong>No Messages found!</strong>
                      </h5>
                      <p>
                        You will get a ping here if anyone sends a message to you
                      </p>
                    </div>
                  </li>
                  <!-- Message -->
                  <li (click)="openMessageWindow();autoClose()" *ngIf="getChatMessageArr.length > 0">
                    <a class="text-center d-block bg-light p-2 message-footer" href="javascript:void(0);">
                      <strong>See All Messages</strong>
                    </a>
                  </li>
                  <li (click)="openMessageWindow();autoClose()" *ngIf="getChatMessageArr.length == 0">
                    <a class="text-center d-block bg-light p-2 message-footer" href="javascript:void(0);"><strong>See
                        all
                        Messages</strong></a>
                  </li>
                </ul>
              </div>
            </li>

           

            <li class="nav-item new-new-item" [ngClass]="{ active: activeTab == 'News' }">
              <a class="nav-link waves-effect waves-dark notification-icon" href="javascript:void(0)" placement="bottom" (click)="changeActiveTab('News');newsListRedirectPage()">
                
                <img alt="loading..."    src="assets/images/svg_patriot/News_sm.png" width="34" alt="news" />
             
                <small class="lable-show mt-1" [ngClass]="{ 'font-weight-bold': activeTab == 'News' }">News</small>
              </a>
            </li>

          </ul>
          <!-- mobile view ends -->
        </div>
      </div>
    </div>
  </nav>
  <!-- mobile/tab nav start -->

  <!-- chat window nav start -->
  <div *ngIf="isChatPage" class="header">
    <nav class="navbar navbar-light topbar fixed-top shadow-sm" aria-label="">
      <div class="container p-0 d-flex justify-content-around">
        <a class="navbar-brand" href="javascript:void(0);">
          <img alt="loading..."    src="{{platform_logo}}" alt="{{platform_name}}" width="100" />
        </a>
      </div>
    </nav>
  </div>
  <!-- chat window nav end -->
</header>
<!-- End Normal header-->
<!-- Contact us deactivated user header-->
<header class="topbar" *ngIf="status == 2">
  <nav class="navbar top-navbar navbar-expand-md navbar-light p-md-0" aria-label="">
    <div class="container pt-0 pb-0">
      <div class="navbar-header w-100 d-flex justify-content-between pb-3 pb-md-0">
        <a class="navbar-brand" routerLink="/login" href="javascript:void(0);">
          <img alt="loading..."    src="{{platform_logo}}" class="img-fluid" alt="..." width="160">
        </a>
        <ul class="navbar-nav my-lg-0 notification-icon d-lg-flex">
          <li class="nav-item dropdown" ngbDropdown placement="bottom-right" [ngClass]="{'active': activeTab=='Settings'}">
            <a ngbDropdownToggle class="nav-link waves-effect waves-dark notification-icon pt-0 pt-sm-2" href="javascript:void(0)" placement="bottom" ngbTooltip="Settings">
          
              <img alt="loading..."    src="assets/images/svg_patriot/Settings_sm.png" width="24" alt="settings" />
              <small class="lable-show text-white">Settings</small>
            </a>
            <div class="dropdown-menu animated fadeIn" ngbDropdownMenu>
              <ul class="dropdown-user">
                <li><a href="javascript:void(0);" (click)="logout()"><i class="fa fa-power-off"></i> Logout</a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
<!-- End Contact us deactivated user header-->

<!-- Logout header start -->
<!-- <div class="header" *ngIf="status == 3">
  <nav class="navbar navbar-light topbar fixed-top shadow-sm px-2" aria-label="">
    <div class="container p-0">
      <a class="navbar-brand mr-0" href="#">
        <img alt="loading..."    src="{{platform_logo}}" alt="{{platform_name}}" width="160" routerLink="/" />
      </a>
      <div class="d-flex justify-align-center float-right">
        <button routerLink="/login" class="btn btn-info btn-sm mr-2" type="button">
          Join Now / Sign In
        </button>
      </div>
    </div>
  </nav>
</div> -->
<app-outer-header-navigation *ngIf="status == 3"></app-outer-header-navigation>

<!-- Logout header end-->

<ng-template #inviteUser>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Invite your friends to {{ platform_name }}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeInvite()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" (change)="changeInviteRadio('phone')" id="inlineRadio2" [(ngModel)]="inviteUserArr.type" value="phone" />
            <label class="form-check-label" for="inlineRadio2">By Phone Number</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" (change)="changeInviteRadio('email')" id="inlineRadio1" [(ngModel)]="inviteUserArr.type" value="email" />
            <label class="form-check-label" for="inlineRadio1">By Email</label>
          </div>
        </div>
      </div>
      <div class="w-100" *ngIf="inviteUserArr.type == 'phone'">
        <div class="col-12">
          <form [formGroup]="inviteByPhone">
            <label for=""><strong>By phone number – </strong>Please enter the phone number and click the "Invite"
              button.
            </label>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <ng-select [items]="countryFlags" formControlName="countryCode" [(ngModel)]="phoneCode" appendTo="body" bindLabel="name" class="mt-2" placeholder="Select Country" dropdownPosition="auto" [virtualScroll]="true">
                    <ng-template ng-label-tmp let-item="item">
                      <img alt="loading..."    height="15" width="20" [src]="item.flag" />
                      <b> {{ item.name }}</b> {{ item.code }}
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      <img alt="loading..."    height="15" width="20" [src]="item.flag" />
                      <b> {{ item.name }}</b> {{ item.code }}
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div *ngIf="!isDeviceSupported" class="col-md-9">
                <div class="form-group">
                  <input type="tel" formControlName="phone" class="form-control shadow-sm mt-2" placeholder="Enter Phone Number" [ngClass]="{'is-invalid': submittedPhone && f1.phone.errors}" maxlength="16" (input)="onKeyPhoneNumberCheck($event)" (keydown.enter)="phoneInvite(inviteUserArr)" autocomplete="off" />

                  <div *ngIf="submittedPhone && f1.phone.errors" class="invalid-feedback">
                    <div *ngIf="f1.phone.errors.required">
                      Phone Number is required
                    </div>
                    <div *ngIf="f1.phone.errors.minlength">
                      Phone Number must be at least 6 characters
                    </div>
                    <div *ngIf="f1.phone.errors.maxlength">
                      Phone Number must be at most 16 characters
                    </div>
                    <div *ngIf="f1.phone.errors.pattern">
                      Phone Number with 0 at start not allowed
                    </div>
                  </div>
                  <span *ngIf="UserInputPhoneCheck" class="textNotAllowed">{{UserInputPhoneCheck}}</span>
                </div>
              </div>
              <div *ngIf="isDeviceSupported" class="col-md-9">
                <div class="form-group position-relative">
                  <input type="tel" formControlName="phone" class="form-control shadow-sm mt-2" placeholder="Enter Phone Number" [ngClass]="{'is-invalid': submittedPhone && f1.phone.errors}" maxlength="16" (input)="onKeyPhoneNumberCheck($event)" [(ngModel)]="selectPhoneNumber" (keydown.enter)="phoneInvite(inviteUserArr)" autocomplete="off" />
                  <a class="text-info" href="javascript:void(0);" (click)="getPhoneContactListOpenModal(isDeviceSupportedOpenModal)"><span class="mdi mdi-contacts mdi-18px phoneBook mt-1"></span></a>
                  <div *ngIf="submittedPhone && f1.phone.errors" class="invalid-feedback">
                    <div *ngIf="f1.phone.errors.required">
                      Phone Number is required
                    </div>
                    <div *ngIf="f1.phone.errors.minlength">
                      Phone Number must be at least 6 characters
                    </div>
                    <div *ngIf="f1.phone.errors.maxlength">
                      Phone Number must be at most 16 characters
                    </div>
                    <div *ngIf="f1.phone.errors.pattern">
                      Phone Number with 0 at start not allowed
                    </div>
                  </div>
                  <span *ngIf="UserInputPhoneCheck" class="textNotAllowed">{{UserInputPhoneCheck}}</span>
                  <div class="modal-body text-center" *ngIf="multiContactArr.length>0">
                    <ul class="list-unstyled qa-list">
                      <li class="report-hover" *ngFor="let contact of multiContactArr;let key=index">
                        <a href="javascript:void(0);" (click)="selectContact(contact);">
                          <h4><span class="mdi mdi-cellphone-android text-info ml-2"></span> {{contact}}
                           
                          </h4>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="w-100" *ngIf="inviteUserArr.type == 'email'">
        <div class="col-12">
          <form [formGroup]="inviteByEmail">
            <div class="form-group">
              <label for="exampleInputEmail1"><strong>By email address – </strong>Please enter the email address and
                click the "Invite" button.</label>
              <input type="email" formControlName="email" class="form-control shadow-sm mt-2" placeholder="Enter Email Address" [ngClass]="{'is-invalid': submitted && f.email.errors}" (input)="inviteEmailCheck($event)" (keydown.enter)="emailInvite(inviteUserArr)" maxlength="200" />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">
                  Email is required
                </div>
                <div *ngIf="f.email.errors.email || f.email.errors.pattern">
                  Email must be a valid email address
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="border-top border-light py-2 text-right">
      <button type="button" class="btn btn-info btn-sm invite-button" *ngIf="inviteUserArr.type == 'email'" (click)="emailInvite(inviteUserArr)">
        Invite
      </button>
      <button type="button" class="btn btn-info btn-sm invite-button" *ngIf="inviteUserArr.type == 'phone'" (click)="phoneInvite(inviteUserArr)">
        Invite
      </button>
      <button type="button" class="btn btn-light btn-sm ml-2 invite-button" (click)="modalRef.hide()">
        Cancel
      </button>
    </div>
  </div>
</ng-template>

<!-- Redirect open modal to edit profile for the set the stealth/alias name(D) -->
<ng-template #aliasNameBlank>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Complete your Profile Info</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <h6>{{alias_mode_name}} Name is required to access this timeline.</h6>
    <button type="button" class="btn btn-info btn-sm" (click)="redirectEditProfile();modalRef.hide();">Go to Edit
      Profile</button>
    <button type="button" class="btn btn-light btn-sm ml-2" (click)="decline()">Cancel</button>
  </div>
</ng-template>
<!-- End Redirect open modal to edit profile for the set the stealth/alias name(D) -->

<ng-template #acceptConnectRequest>
  <div class="modal-body text-center">
    <h5 class="text-info">Confirm Request?</h5>
    <div class="form-group form-check my-3">
      <label class="form-check-label" for="exampleCheck1">Do you want to confirm the request from <strong class="truncate_post_url">{{userName}} ?</strong></label>
    </div>
    <button type="button" class="btn btn-info btn-sm" [disabled]="acceptConnectRequestClicked" (click)="confirmConnectRequest()">Confirm</button>
    <button type="button" class="btn btn-light btn-sm ml-2" [disabled]="acceptConnectRequestClicked" (click)="cancelAcceptRequest()">Cancel</button>
  </div>
  <div class="text-center p-2" *ngIf="acceptConnectRequestClicked">
    <div class="spinner-border text-info" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #rejectConnectRequest>
  <div class="modal-body text-center">
    <h5 class="text-info">Reject Request?</h5>
    <div class="form-group form-check my-3">
      <label class="form-check-label" for="exampleCheck1">Do you want to reject the request <strong class="truncate_post_url">{{userName}} ?</strong></label>
    </div>
    <button type="button" class="btn btn-info btn-sm" [disabled]="rejectConnectRequestClicked" (click)="confirmOrRejectConnectRequest()">Confirm</button>
    <button type="button" class="btn btn-light btn-sm ml-2" [disabled]="rejectConnectRequestClicked" (click)="cancelRejectRequest()">Cancel</button>
  </div>
  <div class="text-center p-2" *ngIf="rejectConnectRequestClicked">
    <div class="spinner-border text-info" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #follow>
  <div class="modal-body text-center">
    <p>Do you want to follow <strong class="truncate_post_url">{{userName}} ?</strong></p>
    <button type="button" class="btn btn-info btn-sm" [disabled]="isFollowBackclicked" (click)="followUserConfirm();modalRef.hide();">Follow</button>
    <button type="button" class="btn btn-light btn-sm ml-2" [disabled]="isFollowBackclicked" (click)="followUserCancel()">Cancel</button>
  </div>
  <div class="text-center p-2" *ngIf="isFollowBackclicked">
    <div class="spinner-border text-info" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #isDeviceSupportedOpenModal>
  <div class="modal-body text-center">
    <h5 class="text-info">Contact pick</h5>
    <div class="m-1 p-1">
      <label>Your device or browsers not supported for contact pick</label>
    </div>
    <button type="button" class="btn btn-info btn-sm" (click)="modalRef1.hide()">Close</button>
  </div>
</ng-template>

<ng-template #acceptPostRequest>
  <div class="modal-body text-center">
    <h5 class="text-info">Confirm Post?</h5>
    <div class="form-group form-check my-3">
      <label class="form-check-label" for="exampleCheck1">Do you want to confirm the post from <strong class="truncate_post_url">{{userName}} ?</strong></label>
    </div>
    <button type="button" class="btn btn-info btn-sm" [disabled]="acceptPostRequestClicked" (click)="confirmPostRequest()">Confirm</button>
    <button type="button" class="btn btn-light btn-sm ml-2" [disabled]="acceptPostRequestClicked" (click)="cancelPostAcceptRequest()">Cancel</button>
  </div>
  <div class="text-center p-2" *ngIf="acceptPostRequestClicked">
    <div class="spinner-border text-info" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #rejectPostRequest>
  <div class="modal-body text-center">
    <h5 class="text-info">Reject Request?</h5>
    <div class="form-group form-check my-3">
      <label class="form-check-label" for="exampleCheck1">Do you want to reject the post <strong class="truncate_post_url">{{userName}} ?</strong></label>
    </div>
    <button type="button" class="btn btn-info btn-sm" [disabled]="rejectPostRequestClicked" (click)="confirmOrRejectPostRequest()">Confirm</button>
    <button type="button" class="btn btn-light btn-sm ml-2" [disabled]="rejectPostRequestClicked" (click)="cancelPostRejectRequest()">Cancel</button>
  </div>
  <div class="text-center p-2" *ngIf="rejectPostRequestClicked">
    <div class="spinner-border text-info" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
<ng-template #scores>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Scores
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closescores()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0">
    <ul *ngIf="leaguesList.length > 0" class="list-unstyled notMes">

      <div>
        <div style="cursor: pointer;">
          <li class="px-4" href="javascript:void(0)" (click)="searchTeamLeagueNameClick('all')">
            <img alt="loading..."    src="assets/images/icon/My-Leagues-Icon.svg" class="img-fluid rounded-circle img-mh leauge-icon" alt="..." width="20" /><span class="ml-2">All leagues</span>

            <span *ngIf="totalMatchCount && totalMatchCount != 0" class="pull-right ml-1 mt-2 label nfl-badge">{{totalMatchCount}}</span>

          </li>
        </div>
      </div>

      <h4 class="bg-dark-blue fs-14 text-center py-1 px-3">Followed Leagues</h4>
      <div *ngIf="followedLeagues.length > 0">
        <div style="cursor: pointer;" *ngFor="let league of followedLeagues; let i = index;">
          <li class="px-4" href="javascript:void(0)" (click)="searchTeamLeagueNameClick(league)">
            <img alt="loading..."    *ngIf="league.profile_pic != ''" src="{{ league.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="25" /><span class="mw-name"> {{league.name}}</span>

            <span *ngIf="league.score_count && league.score_count != null" class="pull-right ml-1 label nfl-badge">{{league.score_count}}</span>

            <span *ngIf="league.live_score && league.live_score > 0" class="pull-right label nfl-badge">Live</span>


          </li>
        </div>
      </div>
      <li class="mx-2" *ngIf="followedLeagues.length == 0"> You Haven't Followed Any League </li>

      <h4 *ngIf="unfollowedLeagues.length > 0" class="bg-dark-blue fs-14 text-center  sticky-top py-1 px-3">Other
        Leagues</h4>
      <div *ngIf="unfollowedLeagues.length > 0">
        <div style="cursor: pointer;" *ngFor="let league of unfollowedLeagues; let i = index;">
          <li class="px-4" href="javascript:void(0)" (click)="searchTeamLeagueNameClick(league)">
            <img alt="loading..."    *ngIf="league.profile_pic != ''" src="{{ league.profile_pic }}" class="img-fluid rounded-circle img-mh" onError="this.onerror=null; this.src='assets/images/default-image-sports.png';" alt="..." width="25" />
            <span class="mw-name"> {{league.name}}</span>
            <span *ngIf="league.score_count && league.score_count != null" class="pull-right ml-1 label nfl-badge">{{league.score_count}}</span>

            <span *ngIf="league.live_score && league.live_score > 0" class="pull-right label nfl-badge">Live</span>


          </li>
        </div>
      </div>
    </ul>
    <div class="text-center pt-1 py-4" *ngIf="leaguesLoader">
      <div class="p-2" *ngFor="let e of [].constructor(3);">
        <h5 class="mt-0 d-flex align-items-start mx-2">
          <span class="shared-dom">
            <div class="sub-rect-connection pure-background"></div>
          </span>
          <span class="flex-fill animated-background-name first-loader-name mt-2">
            <div class="background-masker btn-divide-left"></div>
          </span>
        </h5>
      </div>
   
    </div>
  </div>
</ng-template>

<ng-template #getMobileApp>
  <div class="modal-header">
      <h4 class="modal-title pull-left">
          Get Mobile App 
      </h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeInvite()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="getMobileApp-modal-body">
      <a  target="_blank" href="{{appleStoreAppShortcut}}" >
          <img src="assets/getMobileApp/appleStoreImage.webp" alt="AppleStore..." >
      </a>

      <a target="_blank" href="{{playStoreAppShortcut}}" class="my-3">
          <img src="assets/getMobileApp/playStoreImage.webp" alt="PlayStore...">
      </a>

      <a  target="_blank" href="{{web_shortcut}}" >
          <img src="assets/getMobileApp/createShortcut2.png" alt="CreateShortcut...">
      </a>
  </div>
</ng-template>



<ngx-guided-tour skipText="Skip x"></ngx-guided-tour>