import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountryFlagService {

  getCountryCode() {
    return [
      {
        name: "USA",
        flag: "assets/images/flag/Flag_of_the_United_States.svg",
        code: "+1"
      },
      {
        name: "United States",
        flag: "assets/images/flag/Flag_of_the_United_States.svg",
        code: "+1"
      },
      {
        name: "Afghanistan",
        flag: "assets/images/flag/Flag_of_Afghanistan.svg",
        code: "+93"
      },
      {
        name: "Albania",
        flag: "assets/images/flag/Flag_of_Albania.svg",
        code: "+355"
      },
      {
        name: "Algeria",
        flag: "assets/images/flag/Flag_of_Algeria.svg",
        code: "+213"
      },
      {
        name: "American Samoa",
        flag: "assets/images/flag/Flag_of_American_Samoa.svg",
        code: "+1 684"
      },
      {
        name: "Andorra",
        flag: "assets/images/flag/Flag_of_Andorra.svg",
        code: "+376"
      },
      {
        name: "Angola",
        flag: "assets/images/flag/Flag_of_Angola.svg",
        code: "+244"
      },
      {
        name: "Anguilla",
        flag: "assets/images/flag/Flag_of_Anguilla.svg",
        code: "+1 264"
      },
      {
        name: "Antigua and Barbuda",
        flag: "assets/images/flag/Flag_of_Antigua_and_Barbuda.svg",
        code: "+1268"
      },
      {
        name: "Argentina",
        flag: "assets/images/flag/Flag_of_Argentina.svg",
        code: "+54"
      },
      {
        name: "Armenia",
        flag: "assets/images/flag/Flag_of_Armenia.svg",
        code: "+374"
      },
      {
        name: "Aruba",
        flag: "assets/images/flag/Flag_of_Aruba.svg",
        code: "+297"
      },
      {
        name: "Australia",
        flag: "assets/images/flag/Flag_of_Australia_%28converted%29.svg",
        code: "+61"
      },
      {
        name: "Austria",
        flag: "assets/images/flag/Flag_of_Austria.svg",
        code: "+43"
      },
      {
        name: "Azerbaijan",
        flag: "assets/images/flag/Flag_of_Azerbaijan.svg",
        code: "+994"
      },
      {
        name: "Bahamas",
        flag: "assets/images/flag/Flag_of_the_Bahamas.svg",
        code: "+1 242"
      },
      {
        name: "Bahrain",
        flag: "assets/images/flag/Flag_of_Bahrain.svg",
        code: "+973"
      },
      {
        name: "Bangladesh",
        flag: "assets/images/flag/Flag_of_Bangladesh.svg",
        code: "+880"
      },
      {
        name: "Barbados",
        flag: "assets/images/flag/Flag_of_Barbados.svg",
        code: "+1 246"
      },
      {
        name: "Belarus",
        flag: "assets/images/flag/Flag_of_Belarus.svg",
        code: "+375"
      },
      {
        name: "Belgium",
        flag: "assets/images/flag/Flag_of_Belgium.svg",
        code: "+32"
      },
      {
        name: "Belize",
        flag: "assets/images/flag/Flag_of_Belize.svg",
        code: "+501"
      },
      {
        name: "Benin",
        flag: "assets/images/flag/Flag_of_Benin.svg",
        code: "+229"
      },
      {
        name: "Bermuda",
        flag: "assets/images/flag/Flag_of_Bermuda.svg",
        code: "+1 441"
      },
      {
        name: "Bhutan",
        flag: "assets/images/flag/Flag_of_Bhutan.svg",
        code: "+975"
      },
      {
        name: "Bosnia and Herzegovina",
        flag: "assets/images/flag/Flag_of_Bosnia_and_Herzegovina.svg",
        code: "+387"
      },
      {
        name: "Botswana",
        flag: "assets/images/flag/Flag_of_Botswana.svg",
        code: "+267"
      },
      {
        name: "Bouvet Island",
        flag: "assets/images/flag/Flag_of_Norway.svg",
        code: "+55"
      },
      {
        name: "Brazil",
        flag: "assets/images/flag/Flag_of_Brazil.svg",
        code: "+55"
      },
      {
        name: "British Indian Ocean Territory",
        flag: "assets/images/flag/Flag_of_the_British_Indian_Ocean_Territory.svg",
        code: "+246"
      },
      {
        name: "Brunei",
        flag: "assets/images/flag/Flag_of_Brunei.svg",
        code: "+673"
      },
      {
        name: "Bulgaria",
        flag: "assets/images/flag/Flag_of_Bulgaria.svg",
        code: "+359"
      },
      {
        name: "Burkina Faso",
        flag: "assets/images/flag/Flag_of_Burkina_Faso.svg",
        code: "+226"
      },
      {
        name: "Burundi",
        flag: "assets/images/flag/Flag_of_Burundi.svg",
        code: "+257"
      },
      {
        name: "Cambodia",
        flag: "assets/images/flag/Flag_of_Cambodia.svg",
        code: "+855"
      },
      {
        name: "Cameroon",
        flag: "assets/images/flag/Flag_of_Cameroon.svg",
        code: "+237"
      },
      {
        name: "Canada",
        flag: "assets/images/flag/Flag_of_Canada.svg",
        code: "+1"
      },
      {
        name: "Cape Verde",
        flag: "assets/images/flag/Flag_of_Cape_Verde.svg",
        code: "+238"
      },
      {
        name: "Cayman Islands",
        flag: "assets/images/flag/Flag_of_the_Cayman_Islands.svg",
        code: "+1345"
      },
      {
        name: "Central African Republic",
        flag: "assets/images/flag/Flag_of_the_Central_African_Republic.svg",
        code: "+236"
      },
      {
        name: "Chad",
        flag: "assets/images/flag/Flag_of_Chad.svg",
        code: "+235"
      },
      {
        name: "Chile",
        flag: "assets/images/flag/Flag_of_Chile.svg",
        code: "+56"
      },
      {
        name: "China",
        flag: "assets/images/flag/Flag_of_the_People%27s_Republic_of_China.svg",
        code: "+86"
      },
      {
        name: "Christmas Island",
        flag: "assets/images/flag/Flag_of_Christmas_Island.svg",
        code: "+61"
      },
      {
        name: "Cocos (Keeling) Islands",
        flag: "assets/images/flag/Flag_of_the_Cocos_%28Keeling%29_Islands.svg",
        code: "+61"
      },
      {
        name: "Colombia",
        flag: "assets/images/flag/Flag_of_Colombia.svg",
        code: "+57"
      },
      {
        name: "Comoros",
        flag: "assets/images/flag/Flag_of_the_Comoros.svg",
        code: "+269"
      },
      {
        name: "Congo",
        flag: "assets/images/flag/Flag_of_the_Republic_of_the_Congo.svg",
        code: "+242"
      },
      {
        name: "Cook Islands",
        flag: "assets/images/flag/Flag_of_the_Cook_Islands.svg",
        code: "+682"
      },
      {
        name: "Costa Rica",
        flag: "assets/images/flag/Flag_of_Costa_Rica_%28state%29.svg",
        code: "+506"
      },
      {
        name: "Croatia",
        flag: "assets/images/flag/Flag_of_Croatia.svg",
        code: "+385"
      },
      {
        name: "Cuba",
        flag: "assets/images/flag/Flag_of_Cuba.svg",
        code: "+53"
      },
      {
        name: "Cyprus",
        flag: "assets/images/flag/Flag_of_Cyprus.svg",
        code: "+357"
      },
      {
        name: "Czech Republic",
        flag: "assets/images/flag/Flag_of_the_Czech_Republic.svg",
        code: "+420"
      },
      {
        name: "Denmark",
        flag: "assets/images/flag/Flag_of_Denmark.svg",
        code: "+45"
      },
      {
        name: "Djibouti",
        flag: "assets/images/flag/Flag_of_Djibouti.svg",
        code: "+253"
      },
      {
        name: "Dominica",
        flag: "assets/images/flag/Flag_of_Dominica.svg",
        code: "+1 767"
      },
      {
        name: "Dominican Republic",
        flag: "assets/images/flag/Flag_of_the_Dominican_Republic.svg",
        code: "+1 849"
      },
      {
        name: "Ecuador",
        flag: "assets/images/flag/Flag_of_Ecuador.svg",
        code: "+593"
      },
      {
        name: "Egypt",
        flag: "assets/images/flag/Flag_of_Egypt.svg",
        code: "+20"
      },
      {
        name: "El Salvador",
        flag: "assets/images/flag/Flag_of_El_Salvador.svg",
        code: "+503"
      },
      {
        name: "Equatorial Guinea",
        flag: "assets/images/flag/Flag_of_Equatorial_Guinea.svg",
        code: "+240"
      },
      {
        name: "Eritrea",
        flag: "assets/images/flag/Flag_of_Eritrea.svg",
        code: "+291"
      },
      {
        name: "Estonia",
        flag: "assets/images/flag/Flag_of_Estonia.svg",
        code: "+372"
      },
      {
        name: "Ethiopia",
        flag: "assets/images/flag/Flag_of_Ethiopia.svg",
        code: "+251"
      },
      {
        name: "Falkland Islands",
        flag: "assets/images/flag/Flag_of_the_Falkland_Islands.svg",
        code: "+500"
      },
      {
        name: "Faroe Islands",
        flag: "assets/images/flag/Flag_of_the_Faroe_Islands.svg",
        code: "+298"
      },
      {
        name: "Fiji Islands",
        flag: "assets/images/flag/Flag_of_Fiji.svg",
        code: "+679"
      },
      {
        name: "Fiji",
        flag: "assets/images/flag/Flag_of_Fiji.svg",
        code: "+679"
      },
      {
        name: "Finland",
        flag: "assets/images/flag/Flag_of_Finland.svg",
        code: "+358"
      },
      {
        name: "France",
        flag: "assets/images/flag/Flag_of_France.svg",
        code: "+33"
      },
      {
        name: "French Guiana",
        flag: "assets/images/flag/Flag_of_French_Guiana.svg",
        code: "+594"
      },
      {
        name: "French Polynesia",
        flag: "assets/images/flag/Flag_of_French_Polynesia.svg",
        code: "+689"
      },
      {
        name: "Gabon",
        flag: "assets/images/flag/Flag_of_Gabon.svg",
        code: "+241"
      },
      {
        name: "Gambia",
        flag: "assets/images/flag/Flag_of_The_Gambia.svg",
        code: "+220"
      },
      {
        name: "Georgia",
        flag: "assets/images/flag/Flag_of_Georgia.svg",
        code: "+995"
      },
      {
        name: "Germany",
        flag: "assets/images/flag/Flag_of_Germany.svg",
        code: "+49"
      },
      {
        name: "Ghana",
        flag: "assets/images/flag/Flag_of_Ghana.svg",
        code: "+233"
      },
      {
        name: "Gibraltar",
        flag: "assets/images/flag/Flag_of_Gibraltar.svg",
        code: "+350"
      },
      {
        name: "Greece",
        flag: "assets/images/flag/Flag_of_Greece.svg",
        code: "+30"
      },
      {
        name: "Greenland",
        flag: "assets/images/flag/Flag_of_Greenland.svg",
        code: "+299"
      },
      {
        name: "Grenada",
        flag: "assets/images/flag/Flag_of_Grenada.svg",
        code: "+1 473"
      },
      {
        name: "Guadeloupe",
        flag: "assets/images/flag/Flag_of_Guadeloupe.svg",
        code: "+590"
      },
      {
        name: "Guam",
        flag: "assets/images/flag/Flag_of_Guam.svg",
        code: "+1 671"
      },
      {
        name: "Guatemala",
        flag: "assets/images/flag/Flag_of_Guatemala.svg",
        code: "+502"
      },
      {
        name: "Guernsey",
        flag: "assets/images/flag/Flag_of_Guernsey.svg",
        code: "+44"
      },
      {
        name: "Guinea",
        flag: "assets/images/flag/Flag_of_Guinea.svg",
        code: "+224"
      },
      {
        name: "Guinea-Bissau",
        flag: "assets/images/flag/Flag_of_Guinea-Bissau.svg",
        code: "+245"
      },
      {
        name: "Guyana",
        flag: "assets/images/flag/Flag_of_Guyana.svg",
        code: "+592"
      },
      {
        name: "Haiti",
        flag: "assets/images/flag/Flag_of_Haiti.svg",
        code: "+509"
      },
      {
        name: "Heard and McDonald Islands",
        flag: "assets/images/flag/flag_of_Antarctica.svg",
        code: "+672"
      },
      {
        name: "Vatican City State (Holy See)",
        flag: "assets/images/flag/Flag_of_the_Vatican_City.svg",
        code: "+379"
      },
      {
        name: "Honduras",
        flag: "assets/images/flag/Flag_of_Honduras.svg",
        code: "+504"
      },
      {
        name: "Hong Kong",
        flag: "assets/images/flag/Flag_of_Hong_Kong.svg",
        code: "+852"
      },
      {
        name: "Hungary",
        flag: "assets/images/flag/Flag_of_Hungary.svg",
        code: "+36"
      },
      {
        name: "Iceland",
        flag: "assets/images/flag/Flag_of_Iceland.svg",
        code: "+354"
      },
      {
        name: "India",
        flag: "assets/images/flag/Flag_of_India.svg",
        code: "+91"
      },
      {
        name: "Indonesia",
        flag: "assets/images/flag/Flag_of_Indonesia.svg",
        code: "+62"
      },
      {
        name: "Iran",
        flag: " assets/images/flag/Flag_of_Iran.svg",
        code: "+98"
      },
      {
        name: "Iraq",
        flag: "assets/images/flag/Flag_of_Iraq.svg",
        code: "+964"
      },
      {
        name: "Ireland",
        flag: "assets/images/flag/Flag_of_Ireland.svg",
        code: "+353"
      },
      {
        name: "Isle of Man",
        flag: "assets/images/flag/Flag_of_the_Isle_of_Man.svg",
        code: "+44"
      },
      {
        name: "Israel",
        flag: "assets/images/flag/Flag_of_Israel.svg",
        code: "+972"
      },
      {
        name: "Italy",
        flag: "assets/images/flag/Flag_of_Italy.svg",
        code: "+39"
      },
      {
        name: "Jamaica",
        flag: "assets/images/flag/Flag_of_Jamaica.svg",
        code: "+1 876"
      },
      {
        name: "Japan",
        flag: "assets/images/flag/Flag_of_Japan.svg",
        code: "+81"
      },
      {
        name: "Jersey",
        flag: "assets/images/flag/Flag_of_Jersey.svg",
        code: "+44"
      },
      {
        name: "Jordan",
        flag: "assets/images/flag/Flag_of_Jordan.svg",
        code: "+962"
      },
      {
        name: "Kazakhstan",
        flag: "assets/images/flag/Flag_of_Kazakhstan.svg",
        code: "+7"
      },
      {
        name: "Kenya",
        flag: "assets/images/flag/Flag_of_Kenya.svg",
        code: "+254"
      },
      {
        name: "Kiribati",
        flag: "assets/images/flag/Flag_of_Kiribati.svg",
        code: "+686"
      },
      {
        name: "Kuwait",
        flag: "assets/images/flag/Flag_of_Kuwait.svg",
        code: "+965"
      },
      {
        name: "Kyrgyzstan",
        flag: "assets/images/flag/Flag_of_Kyrgyzstan.svg",
        code: "+996"
      },
      {
        name: "Laos",
        flag: "assets/images/flag/Flag_of_Laos.svg",
        code: "+856"
      },
      {
        name: "Latvia",
        flag: "assets/images/flag/Flag_of_Latvia.svg",
        code: "+371"
      },
      {
        name: "Lebanon",
        flag: "assets/images/flag/Flag_of_Lebanon.svg",
        code: "+961"
      },
      {
        name: "Lesotho",
        flag: "assets/images/flag/Flag_of_Lesotho.svg",
        code: "+266"
      },
      {
        name: "Liberia",
        flag: "assets/images/flag/Flag_of_Liberia.svg",
        code: "+231"
      },
      {
        name: "Liechtenstein",
        flag: "assets/images/flag/Flag_of_Liechtenstein.svg",
        code: "+423"
      },
      {
        name: "Lithuania",
        flag: "assets/images/flag/Flag_of_Lithuania.svg",
        code: "+370"
      },
      {
        name: "Luxembourg",
        flag: "assets/images/flag/Flag_of_Luxembourg.svg",
        code: "+352"
      },
      {
        name: "Macedonia",
        flag: "assets/images/flag/Flag_of_Macedonia.svg",
        code: "+389"
      },
      {
        name: "Macau",
        flag: "assets/images/flag/Flag_of_Macau.svg",
        code: "+853"
      },
      {
        name: "Madagascar",
        flag: "assets/images/flag/Flag_of_Madagascar.svg",
        code: "+261"
      },
      {
        name: "Malawi",
        flag: "assets/images/flag/Flag_of_Malawi.svg",
        code: "+265"
      },
      {
        name: "Malaysia",
        flag: "assets/images/flag/Flag_of_Malaysia.svg",
        code: "+60"
      },
      {
        name: "Maldives",
        flag: "assets/images/flag/Flag_of_Maldives.svg",
        code: "+960"
      },
      {
        name: "Mali",
        flag: "assets/images/flag/Flag_of_Mali.svg",
        code: "+223"
      },
      {
        name: "Malta",
        flag: "assets/images/flag/Flag_of_Malta.svg",
        code: "+356"
      },
      {
        name: "Marshall Islands",
        flag: "assets/images/flag/Flag_of_the_Marshall_Islands.svg",
        code: "+692"
      },
      {
        name: "Martinique",
        flag: "assets/images/flag/Snake_Flag_of_Martinique.svg",
        code: "+596"
      },
      {
        name: "Mauritania",
        flag: "assets/images/flag/Flag_of_Mauritania.svg",
        code: "+222"
      },
      {
        name: "Mauritius",
        flag: "assets/images/flag/Flag_of_Mauritius.svg",
        code: "+230"
      },
      {
        name: "Mayotte",
        flag: "assets/images/flag/Flag_of_Mayotte.svg",
        code: "+262"
      },
      {
        name: "Mexico",
        flag: "assets/images/flag/Flag_of_Mexico.svg",
        code: "+52"
      },
      {
        name: "Monaco",
        flag: "assets/images/flag/Flag_of_Monaco.svg",
        code: "+377"
      },
      {
        name: "Mongolia",
        flag: "assets/images/flag/Flag_of_Mongolia.svg",
        code: "+976"
      },
      {
        name: "Montserrat",
        flag: "assets/images/flag/Flag_of_Montserrat.svg",
        code: "+1664"
      },
      {
        name: "Morocco",
        flag: "assets/images/flag/Flag_of_Morocco.svg",
        code: "+212"
      },
      {
        name: "Mozambique",
        flag: "assets/images/flag/Flag_of_Mozambique.svg",
        code: "+258"
      },
      {
        name: "Myanmar",
        flag: "assets/images/flag/Flag_of_Myanmar.svg",
        code: "+95"
      },
      {
        name: "Namibia",
        flag: "assets/images/flag/Flag_of_Namibia.svg",
        code: "+264"
      },
      {
        name: "Nauru",
        flag: "assets/images/flag/Flag_of_Nauru.svg",
        code: "+674"
      },
      {
        name: "Nepal",
        flag: "assets/images/flag/Flag_of_Nepal.svg",
        code: "+977"
      },
      {
        name: "Netherlands",
        flag: "assets/images/flag/Flag_of_the_Netherlands.svg",
        code: "+31"
      },
      {
        name: "New Caledonia",
        flag: "assets/images/flag/Flags_of_New_Caledonia.svg",
        code: "+687"
      },
      {
        name: "New Zealand",
        flag: "assets/images/flag/Flag_of_New_Zealand.svg",
        code: "+64"
      },
      {
        name: "Nicaragua",
        flag: "assets/images/flag/Flag_of_Nicaragua.svg",
        code: "+505"
      },
      {
        name: "Niger",
        flag: "assets/images/flag/Flag_of_Niger.svg",
        code: "+227"
      },
      {
        name: "Nigeria",
        flag: "assets/images/flag/Flag_of_Nigeria.svg",
        code: "+234"
      },
      {
        name: "Niue",
        flag: "assets/images/flag/Flag_of_Niue.svg",
        code: "+683"
      },
      {
        name: "Norfolk Island",
        flag: "assets/images/flag/Flag_of_Norfolk_Island.svg",
        code: "+672"
      },
      {
        name: "Northern Mariana Islands",
        flag: "assets/images/flag/Flag_of_the_Northern_Mariana_Islands.svg",
        code: "+1 670"
      },
      {
        name: "Norway",
        flag: "assets/images/flag/Flag_of_Norway.svg",
        code: "+47"
      },
      {
        name: "Oman",
        flag: "assets/images/flag/Flag_of_Oman.svg",
        code: "+968"
      },
      {
        name: "Pakistan",
        flag: "assets/images/flag/Flag_of_Pakistan.svg",
        code: "+92"
      },
      {
        name: "Palau",
        flag: "assets/images/flag/Flag_of_Palau.svg",
        code: "+680"
      },
      {
        name: "Panama",
        flag: "assets/images/flag/Flag_of_Panama.svg",
        code: "+507"
      },
      {
        name: "Papua New Guinea",
        flag: "assets/images/flag/Flag_of_Papua_New_Guinea.svg",
        code: "+675"
      },
      {
        name: "Paraguay",
        flag: "assets/images/flag/Flag_of_Paraguay.svg",
        code: "+595"
      },
      {
        name: "Peru",
        flag: "assets/images/flag/Flag_of_Peru.svg",
        code: "+51"
      },
      {
        name: "Philippines",
        flag: "assets/images/flag/Flag_of_the_Philippines.svg",
        code: "+63"
      },
      {
        name: "Pitcairn Island",
        flag: "assets/images/flag/Flag_of_the_Pitcairn_Islands.svg",
        code: "+870"
      },
      {
        name: "Poland",
        flag: "assets/images/flag/Flag_of_Poland.svg",
        code: "+48"
      },
      {
        name: "Portugal",
        flag: "assets/images/flag/Flag_of_Portugal.svg",
        code: "+351"
      },
      {
        name: "Puerto Rico",
        flag: "assets/images/flag/Flag_of_Puerto_Rico.svg",
        code: "+1 939"
      },
      {
        name: "Qatar",
        flag: "assets/images/flag/Flag_of_Qatar.svg",
        code: "+974"
      },
      {
        name: "Reunion",
        flag: "assets/images/flag/Flag_of_France.svg",
        code: "+262"
      },
      {
        name: "Romania",
        flag: "assets/images/flag/Flag_of_Romania.svg",
        code: "+40"
      },
      {
        name: "Rwanda",
        flag: "assets/images/flag/Flag_of_Rwanda.svg",
        code: "+250"
      },
      {
        name: "Saint Kitts And Nevis",
        flag: "assets/images/flag/Flag_of_Saint_Kitts_and_Nevis.svg",
        code: "+1 869"
      },
      {
        name: "Saint Lucia",
        flag: "assets/images/flag/Flag_of_Saint_Lucia.svg",
        code: "+1 758"
      },
      {
        name: "Saint Pierre and Miquelon",
        flag: "assets/images/flag/Flag_of_Saint-Pierre_and_Miquelon.svg",
        code: "+508"
      },
      {
        name: "Saint Vincent And The Grenadines",
        flag: "assets/images/flag/Flag_of_Saint_Vincent_and_the_Grenadines.svg",
        code: "+1 784"
      },
      {
        name: "Samoa",
        flag: "assets/images/flag/Flag_of_Samoa.svg",
        code: "+685"
      },
      {
        name: "San Marino",
        flag: "assets/images/flag/Flag_of_San_Marino.svg",
        code: "+378"
      },
      {
        name: "Sao Tome and Principe",
        flag: "assets/images/flag/Flag_of_Sao_Tome_and_Principe.svg",
        code: "+239"
      },
      {
        name: "Saudi Arabia",
        flag: "assets/images/flag/Flag_of_Saudi_Arabia.svg",
        code: "+966"
      },
      {
        name: "Senegal",
        flag: "assets/images/flag/Flag_of_Senegal.svg",
        code: "+221"
      },
      {
        name: "Serbia",
        flag: "assets/images/flag/Flag_of_Serbia.svg",
        code: "+381"
      },
      {
        name: "Seychelles",
        flag: "assets/images/flag/Flag_of_Seychelles.svg",
        code: "+248"
      },
      {
        name: "Sierra Leone",
        flag: "assets/images/flag/Flag_of_Sierra_Leone.svg",
        code: "+232"
      },
      {
        name: "Singapore",
        flag: "assets/images/flag/Flag_of_Singapore.svg",
        code: "+65"
      },
      {
        name: "Slovakia",
        flag: "assets/images/flag/Flag_of_Slovakia.svg",
        code: "+421"
      },
      {
        name: "Slovenia",
        flag: "assets/images/flag/Flag_of_Slovenia.svg",
        code: "+386"
      },
      {
        name: "Solomon Islands",
        flag: "assets/images/flag/Flag_of_the_Solomon_Islands.svg",
        code: "+677"
      },
      {
        name: "Somalia",
        flag: "assets/images/flag/Flag_of_Somalia.svg",
        code: "+252"
      },
      {
        name: "South Africa",
        flag: "assets/images/flag/Flag_of_South_Africa.svg",
        code: "+27"
      },
      {
        name: "South Georgia",
        flag: "assets/images/flag/Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg",
        code: "+500"
      },
      {
        name: "Spain",
        flag: "assets/images/flag/Flag_of_Spain.svg",
        code: "+34"
      },
      {
        name: "Sri Lanka",
        flag: "assets/images/flag/Flag_of_Sri_Lanka.svg",
        code: "+94"
      },
      {
        name: "Sudan",
        flag: "assets/images/flag/Flag_of_Sudan.svg",
        code: "+249"
      },
      {
        name: "Suriname",
        flag: "assets/images/flag/Flag_of_Suriname.svg",
        code: "+597"
      },
      {
        name: "Swaziland",
        flag: "assets/images/flag/Flag_of_Swaziland.svg",
        code: "+268"
      },
      {
        name: "Sweden",
        flag: "assets/images/flag/Flag_of_Sweden.svg",
        code: "+46"
      },
      {
        name: "Switzerland",
        flag: "assets/images/flag/Flag_of_Switzerland_%28Pantone%29.svg",
        code: "+41"
      },
      {
        name: "Syria",
        flag: "assets/images/flag/Flag_of_Syria.svg",
        code: "+963"
      },
      {
        name: "Taiwan",
        flag: "assets/images/flag/Flag_of_the_Republic_of_China.svg",
        code: "+886"
      },
      {
        name: "Tajikistan",
        flag: "assets/images/flag/Flag_of_Tajikistan.svg",
        code: "+992"
      },
      {
        name: "Thailand",
        flag: "assets/images/flag/Flag_of_Thailand.svg",
        code: "+66"
      },
      {
        name: "Togo",
        flag: "assets/images/flag/Flag_of_Togo.svg",
        code: "+228"
      },
      {
        name: "Tokelau",
        flag: "assets/images/flag/Flag_of_Tokelau.svg",
        code: "+690"
      },
      {
        name: "Tonga",
        flag: "assets/images/flag/Flag_of_Tonga.svg",
        code: "+676"
      },
      {
        name: "Trinidad And Tobago",
        flag: "assets/images/flag/Flag_of_Trinidad_and_Tobago.svg",
        code: "+1 868"
      },
      {
        name: "Tunisia",
        flag: "assets/images/flag/Flag_of_Tunisia.svg",
        code: "+216"
      },
      {
        name: "Turkey",
        flag: "assets/images/flag/Flag_of_Turkey.svg",
        code: "+90"
      },
      {
        name: "Turkmenistan",
        flag: "assets/images/flag/Flag_of_Turkmenistan.svg",
        code: "+993"
      },
      {
        name: "Turks And Caicos Islands",
        flag: "assets/images/flag/Flag_of_the_Turks_and_Caicos_Islands.svg",
        code: "+1 649"
      },
      {
        name: "Tuvalu",
        flag: "assets/images/flag/Flag_of_Tuvalu.svg",
        code: "+688"
      },
      {
        name: "Uganda",
        flag: "assets/images/flag/Flag_of_Uganda.svg",
        code: "+256"
      },
      {
        name: "Ukraine",
        flag: "assets/images/flag/Flag_of_Ukraine.svg",
        code: "+380"
      },
      {
        name: "United Arab Emirates",
        flag: "assets/images/flag/Flag_of_the_United_Arab_Emirates.svg",
        code: "+971"
      },
      {
        name: "United Kingdom",
        flag: "assets/images/flag/Flag_of_the_United_Kingdom.svg",
        code: "+44"
      },
      {
        name: "United States Minor Outlying Islands",
        flag: "assets/images/flag/Flag_of_the_United_States.svg",
        code: "+1581"
      },
      {
        name: "Uruguay",
        flag: "assets/images/flag/Flag_of_Uruguay.svg",
        code: "+598"
      },
      {
        name: "Uzbekistan",
        flag: "assets/images/flag/Flag_of_Uzbekistan.svg",
        code: "+998"
      },
      {
        name: "Vanuatu",
        flag: "assets/images/flag/Flag_of_Vanuatu.svg",
        code: "+678"
      },
      {
        name: "Vietnam",
        flag: "assets/images/flag/Flag_of_Vietnam.svg",
        code: "+84"
      },
      {
        name: "Virgin Islands (US)",
        flag: "assets/images/flag/Flag_of_the_United_States_Virgin_Islands.svg",
        code: "+1 340"
      },
      {
        name: "Wallis And Futuna Islands",
        flag: "assets/images/flag/Flag_of_Wallis_and_Futuna.svg",
        code: "+681"
      },
      {
        name: "Yemen",
        flag: "assets/images/flag/Flag_of_Yemen.svg",
        code: "+967"
      },
      {
        name: "Yugoslavia",
        flag: "assets/images/flag/Flags_of_Yugoslavia.svg",
        code: "+38"
      },
      {
        name: "Zambia",
        flag: "assets/images/flag/Flag_of_Zambia.svg",
        code: "+260"
      },
      {
        name: "Zimbabwe",
        flag: "assets/images/flag/Flag_of_Zimbabwe.svg",
        code: "+263"
      },
      {
        name: "England",
        flag: "assets/images/flag/Flag_of_England.svg",
        code: "+44"
      },
      {
        name: "Venezuela",
        flag: "assets/images/flag/Flag_of_England.svg",
        code: "+58"
      },
      {
        name: "Korea",
        flag: "assets/images/flag/Unification_flag_of_Korea.svg",
        code: "+"
      },
      {
        name: "North Korea",
        flag: "assets/images/flag/Flag_of_North_Korea.svg",
        code: "+850"
      },
      {
        name: "South Korea",
        flag: "assets/images/flag/Flag_of_South_Korea.svg",
        code: "+82"
      },
      {
        name: "Chinese Taipei",
        flag: "assets/images/flag/Flag_of_the_Republic_of_China.svg",
        code: "+886"
      },
      {
        name: "Russia",
        flag: "assets/images/flag/Flag_of_Russia.svg",
        code: "+7"
      },
      {
        name: "South Sudan",
        flag: "assets/images/flag/Flag_of_South_Sudan.svg",
        code: "+211"
      },
      {
        name: "Ivory Coast",
        flag: "assets/images/flag/Flag_of_Ivory_Coast.svg",
        code: "+225"
      },
      {
        name: "Scotland",
        flag: "assets/images/flag/Flag_of_Scotland.svg",
        code: "+44"
      },
      {
        name: "DR Congo",
        flag: "assets/images/flag/Flag_of_the_Democratic_Republic_of_the_Congo.svg",
        code: "+243"
      },
      {
        name: "Trinidad & Tobago",
        flag: "assets/images/flag/Flag_of_Trinidad_and_Tobago.svg",
        code: "+1 868"
      },
      {
        name: "Moldova",
        flag: "assets/images/flag/Flag_of_Moldova.svg",
        code: "+373"
      },
      {
        name: "Montenegro",
        flag: "assets/images/flag/Flag_of_Montenegro.svg",
        code: "+382"
      },
      {
        name: "Bolivia",
        flag: "assets/images/flag/Flag_of_Bolivia_(state).svg",
        code: "+591"
      },
      {
        name: "North Macedonia",
        flag: "assets/images/flag/Flag_of_Macedonia.svg",
        code: "+389"
      },
      {
        name: "Northern Ireland",
        flag: "assets/images/flag/Flag_of_Northern_Ireland_(1953–1972).svg",
        code: "+44"
      },
      {
        name: "Wales",
        flag: "assets/images/flag/Flag_of_Wales.svg",
        code: "+44"
      },
    ]
  }


  getSportsIcons() {
    return [
      {
        id: '1',
        name: "American Football",
        flag: "assets/icons/sports-icon/american-football.svg",
      },
      {
        id: '2',
        name: "Baseball",
        flag: "assets/icons/sports-icon/Softball.svg",
      },
      {
        id: '3',
        name: "Basketball",
        flag: "assets/icons/sports-icon/Basketball.svg",
      },
      {
        id: '4',
        name: "Cricket",
        flag: "assets/icons/sports-icon/Cricket.svg",
      },
      {
        id: '5',
        name: "Curling",
        flag: "assets/icons/sports-icon/Curling.svg",
      },
      {
        id: '6',
        name: "Diving",
        flag: "assets/icons/sports-icon/Diving.svg",
      },
      {
        id: '7',
        name: "Equestrian",
        flag: "assets/icons/sports-icon/Equestrian.svg",
      },
      {
        id: '8',
        name: "Fencing",
        flag: "assets/icons/sports-icon/Fencing.svg",
      },
      {
        id: '9',
        name: "Golf",
        flag: "assets/icons/sports-icon/Golf.svg",
      },
      {
        id: '10',
        name: "Gymnastics",
        flag: "assets/icons/sports-icon/Gymnastics.svg",
      },
      {
        id: '11',
        name: "Horse Racing",
        flag: "assets/icons/sports-icon/Horse-racing.svg",
      },
      {
        id: '12',
        name: "Ice Hockey",
        flag: "assets/icons/sports-icon/Ice-Hockey.svg",
      },
      {
        id: '13',
        name: "Rowing",
        flag: "assets/icons/sports-icon/Rowing.svg",
      },
      {
        id: '14',
        name: "Rugby",
        flag: "assets/icons/sports-icon/Rugby.svg",
      },
      {
        id: '15',
        name: "Soccer",
        flag: "assets/icons/sports-icon/Soccer.svg",
      },
      {
        id: '16',
        name: "Softball",
        flag: "assets/icons/sports-icon/Softball.svg",
      },
      {
        id: '17',
        name: "Swimming",
        flag: "assets/icons/sports-icon/Swimming.svg",
      },
      {
        id: '18',
        name: "Tabel Tennis",
        flag: "assets/icons/sports-icon/Tabel-Tennis.svg",
      },
      {
        id: '19',
        name: "Tennis",
        flag: "assets/icons/sports-icon/Tennis.svg",
      },
      {
        id: '20',
        name: "Track & Field",
        flag: "assets/icons/sports-icon/Track_Field.svg",
      },
      {
        id: '21',
        name: "Volleyball",
        flag: "assets/icons/sports-icon/Volleyball.svg",
      },
      {
        id: '22',
        name: "Weightlifting",
        flag: "assets/icons/sports-icon/Weightlifting.svg",
      },
      {
        id: '23',
        name: "Wrestling",
        flag: "assets/icons/sports-icon/Wrestling.svg",
      },
    ]
  }
}
