import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../_Service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  static canActivate: any;

  constructor(
    private _authService: AuthService,
    private _router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this._authService.loggedIn();
    const routeUrl0 = (route && route.url && route.url[0] && route.url[0].path) ? route.url[0].path : '';
    if (isLoggedIn) {
      return true;
    } else {
      localStorage.clear();
      switch (routeUrl0) {
        case 'index':
          this._router.navigateByUrl('home');
          break;
        case 'aliasPostViewDetail':
        case 'postViewDetail':
          this._router.navigateByUrl('/postDetails?string=' + route.queryParams['string']);
          return false;
        case 'followUserProfile':
        case 'followuserprofile':
      
          this._router.navigateByUrl('/login');
          return false;

        default:
          return false;
      }

      

    }
  }

}
