export const customEmojisNFL: any = [
    // {
    //     name: 'Logo',
    //     shortNames: ['logo'],
    //     custom: true,
    //     text: '(logo)',
    //     emoticons: [],
    //     keywords: ['logo'],
    //     imageUrl: 'https://www.freepnglogos.com/uploads/nfl-regional-combines-logo-png-7.png',
    // },
    // {
    //     name: 'Nfllogo',
    //     shortNames: ['nfl-logo'],
    //     custom: true,
    //     text: '(nfl-logo)',
    //     emoticons: [],
    //     keywords: ['nfl-logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666162847-icons8-nfl-250.png',
    // },
    // {
    //     name: 'Nfl',
    //     shortNames: ['nfl'],
    //     custom: true,
    //     text: '(nfl)',
    //     emoticons: [],
    //     keywords: ['nfl', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665745811-nfl-1.png',
    // },
    // {
    //     name: 'Stadium',
    //     shortNames: ['stadium'],
    //     custom: true,
    //     text: '(Stadium)',
    //     emoticons: [],
    //     keywords: ['stadium', 'ground'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665744832-stadium-1.png',
    // },

    // {
    //     name: 'Nflcolor',
    //     shortNames: ['nflcolor'],
    //     custom: true,
    //     text: '(nflcolor)',
    //     emoticons: [],
    //     keywords: ['nflcolor'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666163236-icons8-nfl-250-1.png',
    // },

    // {
    //     name: 'Goldennfl',
    //     shortNames: ['golden-nfl'],
    //     custom: true,
    //     text: '(golden-nfl)',
    //     emoticons: [],
    //     keywords: ['golden-nfl'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666164127-nfl-logo-png-2.png',
    // },
    // {
    //     name: 'Heavymetal',
    //     shortNames: ['heavy-metal'],
    //     custom: true,
    //     text: '(heavy-metal)',
    //     emoticons: [],
    //     keywords: ['heavy-metal'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666164289-nfl-logos-heavy-metal-png-24.png',
    // },
    // {
    //     name: 'Player',
    //     shortNames: ['player'],
    //     custom: true,
    //     text: '(player)',
    //     emoticons: [],
    //     keywords: ['player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747523-american-football-player-1.png',
    // },
    // {
    //     name: 'Americanfootballfield',
    //     shortNames: ['american-football-field'],
    //     custom: true,
    //     text: '(american-football-field)',
    //     emoticons: [],
    //     keywords: ['americanfootballfield', 'footballfield'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747713-american-football-field-1.png',
    // },
    // {
    //     name: 'Supporter',
    //     shortNames: ['supporter'],
    //     custom: true,
    //     text: '(supporter)',
    //     emoticons: [],
    //     keywords: ['supporter', 'fans'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747895-supporter-1.png',
    // },
    // {
    //     name: 'Nflicon',
    //     shortNames: ['nfl-icon'],
    //     custom: true,
    //     text: '(nfl-ico)',
    //     emoticons: [],
    //     keywords: ['nfl-ico'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666164778-NFL-icon.png',
    // },
    // {
    //     name: 'Rugby',
    //     shortNames: ['rugby'],
    //     custom: true,
    //     text: '(rugby)',
    //     emoticons: [],
    //     keywords: ['rugby'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665745650-rugby.png',
    // },
    // {
    //     name: 'American football player',
    //     shortNames: ['american-football-player'],
    //     custom: true,
    //     text: '(american-football-player)',
    //     emoticons: [],
    //     keywords: ['american-football-player', 'american', 'football', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665745938-american-football.png',
    // },
    // {
    //     name: 'Rugbytrophy',
    //     shortNames: ['rugby-trophy'],
    //     custom: true,
    //     text: '(rugby-trophy)',
    //     emoticons: [],
    //     keywords: ['rugby-trophy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092000-trophy-1.png',
    // },
    // {
    //     name: 'Competition',
    //     shortNames: ['competition'],
    //     custom: true,
    //     text: '(competition)',
    //     emoticons: [],
    //     keywords: ['competition', 'match', 'live'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665746559-competition.png',
    // },
    // {
    //     name: 'Helmet',
    //     shortNames: ['helmet'],
    //     custom: true,
    //     text: '(helmet)',
    //     emoticons: [],
    //     keywords: ['helmet'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092000-american-football-2.png',
    // },
    // {
    //     name: 'Rugby ball',
    //     shortNames: ['rugbyball'],
    //     custom: true,
    //     text: '(rugbyball)',
    //     emoticons: [],
    //     keywords: ['soccer-ball', 'ball', 'rugby-ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747078-ball.png',
    // },
    // {
    //     name: 'Free-kick',
    //     shortNames: ['free-kick'],
    //     custom: true,
    //     text: '(free-kick)',
    //     emoticons: [],
    //     keywords: ['free-kick', 'ball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747357-free-kick.png',
    // },
    // {
    //     name: 'Trophy',
    //     shortNames: ['trophy'],
    //     custom: true,
    //     text: '(trophy)',
    //     emoticons: [],
    //     keywords: ['trophy', 'win'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747834-trophy.png',
    // },
    
    // {
    //     name: 'Tournament',
    //     shortNames: ['tournament'],
    //     custom: true,
    //     text: '(tournament)',
    //     emoticons: [],
    //     keywords: ['tournament'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092000-rugby-tournament.png',
    // },
    // {
    //     name: 'Whistle',
    //     shortNames: ['whistle'],
    //     custom: true,
    //     text: '(whistle)',
    //     emoticons: [],
    //     keywords: ['whistle'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092101-whistle.png',
    // },
    // {
    //     name: 'Goldentrophy',
    //     shortNames: ['golden-trophy'],
    //     custom: true,
    //     text: '(golden-trophy)',
    //     emoticons: [],
    //     keywords: ['golden-trophy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092101-trophy-2.png',
    // },
    
    // {
    //     name: 'Fireball',
    //     shortNames: ['fireball'],
    //     custom: true,
    //     text: '(fireball)',
    //     emoticons: [],
    //     keywords: ['fireball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092084-rugby-ball-4.png',
    // },
    // {
    //     name: 'rugbyplayer',
    //     shortNames: ['rugby-player'],
    //     custom: true,
    //     text: '(rugby-player)',
    //     emoticons: [],
    //     keywords: ['rugby-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092084-rugby-player.png',
    // },
    // {
    //     name: 'Tackle',
    //     shortNames: ['tackle'],
    //     custom: true,
    //     text: '(tackle)',
    //     emoticons: [],
    //     keywords: ['tackle', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092084-tackle.png',
    // },
    // {
    //     name: 'Scoreboard',
    //     shortNames: ['scoreboard'],
    //     custom: true,
    //     text: '(scoreboard)',
    //     emoticons: [],
    //     keywords: ['scoreboard'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092084-scoreboard.png',
    // },
    // {
    //     name: 'Touchdown',
    //     shortNames: ['touchdown'],
    //     custom: true,
    //     text: '(touchdown)',
    //     emoticons: [],
    //     keywords: ['touchdown'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092083-touchdown-1.png',
    // },
    // {
    //     name: 'Rugbygoal',
    //     shortNames: ['rugby-goal'],
    //     custom: true,
    //     text: '(rugby-goal)',
    //     emoticons: [],
    //     keywords: ['rugby-goal'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092083-rugby-goal.png',
    // },
    // {
    //     name: 'Runingplayer',
    //     shortNames: ['runing-player'],
    //     custom: true,
    //     text: '(runing-player)',
    //     emoticons: [],
    //     keywords: ['runing-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092083-rugby-player-1.png',
    // },
    // {
    //     name: 'Rugbyking',
    //     shortNames: ['rugby-king'],
    //     custom: true,
    //     text: '(rugby-king)',
    //     emoticons: [],
    //     keywords: ['rugby-king'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092083-rugby-ball-3.png',
    // },
    // {
    //     name: 'Happyplayer',
    //     shortNames: ['happy-player'],
    //     custom: true,
    //     text: '(happy-player)',
    //     emoticons: [],
    //     keywords: ['happy-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092065-rugby-ball-1.png',
    // },
    // {
    //     name: 'Ballplayer',
    //     shortNames: ['ball-player'],
    //     custom: true,
    //     text: '(ball-player)',
    //     emoticons: [],
    //     keywords: ['ball-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092065-rugby-5.png',
    // },
    // {
    //     name: 'Rugbystand',
    //     shortNames: ['rugby-stand'],
    //     custom: true,
    //     text: '(rugby-stand)',
    //     emoticons: [],
    //     keywords: ['rugby-stand'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092065-rugby-2.png',
    // },
    // {
    //     name: 'Star',
    //     shortNames: ['star'],
    //     custom: true,
    //     text: '(star)',
    //     emoticons: [],
    //     keywords: ['star'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092064-rugby-4.png',
    // },
    // {
    //     name: 'Rugbylove',
    //     shortNames: ['rugby-love'],
    //     custom: true,
    //     text: '(rugby-love)',
    //     emoticons: [],
    //     keywords: ['rugby-love'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092064-rugby-3.png',
    // },
    // {
    //     name: 'Rugbylover',
    //     shortNames: ['rugby-lover'],
    //     custom: true,
    //     text: '(rugby-lover)',
    //     emoticons: [],
    //     keywords: ['rugby-lover'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092064-rugby-6.png',
    // },
    // {
    //     name: 'Newrugby',
    //     shortNames: ['new-rugby'],
    //     custom: true,
    //     text: '(new-rugby)',
    //     emoticons: [],
    //     keywords: ['new-rugby'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092064-rugby-ball-2.png',
    // },
    // {
    //     name: 'Rugbyboy',
    //     shortNames: ['rugby-boy'],
    //     custom: true,
    //     text: '(rugby-boy)',
    //     emoticons: [],
    //     keywords: ['rugby-boy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092064-rugby-1.png',
    // },
    // {
    //     name: 'Team',
    //     shortNames: ['team'],
    //     custom: true,
    //     text: '(team)',
    //     emoticons: [],
    //     keywords: ['team'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092064-player-2.png',
    // },
    // {
    //     name: 'Association',
    //     shortNames: ['association'],
    //     custom: true,
    //     text: '(association)',
    //     emoticons: [],
    //     keywords: ['association'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092038-association.png',
    // },
    // {
    //     name: 'Pinkhelmet',
    //     shortNames: ['pink-helmet'],
    //     custom: true,
    //     text: '(pink-helmet)',
    //     emoticons: [],
    //     keywords: ['pink-helmet'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092038-helmet.png',
    // },
    // {
    //     name: 'hat',
    //     shortNames: ['hat'],
    //     custom: true,
    //     text: '(hat)',
    //     emoticons: [],
    //     keywords: ['hat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092038-hat.png',
    // },
    // {
    //     name: 'Goal',
    //     shortNames: ['goal'],
    //     custom: true,
    //     text: '(goal)',
    //     emoticons: [],
    //     keywords: ['goal'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666092038-goal.png',
    // },
    // {
    //     name: 'Hourglass',
    //     shortNames: ['hourglass'],
    //     custom: true,
    //     text: '(hourglass)',
    //     emoticons: [],
    //     keywords: ['hourglass'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159032-hourglass.png',
    // },
    // {
    //     name: 'Calendar',
    //     shortNames: ['calendar'],
    //     custom: true,
    //     text: '(calendar)',
    //     emoticons: [],
    //     keywords: ['calendar'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159032-calendar.png',
    // },
    // {
    //     name: 'Tv',
    //     shortNames: ['tv'],
    //     custom: true,
    //     text: '(tv)',
    //     emoticons: [],
    //     keywords: ['tv'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159032-tv.png',
    // },
    // {
    //     name: 'Smartphone',
    //     shortNames: ['smartphone'],
    //     custom: true,
    //     text: '(smartphone)',
    //     emoticons: [],
    //     keywords: ['smartphone'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159033-smartphone.png',
    // },
    // {
    //     name: 'Womenplayer',
    //     shortNames: ['womenplayer'],
    //     custom: true,
    //     text: '(womenplayer)',
    //     emoticons: [],
    //     keywords: ['womenplayer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159033-player-4.png',
    // },
    // {
    //     name: 'Women',
    //     shortNames: ['women'],
    //     custom: true,
    //     text: '(women)',
    //     emoticons: [],
    //     keywords: ['women'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159033-american-football-player-3.png',
    // },
    // {
    //     name: 'Men',
    //     shortNames: ['men'],
    //     custom: true,
    //     text: '(men)',
    //     emoticons: [],
    //     keywords: ['men'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159033-american-football-player-2.png',
    // },
    // {
    //     name: 'Thinking',
    //     shortNames: ['thinking'],
    //     custom: true,
    //     text: '(thinking)',
    //     emoticons: [],
    //     keywords: ['thinking'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159033-player-3.png',
    // },
    // {
    //     name: 'Megaphone',
    //     shortNames: ['megaphone'],
    //     custom: true,
    //     text: '(megaphone)',
    //     emoticons: [],
    //     keywords: ['megaphone'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159033-megaphone.png',
    // },
    // {
    //     name: 'Tackleplayer',
    //     shortNames: ['tackle-player'],
    //     custom: true,
    //     text: '(tackle-player)',
    //     emoticons: [],
    //     keywords: ['tackle-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159033-tackle.png',
    // },
    // {
    //     name: 'Americanplayer',
    //     shortNames: ['american-player'],
    //     custom: true,
    //     text: '(american-player)',
    //     emoticons: [],
    //     keywords: ['american-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-american-football-player-1.png',
    // },
    // {
    //     name: 'Boy',
    //     shortNames: ['boy'],
    //     custom: true,
    //     text: '(boy)',
    //     emoticons: [],
    //     keywords: ['boy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-boy.png',
    // },
    // {
    //     name: 'Ballinhand',
    //     shortNames: ['ball-in-hand'],
    //     custom: true,
    //     text: '(ball-in-hand)',
    //     emoticons: [],
    //     keywords: ['ball-in-hand'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-rugby-player-on-one-knee-with-the-ball-in-a-hand.png',
    // },
    // {
    //     name: 'Runing',
    //     shortNames: ['runing'],
    //     custom: true,
    //     text: '(runing)',
    //     emoticons: [],
    //     keywords: ['runing'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-rugby-player-2.png',
    // },
    // {
    //     name: 'Yellowplayer',
    //     shortNames: ['yellow-player'],
    //     custom: true,
    //     text: '(yellow-player)',
    //     emoticons: [],
    //     keywords: ['yellow-player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-rugby-player-1.png',
    // },
    // {
    //     name: 'playerwithball',
    //     shortNames: ['player-with-ball'],
    //     custom: true,
    //     text: '(player-with-ball)',
    //     emoticons: [],
    //     keywords: ['player-with-ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-rugby-player-with-the-ball.png',
    // },
    // {
    //     name: 'Competitive',
    //     shortNames: ['competitive'],
    //     custom: true,
    //     text: '(competitive)',
    //     emoticons: [],
    //     keywords: ['competitive'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-competitive.png',
    // },
    // {
    //     name: 'Chase',
    //     shortNames: ['chase'],
    //     custom: true,
    //     text: '(chase)',
    //     emoticons: [],
    //     keywords: ['chase'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-chase.png',
    // },
    // {
    //     name: 'Enjoy',
    //     shortNames: ['enjoy'],
    //     custom: true,
    //     text: '(enjoy)',
    //     emoticons: [],
    //     keywords: ['enjoy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159080-player-2.png',
    // },
    // {
    //     name: 'Playrwithhelmet',
    //     shortNames: ['playr-with-helmet'],
    //     custom: true,
    //     text: '(playr-with-helmet)',
    //     emoticons: [],
    //     keywords: ['playr-with-helmets'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666159088-rugby-3.png',
    // },
    // {
    //     name: 'Ramsicon',
    //     shortNames: ['rams-icon'],
    //     custom: true,
    //     text: '(rams-icon)',
    //     emoticons: [],
    //     keywords: ['rams-icon'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666164951-Rams-icon.png',
    // },
    // {
    //     name: 'Seahawksicon',
    //     shortNames: ['seahawks-icon'],
    //     custom: true,
    //     text: '(seahawks-icon)',
    //     emoticons: [],
    //     keywords: ['seahawks-icon'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666165126-Seahawks-icon.png',
    // },
    // {
    //     name: 'Lionsicon',
    //     shortNames: ['lions-icon'],
    //     custom: true,
    //     text: '(lions-icon)',
    //     emoticons: [],
    //     keywords: ['lions-icon'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666165360-Lions-icon.png',
    // },
    // {
    //     name: 'Bengalsicon',
    //     shortNames: ['bengals-icon'],
    //     custom: true,
    //     text: '(bengals-icon)',
    //     emoticons: [],
    //     keywords: ['bengals-icon'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666165209-Bengals-icon.png',
    // },
    // {
    //     name: 'Jaguarsicon',
    //     shortNames: ['jaguars-icon'],
    //     custom: true,
    //     text: '(jaguars-icon)',
    //     emoticons: [],
    //     keywords: ['jaguars-icon'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666165478-Jaguars-icon.png',
    // },
    // {
    //     name: 'Patriotsicon',
    //     shortNames: ['patriots-icon'],
    //     custom: true,
    //     text: '(patriots-icon)',
    //     emoticons: [],
    //     keywords: ['patriots-icon'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666165571-Patriots-icon.png',
    // },
    // {
    //     name: 'Titansicon',
    //     shortNames: ['titans-icon'],
    //     custom: true,
    //     text: '(titans-icon)',
    //     emoticons: [],
    //     keywords: ['titans-icon'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666165659-Titans-icon.png',
    // },
    // {
    //     name: 'Ravensicon',
    //     shortNames: ['ravens-icon'],
    //     custom: true,
    //     text: '(ravens-icon)',
    //     emoticons: [],
    //     keywords: ['ravens-icon'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666165761-Ravens-icon.png',
    // },
    // {
    //     name: 'Billsicon',
    //     shortNames: ['bills-icon'],
    //     custom: true,
    //     text: '(bills-icon)',
    //     emoticons: [],
    //     keywords: ['bills-icon'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666165886-Bills-icon.png',
    // },
    // {
    //     name: 'Redskinsicon',
    //     shortNames: ['redskins-icon'],
    //     custom: true,
    //     text: '(redskins-icon)',
    //     emoticons: [],
    //     keywords: ['redskins-icon'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666165886-Redskins-icon.png',
    // }
];
