import { NgModule } from "@angular/core";
import { OuterHeaderNavigationComponent } from "./outer-header-navigation.component";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

const routes: Routes = [];

@NgModule({
	declarations: [OuterHeaderNavigationComponent],
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		NgbModule
	],
	exports: [OuterHeaderNavigationComponent]
})

export class OuterHeaderNavigationModule { }