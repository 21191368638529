import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  loginUser(Data) {
    return this.http.post(`${environment.base_url}/auth/login`, Data);
  }

  loginSuperUser(Data) {
    return this.http.post(`${environment.base_url}/auth/superLogin`, Data);
  }

  loggedIn() {
    if (localStorage.getItem("token")) {
      return true;
    } else {
      return false;
    }
  }

  adminLoggedIn() {
    if (localStorage.getItem("token") && this.decryptData(localStorage.getItem("tokenAdmin")) == 'admin') {
      return true;
    } else {
      return false;
    }
  }

  //Role base authorization (D)
  isAuthorized(allowedRoles: string[]): boolean {
    var role = this.decryptData(localStorage.getItem("role"));
    var adminType;
    switch (role) {
      case 1:
         adminType = 'superAdmin';
         break;
      case 2:
         adminType = 'superAdmin';
         break;
      case 3:
          adminType = 'admin';
          break;
      case 5:
          adminType = 'admin';
        break;
      case 4:
          adminType = 'blogAdmin';
        break;
      case 6:
          adminType = 'blogAdmin';
        break;
      case 7:
          adminType = 'talkingPointAdmin';
        break;
      case 8:
          adminType = 'talkingPointAdmin';
        break;
    }
   
    // check if it the token is there or not.
    if (!localStorage.getItem("token") && this.decryptData(localStorage.getItem("tokenAdmin")) == 'admin') {
      return false;
    }

    // check if the user roles is in the list of allowed roles, return true if allowed and false if not allowed
    return allowedRoles.includes(adminType);
  }

  getToken() {
    return localStorage.getItem("token");
  }

  removeToken() {
    return localStorage.removeItem("token");
  }

  //Encrypt Data(n)
  encryptData(data) {

    try {
      if (data) {
        return CryptoJS.AES.encrypt(JSON.stringify(data), '1234509876encryptdecrypt1234567890').toString();
      }
    } catch (e) {
    }
  }
  //End encrypt Data(n)

  //Decrypt Data (n)
  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, '1234509876encryptdecrypt1234567890');
      if (bytes.toString()) {
        if (CryptoJS.enc.Utf8) {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
      }
      if (data) {
        return data;
      }

    } catch (e) {
    }
  }
  //End Decrypt Data (n)

}