import { Injectable } from "@angular/core";
import {
  HttpClient
} from "@angular/common/http";
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SigninSignUpService {

  constructor(private Http: HttpClient) { }


  getGenders(Data) {
    return this.Http.post(`${environment.base_url}/getGenders`, Data);
  }

  getDivisions(Data) {
    return this.Http.post(`${environment.base_url}/getDivisions`, Data);
  }


  checkLogin(Data) {
    return this.Http.post(`${environment.base_url}/auth/login`, Data);
  }

  tokenUpdateInUser(Data) {
    return this.Http.post(`${environment.base_url}/tokenUpdateInUser`, Data);
  }
  //Facebook Login(n)
  socialMediaFBLogin(Data) {
    return this.Http.post(`${environment.base_url}/socialMediaLogin`, Data);
  }

  signUp(Data) {
    return this.Http.post(`${environment.base_url}/signUp`, Data);
  }

  confirmOtp(data){
    return this.Http.post(`${environment.base_url}/confirmOtp`, data);
  }

  sendEmail(Data) {
    return this.Http.post(`${environment.base_url}/userConfirmEmail`, Data);
  }

  getCountry() {
    return this.Http.get(`${environment.base_url}/countryList`);
  }

  getState(Data) {
    return this.Http.get(`${environment.base_url}/stateList?id=` + Data.id);
  }

  getCity(Data) {
    return this.Http.get(`${environment.base_url}/cityList?id=` + Data.id);
  }

  locationUpdate(Data) {
    return this.Http.post(`${environment.base_url}/locationUpdate`, Data);
  }

  getPoliticalParty() {
    return this.Http.get(`${environment.base_url}/politicalPartyList`);
  }

  getCampaignList() {
    return this.Http.get(`${environment.base_url}/campaignList`);
  }

  politicalInfoSubmit(Data) {
    return this.Http.post(`${environment.base_url}/politicalInfo`, Data);
  }

  userBioUpdate(Data) {
    return this.Http.post(`${environment.base_url}/userBioUpdate`, Data);
  }

  getCategories() {
    return this.Http.get(`${environment.base_url}/categoryList`);
  }

  userProfileDetails() {
    return this.Http.get(`${environment.base_url}/getUserProfileDetails`);
  }

  userInterests(Data) {
    return this.Http.post(`${environment.base_url}/userInterests`, Data);
  }

  // Service (m)
  getBasicInfo() {
    return this.Http.get(`${environment.base_url}/getBasicInfo`);
  }

  getBasicContactInfo() {
    return this.Http.get(`${environment.base_url}/getBasicInfo?check=deactivate`);
  }

  updateBasicInfo(data) {
    return this.Http.post(`${environment.base_url}/updateBasicInfo`, data);
  }
  // End Service (m)

  searchUserForgotPassword(data) {
    return this.Http.post(`${environment.base_url}/searchUserForgotPassword`, data);
  }

  matchOtpChangePassword(data) {
    return this.Http.post(`${environment.base_url}/matchOtpChangePassword`, data);
  }

  updatePassword(data) {
    return this.Http.post(`${environment.base_url}/updatePassword`, data);
  }

  getSignUpPostList(data) {
    return this.Http.post(`${environment.base_url}/getSignUpPostList`, data);
  }

  getByUserNameUserDetails(data) {
    return this.Http.post(`${environment.base_url}/getByUserNameUserDetails`, data);
  }

  getSearchUserByUserName(data) {
    return this.Http.post(`${environment.base_url}/getSearchUserByUserName`, data);
  }

  getUserNamePostList(data) {
    return this.Http.post(`${environment.base_url}/getUserNamePostList`, data);
  }

  checkUserLogin() {
    return this.Http.get(`${environment.base_url}/checkUserLogin`);
  }

  connectSocialMedia(Data) {
    return this.Http.post(`${environment.base_url}/connectSocialMedia`, Data);
  }

  clickSendEmail(Data) {
    return this.Http.post(`${environment.social_media_url}/clickSendEmail`, Data);
  }

  clickSendForgotPasswordEmail(Data) {
    return this.Http.post(`${environment.social_media_url}/clickSendForgotPasswordEmail`, Data);
  }

  clickSendSMS(Data) {
    return this.Http.post(`${environment.social_media_url}/clickSendSMS`, Data);
  }
  getBlogsList(Data) {
    return this.Http.post(`${environment.base_url}/getBlogsList`, Data);
  }

  increaseViewCountBlog(Data) {
    return this.Http.post(`${environment.base_url}/increaseViewCountBlog`, Data);
  }


  mostReadBlogs(Data) {
    return this.Http.get(`${environment.base_url}/mostReadBlogs?platform_id=` + Data.platform_id);
  }
  getNewsDetailsBreaking(Data) {
    return this.Http.get(`${environment.base_url}/getNewsDetailsBreaking?platform_id=` + Data.platform_id);
  }
  getVisitNewsDetailsBreaking(Data) {
    return this.Http.post(`${environment.base_url}/getVisitNewsDetailsBreaking`, Data);
  }
  getNewsDetailsBreakingOuter(Data) {
    return this.Http.get(`${environment.base_url}/getNewsDetailsBreakingOuter?platform_id=` + Data.platform_id);
  }

  blogCategories() {
    return this.Http.get(`${environment.base_url}/blogCategories`);
  }
  getNewsDetailsCount() {
    return this.Http.get(`${environment.base_url}/getNewsDetailsCount`);
  }

  addUserContactDetails(Data) {
    return this.Http.post(`${environment.base_url}/addUserContactDetails`, Data);
  }

  contactusEmail(Data) {
    return this.Http.post(`${environment.social_media_url}/contactusEmail`, Data);
  }
  welcomeEmail(Data) {
    return this.Http.post(`${environment.social_media_url}/welcomeEmail`, Data);
  }

  showLeagues(Data) {
    return this.Http.post(`${environment.base_url}/showLeagues`, Data);
  }

  showTeams(Data) {
    return this.Http.post(`${environment.base_url}/showTeams`, Data);
  }

  showPlayers(Data) {
    return this.Http.post(`${environment.base_url}/showPlayers`, Data);
  }

  showLeaguesFollowSport(data) {
    return this.Http.post(`${environment.base_url}/showLeaguesFollowSport`, data);
  }
  showTeamFollowSport(data) {
    return this.Http.post(`${environment.base_url}/showTeamFollowSport`, data);
  }
  showPlayerFollowSport(data) {
    return this.Http.post(`${environment.base_url}/showPlayerFollowSport`, data);
  }

  userAddNotificationToken(data) {
    return this.Http.post(`${environment.base_url}/userAddNotificationToken`, data);
  }

  updateUserIdByNotiToken(data) {
    return this.Http.post(`${environment.base_url}/updateUserIdByNotiToken`, data);
  }
  
  addProfileClaimData(data) {
    return this.Http.post(`${environment.base_url}/addProfileClaimData`, data);
  }

  signUpPlayer(data) {
    return this.Http.post(`${environment.base_url}/signUpPlayer`, data);
  }

  getMatchByTeamGsdID(data){
    return this.Http.post(`${environment.base_url}/getMatchByTeamGsdID`, data);
  }
}
