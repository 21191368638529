import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../_Service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CanLoadRouteGuard implements CanLoad {
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  canLoad(route: Route, segments: UrlSegment[]): any {
    const isLoggedIn = this._authService.loggedIn();
    if (isLoggedIn) {
      return true;
    } else {
      switch (route.path) {
        case 'index':
          this._router.navigateByUrl('');
          break;
        case 'aliasPostViewDetail':
        case 'postViewDetail':
          this._router.navigateByUrl('/postDetails?string=' + this.activatedRoute.queryParams['string']);
          break;
        // case 'teamschedulematch':
        //   this._router.navigate(['/matches', 'scheduled', 'all']);
        //   break;
        default:
          this._router.navigateByUrl('login');
          break;
      }
      return false;
    }
  }
}
