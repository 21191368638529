export const customEmojisGolf: any = [
    // {
    //     name: 'Trajectory',
    //     shortNames: ['trajectory'],
    //     custom: true,
    //     text: '(trajectory)',
    //     emoticons: [],
    //     keywords: ['trajectory', 'golfequipment'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665816388-trajectory-1.png',
    // },
    // {
    //     name: 'Golf stick',
    //     shortNames: ['golf-stick'],
    //     custom: true,
    //     text: '(golf-stick)',
    //     emoticons: [],
    //     keywords: ['golfstick', 'two stick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665816400-golf-stick-1.png',
    // },
    // {
    //     name: 'Golfing',
    //     shortNames: ['golfing'],
    //     custom: true,
    //     text: '(golfing)',
    //     emoticons: [],
    //     keywords: ['golfing', 'golfstick', 'golf ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665817008-golfing.png',
    // },
    // {
    //     name: 'Golffieldimage',
    //     shortNames: ['golf-field-image'],
    //     custom: true,
    //     text: '(golf-field-image)',
    //     emoticons: [],
    //     keywords: ['golf-field', 'golf distance','golf ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665816995-golf-field.png',
    // },
    // {
    //     name: 'Golfer',
    //     shortNames: ['golfer'],
    //     custom: true,
    //     text: '(golfer)',
    //     emoticons: [],
    //     keywords: ['golfer', 'golf ball', 'golf stick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665817016-golfer.png',
    // },
    // {
    //     name: 'Golfcart',
    //     shortNames: ['golf-cart'],
    //     custom: true,
    //     text: '(golf-cart)',
    //     emoticons: [],
    //     keywords: ['cart', 'golf-cart', 'golf equipment'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665817949-golf-cart.png',
    // },
    // {
    //     name: 'Golf-club',
    //     shortNames: ['golf-club'],
    //     custom: true,
    //     text: '(golf-club)',
    //     emoticons: [],
    //     keywords: ['golfball', 'clubhouse','golfclub'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022341-golf-club.png',
    // },
    // {
    //     name: 'Golfclublogo',
    //     shortNames: ['golf-club-logo'],
    //     custom: true,
    //     text: '(golf-club-logo)',
    //     emoticons: [],
    //     keywords: ['golfclublogo', 'clublogo', 'golfstick', 'golfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022341-golf-club-1.png',
    // },
    // {
    //     name: 'Golf',
    //     shortNames: ['golf'],
    //     custom: true,
    //     text: '(golf)',
    //     emoticons: [],
    //     keywords: ['golf', 'golf stick', 'golf ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665818838-mini-golf.png',
    // },
    // {
    //     name: 'Birdie',
    //     shortNames: ['birdie'],
    //     custom: true,
    //     text: '(birdie)',
    //     emoticons: [],
    //     keywords: ['birdie', 'golf field', 'golf distance'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665818544-birdie.png',
    // },
    // {
    //     name: 'Golfswing',
    //     shortNames: ['swing'],
    //     custom: true,
    //     text: '(swing)',
    //     emoticons: [],
    //     keywords: ['swing', 'golfball', 'golf stick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665818981-swing.png',
    // },
    // {
    //     name: 'Caddy',
    //     shortNames: ['caddy'],
    //     custom: true,
    //     text: '(caddy)',
    //     emoticons: [],
    //     keywords: ['gilfbag', 'golfball', 'golf stick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665819170-caddy.png',
    // },
    // {
    //     name: 'Golfplayer',
    //     shortNames: ['golf-player'],
    //     custom: true,
    //     text: '(golf-player)',
    //     emoticons: [],
    //     keywords: ['golfplayer', 'golfball', 'golf stick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665819394-golf-player-2.png',
    // },
    // {
    //     name: 'Golfbirdie',
    //     shortNames: ['golf-birdie'],
    //     custom: true,
    //     text: '(golf-birdie)',
    //     emoticons: [],
    //     keywords: ['birdie', 'golfball', 'destinationflag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022285-birdie.png',
    // },
    // {
    //     name: 'Golfbook',
    //     shortNames: ['golf-book'],
    //     custom: true,
    //     text: '(golf-book)',
    //     emoticons: [],
    //     keywords: ['golfbook', 'golfball', 'flag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022286-book.png',
    // },
    // {
    //     name: 'Caddyboy',
    //     shortNames: ['caddy-boy'],
    //     custom: true,
    //     text: '(caddy-boy)',
    //     emoticons: [],
    //     keywords: ['caddyboy', 'golfball', 'golf stick','caddytrolly'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022286-caddy-1.png',
    // },
    // {
    //     name: 'Caddygirl',
    //     shortNames: ['caddy-girl'],
    //     custom: true,
    //     text: '(caddy-girl)',
    //     emoticons: [],
    //     keywords: ['caddygirl', 'golf stick' , 'stickbag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022286-caddy.png',
    // },
    // {
    //     name: 'Championship',
    //     shortNames: ['championship'],
    //     custom: true,
    //     text: '(championship)',
    //     emoticons: [],
    //     keywords: ['championship', 'trophy', 'golf stick' ,'cup'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022286-championship.png',
    // },
    // {
    //     name: 'Clothing',
    //     shortNames: ['clothing'],
    //     custom: true,
    //     text: '(clothing)',
    //     emoticons: [],
    //     keywords: ['clothing', 'girlsdress', 'uniform'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022286-clothing.png',
    // },
    // {
    //     name: 'Coach',
    //     shortNames: ['coach'],
    //     custom: true,
    //     text: '(coach)',
    //     emoticons: [],
    //     keywords: ['coach', 'golf stick' ,'golfframe'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022286-coach.png',
    // },
    // {
    //     name: 'Discgolf',
    //     shortNames: ['disc-golf'],
    //     custom: true,
    //     text: '(disc-golf)',
    //     emoticons: [],
    //     keywords: ['disc-golf', 'golfball', 'disc'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022286-disc-golf.png',
    // },
    // {
    //     name: 'Golfgame',
    //     shortNames: ['golf-game'],
    //     custom: true,
    //     text: '(golgame)',
    //     emoticons: [],
    //     keywords: ['golfgame', 'golfball', 'golfstick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022315-golf-1.png',
    // },
    // {
    //     name: 'Bags',
    //     shortNames: ['bags'],
    //     custom: true,
    //     text: '(bags)',
    //     emoticons: [],
    //     keywords: ['bags', 'golf stick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022315-golf-2.png',
    // },
    // {
    //     name: 'Golferwomen',
    //     shortNames: ['golfer-women'],
    //     custom: true,
    //     text: '(golfer-women)',
    //     emoticons: [],
    //     keywords: ['golfgame', 'golfball', 'golfer','player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022315-golf-4.png',
    // },
    // {
    //     name: 'Golfshot',
    //     shortNames: ['golf-shot'],
    //     custom: true,
    //     text: '(golf-shot)',
    //     emoticons: [],
    //     keywords: ['golfshot', 'golfball', 'golf stick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022315-golf-3.png',
    // },
    // {
    //     name: 'Golfequpment',
    //     shortNames: ['golf-equpment'],
    //     custom: true,
    //     text: '(golf-equpment)',
    //     emoticons: [],
    //     keywords: ['golfequipment', 'golfball', 'golfstick','golftrolly'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022315-golf-5.png',
    // },
    // {
    //     name: 'Golfshowinginmobile',
    //     shortNames: ['golf-showing-in-mobile'],
    //     custom: true,
    //     text: '(golf-showing-in-mobile)',
    //     emoticons: [],
    //     keywords: ['golfshowinginmobile', 'golfball', 'mobile' ,'showing'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022315-golf-6.png',
    // },
    // {
    //     name: 'Golfdestination',
    //     shortNames: ['golf-destination'],
    //     custom: true,
    //     text: '(golf-destination)',
    //     emoticons: [],
    //     keywords: ['golf destination', 'golfball', 'flag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022315-golf.png',
    // },
    
  
    // {
    //     name: 'Golflargercart',
    //     shortNames: ['golf-largercart'],
    //     custom: true,
    //     text: '(golf-largercart)',
    //     emoticons: [],
    //     keywords: ['golfequipment', 'olflargercart', 'cart'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022341-golf-cart.png',
    // },
    // {
    //     name: 'Colourgolfball',
    //     shortNames: ['colour-golf-ball'],
    //     custom: true,
    //     text: ('colour-golf-ball'),
    //     emoticons: [],
    //     keywords: ['golfequipment', 'golfball','colourgolfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022341-golf-ball.png',
    // },
    // {
    //     name: 'Golfcarrybag',
    //     shortNames: ['golf-carry-bag'],
    //     custom: true,
    //     text: '(golf-carry-bag)',
    //     emoticons: [],
    //     keywords: ['golfequipment', 'golfball', 'golfstick','golfcarrybag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022341-golf-bag.png',
    // },
    // {
    //     name: 'Golfclubicon',
    //     shortNames: ['golf-club-icon'],
    //     custom: true,
    //     text: '(golf-club-icon)',
    //     emoticons: [],
    //     keywords: ['golf-club', 'golfball', 'golfclubicon','icon'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022342-golf-clubs-1.png',
    // },
    // {
    //     name: 'Golf-course',
    //     shortNames: ['golf-course'],
    //     custom: true,
    //     text: '(golf-course)',
    //     emoticons: [],
    //     keywords: ['golf-club', 'golfground', 'golfcourse'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022342-golf-course-1.png',
    // },
    // {
    //     name: 'Golfcourseemoji',
    //     shortNames: ['golf-course-emoji'],
    //     custom: true,
    //     text: '(golf-course-emoji)',
    //     emoticons: [],
    //     keywords: ['golfcourseemoji', 'golfball', 'golfcourse'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022368-golf-course.png',
    // },
    // {
    //     name: 'Womengolfer',
    //     shortNames: ['women-golfer'],
    //     custom: true,
    //     text: '(women-golfer)',
    //     emoticons: [],
    //     keywords: ['womengolfer', 'flag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022368-golfer-1.png',
    // },
    // {
    //     name: 'Stickbag',
    //     shortNames: ['stickbag'],
    //     custom: true,
    //     text: '(stickbag)',
    //     emoticons: [],
    //     keywords: ['stickbag', 'bags'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022342-golf-clubs.png',
    // },
    // {
    //     name: 'Womensgolfer',
    //     shortNames: ['womensgolfer'],
    //     custom: true,
    //     text: '(womensgolfer)',
    //     emoticons: [],
    //     keywords: ['womensgolfer', 'flag','golfer','sticks'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022368-golfer-2.png',
    // },
    // {
    //     name: 'Mangolfer',
    //     shortNames: ['mangolfer'],
    //     custom: true,
    //     text: '(mangolfer)',
    //     emoticons: [],
    //     keywords: ['mangolfer', 'golfstick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022368-golfer-3.png',
    // },
    // {
    //     name: 'Golffield',
    //     shortNames: ['golffield'],
    //     custom: true,
    //     text: '(golffield)',
    //     emoticons: [],
    //     keywords: ['golffield', 'flag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022368-golf-field.png',
    // },
    // {
    //     name: 'Golfgameequipment',
    //     shortNames: ['golfgame-equipment'],
    //     custom: true,
    //     text: '(golfgame-equipment)',
    //     emoticons: [],
    //     keywords: ['golfgame-equipment', 'flag','ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022368-golf-game.png',
    // },
    // {
    //     name: 'Golfgloves',
    //     shortNames: ['golf-gloves'],
    //     custom: true,
    //     text: '(golf-gloves)',
    //     emoticons: [],
    //     keywords: ['golfglovesr', 'golfequipment'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022368-golf-gloves-1.png',
    // },
    // {
    //     name: 'Holeinone',
    //     shortNames: ['hole-in-one'],
    //     custom: true,
    //     text: '(hole-in-one)',
    //     emoticons: [],
    //     keywords: ['hole-in-one','golfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022413-hole-in-one-1.png',
    // },
    // {
    //     name: 'Lessons',
    //     shortNames: ['lessons'],
    //     custom: true,
    //     text: '(lessons)',
    //     emoticons: [],
    //     keywords: ['lessons', 'golfer','golfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022413-lessons-1.png',
    // },
    // {
    //     name: 'Reward',
    //     shortNames: ['reward'],
    //     custom: true,
    //     text: '(reward)',
    //     emoticons: [],
    //     keywords: ['reward', 'trophy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022443-reward.png',
    // },
    // {
    //     name: 'Range',
    //     shortNames: ['range'],
    //     custom: true,
    //     text: '(range)',
    //     emoticons: [],
    //     keywords: ['range'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022443-range.png',
    // },
    // {
    //     name: 'Smartwatchandball',
    //     shortNames: ['smartwatch-and-ball'],
    //     custom: true,
    //     text: '(smartwatch-and-ball)',
    //     emoticons: [],
    //     keywords: ['smartwatchandball', 'golfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022444-smart-watch.png',
    // },
    // {
    //     name: 'Projector',
    //     shortNames: ['projector'],
    //     custom: true,
    //     text: '(projector)',
    //     emoticons: [],
    //     keywords: ['projector', 'flag','golfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022443-projector.png',
    // },
    // {
    //     name: 'Shop',
    //     shortNames: ['shop'],
    //     custom: true,
    //     text: '(shop)',
    //     emoticons: [],
    //     keywords: ['shop','golfaequipment'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022443-shop.png',
    // },
    // {
    //     name: 'Smartwatch',
    //     shortNames: ['smartwatch'],
    //     custom: true,
    //     text: '(smartwatch)',
    //     emoticons: [],
    //     keywords: ['smartwatch', 'flag'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022443-smartwatch.png',
    // },
    // {
    //     name: 'Winner',
    //     shortNames: ['winner'],
    //     custom: true,
    //     text: '(winner)',
    //     emoticons: [],
    //     keywords: ['winner', 'golfer','reward'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022492-winner-1.png',
    // },
    // {
    //     name: 'Teambuilding',
    //     shortNames: ['team-building'],
    //     custom: true,
    //     text: '(team-building)',
    //     emoticons: [],
    //     keywords: ['team-building', 'golfstick','golfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022444-team-building-1.png',
    // },
    // {
    //     name: 'Umbrella',
    //     shortNames: ['umbrella'],
    //     custom: true,
    //     text: '(umbrella)',
    //     emoticons: [],
    //     keywords: ['umbrella','golfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022492-umbrella.png',
    // },
    // {
    //     name: 'Player',
    //     shortNames: ['player'],
    //     custom: true,
    //     text: '(player)',
    //     emoticons: [],
    //     keywords: ['player', 'golfstick','golfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022443-player.png',
    // },
    // {
    //     name: 'Minigolfgame',
    //     shortNames: ['mini-golf-game'],
    //     custom: true,
    //     text: '(mini-golf-game)',
    //     emoticons: [],
    //     keywords: ['minigolfgame', 'golfstick','golfball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666022413-mini-golf.png',
    // }
]