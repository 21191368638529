import { RouterModule, Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { AuthGuard } from './_guards/auth.guard';
import { NotAuthGuard } from './_guards/not-auth.guard';
import { CanLoadRouteGuard } from './_guards/can-load-route.guard';
import { NgModule } from '@angular/core';

// var loggedIn: boolean;
// if (localStorage.getItem("token")) {
//     loggedIn = true;
// } else {
//     loggedIn = false;
// }


export const Approutes: Routes = [

    //routes for mobile app start (uncomment these routes)

    // {
    //     path: '',
    //     // canLoad: [CanLoadOuterGuard],
    //     pathMatch: 'full',
    //     loadChildren: () => import('./signupverification/signup-verification.module').then(m => m.SignupVerificationModule),
    //     // data: { type: 'out', status: loggedIn }
    // },
    // {
    //     path: 'login',
    //     // canLoad: [CanLoadOuterGuard],
    //     loadChildren: () => import('./signupverification/signup-verification.module').then(m => m.SignupVerificationModule)
    //     // data: { type: 'out', status: loggedIn }
    // },
    // {
    //     path: 'home',
    //     // canLoad: [CanLoadOuterGuard],
    //     loadChildren: () => import('./landingpage/landing-page.module').then(m => m.LandingPageModule)
    //     // data: { type: 'out', status: loggedIn }
    // },

    //routes for mobile app end

    // comment these routes for mobile app start
    {
        path: '',
        // canLoad: [CanLoadOuterGuard],
        pathMatch: 'full',
        loadChildren: () => import('./landingpage/landing-page.module').then(m => m.LandingPageModule),
        // data: { type: 'out', status: loggedIn }
    },
    {
        path: 'login',
        // canLoad: [CanLoadOuterGuard],
        loadChildren: () => import('./signupverification/signup-verification.module').then(m => m.SignupVerificationModule)
        // data: { type: 'out', status: loggedIn }
    },
    // comment this routes for mobile app end


    {
        path: 'signupprocess',
        loadChildren: () => import('./signupprocess/signup-process.module').then(m => m.SignupProcessModule),
        // data: { type: 'out', status: loggedIn }
    },
    {
        path: 'athletesignup',
        loadChildren: () => import('./select-athlete-profile/select-athlete-profile.module').then(m => m.SelectAthleteProfileModule)
    },
    {
        path: 'about',
        // canLoad: [CanLoadOuterGuard],
        loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule)
    },
    {
        path: 'news',
        loadChildren: () => import('./join-news-component/join-news.module').then(m => m.JoinNewsModule),
        // data: { type: 'both', status: loggedIn }
    },
    {
        path: 'news/:lable',
        loadChildren: () => import('./join-news-details/join-news-details.module').then(m => m.JoinNewsDetailsModule),
    },
    {
        path: 'blog',
        loadChildren: () => import('./join-blogs/join-blogs.module').then(m => m.JoinBlogsModule),
        // data: { type: 'both', status: loggedIn }
    },
    {
        path: 'blog/:lable',
        loadChildren: () => import('./join-blog-detail/join-blog-detail.module').then(m => m.JoinBlogDetailModule)
    },
    {
        path: 'forgotPassword',
        // canLoad: [CanLoadOuterGuard],
        loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
    },
    {
        path: 'userUpdatePassword',
        // canLoad: [CanLoadOuterGuard],
        loadChildren: () => import('./user-update-password/user-update-password.module').then(m => m.UserUpdatePasswordModule)
    },
    {
        path: 'postDetails',
        canActivate: [NotAuthGuard],
        loadChildren: () => import('./post-details-open/post-details-open.module').then(m => m.PostDetailsOpenModule)
    },

    {
        path: 'login/:id',
        redirectTo: '/:id',
        pathMatch: 'full'
    },

    {
        path: 'newssitemap',
        // canLoad: [CanLoadOuterGuard],
        loadChildren: () => import('./newssitemap/news-site-map.module').then(m => m.NewsSiteMapModule)
    },
    {
        path: 'privacy-policy',
        // canLoad: [CanLoadOuterGuard],
        loadChildren: () => import('./privacy-policies/privacy-policies.module').then(m => m.PrivacyPoliciesModule)
    },
    {
        path: 'terms-of-use',
        // canLoad: [CanLoadOuterGuard],
        loadChildren: () => import('./terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule)
    },
    {
        path: 'shortcut-app',
        // canLoad: [CanLoadRouteGuard],
        loadChildren: () => import('./shortcut-app/shortcut-app.module').then(m => m.ShortcutAppModule)
    },
    {
        path: 'userSerch/:id',
        // canLoad: [CanLoadOuterGuard],
        loadChildren: () => import('./login-search-user/login-search-user.module').then(m => m.LoginSearchUserModule)
    },
    {
        path: 'admin1987',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: 'admin1987/login',
        loadChildren: () => import('./admin/login/admin-login.module').then(m => m.AdminLoginModule)
    },

    {
        path: 'teamschedulematch',
        redirectTo: 'matches/scheduled/all'
    },
    {
        path: 'matches/:id/:id1',
        pathMatch: 'full',
        loadChildren: () => import('./join-matches/join-matches.module').then(m => m.JoinMatchesModule)
    },
    {
        path: 'matches/:id/:id1/:id2',
        pathMatch: 'full',
        loadChildren: () => import('./join-match-detail/join-match-detail.module').then(m => m.JoinMatchDetailModule)
    },

    //loggedin routes.
    {
        path: '',
        component: FullComponent,
        canLoad: [CanLoadRouteGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'index'
            },
            {
                path: 'igtAssistant',
                //canLoad: [CanLoadRouteGuard],
                loadChildren: () => import('./chat-gpt/chat-gpt.module').then(m => m.ChatGPTModule),
                // data: { type: 'in', status: loggedIn }
            },
            {
                path: 'index',
                //canLoad: [CanLoadRouteGuard],
                loadChildren: () => import('./starter/starter.module').then(m => m.StarterModule),
                // data: { type: 'in', status: loggedIn }
            },
            {
                path: 'postViewDetail',
                canActivate: [AuthGuard],
                loadChildren: () => import('./post-view-detail/post-view-detail.module').then(m => m.PostViewDetailModule)
            },
            {
                path: 'contact',
                //canLoad: [CanLoadRouteGuard],
                loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule)
            },
            {
                path: 'myProfile',
                //canLoad: [CanLoadRouteGuard],
                loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfileModule),
                // data: { type: 'in', status: loggedIn }
            },
            {
                path: 'editProfile',
                //canLoad: [CanLoadRouteGuard],
                loadChildren: () => import('./my-profile/edit-profile/edit-profile.module').then(m => m.EditProfileModule),
                // data: { type: 'in', status: loggedIn }
            },
            {
                path: 'createAlbum',
                //canLoad: [CanLoadRouteGuard],
                loadChildren: () => import('./my-profile/create-album/create-album.module').then(m => m.CreateAlbumModule)
            },
            {
                path: 'albumViewDetail',
                //canLoad: [CanLoadRouteGuard],
                loadChildren: () => import('./my-profile/album-details/album-details.module').then(m => m.AlbumDetailsModule)
            },
            {
                path: 'accountSetting',
                //canLoad: [CanLoadRouteGuard],
                loadChildren: () => import('./account-setting/account-setting.module').then(m => m.AccountSettingModule)
            },
            {
                path: 'accountSetting/:id',
                //canLoad: [CanLoadRouteGuard],
                loadChildren: () => import('./account-setting/account-setting.module').then(m => m.AccountSettingModule)
            },
            {
                path: 'notification',
                //canLoad: [CanLoadRouteGuard],
                loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule),
                // data: { type: 'in', status: loggedIn }
            },
            {
                path: 'followUserProfile',
                canActivate: [AuthGuard],
                loadChildren: () => import('./follow-user-profile/follow-user-profile.module').then(m => m.FollowUserProfileModule),
                // data: { type: 'in', status: loggedIn }
            },
            {
                path: 'followuserprofile/:id1',
                canActivate: [AuthGuard],
                loadChildren: () => import('./follow-user-profile/follow-user-profile.module').then(m => m.FollowUserProfileModule)
            },
            {
                path: 'followuserprofile/:id1/:id2',
                canActivate: [AuthGuard],
                loadChildren: () => import('./follow-user-profile/follow-user-profile.module').then(m => m.FollowUserProfileModule)
            },
            {
                path: 'followuserprofile/:id1/:id2/:id3',
                canActivate: [AuthGuard],
                loadChildren: () => import('./follow-user-profile/follow-user-profile.module').then(m => m.FollowUserProfileModule)
            },
            {
                path: 'followuserprofile/:id1/:id2/:id3/:id4',
                canActivate: [AuthGuard],
                loadChildren: () => import('./follow-user-profile/follow-user-profile.module').then(m => m.FollowUserProfileModule)
            },
            {
                path: 'followuserprofile/:id1/:id2/:id3/:id4/:id5',
                canActivate: [AuthGuard],
                loadChildren: () => import('./follow-user-profile/follow-user-profile.module').then(m => m.FollowUserProfileModule)
            },
            {
                path: 'visitAlbumViewDetail',
                //canLoad: [CanLoadRouteGuard],
                loadChildren: () => import('./follow-user-profile/visit-album-details/visit-album-details.module').then(m => m.VisitAlbumDetailsModule)
            },
            {
                path: 'userSearch/:id',
                //canLoad: [CanLoadRouteGuard],
                loadChildren: () => import('./search-user-connection/search-user-connection.module').then(m => m.SearchUserConnectionModule)
            },
            {
                path: 'followGroup',
                //canLoad: [CanLoadRouteGuard],
                loadChildren: () => import('./follow-group/follow-group.module').then(m => m.FollowGroupModule)
            },
            {
                path: 'messageChat',
                //canLoad: [CanLoadRouteGuard],
                loadChildren: () => import('./message-chat/message-chat.module').then(m => m.MessageChatModule),
                // data: { type: 'in', status: loggedIn }
            },
            {
                path: 'allSports',
                //canLoad: [CanLoadRouteGuard],
                loadChildren: () => import('./all-sports/all-sports.module').then(m => m.AllSportsModule),
                // data: { type: 'in', status: loggedIn }
            },
            // {
            //     path: 'teamschedulematch',
            //     // canLoad: [CanLoadRouteGuard],
            //     loadChildren: () => import('./teamschedulematch/team-scheduled-match.module').then(m => m.TeamScheduledMatchModule),
            //     // data: { type: 'in', status: loggedIn }
            // },
            {
                path: 'eventChat',
                //canLoad: [CanLoadRouteGuard],
                loadChildren: () => import('./live-match-chat/live-match-chat.module').then(m => m.LiveMatchChatModule)
            },

            {
                path: ':id1/:id2',
                //canLoad: [CanLoadRouteGuard],
                loadChildren: () => import('./follow-user-profile/follow-user-profile.module').then(m => m.FollowUserProfileModule)
            },
        ]
    },

    {
        path: ':id',
        loadChildren: () => import('./login-v1/login-v1.module').then(m => m.LoginV1Module)
    },
    {
        path: 'userProfile',
        loadChildren: () => import('./login-v1/login-v1.module').then(m => m.LoginV1Module)
    },

    {
        path: '**',
        redirectTo: '/login',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(Approutes, {
            useHash: true,
            scrollPositionRestoration: 'enabled',
            initialNavigation: 'enabled'
            // enableTracing: true
            // preloadingStrategy: CustomPreloadingStrategy,
            // preloadingStrategy: PreloadAllModules
        }),
    ],
    // providers: [CustomPreloadingStrategy],
    exports: [RouterModule]
})
export class AppRoutingModule { }

