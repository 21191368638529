import { Pipe, PipeTransform, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { emojis, EmojiService, CompressedEmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { customEmojisBasketball } from '../../assets/images/emoji/custom/ncaa_basketball';
import { customEmojisFootball } from '../../assets/images/emoji/custom/ncaa_football';
import { customEmojisGolf } from '../../assets/images/emoji/custom/golf';
import { customEmojisNFL } from '../../assets/images/emoji/custom/nfl';
import { customEmojisMLB } from '../../assets/images/emoji/custom/mlb';
import { customEmojisMLS } from '../../assets/images/emoji/custom/mls';
import { customEmojisNBA } from '../../assets/images/emoji/custom/nba';
import { customEmojisTennis } from '../../assets/images/emoji/custom/tennis';
import { customEmojisNHL } from '../../assets/images/emoji/custom/nhl';
import { customEmojisAll } from '../../assets/images/emoji/custom/all-custom-emoji';
/**
 * Angular2+ pipe to transform HTML containing unicode emojis to
 * HTML containing emoji image elements
 *
 * Sample usage (angular template code):
 * `<div [innerHTML]="'<a title=\'Ang🧛‍♀️ular\'>Hello</a> 👌 Ang🧛‍♀️ular 👌!' | replaceEmojis"></div>`
 *
 * By default the pipe will use the apple sheet
 * (hosted at 'https://unpkg.com/emoji-datasource-apple@4.0.4/img/apple/sheets-256/32.png')
 * - for available sheets see https://missive.github.io/emoji-mart/
 *
 * Sample usage with all available parameters (all optional!):
 * `<div [innerHTML]="html | replaceEmojis:set:size:sheetSize:backgroundImageFn"></div>`
 *
 * set: emoji mart set to use for image representation
 * size: size of an emoji image in px
 * sheetSize size of each original image - will be resized to size
 * backgroundImageFn function to retrieve bg URL or path (see docs at https://github.com/TypeCtrl/ngx-emoji-mart)
 *
 * StackBlitz sample: https://stackblitz.com/edit/pt-emoji-yrpfgo
 */
@Pipe({
    name: 'replaceEmojis'
})
export class ReplaceEmojisPipe implements PipeTransform {
    public static readonly DEFAULT_SHEET = 'apple';
    public static readonly DEFAULT_IMAGE_SIZE = 24;
    public static readonly DEFAULT_SHEET_SIZE = 64;
    private static cachedEmojiRegex: RegExp;

    customLeagueEmojis: any;

    /**
     * Utility method to get all text node descendants of a DOM node
     * @param node the DOM node to get text nodes for
     */
    public static getAllTextNodes(node: Node) {
        let all = [];
        for (node = node.firstChild; node; node = node.nextSibling) {
            if (node.nodeType === Node.TEXT_NODE) {
                all.push(node);
            } else {
                all.push(...ReplaceEmojisPipe.getAllTextNodes(node));
            }
        }
        return all;
    }

    constructor(@Inject(DOCUMENT)
    private document: Document,
        private sanitizer: DomSanitizer,
        private emojiService: EmojiService,
        private renderer: Renderer2,
    ) { }

    /**
     * Pipe transform entry point
     * @param html HTML to parse
     * @param set emoji mart set to use for image representation
     * @param size size of an emoji image in px
     * @param sheetSize sheetSize (size of each original image - will be resized to size)
     * @param backgroundImageFn function to retrieve bg URL or path (see docs at https://github.com/TypeCtrl/ngx-emoji-mart)
     * @param sheetId type of custom emoji sheet according to league
     */
    public transform(
        html: string,
        set: '' | 'apple' | 'facebook' | 'twitter' | 'emojione' | 'google' | 'messenger' = ReplaceEmojisPipe.DEFAULT_SHEET,
        size = ReplaceEmojisPipe.DEFAULT_IMAGE_SIZE,
        sheetSize: 16 | 20 | 32 | 64 = ReplaceEmojisPipe.DEFAULT_SHEET_SIZE,
        sheetId: any = false,
        classCheck = false,
        backgroundImageFn?: (set: string, sheetSize: number) => string,
        navCheck = false) {

        return this.emojisToImages(
            html,
            set,
            size,
            sheetSize,
            sheetId,
            classCheck,
            backgroundImageFn,
            navCheck
        );
    }



    /**
     * Replaces all unicode emojis available through emoji-mart with a span displaying
     * the image representation of that emoji
     * @param html HTML to parse
     * @param set emoji mart set to use for image representation
     * @param size size of an emoji image in px
     * @param sheetSize sheetSize (size of each original image - will be resized to size)
     * @param sheetId type of custom emoji sheet according to league
     * @param backgroundImageFn function to retrieve bg URL or path (see docs at https://github.com/TypeCtrl/ngx-emoji-mart)
     */

    public emojisToImages(
        html: string,
        set: '' | 'apple' | 'facebook' | 'twitter' | 'emojione' | 'google' | 'messenger',
        size: number,
        sheetSize: 16 | 20 | 32 | 64,
        sheetId: any = false,
        classCheck = false,
        backgroundImageFn?: (set: string, sheetSize: number) => string,
        navCheck = false
    ) {
        // Ensure most html entities are parsed to unicode:

        let div = <Element>this.document.createElement('div');


        function mainFunction(callback1, callback2, callback3) {

            let first_parameter = "ONE"
            callback1(first_parameter);
        }

        let $this = this;
        function one(a) {
            if (sheetId == 1) {
                $this.customLeagueEmojis = customEmojisNFL;
            }
            else if (sheetId == 2) {
                $this.customLeagueEmojis = customEmojisMLB;
            }
            else if (sheetId == 3) {
                $this.customLeagueEmojis = customEmojisNHL;
            }
            else if (sheetId == 4) {
                $this.customLeagueEmojis = customEmojisNBA;
            }
            else if (sheetId == 26) {
                $this.customLeagueEmojis = customEmojisFootball;
            }
            else if (sheetId == 27) {
                $this.customLeagueEmojis = customEmojisBasketball;
            }
            else if (sheetId == 28) {
                $this.customLeagueEmojis = customEmojisMLS;
            }
            else if (sheetId == 29) {
                $this.customLeagueEmojis = customEmojisTennis;
            }
            else if (sheetId == 30) {
                $this.customLeagueEmojis = customEmojisGolf;
            }
            else if (sheetId == 100) {
                $this.customLeagueEmojis = customEmojisAll;
            }


            if (html && set && size && sheetSize) {
                $this.findEmojiDataSpecial(html, set, size, sheetSize, backgroundImageFn, function (res) {
                    two(res);
                });
            }
        }

        function two(textContent) {
            div.innerHTML = textContent;

            let textNodes = ReplaceEmojisPipe.getAllTextNodes(div);

            for (let currentItem of textNodes) {

                let matches: RegExpExecArray;
                while ((matches = $this.emojiRegex.exec(currentItem.textContent)) !== null) {
                    let unicodeEmoji = currentItem.textContent.substr(matches.index, matches[0].length);
                    let hexCodeSegments = [];
                    let jj = 0;
                    while (jj < unicodeEmoji.length) {
                        let segment = unicodeEmoji.codePointAt(jj).toString(16).toUpperCase();
                        hexCodeSegments.push(segment);

                        jj += Math.ceil(segment.length / 4);
                    }
                    let hexCode = hexCodeSegments.join('-');
                    let matchingData = $this.findEmojiData(hexCode);



                    if (matchingData) {
                        let span = document.createElement('span');
                        if (/Android/i.test(navigator.userAgent)) {
                            span.setAttribute('contenteditable', 'false');
                            span.setAttribute('selectable', 'true');
                            span.setAttribute('inline', 'true');
                            span.setAttribute('group', 'inline');
                            span.setAttribute('dragable', 'true');
                        } else {
                            span.setAttribute('contenteditable', 'false');
                        }
                        span.className = 'emoji-pipe-image';
                        span.textContent = matches[0];

                        let styles = $this.emojiService.emojiSpriteStyles(
                            matchingData.sheet,
                            set,
                            size,
                            sheetSize,
                            backgroundImageFn
                        );
                        Object.assign(span.style, styles, {
                            overflow: 'hidden',
                            textIndent: '-1000px',
                            position: 'relative',
                            top: '7px',
                            display: 'inline-block',
                        });

                        let text = currentItem.textContent;
                        currentItem.textContent = text.substr(0, matches.index);

                    
                        currentItem.parentNode.insertBefore(span, currentItem.nextSibling);
                        currentItem = $this.document.createTextNode(text.substr(matches.index + matches[0].length));
                        span.parentNode.insertBefore(currentItem, span.nextSibling);

                        $this.emojiRegex.lastIndex = 0;
                    }
                }

            }
            let third_parameter = "THREE";
            three(third_parameter);
        }

        function three(c) {
            if ($this.customLeagueEmojis) {
                for (let customEmoji of $this.customLeagueEmojis) {
                    if (customEmoji && customEmoji.imageUrl && customEmoji.text && div && div.innerHTML) {
                        let span = <HTMLElement>$this.renderer.createElement('span');
                        Object.assign(span.style, {
                            overflow: 'hidden',
                            textIndent: '-1000px',
                            position: 'relative',
                            top: '7px',
                            display: 'inline-block',
                            height: '24px',
                            width: '24px',
                            backgroundImage: 'url(' + customEmoji.imageUrl + ')',
                            backgroundSize: 'cover'
                        });
                        span.className = 'emoji-pipe-image';
                        span.textContent = customEmoji.text;
                        if (/Android/i.test(navigator.userAgent)) {
                            span.setAttribute('contenteditable', 'false');
                            span.setAttribute('selectable', 'true');
                            span.setAttribute('inline', 'true');
                            span.setAttribute('group', 'inline');
                            span.setAttribute('dragable', 'true');
                        } else {
                            span.setAttribute('contenteditable', 'false');
                        }
                        let findReplaceWith = '' + span.outerHTML + '';
                        
                        const searchTxt = (customEmoji.text).replace(/\s/g, '');
                        const regEscape = v => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/ig, '\\$&');
                        div.innerHTML = (div.innerHTML).split(new RegExp(regEscape(searchTxt), "ig")).join(findReplaceWith);
                    }
                }
            }


        }


        mainFunction(one, two, three);
        let resCheckSpan = (((div.innerHTML).replace(/(?:<span[^>]*)(?:(?:\/>)|(?:>.*?<\/span>))/ig, '')).replace(/ /ig, '')).replace(/&nbsp;/ig, '');
        resCheckSpan = (resCheckSpan).replace(/(?:<span[^>]*)(?:(?:\/>)|(?:>.*?))/ig, '');
        resCheckSpan = (resCheckSpan).replace(/(<\/span>)/ig, '');
        if (classCheck) {
            if (resCheckSpan.length == 0 || resCheckSpan == null) {
               let htmlContent;
                if (navCheck == true) {
                     htmlContent = (div.innerHTML).split('24px').join('24px !important');
                } else {
                    htmlContent = (div.innerHTML).split('24px').join('34px !important');
                }
                return { html: htmlContent, classShow: true };
            } else {
                return { html: div.innerHTML, classShow: false };
            }
        } else {
            return div.innerHTML;
        }

    }

    /**
     * Regex matching all unicode emojis contained in emoji-mart
     */
    private get emojiRegex(): RegExp {
        if (ReplaceEmojisPipe.cachedEmojiRegex) {
            return ReplaceEmojisPipe.cachedEmojiRegex;
        }

        let characterRegexStrings: string[] = [];

        for (let emoji of emojis) {
            characterRegexStrings.push(this.emojiService.unifiedToNative(emoji.unified).replace(/[.*+?^${}()|[\]\\]/ig, '\\$&'));

            if (emoji.skinVariations) {
                for (let skinVariation of emoji.skinVariations) {

                    characterRegexStrings.push(this.emojiService.unifiedToNative(skinVariation.unified).replace(/[.*+?^${}()|[\]\\]/ig, '\\$&'));
                }
            }
        }

        characterRegexStrings = characterRegexStrings.sort((a, b) => {
            if (a.length > b.length) {
                return -1;
            }

            if (b.length > a.length) {
                return 1;
            }

            return 0;
        });

        let strings = characterRegexStrings;
        let reString = '(' + strings.join('|') + ')';
        ReplaceEmojisPipe.cachedEmojiRegex = new RegExp(reString, 'gu');

        return ReplaceEmojisPipe.cachedEmojiRegex;
    }

    /**
     * Find raw emoji-mart data for a specific emoji hex code
     * @param hexCode String representation of the emoji hex code
     */
    private findEmojiData(hexCode: string): CompressedEmojiData {

        for (let emojiData of emojis) {

            if (emojiData.unified === hexCode) {
                return emojiData;
            }

            if (emojiData.skinVariations) {
                for (let skinVariation of emojiData.skinVariations) {
                    if (skinVariation.unified === hexCode) {
                        let skinData = Object.assign({}, emojiData);
                        skinData.sheet = skinVariation.sheet;
                        return skinData;
                    }
                }
            }
        }

        return null;
    }


    // /**
    //  * Find raw emoji-mart data for a specific emoji hex code
    //  * @param chatMsg String representation of the emoji hex code
    //  */

    private findEmojiDataSpecial(chatMsg, set, size, sheetSize, backgroundImageFn, callback) {
        let htmlContent = chatMsg;
        let count: number = 0;

        for (let emojiArr of emojis) {

            if (emojiArr) {
                if (emojiArr.text) {
                    let tempText = (emojiArr.text).replace(/[.*+?^${}()|[\]\<\>\\]/ig, '\\$&');
                    let re = new RegExp('(' + tempText + ')(?!(.(?!<span))*<\/span>)', "ig");
                    let span,styles1;
                    if (emojiArr.text && (re).test(htmlContent)) {
                        span = <HTMLElement>this.renderer.createElement('span');
                        styles1 = this.emojiService.emojiSpriteStyles(
                            emojiArr.sheet,
                            set,
                            size,
                            sheetSize,
                            backgroundImageFn
                        );
                        Object.assign(span.style, styles1, {
                            overflow: 'hidden',
                            textIndent: '-1000px',
                            position: 'relative',
                            top: '7px',
                            display: 'inline-block',
                        });
                        span.className = 'emoji-pipe-image';
                        span.textContent = emojiArr.text;
                        if (/Android/i.test(navigator.userAgent)) {
                            span.setAttribute('contenteditable', 'false');
                            span.setAttribute('selectable', 'true');
                            span.setAttribute('inline', 'true');
                            span.setAttribute('group', 'inline');
                            span.setAttribute('dragable', 'true');
                        } else {
                            span.setAttribute('contenteditable', 'false');
                        }
                        let findReplaceWith1 = '' + span.outerHTML + '';
                        htmlContent = (htmlContent).replace(re, findReplaceWith1);
                    }
                }

                if (emojiArr.shortName) {
                    let temp_short = "(" + emojiArr.shortName + ")";
                    let tempHTML = htmlContent.toLowerCase();
                    if (temp_short && (tempHTML).includes(temp_short)) {
                     
                        let span = <HTMLElement>this.renderer.createElement('span');
                        let styles1 = this.emojiService.emojiSpriteStyles(
                            emojiArr.sheet,
                            set,
                            size,
                            sheetSize,
                            backgroundImageFn
                        );
                        Object.assign(span.style, styles1, {
                            overflow: 'hidden',
                            textIndent: '-1000px',
                            position: 'relative',
                            top: '7px',
                            display: 'inline-block',
                        });
                        span.className = 'emoji-pipe-image';
                        span.textContent = temp_short;
                        if (/Android/i.test(navigator.userAgent)) {
                            span.setAttribute('contenteditable', 'false');
                            span.setAttribute('selectable', 'true');
                            span.setAttribute('inline', 'true');
                            span.setAttribute('group', 'inline');
                            span.setAttribute('dragable', 'true');
                        } else {
                            span.setAttribute('contenteditable', 'false');
                        }
                        let findReplaceWith1 = '' + span.outerHTML + '';
                        const searchTxt = (temp_short).replace(/\s/g, '');
                        const regEscape = v => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/ig, '\\$&');
                        htmlContent = (htmlContent).replace(new RegExp(regEscape(searchTxt), "ig"), findReplaceWith1);
                    }
                }

                if (emojiArr.emoticons) {
                    for (let emojiSortcutText of emojiArr.emoticons) {
                        let tempText1 = (emojiSortcutText).replace(/[.*+?^${}()|[\]\<\>\\]/ig, '\\$&');
                        let re1 = new RegExp('(' + tempText1 + ')(?!(.(?!<span))*<\/span>)', "ig");
                        if (emojiSortcutText && (re1).test(htmlContent)) {
                            let span = <HTMLElement>this.renderer.createElement('span');
                            let styles1 = this.emojiService.emojiSpriteStyles(
                                emojiArr.sheet,
                                set,
                                size,
                                sheetSize,
                                backgroundImageFn
                            );
                            Object.assign(span.style, styles1, {
                                overflow: 'hidden',
                                textIndent: '-1000px',
                                position: 'relative',
                                top: '7px',
                                display: 'inline-block',
                            });
                            span.className = 'emoji-pipe-image';
                            span.textContent = emojiSortcutText;
                            if (/Android/i.test(navigator.userAgent)) {
                                span.setAttribute('contenteditable', 'false');
                                span.setAttribute('selectable', 'true');
                                span.setAttribute('inline', 'true');
                                span.setAttribute('group', 'inline');
                                span.setAttribute('dragable', 'true');
                            } else {
                                span.setAttribute('contenteditable', 'false');
                            }
                            let findReplaceWithArr = '' + span.outerHTML + '';
                            htmlContent = (htmlContent).replace(re1, findReplaceWithArr);
                        }
                    }
                }

            }
            count = count + 1;
        }
        if (count == emojis.length) {
            return callback(htmlContent);
        }
    }

}
